const OTP_EXPIRE_VEHICLEBRANDS = 7884008640;
const OTP_EXPIRE_VEHICLEBRANDYEARS = 2628002880;
const OTP_EXPIRE_VEHICLEMODELS = 1209600000;
export const OTP_EXPIRE_DICT = Object.freeze({
  vehicleBrands: OTP_EXPIRE_VEHICLEBRANDS,
  vehicleBrandYears: OTP_EXPIRE_VEHICLEBRANDYEARS,
  vehicleModels: OTP_EXPIRE_VEHICLEMODELS,
});

// This variable is not being used, 
// but is keeped in case that original structure must be checked
export const legacyCache = {
  vehicleBrandYears: {
    'ES-MX_/browse/cars_/browse/cars/acura': [
      { code: '/browse/cars/acura/2021', desc: '2021' },
      { code: '/browse/cars/acura/2020', desc: '2020' },
      { code: '/browse/cars/acura/2019', desc: '2019' },
      { code: '/browse/cars/acura/2018', desc: '2018' },
      { code: '/browse/cars/acura/2017', desc: '2017' },
      { code: '/browse/cars/acura/2016', desc: '2016' },
      { code: '/browse/cars/acura/2015', desc: '2015' },
      { code: '/browse/cars/acura/2014', desc: '2014' },
      { code: '/browse/cars/acura/2013', desc: '2013' },
      { code: '/browse/cars/acura/2012', desc: '2012' },
      { code: '/browse/cars/acura/2011', desc: '2011' },
      { code: '/browse/cars/acura/2010', desc: '2010' },
      { code: '/browse/cars/acura/2009', desc: '2009' },
      { code: '/browse/cars/acura/2008', desc: '2008' },
      { code: '/browse/cars/acura/2007', desc: '2007' },
      { code: '/browse/cars/acura/2006', desc: '2006' },
      { code: '/browse/cars/acura/2005', desc: '2005' },
      { code: '/browse/cars/acura/2004', desc: '2004' },
      { code: '/browse/cars/acura/2003', desc: '2003' },
      { code: '/browse/cars/acura/2002', desc: '2002' },
      { code: '/browse/cars/acura/2001', desc: '2001' },
      { code: '/browse/cars/acura/2000', desc: '2000' },
      { code: '/browse/cars/acura/1999', desc: '1999' },
      { code: '/browse/cars/acura/1998', desc: '1998' },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat': [
      { code: '/browse/vans/fiat/2019', desc: '2019' },
      { code: '/browse/vans/fiat/2018', desc: '2018' },
      { code: '/browse/vans/fiat/2017', desc: '2017' },
      { code: '/browse/vans/fiat/2016', desc: '2016' },
      { code: '/browse/vans/fiat/2015', desc: '2015' },
      { code: '/browse/vans/fiat/2014', desc: '2014' },
      { code: '/browse/vans/fiat/2013', desc: '2013' },
      { code: '/browse/vans/fiat/2012', desc: '2012' },
      { code: '/browse/vans/fiat/2011', desc: '2011' },
      { code: '/browse/vans/fiat/2010', desc: '2010' },
      { code: '/browse/vans/fiat/2009', desc: '2009' },
      { code: '/browse/vans/fiat/2008', desc: '2008' },
      { code: '/browse/vans/fiat/2007', desc: '2007' },
      { code: '/browse/vans/fiat/2006', desc: '2006' },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai': [
      { code: '/browse/vans/hyundai/1992', desc: '1992' },
      { code: '/browse/vans/hyundai/1991', desc: '1991' },
      { code: '/browse/vans/hyundai/1990', desc: '1990' },
      { code: '/browse/vans/hyundai/1989', desc: '1989' },
      { code: '/browse/vans/hyundai/1988', desc: '1988' },
      { code: '/browse/vans/hyundai/1987', desc: '1987' },
      { code: '/browse/vans/hyundai/1986', desc: '1986' },
      { code: '/browse/vans/hyundai/1985', desc: '1985' },
      { code: '/browse/vans/hyundai/1984', desc: '1984' },
      { code: '/browse/vans/hyundai/1983', desc: '1983' },
      { code: '/browse/vans/hyundai/1982', desc: '1982' },
    ],
    'ES-MX_MOTO_BENELLI-MOTO': [
      { code: 'BENELLI-MOTO/2020', desc: '2020' },
      { code: 'BENELLI-MOTO/2019', desc: '2019' },
      { code: 'BENELLI-MOTO/2018', desc: '2018' },
      { code: 'BENELLI-MOTO/2017', desc: '2017' },
      { code: 'BENELLI-MOTO/2016', desc: '2016' },
      { code: 'BENELLI-MOTO/2015', desc: '2015' },
      { code: 'BENELLI-MOTO/2014', desc: '2014' },
      { code: 'BENELLI-MOTO/2013', desc: '2013' },
      { code: 'BENELLI-MOTO/2012', desc: '2012' },
      { code: 'BENELLI-MOTO/2011', desc: '2011' },
      { code: 'BENELLI-MOTO/2010', desc: '2010' },
      { code: 'BENELLI-MOTO/2009', desc: '2009' },
      { code: 'BENELLI-MOTO/2008', desc: '2008' },
      { code: 'BENELLI-MOTO/2007', desc: '2007' },
      { code: 'BENELLI-MOTO/2006', desc: '2006' },
      { code: 'BENELLI-MOTO/2005', desc: '2005' },
      { code: 'BENELLI-MOTO/2004', desc: '2004' },
      { code: 'BENELLI-MOTO/2003', desc: '2003' },
      { code: 'BENELLI-MOTO/2002', desc: '2002' },
      { code: 'BENELLI-MOTO/2001', desc: '2001' },
      { code: 'BENELLI-MOTO/2000', desc: '2000' },
      { code: 'BENELLI-MOTO/1999', desc: '1999' },
      { code: 'BENELLI-MOTO/1998', desc: '1998' },
      { code: 'BENELLI-MOTO/1997', desc: '1997' },
      { code: 'BENELLI-MOTO/1996', desc: '1996' },
      { code: 'BENELLI-MOTO/1995', desc: '1995' },
      { code: 'BENELLI-MOTO/1994', desc: '1994' },
      { code: 'BENELLI-MOTO/1993', desc: '1993' },
      { code: 'BENELLI-MOTO/1992', desc: '1992' },
      { code: 'BENELLI-MOTO/1991', desc: '1991' },
      { code: 'BENELLI-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo': [
      { code: '/browse/cars/alfa_romeo/2021', desc: '2021' },
      { code: '/browse/cars/alfa_romeo/2020', desc: '2020' },
      { code: '/browse/cars/alfa_romeo/2019', desc: '2019' },
      { code: '/browse/cars/alfa_romeo/2018', desc: '2018' },
      { code: '/browse/cars/alfa_romeo/2017', desc: '2017' },
      { code: '/browse/cars/alfa_romeo/2016', desc: '2016' },
      { code: '/browse/cars/alfa_romeo/2015', desc: '2015' },
      { code: '/browse/cars/alfa_romeo/2014', desc: '2014' },
      { code: '/browse/cars/alfa_romeo/2013', desc: '2013' },
      { code: '/browse/cars/alfa_romeo/2012', desc: '2012' },
      { code: '/browse/cars/alfa_romeo/2011', desc: '2011' },
      { code: '/browse/cars/alfa_romeo/2010', desc: '2010' },
      { code: '/browse/cars/alfa_romeo/2009', desc: '2009' },
      { code: '/browse/cars/alfa_romeo/2008', desc: '2008' },
      { code: '/browse/cars/alfa_romeo/2007', desc: '2007' },
      { code: '/browse/cars/alfa_romeo/2006', desc: '2006' },
      { code: '/browse/cars/alfa_romeo/2005', desc: '2005' },
      { code: '/browse/cars/alfa_romeo/2004', desc: '2004' },
      { code: '/browse/cars/alfa_romeo/2003', desc: '2003' },
      { code: '/browse/cars/alfa_romeo/2002', desc: '2002' },
      { code: '/browse/cars/alfa_romeo/2001', desc: '2001' },
      { code: '/browse/cars/alfa_romeo/2000', desc: '2000' },
      { code: '/browse/cars/alfa_romeo/1999', desc: '1999' },
      { code: '/browse/cars/alfa_romeo/1998', desc: '1998' },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi': [
      { code: '/browse/cars/audi/2022', desc: '2022' },
      { code: '/browse/cars/audi/2021', desc: '2021' },
      { code: '/browse/cars/audi/2020', desc: '2020' },
      { code: '/browse/cars/audi/2019', desc: '2019' },
      { code: '/browse/cars/audi/2018', desc: '2018' },
      { code: '/browse/cars/audi/2017', desc: '2017' },
      { code: '/browse/cars/audi/2016', desc: '2016' },
      { code: '/browse/cars/audi/2015', desc: '2015' },
      { code: '/browse/cars/audi/2014', desc: '2014' },
      { code: '/browse/cars/audi/2013', desc: '2013' },
      { code: '/browse/cars/audi/2012', desc: '2012' },
      { code: '/browse/cars/audi/2011', desc: '2011' },
      { code: '/browse/cars/audi/2010', desc: '2010' },
      { code: '/browse/cars/audi/2009', desc: '2009' },
      { code: '/browse/cars/audi/2008', desc: '2008' },
      { code: '/browse/cars/audi/2007', desc: '2007' },
      { code: '/browse/cars/audi/2006', desc: '2006' },
      { code: '/browse/cars/audi/2005', desc: '2005' },
      { code: '/browse/cars/audi/2004', desc: '2004' },
      { code: '/browse/cars/audi/2003', desc: '2003' },
      { code: '/browse/cars/audi/2002', desc: '2002' },
      { code: '/browse/cars/audi/2001', desc: '2001' },
      { code: '/browse/cars/audi/2000', desc: '2000' },
      { code: '/browse/cars/audi/1999', desc: '1999' },
      { code: '/browse/cars/audi/1998', desc: '1998' },
      { code: '/browse/cars/audi/1997', desc: '1997' },
      { code: '/browse/cars/audi/1996', desc: '1996' },
      { code: '/browse/cars/audi/1995', desc: '1995' },
      { code: '/browse/cars/audi/1994', desc: '1994' },
      { code: '/browse/cars/audi/1993', desc: '1993' },
      { code: '/browse/cars/audi/1992', desc: '1992' },
      { code: '/browse/cars/audi/1991', desc: '1991' },
      { code: '/browse/cars/audi/1990', desc: '1990' },
      { code: '/browse/cars/audi/1989', desc: '1989' },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley': [
      { code: '/browse/cars/bentley/2022', desc: '2022' },
      { code: '/browse/cars/bentley/2021', desc: '2021' },
      { code: '/browse/cars/bentley/2020', desc: '2020' },
      { code: '/browse/cars/bentley/2019', desc: '2019' },
      { code: '/browse/cars/bentley/2018', desc: '2018' },
      { code: '/browse/cars/bentley/2017', desc: '2017' },
      { code: '/browse/cars/bentley/2016', desc: '2016' },
      { code: '/browse/cars/bentley/2015', desc: '2015' },
      { code: '/browse/cars/bentley/2014', desc: '2014' },
      { code: '/browse/cars/bentley/2013', desc: '2013' },
      { code: '/browse/cars/bentley/2012', desc: '2012' },
      { code: '/browse/cars/bentley/2011', desc: '2011' },
      { code: '/browse/cars/bentley/2010', desc: '2010' },
      { code: '/browse/cars/bentley/2009', desc: '2009' },
      { code: '/browse/cars/bentley/2008', desc: '2008' },
      { code: '/browse/cars/bentley/2007', desc: '2007' },
      { code: '/browse/cars/bentley/2006', desc: '2006' },
      { code: '/browse/cars/bentley/2005', desc: '2005' },
      { code: '/browse/cars/bentley/2004', desc: '2004' },
      { code: '/browse/cars/bentley/2003', desc: '2003' },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw': [
      { code: '/browse/cars/bmw/2022', desc: '2022' },
      { code: '/browse/cars/bmw/2021', desc: '2021' },
      { code: '/browse/cars/bmw/2020', desc: '2020' },
      { code: '/browse/cars/bmw/2019', desc: '2019' },
      { code: '/browse/cars/bmw/2018', desc: '2018' },
      { code: '/browse/cars/bmw/2017', desc: '2017' },
      { code: '/browse/cars/bmw/2016', desc: '2016' },
      { code: '/browse/cars/bmw/2015', desc: '2015' },
      { code: '/browse/cars/bmw/2014', desc: '2014' },
      { code: '/browse/cars/bmw/2013', desc: '2013' },
      { code: '/browse/cars/bmw/2012', desc: '2012' },
      { code: '/browse/cars/bmw/2011', desc: '2011' },
      { code: '/browse/cars/bmw/2010', desc: '2010' },
      { code: '/browse/cars/bmw/2009', desc: '2009' },
      { code: '/browse/cars/bmw/2008', desc: '2008' },
      { code: '/browse/cars/bmw/2007', desc: '2007' },
      { code: '/browse/cars/bmw/2006', desc: '2006' },
      { code: '/browse/cars/bmw/2005', desc: '2005' },
      { code: '/browse/cars/bmw/2004', desc: '2004' },
      { code: '/browse/cars/bmw/2003', desc: '2003' },
      { code: '/browse/cars/bmw/2002', desc: '2002' },
      { code: '/browse/cars/bmw/2001', desc: '2001' },
      { code: '/browse/cars/bmw/2000', desc: '2000' },
      { code: '/browse/cars/bmw/1999', desc: '1999' },
      { code: '/browse/cars/bmw/1998', desc: '1998' },
      { code: '/browse/cars/bmw/1997', desc: '1997' },
      { code: '/browse/cars/bmw/1996', desc: '1996' },
      { code: '/browse/cars/bmw/1995', desc: '1995' },
      { code: '/browse/cars/bmw/1994', desc: '1994' },
      { code: '/browse/cars/bmw/1993', desc: '1993' },
      { code: '/browse/cars/bmw/1992', desc: '1992' },
      { code: '/browse/cars/bmw/1991', desc: '1991' },
      { code: '/browse/cars/bmw/1990', desc: '1990' },
      { code: '/browse/cars/bmw/1989', desc: '1989' },
      { code: '/browse/cars/bmw/1988', desc: '1988' },
      { code: '/browse/cars/bmw/1987', desc: '1987' },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick': [
      { code: '/browse/cars/buick/2020', desc: '2020' },
      { code: '/browse/cars/buick/2019', desc: '2019' },
      { code: '/browse/cars/buick/2018', desc: '2018' },
      { code: '/browse/cars/buick/2017', desc: '2017' },
      { code: '/browse/cars/buick/2016', desc: '2016' },
      { code: '/browse/cars/buick/2015', desc: '2015' },
      { code: '/browse/cars/buick/2014', desc: '2014' },
      { code: '/browse/cars/buick/2013', desc: '2013' },
      { code: '/browse/cars/buick/2012', desc: '2012' },
      { code: '/browse/cars/buick/2011', desc: '2011' },
      { code: '/browse/cars/buick/2010', desc: '2010' },
      { code: '/browse/cars/buick/2009', desc: '2009' },
    ],
    'ES-MX_/browse/cars_/browse/cars/cadillac': [
      { code: '/browse/cars/cadillac/2020', desc: '2020' },
      { code: '/browse/cars/cadillac/2019', desc: '2019' },
      { code: '/browse/cars/cadillac/2018', desc: '2018' },
      { code: '/browse/cars/cadillac/2017', desc: '2017' },
      { code: '/browse/cars/cadillac/2016', desc: '2016' },
      { code: '/browse/cars/cadillac/2015', desc: '2015' },
      { code: '/browse/cars/cadillac/2014', desc: '2014' },
      { code: '/browse/cars/cadillac/2013', desc: '2013' },
      { code: '/browse/cars/cadillac/2012', desc: '2012' },
      { code: '/browse/cars/cadillac/2011', desc: '2011' },
      { code: '/browse/cars/cadillac/2010', desc: '2010' },
      { code: '/browse/cars/cadillac/2009', desc: '2009' },
      { code: '/browse/cars/cadillac/2008', desc: '2008' },
      { code: '/browse/cars/cadillac/2007', desc: '2007' },
      { code: '/browse/cars/cadillac/2006', desc: '2006' },
      { code: '/browse/cars/cadillac/2005', desc: '2005' },
      { code: '/browse/cars/cadillac/2004', desc: '2004' },
      { code: '/browse/cars/cadillac/2003', desc: '2003' },
      { code: '/browse/cars/cadillac/2002', desc: '2002' },
      { code: '/browse/cars/cadillac/2001', desc: '2001' },
      { code: '/browse/cars/cadillac/2000', desc: '2000' },
      { code: '/browse/cars/cadillac/1999', desc: '1999' },
      { code: '/browse/cars/cadillac/1998', desc: '1998' },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet': [
      { code: '/browse/cars/chevrolet/2022', desc: '2022' },
      { code: '/browse/cars/chevrolet/2021', desc: '2021' },
      { code: '/browse/cars/chevrolet/2020', desc: '2020' },
      { code: '/browse/cars/chevrolet/2019', desc: '2019' },
      { code: '/browse/cars/chevrolet/2018', desc: '2018' },
      { code: '/browse/cars/chevrolet/2017', desc: '2017' },
      { code: '/browse/cars/chevrolet/2016', desc: '2016' },
      { code: '/browse/cars/chevrolet/2015', desc: '2015' },
      { code: '/browse/cars/chevrolet/2014', desc: '2014' },
      { code: '/browse/cars/chevrolet/2013', desc: '2013' },
      { code: '/browse/cars/chevrolet/2012', desc: '2012' },
      { code: '/browse/cars/chevrolet/2011', desc: '2011' },
      { code: '/browse/cars/chevrolet/2010', desc: '2010' },
      { code: '/browse/cars/chevrolet/2009', desc: '2009' },
      { code: '/browse/cars/chevrolet/2008', desc: '2008' },
      { code: '/browse/cars/chevrolet/2007', desc: '2007' },
      { code: '/browse/cars/chevrolet/2006', desc: '2006' },
      { code: '/browse/cars/chevrolet/2005', desc: '2005' },
      { code: '/browse/cars/chevrolet/2004', desc: '2004' },
      { code: '/browse/cars/chevrolet/2003', desc: '2003' },
      { code: '/browse/cars/chevrolet/2002', desc: '2002' },
      { code: '/browse/cars/chevrolet/2001', desc: '2001' },
      { code: '/browse/cars/chevrolet/2000', desc: '2000' },
      { code: '/browse/cars/chevrolet/1999', desc: '1999' },
      { code: '/browse/cars/chevrolet/1998', desc: '1998' },
      { code: '/browse/cars/chevrolet/1997', desc: '1997' },
      { code: '/browse/cars/chevrolet/1996', desc: '1996' },
      { code: '/browse/cars/chevrolet/1995', desc: '1995' },
      { code: '/browse/cars/chevrolet/1994', desc: '1994' },
      { code: '/browse/cars/chevrolet/1993', desc: '1993' },
      { code: '/browse/cars/chevrolet/1992', desc: '1992' },
      { code: '/browse/cars/chevrolet/1991', desc: '1991' },
      { code: '/browse/cars/chevrolet/1990', desc: '1990' },
      { code: '/browse/cars/chevrolet/1989', desc: '1989' },
      { code: '/browse/cars/chevrolet/1988', desc: '1988' },
      { code: '/browse/cars/chevrolet/1987', desc: '1987' },
      { code: '/browse/cars/chevrolet/1986', desc: '1986' },
      { code: '/browse/cars/chevrolet/1985', desc: '1985' },
      { code: '/browse/cars/chevrolet/1984', desc: '1984' },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler': [
      { code: '/browse/cars/chrysler/2021', desc: '2021' },
      { code: '/browse/cars/chrysler/2020', desc: '2020' },
      { code: '/browse/cars/chrysler/2019', desc: '2019' },
      { code: '/browse/cars/chrysler/2018', desc: '2018' },
      { code: '/browse/cars/chrysler/2017', desc: '2017' },
      { code: '/browse/cars/chrysler/2016', desc: '2016' },
      { code: '/browse/cars/chrysler/2015', desc: '2015' },
      { code: '/browse/cars/chrysler/2014', desc: '2014' },
      { code: '/browse/cars/chrysler/2013', desc: '2013' },
      { code: '/browse/cars/chrysler/2012', desc: '2012' },
      { code: '/browse/cars/chrysler/2011', desc: '2011' },
      { code: '/browse/cars/chrysler/2010', desc: '2010' },
      { code: '/browse/cars/chrysler/2009', desc: '2009' },
      { code: '/browse/cars/chrysler/2008', desc: '2008' },
      { code: '/browse/cars/chrysler/2007', desc: '2007' },
      { code: '/browse/cars/chrysler/2006', desc: '2006' },
      { code: '/browse/cars/chrysler/2005', desc: '2005' },
      { code: '/browse/cars/chrysler/2004', desc: '2004' },
      { code: '/browse/cars/chrysler/2003', desc: '2003' },
      { code: '/browse/cars/chrysler/2002', desc: '2002' },
      { code: '/browse/cars/chrysler/2001', desc: '2001' },
      { code: '/browse/cars/chrysler/2000', desc: '2000' },
      { code: '/browse/cars/chrysler/1999', desc: '1999' },
      { code: '/browse/cars/chrysler/1998', desc: '1998' },
      { code: '/browse/cars/chrysler/1997', desc: '1997' },
      { code: '/browse/cars/chrysler/1996', desc: '1996' },
      { code: '/browse/cars/chrysler/1995', desc: '1995' },
      { code: '/browse/cars/chrysler/1994', desc: '1994' },
      { code: '/browse/cars/chrysler/1993', desc: '1993' },
      { code: '/browse/cars/chrysler/1992', desc: '1992' },
      { code: '/browse/cars/chrysler/1991', desc: '1991' },
      { code: '/browse/cars/chrysler/1990', desc: '1990' },
      { code: '/browse/cars/chrysler/1989', desc: '1989' },
      { code: '/browse/cars/chrysler/1988', desc: '1988' },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge': [
      { code: '/browse/cars/dodge/2021', desc: '2021' },
      { code: '/browse/cars/dodge/2020', desc: '2020' },
      { code: '/browse/cars/dodge/2019', desc: '2019' },
      { code: '/browse/cars/dodge/2018', desc: '2018' },
      { code: '/browse/cars/dodge/2017', desc: '2017' },
      { code: '/browse/cars/dodge/2016', desc: '2016' },
      { code: '/browse/cars/dodge/2015', desc: '2015' },
      { code: '/browse/cars/dodge/2014', desc: '2014' },
      { code: '/browse/cars/dodge/2013', desc: '2013' },
      { code: '/browse/cars/dodge/2012', desc: '2012' },
      { code: '/browse/cars/dodge/2011', desc: '2011' },
      { code: '/browse/cars/dodge/2010', desc: '2010' },
      { code: '/browse/cars/dodge/2009', desc: '2009' },
      { code: '/browse/cars/dodge/2008', desc: '2008' },
      { code: '/browse/cars/dodge/2007', desc: '2007' },
      { code: '/browse/cars/dodge/2006', desc: '2006' },
      { code: '/browse/cars/dodge/2005', desc: '2005' },
      { code: '/browse/cars/dodge/2004', desc: '2004' },
      { code: '/browse/cars/dodge/2003', desc: '2003' },
      { code: '/browse/cars/dodge/2002', desc: '2002' },
      { code: '/browse/cars/dodge/2001', desc: '2001' },
      { code: '/browse/cars/dodge/2000', desc: '2000' },
      { code: '/browse/cars/dodge/1999', desc: '1999' },
      { code: '/browse/cars/dodge/1998', desc: '1998' },
      { code: '/browse/cars/dodge/1997', desc: '1997' },
      { code: '/browse/cars/dodge/1996', desc: '1996' },
      { code: '/browse/cars/dodge/1995', desc: '1995' },
      { code: '/browse/cars/dodge/1994', desc: '1994' },
      { code: '/browse/cars/dodge/1993', desc: '1993' },
      { code: '/browse/cars/dodge/1992', desc: '1992' },
      { code: '/browse/cars/dodge/1991', desc: '1991' },
      { code: '/browse/cars/dodge/1990', desc: '1990' },
      { code: '/browse/cars/dodge/1989', desc: '1989' },
      { code: '/browse/cars/dodge/1988', desc: '1988' },
      { code: '/browse/cars/dodge/1987', desc: '1987' },
      { code: '/browse/cars/dodge/1986', desc: '1986' },
      { code: '/browse/cars/dodge/1985', desc: '1985' },
      { code: '/browse/cars/dodge/1984', desc: '1984' },
      { code: '/browse/cars/dodge/1983', desc: '1983' },
      { code: '/browse/cars/dodge/1982', desc: '1982' },
      { code: '/browse/cars/dodge/1981', desc: '1981' },
      { code: '/browse/cars/dodge/1980', desc: '1980' },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari': [
      { code: '/browse/cars/ferrari/2010', desc: '2010' },
      { code: '/browse/cars/ferrari/2004', desc: '2004' },
      { code: '/browse/cars/ferrari/2003', desc: '2003' },
      { code: '/browse/cars/ferrari/2002', desc: '2002' },
      { code: '/browse/cars/ferrari/2001', desc: '2001' },
      { code: '/browse/cars/ferrari/2000', desc: '2000' },
      { code: '/browse/cars/ferrari/1999', desc: '1999' },
      { code: '/browse/cars/ferrari/1998', desc: '1998' },
      { code: '/browse/cars/ferrari/1997', desc: '1997' },
      { code: '/browse/cars/ferrari/1996', desc: '1996' },
      { code: '/browse/cars/ferrari/1995', desc: '1995' },
      { code: '/browse/cars/ferrari/1994', desc: '1994' },
      { code: '/browse/cars/ferrari/1992', desc: '1992' },
      { code: '/browse/cars/ferrari/1991', desc: '1991' },
      { code: '/browse/cars/ferrari/1990', desc: '1990' },
      { code: '/browse/cars/ferrari/1989', desc: '1989' },
      { code: '/browse/cars/ferrari/1988', desc: '1988' },
      { code: '/browse/cars/ferrari/1987', desc: '1987' },
      { code: '/browse/cars/ferrari/1986', desc: '1986' },
      { code: '/browse/cars/ferrari/1985', desc: '1985' },
      { code: '/browse/cars/ferrari/1984', desc: '1984' },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat': [
      { code: '/browse/cars/fiat/2022', desc: '2022' },
      { code: '/browse/cars/fiat/2021', desc: '2021' },
      { code: '/browse/cars/fiat/2020', desc: '2020' },
      { code: '/browse/cars/fiat/2019', desc: '2019' },
      { code: '/browse/cars/fiat/2018', desc: '2018' },
      { code: '/browse/cars/fiat/2017', desc: '2017' },
      { code: '/browse/cars/fiat/2016', desc: '2016' },
      { code: '/browse/cars/fiat/2015', desc: '2015' },
      { code: '/browse/cars/fiat/2014', desc: '2014' },
      { code: '/browse/cars/fiat/2013', desc: '2013' },
      { code: '/browse/cars/fiat/2012', desc: '2012' },
      { code: '/browse/cars/fiat/2011', desc: '2011' },
      { code: '/browse/cars/fiat/2010', desc: '2010' },
      { code: '/browse/cars/fiat/2009', desc: '2009' },
      { code: '/browse/cars/fiat/2008', desc: '2008' },
      { code: '/browse/cars/fiat/2007', desc: '2007' },
      { code: '/browse/cars/fiat/2006', desc: '2006' },
      { code: '/browse/cars/fiat/2005', desc: '2005' },
      { code: '/browse/cars/fiat/2004', desc: '2004' },
      { code: '/browse/cars/fiat/2003', desc: '2003' },
      { code: '/browse/cars/fiat/2002', desc: '2002' },
      { code: '/browse/cars/fiat/2001', desc: '2001' },
      { code: '/browse/cars/fiat/2000', desc: '2000' },
      { code: '/browse/cars/fiat/1999', desc: '1999' },
      { code: '/browse/cars/fiat/1998', desc: '1998' },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford': [
      { code: '/browse/cars/ford/2022', desc: '2022' },
      { code: '/browse/cars/ford/2021', desc: '2021' },
      { code: '/browse/cars/ford/2020', desc: '2020' },
      { code: '/browse/cars/ford/2019', desc: '2019' },
      { code: '/browse/cars/ford/2018', desc: '2018' },
      { code: '/browse/cars/ford/2017', desc: '2017' },
      { code: '/browse/cars/ford/2016', desc: '2016' },
      { code: '/browse/cars/ford/2015', desc: '2015' },
      { code: '/browse/cars/ford/2014', desc: '2014' },
      { code: '/browse/cars/ford/2013', desc: '2013' },
      { code: '/browse/cars/ford/2012', desc: '2012' },
      { code: '/browse/cars/ford/2011', desc: '2011' },
      { code: '/browse/cars/ford/2010', desc: '2010' },
      { code: '/browse/cars/ford/2009', desc: '2009' },
      { code: '/browse/cars/ford/2008', desc: '2008' },
      { code: '/browse/cars/ford/2007', desc: '2007' },
      { code: '/browse/cars/ford/2006', desc: '2006' },
      { code: '/browse/cars/ford/2005', desc: '2005' },
      { code: '/browse/cars/ford/2004', desc: '2004' },
      { code: '/browse/cars/ford/2003', desc: '2003' },
      { code: '/browse/cars/ford/2002', desc: '2002' },
      { code: '/browse/cars/ford/2001', desc: '2001' },
      { code: '/browse/cars/ford/2000', desc: '2000' },
      { code: '/browse/cars/ford/1999', desc: '1999' },
      { code: '/browse/cars/ford/1998', desc: '1998' },
      { code: '/browse/cars/ford/1997', desc: '1997' },
      { code: '/browse/cars/ford/1996', desc: '1996' },
      { code: '/browse/cars/ford/1995', desc: '1995' },
      { code: '/browse/cars/ford/1994', desc: '1994' },
      { code: '/browse/cars/ford/1993', desc: '1993' },
      { code: '/browse/cars/ford/1992', desc: '1992' },
      { code: '/browse/cars/ford/1991', desc: '1991' },
      { code: '/browse/cars/ford/1990', desc: '1990' },
      { code: '/browse/cars/ford/1989', desc: '1989' },
      { code: '/browse/cars/ford/1988', desc: '1988' },
      { code: '/browse/cars/ford/1987', desc: '1987' },
      { code: '/browse/cars/ford/1986', desc: '1986' },
      { code: '/browse/cars/ford/1985', desc: '1985' },
      { code: '/browse/cars/ford/1984', desc: '1984' },
      { code: '/browse/cars/ford/1983', desc: '1983' },
      { code: '/browse/cars/ford/1982', desc: '1982' },
      { code: '/browse/cars/ford/1981', desc: '1981' },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc': [
      { code: '/browse/cars/gmc/2022', desc: '2022' },
      { code: '/browse/cars/gmc/2021', desc: '2021' },
      { code: '/browse/cars/gmc/2020', desc: '2020' },
      { code: '/browse/cars/gmc/2019', desc: '2019' },
      { code: '/browse/cars/gmc/2018', desc: '2018' },
      { code: '/browse/cars/gmc/2017', desc: '2017' },
      { code: '/browse/cars/gmc/2016', desc: '2016' },
      { code: '/browse/cars/gmc/2015', desc: '2015' },
      { code: '/browse/cars/gmc/2014', desc: '2014' },
      { code: '/browse/cars/gmc/2013', desc: '2013' },
      { code: '/browse/cars/gmc/2012', desc: '2012' },
      { code: '/browse/cars/gmc/2011', desc: '2011' },
      { code: '/browse/cars/gmc/2010', desc: '2010' },
      { code: '/browse/cars/gmc/2009', desc: '2009' },
      { code: '/browse/cars/gmc/2008', desc: '2008' },
      { code: '/browse/cars/gmc/2007', desc: '2007' },
      { code: '/browse/cars/gmc/2006', desc: '2006' },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda': [
      { code: '/browse/cars/honda/2022', desc: '2022' },
      { code: '/browse/cars/honda/2021', desc: '2021' },
      { code: '/browse/cars/honda/2020', desc: '2020' },
      { code: '/browse/cars/honda/2019', desc: '2019' },
      { code: '/browse/cars/honda/2018', desc: '2018' },
      { code: '/browse/cars/honda/2017', desc: '2017' },
      { code: '/browse/cars/honda/2016', desc: '2016' },
      { code: '/browse/cars/honda/2015', desc: '2015' },
      { code: '/browse/cars/honda/2014', desc: '2014' },
      { code: '/browse/cars/honda/2013', desc: '2013' },
      { code: '/browse/cars/honda/2012', desc: '2012' },
      { code: '/browse/cars/honda/2011', desc: '2011' },
      { code: '/browse/cars/honda/2010', desc: '2010' },
      { code: '/browse/cars/honda/2009', desc: '2009' },
      { code: '/browse/cars/honda/2008', desc: '2008' },
      { code: '/browse/cars/honda/2007', desc: '2007' },
      { code: '/browse/cars/honda/2006', desc: '2006' },
      { code: '/browse/cars/honda/2005', desc: '2005' },
      { code: '/browse/cars/honda/2004', desc: '2004' },
      { code: '/browse/cars/honda/2003', desc: '2003' },
      { code: '/browse/cars/honda/2002', desc: '2002' },
      { code: '/browse/cars/honda/2001', desc: '2001' },
      { code: '/browse/cars/honda/2000', desc: '2000' },
      { code: '/browse/cars/honda/1999', desc: '1999' },
      { code: '/browse/cars/honda/1998', desc: '1998' },
      { code: '/browse/cars/honda/1997', desc: '1997' },
      { code: '/browse/cars/honda/1996', desc: '1996' },
      { code: '/browse/cars/honda/1995', desc: '1995' },
      { code: '/browse/cars/honda/1994', desc: '1994' },
      { code: '/browse/cars/honda/1993', desc: '1993' },
      { code: '/browse/cars/honda/1992', desc: '1992' },
      { code: '/browse/cars/honda/1991', desc: '1991' },
      { code: '/browse/cars/honda/1990', desc: '1990' },
      { code: '/browse/cars/honda/1989', desc: '1989' },
      { code: '/browse/cars/honda/1988', desc: '1988' },
      { code: '/browse/cars/honda/1987', desc: '1987' },
      { code: '/browse/cars/honda/1986', desc: '1986' },
      { code: '/browse/cars/honda/1985', desc: '1985' },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer': [
      { code: '/browse/cars/hummer/2010', desc: '2010' },
      { code: '/browse/cars/hummer/2009', desc: '2009' },
      { code: '/browse/cars/hummer/2008', desc: '2008' },
      { code: '/browse/cars/hummer/2007', desc: '2007' },
      { code: '/browse/cars/hummer/2006', desc: '2006' },
      { code: '/browse/cars/hummer/2005', desc: '2005' },
      { code: '/browse/cars/hummer/2004', desc: '2004' },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai': [
      { code: '/browse/cars/hyundai/2022', desc: '2022' },
      { code: '/browse/cars/hyundai/2021', desc: '2021' },
      { code: '/browse/cars/hyundai/2020', desc: '2020' },
      { code: '/browse/cars/hyundai/2019', desc: '2019' },
      { code: '/browse/cars/hyundai/2018', desc: '2018' },
      { code: '/browse/cars/hyundai/2017', desc: '2017' },
      { code: '/browse/cars/hyundai/2016', desc: '2016' },
      { code: '/browse/cars/hyundai/2015', desc: '2015' },
      { code: '/browse/cars/hyundai/2014', desc: '2014' },
      { code: '/browse/cars/hyundai/2013', desc: '2013' },
      { code: '/browse/cars/hyundai/2012', desc: '2012' },
      { code: '/browse/cars/hyundai/2011', desc: '2011' },
      { code: '/browse/cars/hyundai/2010', desc: '2010' },
      { code: '/browse/cars/hyundai/2009', desc: '2009' },
      { code: '/browse/cars/hyundai/2008', desc: '2008' },
      { code: '/browse/cars/hyundai/2007', desc: '2007' },
      { code: '/browse/cars/hyundai/2006', desc: '2006' },
      { code: '/browse/cars/hyundai/2005', desc: '2005' },
      { code: '/browse/cars/hyundai/2004', desc: '2004' },
      { code: '/browse/cars/hyundai/1999', desc: '1999' },
      { code: '/browse/cars/hyundai/1998', desc: '1998' },
      { code: '/browse/cars/hyundai/1997', desc: '1997' },
      { code: '/browse/cars/hyundai/1996', desc: '1996' },
      { code: '/browse/cars/hyundai/1995', desc: '1995' },
      { code: '/browse/cars/hyundai/1994', desc: '1994' },
      { code: '/browse/cars/hyundai/1993', desc: '1993' },
      { code: '/browse/cars/hyundai/1992', desc: '1992' },
      { code: '/browse/cars/hyundai/1991', desc: '1991' },
      { code: '/browse/cars/hyundai/1990', desc: '1990' },
      { code: '/browse/cars/hyundai/1989', desc: '1989' },
      { code: '/browse/cars/hyundai/1988', desc: '1988' },
      { code: '/browse/cars/hyundai/1987', desc: '1987' },
      { code: '/browse/cars/hyundai/1986', desc: '1986' },
      { code: '/browse/cars/hyundai/1985', desc: '1985' },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti': [
      { code: '/browse/cars/infiniti/2022', desc: '2022' },
      { code: '/browse/cars/infiniti/2021', desc: '2021' },
      { code: '/browse/cars/infiniti/2020', desc: '2020' },
      { code: '/browse/cars/infiniti/2019', desc: '2019' },
      { code: '/browse/cars/infiniti/2018', desc: '2018' },
      { code: '/browse/cars/infiniti/2017', desc: '2017' },
      { code: '/browse/cars/infiniti/2016', desc: '2016' },
      { code: '/browse/cars/infiniti/2015', desc: '2015' },
      { code: '/browse/cars/infiniti/2014', desc: '2014' },
      { code: '/browse/cars/infiniti/2013', desc: '2013' },
      { code: '/browse/cars/infiniti/2012', desc: '2012' },
      { code: '/browse/cars/infiniti/2011', desc: '2011' },
      { code: '/browse/cars/infiniti/2010', desc: '2010' },
      { code: '/browse/cars/infiniti/2009', desc: '2009' },
      { code: '/browse/cars/infiniti/2008', desc: '2008' },
      { code: '/browse/cars/infiniti/2006', desc: '2006' },
      { code: '/browse/cars/infiniti/2005', desc: '2005' },
      { code: '/browse/cars/infiniti/2004', desc: '2004' },
      { code: '/browse/cars/infiniti/2003', desc: '2003' },
      { code: '/browse/cars/infiniti/2002', desc: '2002' },
      { code: '/browse/cars/infiniti/2001', desc: '2001' },
      { code: '/browse/cars/infiniti/2000', desc: '2000' },
      { code: '/browse/cars/infiniti/1998', desc: '1998' },
      { code: '/browse/cars/infiniti/1993', desc: '1993' },
      { code: '/browse/cars/infiniti/1992', desc: '1992' },
      { code: '/browse/cars/infiniti/1991', desc: '1991' },
      { code: '/browse/cars/infiniti/1990', desc: '1990' },
      { code: '/browse/cars/infiniti/1989', desc: '1989' },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu': [
      { code: '/browse/cars/isuzu/2004', desc: '2004' },
      { code: '/browse/cars/isuzu/2003', desc: '2003' },
      { code: '/browse/cars/isuzu/2002', desc: '2002' },
      { code: '/browse/cars/isuzu/2001', desc: '2001' },
      { code: '/browse/cars/isuzu/2000', desc: '2000' },
      { code: '/browse/cars/isuzu/1999', desc: '1999' },
      { code: '/browse/cars/isuzu/1998', desc: '1998' },
      { code: '/browse/cars/isuzu/1997', desc: '1997' },
      { code: '/browse/cars/isuzu/1996', desc: '1996' },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar': [
      { code: '/browse/cars/jaguar/2022', desc: '2022' },
      { code: '/browse/cars/jaguar/2021', desc: '2021' },
      { code: '/browse/cars/jaguar/2020', desc: '2020' },
      { code: '/browse/cars/jaguar/2019', desc: '2019' },
      { code: '/browse/cars/jaguar/2018', desc: '2018' },
      { code: '/browse/cars/jaguar/2017', desc: '2017' },
      { code: '/browse/cars/jaguar/2016', desc: '2016' },
      { code: '/browse/cars/jaguar/2015', desc: '2015' },
      { code: '/browse/cars/jaguar/2014', desc: '2014' },
      { code: '/browse/cars/jaguar/2013', desc: '2013' },
      { code: '/browse/cars/jaguar/2012', desc: '2012' },
      { code: '/browse/cars/jaguar/2011', desc: '2011' },
      { code: '/browse/cars/jaguar/2010', desc: '2010' },
      { code: '/browse/cars/jaguar/2009', desc: '2009' },
      { code: '/browse/cars/jaguar/2008', desc: '2008' },
      { code: '/browse/cars/jaguar/2007', desc: '2007' },
      { code: '/browse/cars/jaguar/2006', desc: '2006' },
      { code: '/browse/cars/jaguar/2005', desc: '2005' },
      { code: '/browse/cars/jaguar/2004', desc: '2004' },
      { code: '/browse/cars/jaguar/2003', desc: '2003' },
      { code: '/browse/cars/jaguar/2002', desc: '2002' },
      { code: '/browse/cars/jaguar/2001', desc: '2001' },
      { code: '/browse/cars/jaguar/2000', desc: '2000' },
      { code: '/browse/cars/jaguar/1999', desc: '1999' },
      { code: '/browse/cars/jaguar/1998', desc: '1998' },
      { code: '/browse/cars/jaguar/1997', desc: '1997' },
      { code: '/browse/cars/jaguar/1996', desc: '1996' },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep': [
      { code: '/browse/cars/jeep/2021', desc: '2021' },
      { code: '/browse/cars/jeep/2020', desc: '2020' },
      { code: '/browse/cars/jeep/2019', desc: '2019' },
      { code: '/browse/cars/jeep/2018', desc: '2018' },
      { code: '/browse/cars/jeep/2017', desc: '2017' },
      { code: '/browse/cars/jeep/2016', desc: '2016' },
      { code: '/browse/cars/jeep/2015', desc: '2015' },
      { code: '/browse/cars/jeep/2014', desc: '2014' },
      { code: '/browse/cars/jeep/2013', desc: '2013' },
      { code: '/browse/cars/jeep/2012', desc: '2012' },
      { code: '/browse/cars/jeep/2011', desc: '2011' },
      { code: '/browse/cars/jeep/2010', desc: '2010' },
      { code: '/browse/cars/jeep/2009', desc: '2009' },
      { code: '/browse/cars/jeep/2008', desc: '2008' },
      { code: '/browse/cars/jeep/2007', desc: '2007' },
      { code: '/browse/cars/jeep/2006', desc: '2006' },
      { code: '/browse/cars/jeep/2005', desc: '2005' },
      { code: '/browse/cars/jeep/2004', desc: '2004' },
      { code: '/browse/cars/jeep/2003', desc: '2003' },
      { code: '/browse/cars/jeep/2002', desc: '2002' },
      { code: '/browse/cars/jeep/2001', desc: '2001' },
      { code: '/browse/cars/jeep/2000', desc: '2000' },
      { code: '/browse/cars/jeep/1999', desc: '1999' },
      { code: '/browse/cars/jeep/1998', desc: '1998' },
      { code: '/browse/cars/jeep/1997', desc: '1997' },
      { code: '/browse/cars/jeep/1996', desc: '1996' },
      { code: '/browse/cars/jeep/1995', desc: '1995' },
      { code: '/browse/cars/jeep/1994', desc: '1994' },
      { code: '/browse/cars/jeep/1993', desc: '1993' },
      { code: '/browse/cars/jeep/1992', desc: '1992' },
      { code: '/browse/cars/jeep/1991', desc: '1991' },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia': [
      { code: '/browse/cars/kia/2022', desc: '2022' },
      { code: '/browse/cars/kia/2021', desc: '2021' },
      { code: '/browse/cars/kia/2020', desc: '2020' },
      { code: '/browse/cars/kia/2019', desc: '2019' },
      { code: '/browse/cars/kia/2018', desc: '2018' },
      { code: '/browse/cars/kia/2017', desc: '2017' },
      { code: '/browse/cars/kia/2016', desc: '2016' },
      { code: '/browse/cars/kia/2015', desc: '2015' },
      { code: '/browse/cars/kia/2014', desc: '2014' },
      { code: '/browse/cars/kia/2006', desc: '2006' },
      { code: '/browse/cars/kia/2005', desc: '2005' },
      { code: '/browse/cars/kia/2004', desc: '2004' },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini': [
      { code: '/browse/cars/lamborghini/2008', desc: '2008' },
      { code: '/browse/cars/lamborghini/2007', desc: '2007' },
      { code: '/browse/cars/lamborghini/2006', desc: '2006' },
      { code: '/browse/cars/lamborghini/2005', desc: '2005' },
      { code: '/browse/cars/lamborghini/2004', desc: '2004' },
      { code: '/browse/cars/lamborghini/2003', desc: '2003' },
      { code: '/browse/cars/lamborghini/2002', desc: '2002' },
      { code: '/browse/cars/lamborghini/2001', desc: '2001' },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover': [
      { code: '/browse/cars/land_rover/2022', desc: '2022' },
      { code: '/browse/cars/land_rover/2021', desc: '2021' },
      { code: '/browse/cars/land_rover/2020', desc: '2020' },
      { code: '/browse/cars/land_rover/2019', desc: '2019' },
      { code: '/browse/cars/land_rover/2018', desc: '2018' },
      { code: '/browse/cars/land_rover/2017', desc: '2017' },
      { code: '/browse/cars/land_rover/2016', desc: '2016' },
      { code: '/browse/cars/land_rover/2015', desc: '2015' },
      { code: '/browse/cars/land_rover/2014', desc: '2014' },
      { code: '/browse/cars/land_rover/2013', desc: '2013' },
      { code: '/browse/cars/land_rover/2012', desc: '2012' },
      { code: '/browse/cars/land_rover/2011', desc: '2011' },
      { code: '/browse/cars/land_rover/2010', desc: '2010' },
      { code: '/browse/cars/land_rover/2009', desc: '2009' },
      { code: '/browse/cars/land_rover/2008', desc: '2008' },
      { code: '/browse/cars/land_rover/2007', desc: '2007' },
      { code: '/browse/cars/land_rover/2006', desc: '2006' },
      { code: '/browse/cars/land_rover/2005', desc: '2005' },
      { code: '/browse/cars/land_rover/2004', desc: '2004' },
      { code: '/browse/cars/land_rover/2003', desc: '2003' },
      { code: '/browse/cars/land_rover/2002', desc: '2002' },
      { code: '/browse/cars/land_rover/2001', desc: '2001' },
      { code: '/browse/cars/land_rover/2000', desc: '2000' },
      { code: '/browse/cars/land_rover/1999', desc: '1999' },
      { code: '/browse/cars/land_rover/1998', desc: '1998' },
      { code: '/browse/cars/land_rover/1997', desc: '1997' },
      { code: '/browse/cars/land_rover/1996', desc: '1996' },
      { code: '/browse/cars/land_rover/1995', desc: '1995' },
      { code: '/browse/cars/land_rover/1994', desc: '1994' },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln': [
      { code: '/browse/cars/lincoln/2020', desc: '2020' },
      { code: '/browse/cars/lincoln/2019', desc: '2019' },
      { code: '/browse/cars/lincoln/2018', desc: '2018' },
      { code: '/browse/cars/lincoln/2017', desc: '2017' },
      { code: '/browse/cars/lincoln/2016', desc: '2016' },
      { code: '/browse/cars/lincoln/2015', desc: '2015' },
      { code: '/browse/cars/lincoln/2014', desc: '2014' },
      { code: '/browse/cars/lincoln/2013', desc: '2013' },
      { code: '/browse/cars/lincoln/2012', desc: '2012' },
      { code: '/browse/cars/lincoln/2011', desc: '2011' },
      { code: '/browse/cars/lincoln/2010', desc: '2010' },
      { code: '/browse/cars/lincoln/2009', desc: '2009' },
      { code: '/browse/cars/lincoln/2008', desc: '2008' },
      { code: '/browse/cars/lincoln/2007', desc: '2007' },
      { code: '/browse/cars/lincoln/2006', desc: '2006' },
      { code: '/browse/cars/lincoln/2005', desc: '2005' },
      { code: '/browse/cars/lincoln/2004', desc: '2004' },
      { code: '/browse/cars/lincoln/2003', desc: '2003' },
      { code: '/browse/cars/lincoln/2002', desc: '2002' },
      { code: '/browse/cars/lincoln/2001', desc: '2001' },
      { code: '/browse/cars/lincoln/2000', desc: '2000' },
      { code: '/browse/cars/lincoln/1999', desc: '1999' },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus': [
      { code: '/browse/cars/lexus/2003', desc: '2003' },
      { code: '/browse/cars/lexus/2002', desc: '2002' },
      { code: '/browse/cars/lexus/2001', desc: '2001' },
      { code: '/browse/cars/lexus/2000', desc: '2000' },
      { code: '/browse/cars/lexus/1997', desc: '1997' },
      { code: '/browse/cars/lexus/1996', desc: '1996' },
      { code: '/browse/cars/lexus/1995', desc: '1995' },
      { code: '/browse/cars/lexus/1994', desc: '1994' },
      { code: '/browse/cars/lexus/1993', desc: '1993' },
      { code: '/browse/cars/lexus/1992', desc: '1992' },
      { code: '/browse/cars/lexus/1991', desc: '1991' },
    ],
    'ES-MX_/browse/cars_/browse/cars/lotus': [
      { code: '/browse/cars/lotus/1998', desc: '1998' },
      { code: '/browse/cars/lotus/1997', desc: '1997' },
      { code: '/browse/cars/lotus/1996', desc: '1996' },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati': [
      { code: '/browse/cars/maserati/2018', desc: '2018' },
      { code: '/browse/cars/maserati/2017', desc: '2017' },
      { code: '/browse/cars/maserati/2016', desc: '2016' },
      { code: '/browse/cars/maserati/2015', desc: '2015' },
      { code: '/browse/cars/maserati/2014', desc: '2014' },
      { code: '/browse/cars/maserati/2013', desc: '2013' },
      { code: '/browse/cars/maserati/2012', desc: '2012' },
      { code: '/browse/cars/maserati/2011', desc: '2011' },
      { code: '/browse/cars/maserati/2010', desc: '2010' },
      { code: '/browse/cars/maserati/2009', desc: '2009' },
      { code: '/browse/cars/maserati/2008', desc: '2008' },
      { code: '/browse/cars/maserati/2007', desc: '2007' },
      { code: '/browse/cars/maserati/2006', desc: '2006' },
      { code: '/browse/cars/maserati/2005', desc: '2005' },
      { code: '/browse/cars/maserati/2004', desc: '2004' },
      { code: '/browse/cars/maserati/2003', desc: '2003' },
      { code: '/browse/cars/maserati/2002', desc: '2002' },
      { code: '/browse/cars/maserati/2001', desc: '2001' },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach': [
      { code: '/browse/cars/maybach/2012', desc: '2012' },
      { code: '/browse/cars/maybach/2011', desc: '2011' },
      { code: '/browse/cars/maybach/2010', desc: '2010' },
      { code: '/browse/cars/maybach/2009', desc: '2009' },
      { code: '/browse/cars/maybach/2008', desc: '2008' },
      { code: '/browse/cars/maybach/2007', desc: '2007' },
      { code: '/browse/cars/maybach/2006', desc: '2006' },
      { code: '/browse/cars/maybach/2005', desc: '2005' },
      { code: '/browse/cars/maybach/2004', desc: '2004' },
      { code: '/browse/cars/maybach/2003', desc: '2003' },
      { code: '/browse/cars/maybach/2002', desc: '2002' },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda': [
      { code: '/browse/cars/mazda/2022', desc: '2022' },
      { code: '/browse/cars/mazda/2021', desc: '2021' },
      { code: '/browse/cars/mazda/2020', desc: '2020' },
      { code: '/browse/cars/mazda/2019', desc: '2019' },
      { code: '/browse/cars/mazda/2018', desc: '2018' },
      { code: '/browse/cars/mazda/2017', desc: '2017' },
      { code: '/browse/cars/mazda/2016', desc: '2016' },
      { code: '/browse/cars/mazda/2015', desc: '2015' },
      { code: '/browse/cars/mazda/2014', desc: '2014' },
      { code: '/browse/cars/mazda/2013', desc: '2013' },
      { code: '/browse/cars/mazda/2012', desc: '2012' },
      { code: '/browse/cars/mazda/2011', desc: '2011' },
      { code: '/browse/cars/mazda/2010', desc: '2010' },
      { code: '/browse/cars/mazda/2009', desc: '2009' },
      { code: '/browse/cars/mazda/2008', desc: '2008' },
      { code: '/browse/cars/mazda/2007', desc: '2007' },
      { code: '/browse/cars/mazda/2006', desc: '2006' },
      { code: '/browse/cars/mazda/2005', desc: '2005' },
      { code: '/browse/cars/mazda/2004', desc: '2004' },
      { code: '/browse/cars/mazda/2003', desc: '2003' },
      { code: '/browse/cars/mazda/2002', desc: '2002' },
      { code: '/browse/cars/mazda/2001', desc: '2001' },
      { code: '/browse/cars/mazda/1991', desc: '1991' },
      { code: '/browse/cars/mazda/1990', desc: '1990' },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz': [
      { code: '/browse/cars/mercedes-benz/2022', desc: '2022' },
      { code: '/browse/cars/mercedes-benz/2021', desc: '2021' },
      { code: '/browse/cars/mercedes-benz/2020', desc: '2020' },
      { code: '/browse/cars/mercedes-benz/2019', desc: '2019' },
      { code: '/browse/cars/mercedes-benz/2018', desc: '2018' },
      { code: '/browse/cars/mercedes-benz/2017', desc: '2017' },
      { code: '/browse/cars/mercedes-benz/2016', desc: '2016' },
      { code: '/browse/cars/mercedes-benz/2015', desc: '2015' },
      { code: '/browse/cars/mercedes-benz/2014', desc: '2014' },
      { code: '/browse/cars/mercedes-benz/2013', desc: '2013' },
      { code: '/browse/cars/mercedes-benz/2012', desc: '2012' },
      { code: '/browse/cars/mercedes-benz/2011', desc: '2011' },
      { code: '/browse/cars/mercedes-benz/2010', desc: '2010' },
      { code: '/browse/cars/mercedes-benz/2009', desc: '2009' },
      { code: '/browse/cars/mercedes-benz/2008', desc: '2008' },
      { code: '/browse/cars/mercedes-benz/2007', desc: '2007' },
      { code: '/browse/cars/mercedes-benz/2006', desc: '2006' },
      { code: '/browse/cars/mercedes-benz/2005', desc: '2005' },
      { code: '/browse/cars/mercedes-benz/2004', desc: '2004' },
      { code: '/browse/cars/mercedes-benz/2003', desc: '2003' },
      { code: '/browse/cars/mercedes-benz/2002', desc: '2002' },
      { code: '/browse/cars/mercedes-benz/2001', desc: '2001' },
      { code: '/browse/cars/mercedes-benz/2000', desc: '2000' },
      { code: '/browse/cars/mercedes-benz/1999', desc: '1999' },
      { code: '/browse/cars/mercedes-benz/1998', desc: '1998' },
      { code: '/browse/cars/mercedes-benz/1997', desc: '1997' },
      { code: '/browse/cars/mercedes-benz/1996', desc: '1996' },
      { code: '/browse/cars/mercedes-benz/1995', desc: '1995' },
      { code: '/browse/cars/mercedes-benz/1994', desc: '1994' },
      { code: '/browse/cars/mercedes-benz/1993', desc: '1993' },
      { code: '/browse/cars/mercedes-benz/1992', desc: '1992' },
      { code: '/browse/cars/mercedes-benz/1991', desc: '1991' },
      { code: '/browse/cars/mercedes-benz/1990', desc: '1990' },
      { code: '/browse/cars/mercedes-benz/1989', desc: '1989' },
      { code: '/browse/cars/mercedes-benz/1988', desc: '1988' },
      { code: '/browse/cars/mercedes-benz/1987', desc: '1987' },
      { code: '/browse/cars/mercedes-benz/1986', desc: '1986' },
      { code: '/browse/cars/mercedes-benz/1985', desc: '1985' },
      { code: '/browse/cars/mercedes-benz/1984', desc: '1984' },
      { code: '/browse/cars/mercedes-benz/1983', desc: '1983' },
      { code: '/browse/cars/mercedes-benz/1982', desc: '1982' },
      { code: '/browse/cars/mercedes-benz/1981', desc: '1981' },
      { code: '/browse/cars/mercedes-benz/1980', desc: '1980' },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury': [
      { code: '/browse/cars/mercury/2011', desc: '2011' },
      { code: '/browse/cars/mercury/2010', desc: '2010' },
      { code: '/browse/cars/mercury/2009', desc: '2009' },
      { code: '/browse/cars/mercury/2008', desc: '2008' },
      { code: '/browse/cars/mercury/2007', desc: '2007' },
      { code: '/browse/cars/mercury/2006', desc: '2006' },
      { code: '/browse/cars/mercury/2005', desc: '2005' },
      { code: '/browse/cars/mercury/2004', desc: '2004' },
      { code: '/browse/cars/mercury/2003', desc: '2003' },
      { code: '/browse/cars/mercury/2002', desc: '2002' },
      { code: '/browse/cars/mercury/2001', desc: '2001' },
      { code: '/browse/cars/mercury/2000', desc: '2000' },
      { code: '/browse/cars/mercury/1999', desc: '1999' },
      { code: '/browse/cars/mercury/1998', desc: '1998' },
      { code: '/browse/cars/mercury/1997', desc: '1997' },
      { code: '/browse/cars/mercury/1996', desc: '1996' },
      { code: '/browse/cars/mercury/1995', desc: '1995' },
      { code: '/browse/cars/mercury/1994', desc: '1994' },
      { code: '/browse/cars/mercury/1993', desc: '1993' },
      { code: '/browse/cars/mercury/1992', desc: '1992' },
      { code: '/browse/cars/mercury/1991', desc: '1991' },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover': [
      { code: '/browse/cars/mg_rover/2005', desc: '2005' },
      { code: '/browse/cars/mg_rover/2004', desc: '2004' },
      { code: '/browse/cars/mg_rover/2003', desc: '2003' },
      { code: '/browse/cars/mg_rover/2002', desc: '2002' },
      { code: '/browse/cars/mg_rover/2001', desc: '2001' },
      { code: '/browse/cars/mg_rover/2000', desc: '2000' },
      { code: '/browse/cars/mg_rover/1999', desc: '1999' },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini': [
      { code: '/browse/cars/mini/2022', desc: '2022' },
      { code: '/browse/cars/mini/2021', desc: '2021' },
      { code: '/browse/cars/mini/2020', desc: '2020' },
      { code: '/browse/cars/mini/2019', desc: '2019' },
      { code: '/browse/cars/mini/2018', desc: '2018' },
      { code: '/browse/cars/mini/2017', desc: '2017' },
      { code: '/browse/cars/mini/2016', desc: '2016' },
      { code: '/browse/cars/mini/2015', desc: '2015' },
      { code: '/browse/cars/mini/2014', desc: '2014' },
      { code: '/browse/cars/mini/2013', desc: '2013' },
      { code: '/browse/cars/mini/2012', desc: '2012' },
      { code: '/browse/cars/mini/2011', desc: '2011' },
      { code: '/browse/cars/mini/2010', desc: '2010' },
      { code: '/browse/cars/mini/2009', desc: '2009' },
      { code: '/browse/cars/mini/2008', desc: '2008' },
      { code: '/browse/cars/mini/2007', desc: '2007' },
      { code: '/browse/cars/mini/2006', desc: '2006' },
      { code: '/browse/cars/mini/2005', desc: '2005' },
      { code: '/browse/cars/mini/2004', desc: '2004' },
      { code: '/browse/cars/mini/2003', desc: '2003' },
      { code: '/browse/cars/mini/2002', desc: '2002' },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi': [
      { code: '/browse/cars/mitsubishi/2022', desc: '2022' },
      { code: '/browse/cars/mitsubishi/2021', desc: '2021' },
      { code: '/browse/cars/mitsubishi/2020', desc: '2020' },
      { code: '/browse/cars/mitsubishi/2019', desc: '2019' },
      { code: '/browse/cars/mitsubishi/2018', desc: '2018' },
      { code: '/browse/cars/mitsubishi/2017', desc: '2017' },
      { code: '/browse/cars/mitsubishi/2016', desc: '2016' },
      { code: '/browse/cars/mitsubishi/2015', desc: '2015' },
      { code: '/browse/cars/mitsubishi/2014', desc: '2014' },
      { code: '/browse/cars/mitsubishi/2013', desc: '2013' },
      { code: '/browse/cars/mitsubishi/2012', desc: '2012' },
      { code: '/browse/cars/mitsubishi/2011', desc: '2011' },
      { code: '/browse/cars/mitsubishi/2010', desc: '2010' },
      { code: '/browse/cars/mitsubishi/2009', desc: '2009' },
      { code: '/browse/cars/mitsubishi/2008', desc: '2008' },
      { code: '/browse/cars/mitsubishi/2007', desc: '2007' },
      { code: '/browse/cars/mitsubishi/2006', desc: '2006' },
      { code: '/browse/cars/mitsubishi/2005', desc: '2005' },
      { code: '/browse/cars/mitsubishi/2004', desc: '2004' },
      { code: '/browse/cars/mitsubishi/2003', desc: '2003' },
      { code: '/browse/cars/mitsubishi/2002', desc: '2002' },
      { code: '/browse/cars/mitsubishi/2001', desc: '2001' },
      { code: '/browse/cars/mitsubishi/2000', desc: '2000' },
      { code: '/browse/cars/mitsubishi/1999', desc: '1999' },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan': [
      { code: '/browse/cars/nissan/2022', desc: '2022' },
      { code: '/browse/cars/nissan/2021', desc: '2021' },
      { code: '/browse/cars/nissan/2020', desc: '2020' },
      { code: '/browse/cars/nissan/2019', desc: '2019' },
      { code: '/browse/cars/nissan/2018', desc: '2018' },
      { code: '/browse/cars/nissan/2017', desc: '2017' },
      { code: '/browse/cars/nissan/2016', desc: '2016' },
      { code: '/browse/cars/nissan/2015', desc: '2015' },
      { code: '/browse/cars/nissan/2014', desc: '2014' },
      { code: '/browse/cars/nissan/2013', desc: '2013' },
      { code: '/browse/cars/nissan/2012', desc: '2012' },
      { code: '/browse/cars/nissan/2011', desc: '2011' },
      { code: '/browse/cars/nissan/2010', desc: '2010' },
      { code: '/browse/cars/nissan/2009', desc: '2009' },
      { code: '/browse/cars/nissan/2008', desc: '2008' },
      { code: '/browse/cars/nissan/2007', desc: '2007' },
      { code: '/browse/cars/nissan/2006', desc: '2006' },
      { code: '/browse/cars/nissan/2005', desc: '2005' },
      { code: '/browse/cars/nissan/2004', desc: '2004' },
      { code: '/browse/cars/nissan/2003', desc: '2003' },
      { code: '/browse/cars/nissan/2002', desc: '2002' },
      { code: '/browse/cars/nissan/2001', desc: '2001' },
      { code: '/browse/cars/nissan/2000', desc: '2000' },
      { code: '/browse/cars/nissan/1999', desc: '1999' },
      { code: '/browse/cars/nissan/1998', desc: '1998' },
      { code: '/browse/cars/nissan/1997', desc: '1997' },
      { code: '/browse/cars/nissan/1996', desc: '1996' },
      { code: '/browse/cars/nissan/1995', desc: '1995' },
      { code: '/browse/cars/nissan/1994', desc: '1994' },
      { code: '/browse/cars/nissan/1993', desc: '1993' },
      { code: '/browse/cars/nissan/1992', desc: '1992' },
      { code: '/browse/cars/nissan/1991', desc: '1991' },
      { code: '/browse/cars/nissan/1990', desc: '1990' },
      { code: '/browse/cars/nissan/1989', desc: '1989' },
      { code: '/browse/cars/nissan/1988', desc: '1988' },
      { code: '/browse/cars/nissan/1987', desc: '1987' },
      { code: '/browse/cars/nissan/1986', desc: '1986' },
      { code: '/browse/cars/nissan/1985', desc: '1985' },
      { code: '/browse/cars/nissan/1984', desc: '1984' },
      { code: '/browse/cars/nissan/1983', desc: '1983' },
      { code: '/browse/cars/nissan/1982', desc: '1982' },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot': [
      { code: '/browse/cars/peugeot/2022', desc: '2022' },
      { code: '/browse/cars/peugeot/2021', desc: '2021' },
      { code: '/browse/cars/peugeot/2020', desc: '2020' },
      { code: '/browse/cars/peugeot/2019', desc: '2019' },
      { code: '/browse/cars/peugeot/2018', desc: '2018' },
      { code: '/browse/cars/peugeot/2017', desc: '2017' },
      { code: '/browse/cars/peugeot/2016', desc: '2016' },
      { code: '/browse/cars/peugeot/2015', desc: '2015' },
      { code: '/browse/cars/peugeot/2014', desc: '2014' },
      { code: '/browse/cars/peugeot/2013', desc: '2013' },
      { code: '/browse/cars/peugeot/2012', desc: '2012' },
      { code: '/browse/cars/peugeot/2011', desc: '2011' },
      { code: '/browse/cars/peugeot/2010', desc: '2010' },
      { code: '/browse/cars/peugeot/2009', desc: '2009' },
      { code: '/browse/cars/peugeot/2008', desc: '2008' },
      { code: '/browse/cars/peugeot/2007', desc: '2007' },
      { code: '/browse/cars/peugeot/2006', desc: '2006' },
      { code: '/browse/cars/peugeot/2005', desc: '2005' },
      { code: '/browse/cars/peugeot/2004', desc: '2004' },
      { code: '/browse/cars/peugeot/2003', desc: '2003' },
      { code: '/browse/cars/peugeot/2002', desc: '2002' },
      { code: '/browse/cars/peugeot/2001', desc: '2001' },
      { code: '/browse/cars/peugeot/2000', desc: '2000' },
      { code: '/browse/cars/peugeot/1999', desc: '1999' },
      { code: '/browse/cars/peugeot/1998', desc: '1998' },
      { code: '/browse/cars/peugeot/1997', desc: '1997' },
      { code: '/browse/cars/peugeot/1996', desc: '1996' },
      { code: '/browse/cars/peugeot/1995', desc: '1995' },
      { code: '/browse/cars/peugeot/1994', desc: '1994' },
      { code: '/browse/cars/peugeot/1993', desc: '1993' },
      { code: '/browse/cars/peugeot/1992', desc: '1992' },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac': [
      { code: '/browse/cars/pontiac/2010', desc: '2010' },
      { code: '/browse/cars/pontiac/2009', desc: '2009' },
      { code: '/browse/cars/pontiac/2008', desc: '2008' },
      { code: '/browse/cars/pontiac/2007', desc: '2007' },
      { code: '/browse/cars/pontiac/2006', desc: '2006' },
      { code: '/browse/cars/pontiac/2005', desc: '2005' },
      { code: '/browse/cars/pontiac/2004', desc: '2004' },
      { code: '/browse/cars/pontiac/2003', desc: '2003' },
      { code: '/browse/cars/pontiac/2002', desc: '2002' },
      { code: '/browse/cars/pontiac/2001', desc: '2001' },
      { code: '/browse/cars/pontiac/2000', desc: '2000' },
      { code: '/browse/cars/pontiac/1999', desc: '1999' },
      { code: '/browse/cars/pontiac/1998', desc: '1998' },
      { code: '/browse/cars/pontiac/1997', desc: '1997' },
      { code: '/browse/cars/pontiac/1996', desc: '1996' },
      { code: '/browse/cars/pontiac/1995', desc: '1995' },
      { code: '/browse/cars/pontiac/1994', desc: '1994' },
      { code: '/browse/cars/pontiac/1993', desc: '1993' },
      { code: '/browse/cars/pontiac/1992', desc: '1992' },
      { code: '/browse/cars/pontiac/1991', desc: '1991' },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche': [
      { code: '/browse/cars/porsche/2022', desc: '2022' },
      { code: '/browse/cars/porsche/2021', desc: '2021' },
      { code: '/browse/cars/porsche/2020', desc: '2020' },
      { code: '/browse/cars/porsche/2019', desc: '2019' },
      { code: '/browse/cars/porsche/2018', desc: '2018' },
      { code: '/browse/cars/porsche/2017', desc: '2017' },
      { code: '/browse/cars/porsche/2016', desc: '2016' },
      { code: '/browse/cars/porsche/2015', desc: '2015' },
      { code: '/browse/cars/porsche/2014', desc: '2014' },
      { code: '/browse/cars/porsche/2013', desc: '2013' },
      { code: '/browse/cars/porsche/2012', desc: '2012' },
      { code: '/browse/cars/porsche/2011', desc: '2011' },
      { code: '/browse/cars/porsche/2010', desc: '2010' },
      { code: '/browse/cars/porsche/2009', desc: '2009' },
      { code: '/browse/cars/porsche/2008', desc: '2008' },
      { code: '/browse/cars/porsche/2007', desc: '2007' },
      { code: '/browse/cars/porsche/2006', desc: '2006' },
      { code: '/browse/cars/porsche/2005', desc: '2005' },
      { code: '/browse/cars/porsche/2004', desc: '2004' },
      { code: '/browse/cars/porsche/2003', desc: '2003' },
      { code: '/browse/cars/porsche/2002', desc: '2002' },
      { code: '/browse/cars/porsche/2001', desc: '2001' },
      { code: '/browse/cars/porsche/2000', desc: '2000' },
      { code: '/browse/cars/porsche/1999', desc: '1999' },
      { code: '/browse/cars/porsche/1998', desc: '1998' },
      { code: '/browse/cars/porsche/1997', desc: '1997' },
      { code: '/browse/cars/porsche/1996', desc: '1996' },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks': [
      { code: '/browse/cars/ram_trucks/2022', desc: '2022' },
      { code: '/browse/cars/ram_trucks/2021', desc: '2021' },
      { code: '/browse/cars/ram_trucks/2020', desc: '2020' },
      { code: '/browse/cars/ram_trucks/2019', desc: '2019' },
      { code: '/browse/cars/ram_trucks/2018', desc: '2018' },
      { code: '/browse/cars/ram_trucks/2017', desc: '2017' },
      { code: '/browse/cars/ram_trucks/2016', desc: '2016' },
      { code: '/browse/cars/ram_trucks/2015', desc: '2015' },
      { code: '/browse/cars/ram_trucks/2014', desc: '2014' },
      { code: '/browse/cars/ram_trucks/2013', desc: '2013' },
      { code: '/browse/cars/ram_trucks/2012', desc: '2012' },
      { code: '/browse/cars/ram_trucks/2011', desc: '2011' },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault': [
      { code: '/browse/cars/renault/2022', desc: '2022' },
      { code: '/browse/cars/renault/2021', desc: '2021' },
      { code: '/browse/cars/renault/2020', desc: '2020' },
      { code: '/browse/cars/renault/2019', desc: '2019' },
      { code: '/browse/cars/renault/2018', desc: '2018' },
      { code: '/browse/cars/renault/2017', desc: '2017' },
      { code: '/browse/cars/renault/2016', desc: '2016' },
      { code: '/browse/cars/renault/2015', desc: '2015' },
      { code: '/browse/cars/renault/2014', desc: '2014' },
      { code: '/browse/cars/renault/2013', desc: '2013' },
      { code: '/browse/cars/renault/2012', desc: '2012' },
      { code: '/browse/cars/renault/2011', desc: '2011' },
      { code: '/browse/cars/renault/2010', desc: '2010' },
      { code: '/browse/cars/renault/2009', desc: '2009' },
      { code: '/browse/cars/renault/2008', desc: '2008' },
      { code: '/browse/cars/renault/2007', desc: '2007' },
      { code: '/browse/cars/renault/2006', desc: '2006' },
      { code: '/browse/cars/renault/2005', desc: '2005' },
      { code: '/browse/cars/renault/2004', desc: '2004' },
      { code: '/browse/cars/renault/2003', desc: '2003' },
      { code: '/browse/cars/renault/2002', desc: '2002' },
      { code: '/browse/cars/renault/2001', desc: '2001' },
      { code: '/browse/cars/renault/2000', desc: '2000' },
      { code: '/browse/cars/renault/1999', desc: '1999' },
      { code: '/browse/cars/renault/1998', desc: '1998' },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab': [
      { code: '/browse/cars/saab/2019', desc: '2019' },
      { code: '/browse/cars/saab/2018', desc: '2018' },
      { code: '/browse/cars/saab/2017', desc: '2017' },
      { code: '/browse/cars/saab/2016', desc: '2016' },
      { code: '/browse/cars/saab/2015', desc: '2015' },
      { code: '/browse/cars/saab/2014', desc: '2014' },
      { code: '/browse/cars/saab/2013', desc: '2013' },
      { code: '/browse/cars/saab/2012', desc: '2012' },
      { code: '/browse/cars/saab/2011', desc: '2011' },
      { code: '/browse/cars/saab/2010', desc: '2010' },
      { code: '/browse/cars/saab/2009', desc: '2009' },
      { code: '/browse/cars/saab/2008', desc: '2008' },
      { code: '/browse/cars/saab/2007', desc: '2007' },
      { code: '/browse/cars/saab/2006', desc: '2006' },
      { code: '/browse/cars/saab/2005', desc: '2005' },
      { code: '/browse/cars/saab/2004', desc: '2004' },
      { code: '/browse/cars/saab/2003', desc: '2003' },
      { code: '/browse/cars/saab/2002', desc: '2002' },
      { code: '/browse/cars/saab/2001', desc: '2001' },
      { code: '/browse/cars/saab/2000', desc: '2000' },
      { code: '/browse/cars/saab/1999', desc: '1999' },
      { code: '/browse/cars/saab/1998', desc: '1998' },
      { code: '/browse/cars/saab/1997', desc: '1997' },
      { code: '/browse/cars/saab/1993', desc: '1993' },
      { code: '/browse/cars/saab/1992', desc: '1992' },
      { code: '/browse/cars/saab/1991', desc: '1991' },
      { code: '/browse/cars/saab/1990', desc: '1990' },
      { code: '/browse/cars/saab/1989', desc: '1989' },
      { code: '/browse/cars/saab/1988', desc: '1988' },
      { code: '/browse/cars/saab/1987', desc: '1987' },
      { code: '/browse/cars/saab/1986', desc: '1986' },
      { code: '/browse/cars/saab/1985', desc: '1985' },
      { code: '/browse/cars/saab/1984', desc: '1984' },
      { code: '/browse/cars/saab/1983', desc: '1983' },
      { code: '/browse/cars/saab/1982', desc: '1982' },
      { code: '/browse/cars/saab/1981', desc: '1981' },
      { code: '/browse/cars/saab/1980', desc: '1980' },
      { code: '/browse/cars/saab/1979', desc: '1979' },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat': [
      { code: '/browse/cars/seat/2022', desc: '2022' },
      { code: '/browse/cars/seat/2021', desc: '2021' },
      { code: '/browse/cars/seat/2020', desc: '2020' },
      { code: '/browse/cars/seat/2019', desc: '2019' },
      { code: '/browse/cars/seat/2018', desc: '2018' },
      { code: '/browse/cars/seat/2017', desc: '2017' },
      { code: '/browse/cars/seat/2016', desc: '2016' },
      { code: '/browse/cars/seat/2015', desc: '2015' },
      { code: '/browse/cars/seat/2014', desc: '2014' },
      { code: '/browse/cars/seat/2013', desc: '2013' },
      { code: '/browse/cars/seat/2012', desc: '2012' },
      { code: '/browse/cars/seat/2011', desc: '2011' },
      { code: '/browse/cars/seat/2010', desc: '2010' },
      { code: '/browse/cars/seat/2009', desc: '2009' },
      { code: '/browse/cars/seat/2008', desc: '2008' },
      { code: '/browse/cars/seat/2007', desc: '2007' },
      { code: '/browse/cars/seat/2006', desc: '2006' },
      { code: '/browse/cars/seat/2005', desc: '2005' },
      { code: '/browse/cars/seat/2004', desc: '2004' },
      { code: '/browse/cars/seat/2003', desc: '2003' },
      { code: '/browse/cars/seat/2002', desc: '2002' },
      { code: '/browse/cars/seat/2001', desc: '2001' },
      { code: '/browse/cars/seat/2000', desc: '2000' },
      { code: '/browse/cars/seat/1999', desc: '1999' },
      { code: '/browse/cars/seat/1998', desc: '1998' },
      { code: '/browse/cars/seat/1997', desc: '1997' },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart': [
      { code: '/browse/cars/smart/2022', desc: '2022' },
      { code: '/browse/cars/smart/2021', desc: '2021' },
      { code: '/browse/cars/smart/2020', desc: '2020' },
      { code: '/browse/cars/smart/2019', desc: '2019' },
      { code: '/browse/cars/smart/2018', desc: '2018' },
      { code: '/browse/cars/smart/2017', desc: '2017' },
      { code: '/browse/cars/smart/2016', desc: '2016' },
      { code: '/browse/cars/smart/2015', desc: '2015' },
      { code: '/browse/cars/smart/2014', desc: '2014' },
      { code: '/browse/cars/smart/2013', desc: '2013' },
      { code: '/browse/cars/smart/2012', desc: '2012' },
      { code: '/browse/cars/smart/2011', desc: '2011' },
      { code: '/browse/cars/smart/2010', desc: '2010' },
      { code: '/browse/cars/smart/2009', desc: '2009' },
      { code: '/browse/cars/smart/2008', desc: '2008' },
      { code: '/browse/cars/smart/2007', desc: '2007' },
      { code: '/browse/cars/smart/2006', desc: '2006' },
      { code: '/browse/cars/smart/2005', desc: '2005' },
      { code: '/browse/cars/smart/2004', desc: '2004' },
      { code: '/browse/cars/smart/2003', desc: '2003' },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru': [
      { code: '/browse/cars/subaru/2022', desc: '2022' },
      { code: '/browse/cars/subaru/2021', desc: '2021' },
      { code: '/browse/cars/subaru/2020', desc: '2020' },
      { code: '/browse/cars/subaru/2019', desc: '2019' },
      { code: '/browse/cars/subaru/2018', desc: '2018' },
      { code: '/browse/cars/subaru/2017', desc: '2017' },
      { code: '/browse/cars/subaru/2016', desc: '2016' },
      { code: '/browse/cars/subaru/2015', desc: '2015' },
      { code: '/browse/cars/subaru/2014', desc: '2014' },
      { code: '/browse/cars/subaru/2013', desc: '2013' },
      { code: '/browse/cars/subaru/2012', desc: '2012' },
      { code: '/browse/cars/subaru/2011', desc: '2011' },
      { code: '/browse/cars/subaru/2010', desc: '2010' },
      { code: '/browse/cars/subaru/2009', desc: '2009' },
      { code: '/browse/cars/subaru/2008', desc: '2008' },
      { code: '/browse/cars/subaru/2007', desc: '2007' },
      { code: '/browse/cars/subaru/2006', desc: '2006' },
      { code: '/browse/cars/subaru/2005', desc: '2005' },
      { code: '/browse/cars/subaru/2004', desc: '2004' },
      { code: '/browse/cars/subaru/2003', desc: '2003' },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki': [
      { code: '/browse/cars/suzuki/2022', desc: '2022' },
      { code: '/browse/cars/suzuki/2021', desc: '2021' },
      { code: '/browse/cars/suzuki/2020', desc: '2020' },
      { code: '/browse/cars/suzuki/2019', desc: '2019' },
      { code: '/browse/cars/suzuki/2018', desc: '2018' },
      { code: '/browse/cars/suzuki/2017', desc: '2017' },
      { code: '/browse/cars/suzuki/2016', desc: '2016' },
      { code: '/browse/cars/suzuki/2015', desc: '2015' },
      { code: '/browse/cars/suzuki/2014', desc: '2014' },
      { code: '/browse/cars/suzuki/2013', desc: '2013' },
      { code: '/browse/cars/suzuki/2012', desc: '2012' },
      { code: '/browse/cars/suzuki/2011', desc: '2011' },
      { code: '/browse/cars/suzuki/2010', desc: '2010' },
      { code: '/browse/cars/suzuki/2009', desc: '2009' },
      { code: '/browse/cars/suzuki/2008', desc: '2008' },
      { code: '/browse/cars/suzuki/2007', desc: '2007' },
      { code: '/browse/cars/suzuki/2006', desc: '2006' },
      { code: '/browse/cars/suzuki/2005', desc: '2005' },
      { code: '/browse/cars/suzuki/2004', desc: '2004' },
      { code: '/browse/cars/suzuki/2003', desc: '2003' },
      { code: '/browse/cars/suzuki/2002', desc: '2002' },
      { code: '/browse/cars/suzuki/2001', desc: '2001' },
      { code: '/browse/cars/suzuki/2000', desc: '2000' },
      { code: '/browse/cars/suzuki/1999', desc: '1999' },
      { code: '/browse/cars/suzuki/1998', desc: '1998' },
      { code: '/browse/cars/suzuki/1997', desc: '1997' },
      { code: '/browse/cars/suzuki/1996', desc: '1996' },
      { code: '/browse/cars/suzuki/1995', desc: '1995' },
      { code: '/browse/cars/suzuki/1994', desc: '1994' },
      { code: '/browse/cars/suzuki/1993', desc: '1993' },
      { code: '/browse/cars/suzuki/1992', desc: '1992' },
      { code: '/browse/cars/suzuki/1991', desc: '1991' },
      { code: '/browse/cars/suzuki/1990', desc: '1990' },
      { code: '/browse/cars/suzuki/1989', desc: '1989' },
      { code: '/browse/cars/suzuki/1988', desc: '1988' },
      { code: '/browse/cars/suzuki/1987', desc: '1987' },
      { code: '/browse/cars/suzuki/1986', desc: '1986' },
      { code: '/browse/cars/suzuki/1985', desc: '1985' },
      { code: '/browse/cars/suzuki/1984', desc: '1984' },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota': [
      { code: '/browse/cars/toyota/2022', desc: '2022' },
      { code: '/browse/cars/toyota/2021', desc: '2021' },
      { code: '/browse/cars/toyota/2020', desc: '2020' },
      { code: '/browse/cars/toyota/2019', desc: '2019' },
      { code: '/browse/cars/toyota/2018', desc: '2018' },
      { code: '/browse/cars/toyota/2017', desc: '2017' },
      { code: '/browse/cars/toyota/2016', desc: '2016' },
      { code: '/browse/cars/toyota/2015', desc: '2015' },
      { code: '/browse/cars/toyota/2014', desc: '2014' },
      { code: '/browse/cars/toyota/2013', desc: '2013' },
      { code: '/browse/cars/toyota/2012', desc: '2012' },
      { code: '/browse/cars/toyota/2011', desc: '2011' },
      { code: '/browse/cars/toyota/2010', desc: '2010' },
      { code: '/browse/cars/toyota/2009', desc: '2009' },
      { code: '/browse/cars/toyota/2008', desc: '2008' },
      { code: '/browse/cars/toyota/2007', desc: '2007' },
      { code: '/browse/cars/toyota/2006', desc: '2006' },
      { code: '/browse/cars/toyota/2005', desc: '2005' },
      { code: '/browse/cars/toyota/2004', desc: '2004' },
      { code: '/browse/cars/toyota/2003', desc: '2003' },
      { code: '/browse/cars/toyota/2002', desc: '2002' },
      { code: '/browse/cars/toyota/2001', desc: '2001' },
      { code: '/browse/cars/toyota/2000', desc: '2000' },
      { code: '/browse/cars/toyota/1999', desc: '1999' },
      { code: '/browse/cars/toyota/1998', desc: '1998' },
      { code: '/browse/cars/toyota/1997', desc: '1997' },
      { code: '/browse/cars/toyota/1995', desc: '1995' },
      { code: '/browse/cars/toyota/1994', desc: '1994' },
      { code: '/browse/cars/toyota/1993', desc: '1993' },
      { code: '/browse/cars/toyota/1992', desc: '1992' },
      { code: '/browse/cars/toyota/1991', desc: '1991' },
      { code: '/browse/cars/toyota/1990', desc: '1990' },
      { code: '/browse/cars/toyota/1989', desc: '1989' },
      { code: '/browse/cars/toyota/1988', desc: '1988' },
      { code: '/browse/cars/toyota/1987', desc: '1987' },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen': [
      { code: '/browse/cars/volkswagen/2022', desc: '2022' },
      { code: '/browse/cars/volkswagen/2021', desc: '2021' },
      { code: '/browse/cars/volkswagen/2020', desc: '2020' },
      { code: '/browse/cars/volkswagen/2019', desc: '2019' },
      { code: '/browse/cars/volkswagen/2018', desc: '2018' },
      { code: '/browse/cars/volkswagen/2017', desc: '2017' },
      { code: '/browse/cars/volkswagen/2016', desc: '2016' },
      { code: '/browse/cars/volkswagen/2015', desc: '2015' },
      { code: '/browse/cars/volkswagen/2014', desc: '2014' },
      { code: '/browse/cars/volkswagen/2013', desc: '2013' },
      { code: '/browse/cars/volkswagen/2012', desc: '2012' },
      { code: '/browse/cars/volkswagen/2011', desc: '2011' },
      { code: '/browse/cars/volkswagen/2010', desc: '2010' },
      { code: '/browse/cars/volkswagen/2009', desc: '2009' },
      { code: '/browse/cars/volkswagen/2008', desc: '2008' },
      { code: '/browse/cars/volkswagen/2007', desc: '2007' },
      { code: '/browse/cars/volkswagen/2006', desc: '2006' },
      { code: '/browse/cars/volkswagen/2005', desc: '2005' },
      { code: '/browse/cars/volkswagen/2004', desc: '2004' },
      { code: '/browse/cars/volkswagen/2003', desc: '2003' },
      { code: '/browse/cars/volkswagen/2002', desc: '2002' },
      { code: '/browse/cars/volkswagen/2001', desc: '2001' },
      { code: '/browse/cars/volkswagen/2000', desc: '2000' },
      { code: '/browse/cars/volkswagen/1999', desc: '1999' },
      { code: '/browse/cars/volkswagen/1998', desc: '1998' },
      { code: '/browse/cars/volkswagen/1997', desc: '1997' },
      { code: '/browse/cars/volkswagen/1996', desc: '1996' },
      { code: '/browse/cars/volkswagen/1995', desc: '1995' },
      { code: '/browse/cars/volkswagen/1994', desc: '1994' },
      { code: '/browse/cars/volkswagen/1993', desc: '1993' },
      { code: '/browse/cars/volkswagen/1992', desc: '1992' },
      { code: '/browse/cars/volkswagen/1991', desc: '1991' },
      { code: '/browse/cars/volkswagen/1990', desc: '1990' },
      { code: '/browse/cars/volkswagen/1989', desc: '1989' },
      { code: '/browse/cars/volkswagen/1988', desc: '1988' },
      { code: '/browse/cars/volkswagen/1987', desc: '1987' },
      { code: '/browse/cars/volkswagen/1986', desc: '1986' },
      { code: '/browse/cars/volkswagen/1985', desc: '1985' },
      { code: '/browse/cars/volkswagen/1984', desc: '1984' },
      { code: '/browse/cars/volkswagen/1983', desc: '1983' },
      { code: '/browse/cars/volkswagen/1982', desc: '1982' },
      { code: '/browse/cars/volkswagen/1981', desc: '1981' },
      { code: '/browse/cars/volkswagen/1980', desc: '1980' },
      { code: '/browse/cars/volkswagen/1979', desc: '1979' },
      { code: '/browse/cars/volkswagen/1978', desc: '1978' },
      { code: '/browse/cars/volkswagen/1977', desc: '1977' },
      { code: '/browse/cars/volkswagen/1976', desc: '1976' },
      { code: '/browse/cars/volkswagen/1975', desc: '1975' },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo': [
      { code: '/browse/cars/volvo/2022', desc: '2022' },
      { code: '/browse/cars/volvo/2021', desc: '2021' },
      { code: '/browse/cars/volvo/2020', desc: '2020' },
      { code: '/browse/cars/volvo/2019', desc: '2019' },
      { code: '/browse/cars/volvo/2018', desc: '2018' },
      { code: '/browse/cars/volvo/2017', desc: '2017' },
      { code: '/browse/cars/volvo/2016', desc: '2016' },
      { code: '/browse/cars/volvo/2015', desc: '2015' },
      { code: '/browse/cars/volvo/2014', desc: '2014' },
      { code: '/browse/cars/volvo/2013', desc: '2013' },
      { code: '/browse/cars/volvo/2012', desc: '2012' },
      { code: '/browse/cars/volvo/2011', desc: '2011' },
      { code: '/browse/cars/volvo/2010', desc: '2010' },
      { code: '/browse/cars/volvo/2009', desc: '2009' },
      { code: '/browse/cars/volvo/2008', desc: '2008' },
      { code: '/browse/cars/volvo/2007', desc: '2007' },
      { code: '/browse/cars/volvo/2006', desc: '2006' },
      { code: '/browse/cars/volvo/2005', desc: '2005' },
      { code: '/browse/cars/volvo/2004', desc: '2004' },
      { code: '/browse/cars/volvo/2003', desc: '2003' },
      { code: '/browse/cars/volvo/2002', desc: '2002' },
      { code: '/browse/cars/volvo/2001', desc: '2001' },
      { code: '/browse/cars/volvo/2000', desc: '2000' },
      { code: '/browse/cars/volvo/1999', desc: '1999' },
      { code: '/browse/cars/volvo/1998', desc: '1998' },
      { code: '/browse/cars/volvo/1997', desc: '1997' },
      { code: '/browse/cars/volvo/1996', desc: '1996' },
      { code: '/browse/cars/volvo/1995', desc: '1995' },
      { code: '/browse/cars/volvo/1993', desc: '1993' },
      { code: '/browse/cars/volvo/1992', desc: '1992' },
      { code: '/browse/cars/volvo/1991', desc: '1991' },
      { code: '/browse/cars/volvo/1990', desc: '1990' },
      { code: '/browse/cars/volvo/1989', desc: '1989' },
      { code: '/browse/cars/volvo/1988', desc: '1988' },
      { code: '/browse/cars/volvo/1987', desc: '1987' },
      { code: '/browse/cars/volvo/1986', desc: '1986' },
      { code: '/browse/cars/volvo/1985', desc: '1985' },
      { code: '/browse/cars/volvo/1984', desc: '1984' },
      { code: '/browse/cars/volvo/1983', desc: '1983' },
      { code: '/browse/cars/volvo/1982', desc: '1982' },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen': [
      { code: '/browse/vans/volkswagen/2021', desc: '2021' },
      { code: '/browse/vans/volkswagen/2020', desc: '2020' },
      { code: '/browse/vans/volkswagen/2019', desc: '2019' },
      { code: '/browse/vans/volkswagen/2018', desc: '2018' },
      { code: '/browse/vans/volkswagen/2017', desc: '2017' },
      { code: '/browse/vans/volkswagen/2016', desc: '2016' },
      { code: '/browse/vans/volkswagen/2015', desc: '2015' },
      { code: '/browse/vans/volkswagen/2014', desc: '2014' },
      { code: '/browse/vans/volkswagen/2013', desc: '2013' },
      { code: '/browse/vans/volkswagen/2012', desc: '2012' },
      { code: '/browse/vans/volkswagen/2011', desc: '2011' },
      { code: '/browse/vans/volkswagen/2010', desc: '2010' },
      { code: '/browse/vans/volkswagen/2009', desc: '2009' },
      { code: '/browse/vans/volkswagen/2008', desc: '2008' },
      { code: '/browse/vans/volkswagen/2007', desc: '2007' },
      { code: '/browse/vans/volkswagen/2006', desc: '2006' },
      { code: '/browse/vans/volkswagen/1996', desc: '1996' },
      { code: '/browse/vans/volkswagen/1995', desc: '1995' },
      { code: '/browse/vans/volkswagen/1994', desc: '1994' },
      { code: '/browse/vans/volkswagen/1993', desc: '1993' },
      { code: '/browse/vans/volkswagen/1992', desc: '1992' },
      { code: '/browse/vans/volkswagen/1991', desc: '1991' },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz': [
      { code: '/browse/vans/mercedes-benz/2016', desc: '2016' },
      { code: '/browse/vans/mercedes-benz/2015', desc: '2015' },
      { code: '/browse/vans/mercedes-benz/2014', desc: '2014' },
      { code: '/browse/vans/mercedes-benz/2013', desc: '2013' },
      { code: '/browse/vans/mercedes-benz/2012', desc: '2012' },
      { code: '/browse/vans/mercedes-benz/2011', desc: '2011' },
      { code: '/browse/vans/mercedes-benz/2010', desc: '2010' },
      { code: '/browse/vans/mercedes-benz/2009', desc: '2009' },
      { code: '/browse/vans/mercedes-benz/2008', desc: '2008' },
      { code: '/browse/vans/mercedes-benz/2007', desc: '2007' },
      { code: '/browse/vans/mercedes-benz/2006', desc: '2006' },
      { code: '/browse/vans/mercedes-benz/2005', desc: '2005' },
      { code: '/browse/vans/mercedes-benz/2004', desc: '2004' },
      { code: '/browse/vans/mercedes-benz/2003', desc: '2003' },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford': [
      { code: '/browse/vans/ford/2022', desc: '2022' },
      { code: '/browse/vans/ford/2021', desc: '2021' },
      { code: '/browse/vans/ford/2020', desc: '2020' },
      { code: '/browse/vans/ford/2019', desc: '2019' },
      { code: '/browse/vans/ford/2018', desc: '2018' },
      { code: '/browse/vans/ford/2017', desc: '2017' },
      { code: '/browse/vans/ford/2016', desc: '2016' },
      { code: '/browse/vans/ford/2015', desc: '2015' },
      { code: '/browse/vans/ford/2014', desc: '2014' },
      { code: '/browse/vans/ford/2013', desc: '2013' },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu': [
      { code: '/browse/vans/isuzu/2018', desc: '2018' },
      { code: '/browse/vans/isuzu/2017', desc: '2017' },
      { code: '/browse/vans/isuzu/2016', desc: '2016' },
      { code: '/browse/vans/isuzu/2015', desc: '2015' },
      { code: '/browse/vans/isuzu/2014', desc: '2014' },
      { code: '/browse/vans/isuzu/2013', desc: '2013' },
      { code: '/browse/vans/isuzu/2012', desc: '2012' },
      { code: '/browse/vans/isuzu/2011', desc: '2011' },
      { code: '/browse/vans/isuzu/2010', desc: '2010' },
      { code: '/browse/vans/isuzu/2009', desc: '2009' },
      { code: '/browse/vans/isuzu/2008', desc: '2008' },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover': [
      { code: '/browse/vans/land_rover/2021', desc: '2021' },
      { code: '/browse/vans/land_rover/2020', desc: '2020' },
      { code: '/browse/vans/land_rover/2019', desc: '2019' },
      { code: '/browse/vans/land_rover/2018', desc: '2018' },
      { code: '/browse/vans/land_rover/2017', desc: '2017' },
      { code: '/browse/vans/land_rover/2016', desc: '2016' },
      { code: '/browse/vans/land_rover/2015', desc: '2015' },
      { code: '/browse/vans/land_rover/2014', desc: '2014' },
      { code: '/browse/vans/land_rover/2013', desc: '2013' },
      { code: '/browse/vans/land_rover/2012', desc: '2012' },
      { code: '/browse/vans/land_rover/2011', desc: '2011' },
      { code: '/browse/vans/land_rover/2006', desc: '2006' },
      { code: '/browse/vans/land_rover/2005', desc: '2005' },
      { code: '/browse/vans/land_rover/2004', desc: '2004' },
      { code: '/browse/vans/land_rover/2003', desc: '2003' },
      { code: '/browse/vans/land_rover/2002', desc: '2002' },
      { code: '/browse/vans/land_rover/2001', desc: '2001' },
      { code: '/browse/vans/land_rover/2000', desc: '2000' },
      { code: '/browse/vans/land_rover/1999', desc: '1999' },
      { code: '/browse/vans/land_rover/1998', desc: '1998' },
      { code: '/browse/vans/land_rover/1997', desc: '1997' },
      { code: '/browse/vans/land_rover/1996', desc: '1996' },
      { code: '/browse/vans/land_rover/1995', desc: '1995' },
      { code: '/browse/vans/land_rover/1994', desc: '1994' },
      { code: '/browse/vans/land_rover/1993', desc: '1993' },
      { code: '/browse/vans/land_rover/1992', desc: '1992' },
      { code: '/browse/vans/land_rover/1991', desc: '1991' },
      { code: '/browse/vans/land_rover/1990', desc: '1990' },
    ],
    'ES-MX_/browse/vans_/browse/vans/mitsubishi': [
      { code: '/browse/vans/mitsubishi/2010', desc: '2010' },
      { code: '/browse/vans/mitsubishi/2009', desc: '2009' },
      { code: '/browse/vans/mitsubishi/2008', desc: '2008' },
      { code: '/browse/vans/mitsubishi/2007', desc: '2007' },
      { code: '/browse/vans/mitsubishi/2006', desc: '2006' },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan': [
      { code: '/browse/vans/nissan/2013', desc: '2013' },
      { code: '/browse/vans/nissan/2012', desc: '2012' },
      { code: '/browse/vans/nissan/2011', desc: '2011' },
      { code: '/browse/vans/nissan/2010', desc: '2010' },
      { code: '/browse/vans/nissan/2009', desc: '2009' },
      { code: '/browse/vans/nissan/2008', desc: '2008' },
      { code: '/browse/vans/nissan/2007', desc: '2007' },
      { code: '/browse/vans/nissan/2006', desc: '2006' },
      { code: '/browse/vans/nissan/1995', desc: '1995' },
      { code: '/browse/vans/nissan/1994', desc: '1994' },
      { code: '/browse/vans/nissan/1993', desc: '1993' },
      { code: '/browse/vans/nissan/1992', desc: '1992' },
      { code: '/browse/vans/nissan/1991', desc: '1991' },
      { code: '/browse/vans/nissan/1990', desc: '1990' },
      { code: '/browse/vans/nissan/1989', desc: '1989' },
      { code: '/browse/vans/nissan/1988', desc: '1988' },
      { code: '/browse/vans/nissan/1987', desc: '1987' },
      { code: '/browse/vans/nissan/1986', desc: '1986' },
      { code: '/browse/vans/nissan/1985', desc: '1985' },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot': [
      { code: '/browse/vans/peugeot/2019', desc: '2019' },
      { code: '/browse/vans/peugeot/2018', desc: '2018' },
      { code: '/browse/vans/peugeot/2017', desc: '2017' },
      { code: '/browse/vans/peugeot/2016', desc: '2016' },
      { code: '/browse/vans/peugeot/2015', desc: '2015' },
      { code: '/browse/vans/peugeot/2014', desc: '2014' },
      { code: '/browse/vans/peugeot/2013', desc: '2013' },
      { code: '/browse/vans/peugeot/2012', desc: '2012' },
      { code: '/browse/vans/peugeot/2011', desc: '2011' },
      { code: '/browse/vans/peugeot/2010', desc: '2010' },
      { code: '/browse/vans/peugeot/2009', desc: '2009' },
      { code: '/browse/vans/peugeot/2008', desc: '2008' },
      { code: '/browse/vans/peugeot/2007', desc: '2007' },
      { code: '/browse/vans/peugeot/2002', desc: '2002' },
      { code: '/browse/vans/peugeot/2001', desc: '2001' },
      { code: '/browse/vans/peugeot/2000', desc: '2000' },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault': [
      { code: '/browse/vans/renault/2017', desc: '2017' },
      { code: '/browse/vans/renault/2016', desc: '2016' },
      { code: '/browse/vans/renault/2015', desc: '2015' },
      { code: '/browse/vans/renault/2014', desc: '2014' },
      { code: '/browse/vans/renault/2013', desc: '2013' },
      { code: '/browse/vans/renault/2012', desc: '2012' },
      { code: '/browse/vans/renault/2011', desc: '2011' },
      { code: '/browse/vans/renault/2010', desc: '2010' },
      { code: '/browse/vans/renault/2009', desc: '2009' },
      { code: '/browse/vans/renault/2008', desc: '2008' },
      { code: '/browse/vans/renault/2007', desc: '2007' },
      { code: '/browse/vans/renault/2006', desc: '2006' },
      { code: '/browse/vans/renault/2005', desc: '2005' },
      { code: '/browse/vans/renault/2004', desc: '2004' },
      { code: '/browse/vans/renault/2003', desc: '2003' },
      { code: '/browse/vans/renault/2002', desc: '2002' },
      { code: '/browse/vans/renault/2001', desc: '2001' },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota': [
      { code: '/browse/vans/toyota/2019', desc: '2019' },
      { code: '/browse/vans/toyota/2018', desc: '2018' },
      { code: '/browse/vans/toyota/2017', desc: '2017' },
      { code: '/browse/vans/toyota/2016', desc: '2016' },
      { code: '/browse/vans/toyota/2015', desc: '2015' },
      { code: '/browse/vans/toyota/2014', desc: '2014' },
      { code: '/browse/vans/toyota/2013', desc: '2013' },
      { code: '/browse/vans/toyota/2012', desc: '2012' },
      { code: '/browse/vans/toyota/2011', desc: '2011' },
      { code: '/browse/vans/toyota/2010', desc: '2010' },
      { code: '/browse/vans/toyota/2009', desc: '2009' },
      { code: '/browse/vans/toyota/2008', desc: '2008' },
      { code: '/browse/vans/toyota/2007', desc: '2007' },
      { code: '/browse/vans/toyota/2006', desc: '2006' },
      { code: '/browse/vans/toyota/2005', desc: '2005' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO': [
      { code: 'CARABELA-MOTO/2020', desc: '2020' },
      { code: 'CARABELA-MOTO/2019', desc: '2019' },
      { code: 'CARABELA-MOTO/2018', desc: '2018' },
      { code: 'CARABELA-MOTO/2017', desc: '2017' },
      { code: 'CARABELA-MOTO/2016', desc: '2016' },
      { code: 'CARABELA-MOTO/2015', desc: '2015' },
      { code: 'CARABELA-MOTO/2014', desc: '2014' },
      { code: 'CARABELA-MOTO/2013', desc: '2013' },
      { code: 'CARABELA-MOTO/2012', desc: '2012' },
      { code: 'CARABELA-MOTO/2011', desc: '2011' },
      { code: 'CARABELA-MOTO/2010', desc: '2010' },
      { code: 'CARABELA-MOTO/2009', desc: '2009' },
      { code: 'CARABELA-MOTO/2008', desc: '2008' },
      { code: 'CARABELA-MOTO/2007', desc: '2007' },
      { code: 'CARABELA-MOTO/2006', desc: '2006' },
      { code: 'CARABELA-MOTO/2005', desc: '2005' },
      { code: 'CARABELA-MOTO/2004', desc: '2004' },
      { code: 'CARABELA-MOTO/2003', desc: '2003' },
      { code: 'CARABELA-MOTO/2002', desc: '2002' },
      { code: 'CARABELA-MOTO/2001', desc: '2001' },
      { code: 'CARABELA-MOTO/2000', desc: '2000' },
      { code: 'CARABELA-MOTO/1999', desc: '1999' },
      { code: 'CARABELA-MOTO/1998', desc: '1998' },
      { code: 'CARABELA-MOTO/1997', desc: '1997' },
      { code: 'CARABELA-MOTO/1996', desc: '1996' },
      { code: 'CARABELA-MOTO/1995', desc: '1995' },
      { code: 'CARABELA-MOTO/1994', desc: '1994' },
      { code: 'CARABELA-MOTO/1993', desc: '1993' },
      { code: 'CARABELA-MOTO/1992', desc: '1992' },
      { code: 'CARABELA-MOTO/1991', desc: '1991' },
      { code: 'CARABELA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO': [
      { code: 'BAJAJ-MOTO/2020', desc: '2020' },
      { code: 'BAJAJ-MOTO/2019', desc: '2019' },
      { code: 'BAJAJ-MOTO/2018', desc: '2018' },
      { code: 'BAJAJ-MOTO/2017', desc: '2017' },
      { code: 'BAJAJ-MOTO/2016', desc: '2016' },
      { code: 'BAJAJ-MOTO/2015', desc: '2015' },
      { code: 'BAJAJ-MOTO/2014', desc: '2014' },
      { code: 'BAJAJ-MOTO/2013', desc: '2013' },
      { code: 'BAJAJ-MOTO/2012', desc: '2012' },
      { code: 'BAJAJ-MOTO/2011', desc: '2011' },
      { code: 'BAJAJ-MOTO/2010', desc: '2010' },
      { code: 'BAJAJ-MOTO/2009', desc: '2009' },
      { code: 'BAJAJ-MOTO/2008', desc: '2008' },
      { code: 'BAJAJ-MOTO/2007', desc: '2007' },
      { code: 'BAJAJ-MOTO/2006', desc: '2006' },
      { code: 'BAJAJ-MOTO/2005', desc: '2005' },
      { code: 'BAJAJ-MOTO/2004', desc: '2004' },
      { code: 'BAJAJ-MOTO/2003', desc: '2003' },
      { code: 'BAJAJ-MOTO/2002', desc: '2002' },
      { code: 'BAJAJ-MOTO/2001', desc: '2001' },
      { code: 'BAJAJ-MOTO/2000', desc: '2000' },
      { code: 'BAJAJ-MOTO/1999', desc: '1999' },
      { code: 'BAJAJ-MOTO/1998', desc: '1998' },
      { code: 'BAJAJ-MOTO/1997', desc: '1997' },
      { code: 'BAJAJ-MOTO/1996', desc: '1996' },
      { code: 'BAJAJ-MOTO/1995', desc: '1995' },
      { code: 'BAJAJ-MOTO/1994', desc: '1994' },
      { code: 'BAJAJ-MOTO/1993', desc: '1993' },
      { code: 'BAJAJ-MOTO/1992', desc: '1992' },
      { code: 'BAJAJ-MOTO/1991', desc: '1991' },
      { code: 'BAJAJ-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_BMW-MOTO': [
      { code: 'BMW-MOTO/2020', desc: '2020' },
      { code: 'BMW-MOTO/2019', desc: '2019' },
      { code: 'BMW-MOTO/2018', desc: '2018' },
      { code: 'BMW-MOTO/2017', desc: '2017' },
      { code: 'BMW-MOTO/2016', desc: '2016' },
      { code: 'BMW-MOTO/2015', desc: '2015' },
      { code: 'BMW-MOTO/2014', desc: '2014' },
      { code: 'BMW-MOTO/2013', desc: '2013' },
      { code: 'BMW-MOTO/2012', desc: '2012' },
      { code: 'BMW-MOTO/2011', desc: '2011' },
      { code: 'BMW-MOTO/2010', desc: '2010' },
      { code: 'BMW-MOTO/2009', desc: '2009' },
      { code: 'BMW-MOTO/2008', desc: '2008' },
      { code: 'BMW-MOTO/2007', desc: '2007' },
      { code: 'BMW-MOTO/2006', desc: '2006' },
      { code: 'BMW-MOTO/2005', desc: '2005' },
      { code: 'BMW-MOTO/2004', desc: '2004' },
      { code: 'BMW-MOTO/2003', desc: '2003' },
      { code: 'BMW-MOTO/2002', desc: '2002' },
      { code: 'BMW-MOTO/2001', desc: '2001' },
      { code: 'BMW-MOTO/2000', desc: '2000' },
      { code: 'BMW-MOTO/1999', desc: '1999' },
      { code: 'BMW-MOTO/1998', desc: '1998' },
      { code: 'BMW-MOTO/1997', desc: '1997' },
      { code: 'BMW-MOTO/1996', desc: '1996' },
      { code: 'BMW-MOTO/1995', desc: '1995' },
      { code: 'BMW-MOTO/1994', desc: '1994' },
      { code: 'BMW-MOTO/1993', desc: '1993' },
      { code: 'BMW-MOTO/1992', desc: '1992' },
      { code: 'BMW-MOTO/1991', desc: '1991' },
      { code: 'BMW-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_CANAM-MOTO': [
      { code: 'CANAM-MOTO/2020', desc: '2020' },
      { code: 'CANAM-MOTO/2019', desc: '2019' },
      { code: 'CANAM-MOTO/2018', desc: '2018' },
      { code: 'CANAM-MOTO/2017', desc: '2017' },
      { code: 'CANAM-MOTO/2016', desc: '2016' },
      { code: 'CANAM-MOTO/2015', desc: '2015' },
      { code: 'CANAM-MOTO/2014', desc: '2014' },
      { code: 'CANAM-MOTO/2013', desc: '2013' },
      { code: 'CANAM-MOTO/2012', desc: '2012' },
      { code: 'CANAM-MOTO/2011', desc: '2011' },
      { code: 'CANAM-MOTO/2010', desc: '2010' },
      { code: 'CANAM-MOTO/2009', desc: '2009' },
      { code: 'CANAM-MOTO/2008', desc: '2008' },
      { code: 'CANAM-MOTO/2007', desc: '2007' },
      { code: 'CANAM-MOTO/2006', desc: '2006' },
      { code: 'CANAM-MOTO/2005', desc: '2005' },
      { code: 'CANAM-MOTO/2004', desc: '2004' },
      { code: 'CANAM-MOTO/2003', desc: '2003' },
      { code: 'CANAM-MOTO/2002', desc: '2002' },
      { code: 'CANAM-MOTO/2001', desc: '2001' },
      { code: 'CANAM-MOTO/2000', desc: '2000' },
      { code: 'CANAM-MOTO/1999', desc: '1999' },
      { code: 'CANAM-MOTO/1998', desc: '1998' },
      { code: 'CANAM-MOTO/1997', desc: '1997' },
      { code: 'CANAM-MOTO/1996', desc: '1996' },
      { code: 'CANAM-MOTO/1995', desc: '1995' },
      { code: 'CANAM-MOTO/1994', desc: '1994' },
      { code: 'CANAM-MOTO/1993', desc: '1993' },
      { code: 'CANAM-MOTO/1992', desc: '1992' },
      { code: 'CANAM-MOTO/1991', desc: '1991' },
      { code: 'CANAM-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_DINAMO-MOTO': [
      { code: 'DINAMO-MOTO/2020', desc: '2020' },
      { code: 'DINAMO-MOTO/2019', desc: '2019' },
      { code: 'DINAMO-MOTO/2018', desc: '2018' },
      { code: 'DINAMO-MOTO/2017', desc: '2017' },
      { code: 'DINAMO-MOTO/2016', desc: '2016' },
      { code: 'DINAMO-MOTO/2015', desc: '2015' },
      { code: 'DINAMO-MOTO/2014', desc: '2014' },
      { code: 'DINAMO-MOTO/2013', desc: '2013' },
      { code: 'DINAMO-MOTO/2012', desc: '2012' },
      { code: 'DINAMO-MOTO/2011', desc: '2011' },
      { code: 'DINAMO-MOTO/2010', desc: '2010' },
      { code: 'DINAMO-MOTO/2009', desc: '2009' },
      { code: 'DINAMO-MOTO/2008', desc: '2008' },
      { code: 'DINAMO-MOTO/2007', desc: '2007' },
      { code: 'DINAMO-MOTO/2006', desc: '2006' },
      { code: 'DINAMO-MOTO/2005', desc: '2005' },
      { code: 'DINAMO-MOTO/2004', desc: '2004' },
      { code: 'DINAMO-MOTO/2003', desc: '2003' },
      { code: 'DINAMO-MOTO/2002', desc: '2002' },
      { code: 'DINAMO-MOTO/2001', desc: '2001' },
      { code: 'DINAMO-MOTO/2000', desc: '2000' },
      { code: 'DINAMO-MOTO/1999', desc: '1999' },
      { code: 'DINAMO-MOTO/1998', desc: '1998' },
      { code: 'DINAMO-MOTO/1997', desc: '1997' },
      { code: 'DINAMO-MOTO/1996', desc: '1996' },
      { code: 'DINAMO-MOTO/1995', desc: '1995' },
      { code: 'DINAMO-MOTO/1994', desc: '1994' },
      { code: 'DINAMO-MOTO/1993', desc: '1993' },
      { code: 'DINAMO-MOTO/1992', desc: '1992' },
      { code: 'DINAMO-MOTO/1991', desc: '1991' },
      { code: 'DINAMO-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_DUCATI-MOTO': [
      { code: 'DUCATI-MOTO/2020', desc: '2020' },
      { code: 'DUCATI-MOTO/2019', desc: '2019' },
      { code: 'DUCATI-MOTO/2018', desc: '2018' },
      { code: 'DUCATI-MOTO/2017', desc: '2017' },
      { code: 'DUCATI-MOTO/2016', desc: '2016' },
      { code: 'DUCATI-MOTO/2015', desc: '2015' },
      { code: 'DUCATI-MOTO/2014', desc: '2014' },
      { code: 'DUCATI-MOTO/2013', desc: '2013' },
      { code: 'DUCATI-MOTO/2012', desc: '2012' },
      { code: 'DUCATI-MOTO/2011', desc: '2011' },
      { code: 'DUCATI-MOTO/2010', desc: '2010' },
      { code: 'DUCATI-MOTO/2009', desc: '2009' },
      { code: 'DUCATI-MOTO/2008', desc: '2008' },
      { code: 'DUCATI-MOTO/2007', desc: '2007' },
      { code: 'DUCATI-MOTO/2006', desc: '2006' },
      { code: 'DUCATI-MOTO/2005', desc: '2005' },
      { code: 'DUCATI-MOTO/2004', desc: '2004' },
      { code: 'DUCATI-MOTO/2003', desc: '2003' },
      { code: 'DUCATI-MOTO/2002', desc: '2002' },
      { code: 'DUCATI-MOTO/2001', desc: '2001' },
      { code: 'DUCATI-MOTO/2000', desc: '2000' },
      { code: 'DUCATI-MOTO/1999', desc: '1999' },
      { code: 'DUCATI-MOTO/1998', desc: '1998' },
      { code: 'DUCATI-MOTO/1997', desc: '1997' },
      { code: 'DUCATI-MOTO/1996', desc: '1996' },
      { code: 'DUCATI-MOTO/1995', desc: '1995' },
      { code: 'DUCATI-MOTO/1994', desc: '1994' },
      { code: 'DUCATI-MOTO/1993', desc: '1993' },
      { code: 'DUCATI-MOTO/1992', desc: '1992' },
      { code: 'DUCATI-MOTO/1991', desc: '1991' },
      { code: 'DUCATI-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_GASGAS-MOTO': [
      { code: 'GASGAS-MOTO/2020', desc: '2020' },
      { code: 'GASGAS-MOTO/2019', desc: '2019' },
      { code: 'GASGAS-MOTO/2018', desc: '2018' },
      { code: 'GASGAS-MOTO/2017', desc: '2017' },
      { code: 'GASGAS-MOTO/2016', desc: '2016' },
      { code: 'GASGAS-MOTO/2015', desc: '2015' },
      { code: 'GASGAS-MOTO/2014', desc: '2014' },
      { code: 'GASGAS-MOTO/2013', desc: '2013' },
      { code: 'GASGAS-MOTO/2012', desc: '2012' },
      { code: 'GASGAS-MOTO/2011', desc: '2011' },
      { code: 'GASGAS-MOTO/2010', desc: '2010' },
      { code: 'GASGAS-MOTO/2009', desc: '2009' },
      { code: 'GASGAS-MOTO/2008', desc: '2008' },
      { code: 'GASGAS-MOTO/2007', desc: '2007' },
      { code: 'GASGAS-MOTO/2006', desc: '2006' },
      { code: 'GASGAS-MOTO/2005', desc: '2005' },
      { code: 'GASGAS-MOTO/2004', desc: '2004' },
      { code: 'GASGAS-MOTO/2003', desc: '2003' },
      { code: 'GASGAS-MOTO/2002', desc: '2002' },
      { code: 'GASGAS-MOTO/2001', desc: '2001' },
      { code: 'GASGAS-MOTO/2000', desc: '2000' },
      { code: 'GASGAS-MOTO/1999', desc: '1999' },
      { code: 'GASGAS-MOTO/1998', desc: '1998' },
      { code: 'GASGAS-MOTO/1997', desc: '1997' },
      { code: 'GASGAS-MOTO/1996', desc: '1996' },
      { code: 'GASGAS-MOTO/1995', desc: '1995' },
      { code: 'GASGAS-MOTO/1994', desc: '1994' },
      { code: 'GASGAS-MOTO/1993', desc: '1993' },
      { code: 'GASGAS-MOTO/1992', desc: '1992' },
      { code: 'GASGAS-MOTO/1991', desc: '1991' },
      { code: 'GASGAS-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_GILERA-MOTO': [
      { code: 'GILERA-MOTO/2020', desc: '2020' },
      { code: 'GILERA-MOTO/2019', desc: '2019' },
      { code: 'GILERA-MOTO/2018', desc: '2018' },
      { code: 'GILERA-MOTO/2017', desc: '2017' },
      { code: 'GILERA-MOTO/2016', desc: '2016' },
      { code: 'GILERA-MOTO/2015', desc: '2015' },
      { code: 'GILERA-MOTO/2014', desc: '2014' },
      { code: 'GILERA-MOTO/2013', desc: '2013' },
      { code: 'GILERA-MOTO/2012', desc: '2012' },
      { code: 'GILERA-MOTO/2011', desc: '2011' },
      { code: 'GILERA-MOTO/2010', desc: '2010' },
      { code: 'GILERA-MOTO/2009', desc: '2009' },
      { code: 'GILERA-MOTO/2008', desc: '2008' },
      { code: 'GILERA-MOTO/2007', desc: '2007' },
      { code: 'GILERA-MOTO/2006', desc: '2006' },
      { code: 'GILERA-MOTO/2005', desc: '2005' },
      { code: 'GILERA-MOTO/2004', desc: '2004' },
      { code: 'GILERA-MOTO/2003', desc: '2003' },
      { code: 'GILERA-MOTO/2002', desc: '2002' },
      { code: 'GILERA-MOTO/2001', desc: '2001' },
      { code: 'GILERA-MOTO/2000', desc: '2000' },
      { code: 'GILERA-MOTO/1999', desc: '1999' },
      { code: 'GILERA-MOTO/1998', desc: '1998' },
      { code: 'GILERA-MOTO/1997', desc: '1997' },
      { code: 'GILERA-MOTO/1996', desc: '1996' },
      { code: 'GILERA-MOTO/1995', desc: '1995' },
      { code: 'GILERA-MOTO/1994', desc: '1994' },
      { code: 'GILERA-MOTO/1993', desc: '1993' },
      { code: 'GILERA-MOTO/1992', desc: '1992' },
      { code: 'GILERA-MOTO/1991', desc: '1991' },
      { code: 'GILERA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO': [
      { code: 'HARLEYDAVIDSON-MOTO/2020', desc: '2020' },
      { code: 'HARLEYDAVIDSON-MOTO/2019', desc: '2019' },
      { code: 'HARLEYDAVIDSON-MOTO/2018', desc: '2018' },
      { code: 'HARLEYDAVIDSON-MOTO/2017', desc: '2017' },
      { code: 'HARLEYDAVIDSON-MOTO/2016', desc: '2016' },
      { code: 'HARLEYDAVIDSON-MOTO/2015', desc: '2015' },
      { code: 'HARLEYDAVIDSON-MOTO/2014', desc: '2014' },
      { code: 'HARLEYDAVIDSON-MOTO/2013', desc: '2013' },
      { code: 'HARLEYDAVIDSON-MOTO/2012', desc: '2012' },
      { code: 'HARLEYDAVIDSON-MOTO/2011', desc: '2011' },
      { code: 'HARLEYDAVIDSON-MOTO/2010', desc: '2010' },
      { code: 'HARLEYDAVIDSON-MOTO/2009', desc: '2009' },
      { code: 'HARLEYDAVIDSON-MOTO/2008', desc: '2008' },
      { code: 'HARLEYDAVIDSON-MOTO/2007', desc: '2007' },
      { code: 'HARLEYDAVIDSON-MOTO/2006', desc: '2006' },
      { code: 'HARLEYDAVIDSON-MOTO/2005', desc: '2005' },
      { code: 'HARLEYDAVIDSON-MOTO/2004', desc: '2004' },
      { code: 'HARLEYDAVIDSON-MOTO/2003', desc: '2003' },
      { code: 'HARLEYDAVIDSON-MOTO/2002', desc: '2002' },
      { code: 'HARLEYDAVIDSON-MOTO/2001', desc: '2001' },
      { code: 'HARLEYDAVIDSON-MOTO/2000', desc: '2000' },
      { code: 'HARLEYDAVIDSON-MOTO/1999', desc: '1999' },
      { code: 'HARLEYDAVIDSON-MOTO/1998', desc: '1998' },
      { code: 'HARLEYDAVIDSON-MOTO/1997', desc: '1997' },
      { code: 'HARLEYDAVIDSON-MOTO/1996', desc: '1996' },
      { code: 'HARLEYDAVIDSON-MOTO/1995', desc: '1995' },
      { code: 'HARLEYDAVIDSON-MOTO/1994', desc: '1994' },
      { code: 'HARLEYDAVIDSON-MOTO/1993', desc: '1993' },
      { code: 'HARLEYDAVIDSON-MOTO/1992', desc: '1992' },
      { code: 'HARLEYDAVIDSON-MOTO/1991', desc: '1991' },
      { code: 'HARLEYDAVIDSON-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_HONDA-MOTO': [
      { code: 'HONDA-MOTO/2020', desc: '2020' },
      { code: 'HONDA-MOTO/2019', desc: '2019' },
      { code: 'HONDA-MOTO/2018', desc: '2018' },
      { code: 'HONDA-MOTO/2017', desc: '2017' },
      { code: 'HONDA-MOTO/2016', desc: '2016' },
      { code: 'HONDA-MOTO/2015', desc: '2015' },
      { code: 'HONDA-MOTO/2014', desc: '2014' },
      { code: 'HONDA-MOTO/2013', desc: '2013' },
      { code: 'HONDA-MOTO/2012', desc: '2012' },
      { code: 'HONDA-MOTO/2011', desc: '2011' },
      { code: 'HONDA-MOTO/2010', desc: '2010' },
      { code: 'HONDA-MOTO/2009', desc: '2009' },
      { code: 'HONDA-MOTO/2008', desc: '2008' },
      { code: 'HONDA-MOTO/2007', desc: '2007' },
      { code: 'HONDA-MOTO/2006', desc: '2006' },
      { code: 'HONDA-MOTO/2005', desc: '2005' },
      { code: 'HONDA-MOTO/2004', desc: '2004' },
      { code: 'HONDA-MOTO/2003', desc: '2003' },
      { code: 'HONDA-MOTO/2002', desc: '2002' },
      { code: 'HONDA-MOTO/2001', desc: '2001' },
      { code: 'HONDA-MOTO/2000', desc: '2000' },
      { code: 'HONDA-MOTO/1999', desc: '1999' },
      { code: 'HONDA-MOTO/1998', desc: '1998' },
      { code: 'HONDA-MOTO/1997', desc: '1997' },
      { code: 'HONDA-MOTO/1996', desc: '1996' },
      { code: 'HONDA-MOTO/1995', desc: '1995' },
      { code: 'HONDA-MOTO/1994', desc: '1994' },
      { code: 'HONDA-MOTO/1993', desc: '1993' },
      { code: 'HONDA-MOTO/1992', desc: '1992' },
      { code: 'HONDA-MOTO/1991', desc: '1991' },
      { code: 'HONDA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO': [
      { code: 'HUSQVARNA-MOTO/2020', desc: '2020' },
      { code: 'HUSQVARNA-MOTO/2019', desc: '2019' },
      { code: 'HUSQVARNA-MOTO/2018', desc: '2018' },
      { code: 'HUSQVARNA-MOTO/2017', desc: '2017' },
      { code: 'HUSQVARNA-MOTO/2016', desc: '2016' },
      { code: 'HUSQVARNA-MOTO/2015', desc: '2015' },
      { code: 'HUSQVARNA-MOTO/2014', desc: '2014' },
      { code: 'HUSQVARNA-MOTO/2013', desc: '2013' },
      { code: 'HUSQVARNA-MOTO/2012', desc: '2012' },
      { code: 'HUSQVARNA-MOTO/2011', desc: '2011' },
      { code: 'HUSQVARNA-MOTO/2010', desc: '2010' },
      { code: 'HUSQVARNA-MOTO/2009', desc: '2009' },
      { code: 'HUSQVARNA-MOTO/2008', desc: '2008' },
      { code: 'HUSQVARNA-MOTO/2007', desc: '2007' },
      { code: 'HUSQVARNA-MOTO/2006', desc: '2006' },
      { code: 'HUSQVARNA-MOTO/2005', desc: '2005' },
      { code: 'HUSQVARNA-MOTO/2004', desc: '2004' },
      { code: 'HUSQVARNA-MOTO/2003', desc: '2003' },
      { code: 'HUSQVARNA-MOTO/2002', desc: '2002' },
      { code: 'HUSQVARNA-MOTO/2001', desc: '2001' },
      { code: 'HUSQVARNA-MOTO/2000', desc: '2000' },
      { code: 'HUSQVARNA-MOTO/1999', desc: '1999' },
      { code: 'HUSQVARNA-MOTO/1998', desc: '1998' },
      { code: 'HUSQVARNA-MOTO/1997', desc: '1997' },
      { code: 'HUSQVARNA-MOTO/1996', desc: '1996' },
      { code: 'HUSQVARNA-MOTO/1995', desc: '1995' },
      { code: 'HUSQVARNA-MOTO/1994', desc: '1994' },
      { code: 'HUSQVARNA-MOTO/1993', desc: '1993' },
      { code: 'HUSQVARNA-MOTO/1992', desc: '1992' },
      { code: 'HUSQVARNA-MOTO/1991', desc: '1991' },
      { code: 'HUSQVARNA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_INDIAN-MOTO': [
      { code: 'INDIAN-MOTO/2020', desc: '2020' },
      { code: 'INDIAN-MOTO/2019', desc: '2019' },
      { code: 'INDIAN-MOTO/2018', desc: '2018' },
      { code: 'INDIAN-MOTO/2017', desc: '2017' },
      { code: 'INDIAN-MOTO/2016', desc: '2016' },
      { code: 'INDIAN-MOTO/2015', desc: '2015' },
      { code: 'INDIAN-MOTO/2014', desc: '2014' },
      { code: 'INDIAN-MOTO/2013', desc: '2013' },
      { code: 'INDIAN-MOTO/2012', desc: '2012' },
      { code: 'INDIAN-MOTO/2011', desc: '2011' },
      { code: 'INDIAN-MOTO/2010', desc: '2010' },
      { code: 'INDIAN-MOTO/2009', desc: '2009' },
      { code: 'INDIAN-MOTO/2008', desc: '2008' },
      { code: 'INDIAN-MOTO/2007', desc: '2007' },
      { code: 'INDIAN-MOTO/2006', desc: '2006' },
      { code: 'INDIAN-MOTO/2005', desc: '2005' },
      { code: 'INDIAN-MOTO/2004', desc: '2004' },
      { code: 'INDIAN-MOTO/2003', desc: '2003' },
      { code: 'INDIAN-MOTO/2002', desc: '2002' },
      { code: 'INDIAN-MOTO/2001', desc: '2001' },
      { code: 'INDIAN-MOTO/2000', desc: '2000' },
      { code: 'INDIAN-MOTO/1999', desc: '1999' },
      { code: 'INDIAN-MOTO/1998', desc: '1998' },
      { code: 'INDIAN-MOTO/1997', desc: '1997' },
      { code: 'INDIAN-MOTO/1996', desc: '1996' },
      { code: 'INDIAN-MOTO/1995', desc: '1995' },
      { code: 'INDIAN-MOTO/1994', desc: '1994' },
      { code: 'INDIAN-MOTO/1993', desc: '1993' },
      { code: 'INDIAN-MOTO/1992', desc: '1992' },
      { code: 'INDIAN-MOTO/1991', desc: '1991' },
      { code: 'INDIAN-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_ISLO-MOTO': [
      { code: 'ISLO-MOTO/2020', desc: '2020' },
      { code: 'ISLO-MOTO/2019', desc: '2019' },
      { code: 'ISLO-MOTO/2018', desc: '2018' },
      { code: 'ISLO-MOTO/2017', desc: '2017' },
      { code: 'ISLO-MOTO/2016', desc: '2016' },
      { code: 'ISLO-MOTO/2015', desc: '2015' },
      { code: 'ISLO-MOTO/2014', desc: '2014' },
      { code: 'ISLO-MOTO/2013', desc: '2013' },
      { code: 'ISLO-MOTO/2012', desc: '2012' },
      { code: 'ISLO-MOTO/2011', desc: '2011' },
      { code: 'ISLO-MOTO/2010', desc: '2010' },
      { code: 'ISLO-MOTO/2009', desc: '2009' },
      { code: 'ISLO-MOTO/2008', desc: '2008' },
      { code: 'ISLO-MOTO/2007', desc: '2007' },
      { code: 'ISLO-MOTO/2006', desc: '2006' },
      { code: 'ISLO-MOTO/2005', desc: '2005' },
      { code: 'ISLO-MOTO/2004', desc: '2004' },
      { code: 'ISLO-MOTO/2003', desc: '2003' },
      { code: 'ISLO-MOTO/2002', desc: '2002' },
      { code: 'ISLO-MOTO/2001', desc: '2001' },
      { code: 'ISLO-MOTO/2000', desc: '2000' },
      { code: 'ISLO-MOTO/1999', desc: '1999' },
      { code: 'ISLO-MOTO/1998', desc: '1998' },
      { code: 'ISLO-MOTO/1997', desc: '1997' },
      { code: 'ISLO-MOTO/1996', desc: '1996' },
      { code: 'ISLO-MOTO/1995', desc: '1995' },
      { code: 'ISLO-MOTO/1994', desc: '1994' },
      { code: 'ISLO-MOTO/1993', desc: '1993' },
      { code: 'ISLO-MOTO/1992', desc: '1992' },
      { code: 'ISLO-MOTO/1991', desc: '1991' },
      { code: 'ISLO-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO': [
      { code: 'ITALICA-MOTO/2020', desc: '2020' },
      { code: 'ITALICA-MOTO/2019', desc: '2019' },
      { code: 'ITALICA-MOTO/2018', desc: '2018' },
      { code: 'ITALICA-MOTO/2017', desc: '2017' },
      { code: 'ITALICA-MOTO/2016', desc: '2016' },
      { code: 'ITALICA-MOTO/2015', desc: '2015' },
      { code: 'ITALICA-MOTO/2014', desc: '2014' },
      { code: 'ITALICA-MOTO/2013', desc: '2013' },
      { code: 'ITALICA-MOTO/2012', desc: '2012' },
      { code: 'ITALICA-MOTO/2011', desc: '2011' },
      { code: 'ITALICA-MOTO/2010', desc: '2010' },
      { code: 'ITALICA-MOTO/2009', desc: '2009' },
      { code: 'ITALICA-MOTO/2008', desc: '2008' },
      { code: 'ITALICA-MOTO/2007', desc: '2007' },
      { code: 'ITALICA-MOTO/2006', desc: '2006' },
      { code: 'ITALICA-MOTO/2005', desc: '2005' },
      { code: 'ITALICA-MOTO/2004', desc: '2004' },
      { code: 'ITALICA-MOTO/2003', desc: '2003' },
      { code: 'ITALICA-MOTO/2002', desc: '2002' },
      { code: 'ITALICA-MOTO/2001', desc: '2001' },
      { code: 'ITALICA-MOTO/2000', desc: '2000' },
      { code: 'ITALICA-MOTO/1999', desc: '1999' },
      { code: 'ITALICA-MOTO/1998', desc: '1998' },
      { code: 'ITALICA-MOTO/1997', desc: '1997' },
      { code: 'ITALICA-MOTO/1996', desc: '1996' },
      { code: 'ITALICA-MOTO/1995', desc: '1995' },
      { code: 'ITALICA-MOTO/1994', desc: '1994' },
      { code: 'ITALICA-MOTO/1993', desc: '1993' },
      { code: 'ITALICA-MOTO/1992', desc: '1992' },
      { code: 'ITALICA-MOTO/1991', desc: '1991' },
      { code: 'ITALICA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO': [
      { code: 'IZUKA-MOTO/2020', desc: '2020' },
      { code: 'IZUKA-MOTO/2019', desc: '2019' },
      { code: 'IZUKA-MOTO/2018', desc: '2018' },
      { code: 'IZUKA-MOTO/2017', desc: '2017' },
      { code: 'IZUKA-MOTO/2016', desc: '2016' },
      { code: 'IZUKA-MOTO/2015', desc: '2015' },
      { code: 'IZUKA-MOTO/2014', desc: '2014' },
      { code: 'IZUKA-MOTO/2013', desc: '2013' },
      { code: 'IZUKA-MOTO/2012', desc: '2012' },
      { code: 'IZUKA-MOTO/2011', desc: '2011' },
      { code: 'IZUKA-MOTO/2010', desc: '2010' },
      { code: 'IZUKA-MOTO/2009', desc: '2009' },
      { code: 'IZUKA-MOTO/2008', desc: '2008' },
      { code: 'IZUKA-MOTO/2007', desc: '2007' },
      { code: 'IZUKA-MOTO/2006', desc: '2006' },
      { code: 'IZUKA-MOTO/2005', desc: '2005' },
      { code: 'IZUKA-MOTO/2004', desc: '2004' },
      { code: 'IZUKA-MOTO/2003', desc: '2003' },
      { code: 'IZUKA-MOTO/2002', desc: '2002' },
      { code: 'IZUKA-MOTO/2001', desc: '2001' },
      { code: 'IZUKA-MOTO/2000', desc: '2000' },
      { code: 'IZUKA-MOTO/1999', desc: '1999' },
      { code: 'IZUKA-MOTO/1998', desc: '1998' },
      { code: 'IZUKA-MOTO/1997', desc: '1997' },
      { code: 'IZUKA-MOTO/1996', desc: '1996' },
      { code: 'IZUKA-MOTO/1995', desc: '1995' },
      { code: 'IZUKA-MOTO/1994', desc: '1994' },
      { code: 'IZUKA-MOTO/1993', desc: '1993' },
      { code: 'IZUKA-MOTO/1992', desc: '1992' },
      { code: 'IZUKA-MOTO/1991', desc: '1991' },
      { code: 'IZUKA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO': [
      { code: 'KAWASAKI-MOTO/2020', desc: '2020' },
      { code: 'KAWASAKI-MOTO/2019', desc: '2019' },
      { code: 'KAWASAKI-MOTO/2018', desc: '2018' },
      { code: 'KAWASAKI-MOTO/2017', desc: '2017' },
      { code: 'KAWASAKI-MOTO/2016', desc: '2016' },
      { code: 'KAWASAKI-MOTO/2015', desc: '2015' },
      { code: 'KAWASAKI-MOTO/2014', desc: '2014' },
      { code: 'KAWASAKI-MOTO/2013', desc: '2013' },
      { code: 'KAWASAKI-MOTO/2012', desc: '2012' },
      { code: 'KAWASAKI-MOTO/2011', desc: '2011' },
      { code: 'KAWASAKI-MOTO/2010', desc: '2010' },
      { code: 'KAWASAKI-MOTO/2009', desc: '2009' },
      { code: 'KAWASAKI-MOTO/2008', desc: '2008' },
      { code: 'KAWASAKI-MOTO/2007', desc: '2007' },
      { code: 'KAWASAKI-MOTO/2006', desc: '2006' },
      { code: 'KAWASAKI-MOTO/2005', desc: '2005' },
      { code: 'KAWASAKI-MOTO/2004', desc: '2004' },
      { code: 'KAWASAKI-MOTO/2003', desc: '2003' },
      { code: 'KAWASAKI-MOTO/2002', desc: '2002' },
      { code: 'KAWASAKI-MOTO/2001', desc: '2001' },
      { code: 'KAWASAKI-MOTO/2000', desc: '2000' },
      { code: 'KAWASAKI-MOTO/1999', desc: '1999' },
      { code: 'KAWASAKI-MOTO/1998', desc: '1998' },
      { code: 'KAWASAKI-MOTO/1997', desc: '1997' },
      { code: 'KAWASAKI-MOTO/1996', desc: '1996' },
      { code: 'KAWASAKI-MOTO/1995', desc: '1995' },
      { code: 'KAWASAKI-MOTO/1994', desc: '1994' },
      { code: 'KAWASAKI-MOTO/1993', desc: '1993' },
      { code: 'KAWASAKI-MOTO/1992', desc: '1992' },
      { code: 'KAWASAKI-MOTO/1991', desc: '1991' },
      { code: 'KAWASAKI-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO': [
      { code: 'KEEWAY-MOTO/2020', desc: '2020' },
      { code: 'KEEWAY-MOTO/2019', desc: '2019' },
      { code: 'KEEWAY-MOTO/2018', desc: '2018' },
      { code: 'KEEWAY-MOTO/2017', desc: '2017' },
      { code: 'KEEWAY-MOTO/2016', desc: '2016' },
      { code: 'KEEWAY-MOTO/2015', desc: '2015' },
      { code: 'KEEWAY-MOTO/2014', desc: '2014' },
      { code: 'KEEWAY-MOTO/2013', desc: '2013' },
      { code: 'KEEWAY-MOTO/2012', desc: '2012' },
      { code: 'KEEWAY-MOTO/2011', desc: '2011' },
      { code: 'KEEWAY-MOTO/2010', desc: '2010' },
      { code: 'KEEWAY-MOTO/2009', desc: '2009' },
      { code: 'KEEWAY-MOTO/2008', desc: '2008' },
      { code: 'KEEWAY-MOTO/2007', desc: '2007' },
      { code: 'KEEWAY-MOTO/2006', desc: '2006' },
      { code: 'KEEWAY-MOTO/2005', desc: '2005' },
      { code: 'KEEWAY-MOTO/2004', desc: '2004' },
      { code: 'KEEWAY-MOTO/2003', desc: '2003' },
      { code: 'KEEWAY-MOTO/2002', desc: '2002' },
      { code: 'KEEWAY-MOTO/2001', desc: '2001' },
      { code: 'KEEWAY-MOTO/2000', desc: '2000' },
      { code: 'KEEWAY-MOTO/1999', desc: '1999' },
      { code: 'KEEWAY-MOTO/1998', desc: '1998' },
      { code: 'KEEWAY-MOTO/1997', desc: '1997' },
      { code: 'KEEWAY-MOTO/1996', desc: '1996' },
      { code: 'KEEWAY-MOTO/1995', desc: '1995' },
      { code: 'KEEWAY-MOTO/1994', desc: '1994' },
      { code: 'KEEWAY-MOTO/1993', desc: '1993' },
      { code: 'KEEWAY-MOTO/1992', desc: '1992' },
      { code: 'KEEWAY-MOTO/1991', desc: '1991' },
      { code: 'KEEWAY-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_KIMCO-MOTO': [
      { code: 'KIMCO-MOTO/2020', desc: '2020' },
      { code: 'KIMCO-MOTO/2019', desc: '2019' },
      { code: 'KIMCO-MOTO/2018', desc: '2018' },
      { code: 'KIMCO-MOTO/2017', desc: '2017' },
      { code: 'KIMCO-MOTO/2016', desc: '2016' },
      { code: 'KIMCO-MOTO/2015', desc: '2015' },
      { code: 'KIMCO-MOTO/2014', desc: '2014' },
      { code: 'KIMCO-MOTO/2013', desc: '2013' },
      { code: 'KIMCO-MOTO/2012', desc: '2012' },
      { code: 'KIMCO-MOTO/2011', desc: '2011' },
      { code: 'KIMCO-MOTO/2010', desc: '2010' },
      { code: 'KIMCO-MOTO/2009', desc: '2009' },
      { code: 'KIMCO-MOTO/2008', desc: '2008' },
      { code: 'KIMCO-MOTO/2007', desc: '2007' },
      { code: 'KIMCO-MOTO/2006', desc: '2006' },
      { code: 'KIMCO-MOTO/2005', desc: '2005' },
      { code: 'KIMCO-MOTO/2004', desc: '2004' },
      { code: 'KIMCO-MOTO/2003', desc: '2003' },
      { code: 'KIMCO-MOTO/2002', desc: '2002' },
      { code: 'KIMCO-MOTO/2001', desc: '2001' },
      { code: 'KIMCO-MOTO/2000', desc: '2000' },
      { code: 'KIMCO-MOTO/1999', desc: '1999' },
      { code: 'KIMCO-MOTO/1998', desc: '1998' },
      { code: 'KIMCO-MOTO/1997', desc: '1997' },
      { code: 'KIMCO-MOTO/1996', desc: '1996' },
      { code: 'KIMCO-MOTO/1995', desc: '1995' },
      { code: 'KIMCO-MOTO/1994', desc: '1994' },
      { code: 'KIMCO-MOTO/1993', desc: '1993' },
      { code: 'KIMCO-MOTO/1992', desc: '1992' },
      { code: 'KIMCO-MOTO/1991', desc: '1991' },
      { code: 'KIMCO-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_KTM-MOTO': [
      { code: 'KTM-MOTO/2020', desc: '2020' },
      { code: 'KTM-MOTO/2019', desc: '2019' },
      { code: 'KTM-MOTO/2018', desc: '2018' },
      { code: 'KTM-MOTO/2017', desc: '2017' },
      { code: 'KTM-MOTO/2016', desc: '2016' },
      { code: 'KTM-MOTO/2015', desc: '2015' },
      { code: 'KTM-MOTO/2014', desc: '2014' },
      { code: 'KTM-MOTO/2013', desc: '2013' },
      { code: 'KTM-MOTO/2012', desc: '2012' },
      { code: 'KTM-MOTO/2011', desc: '2011' },
      { code: 'KTM-MOTO/2010', desc: '2010' },
      { code: 'KTM-MOTO/2009', desc: '2009' },
      { code: 'KTM-MOTO/2008', desc: '2008' },
      { code: 'KTM-MOTO/2007', desc: '2007' },
      { code: 'KTM-MOTO/2006', desc: '2006' },
      { code: 'KTM-MOTO/2005', desc: '2005' },
      { code: 'KTM-MOTO/2004', desc: '2004' },
      { code: 'KTM-MOTO/2003', desc: '2003' },
      { code: 'KTM-MOTO/2002', desc: '2002' },
      { code: 'KTM-MOTO/2001', desc: '2001' },
      { code: 'KTM-MOTO/2000', desc: '2000' },
      { code: 'KTM-MOTO/1999', desc: '1999' },
      { code: 'KTM-MOTO/1998', desc: '1998' },
      { code: 'KTM-MOTO/1997', desc: '1997' },
      { code: 'KTM-MOTO/1996', desc: '1996' },
      { code: 'KTM-MOTO/1995', desc: '1995' },
      { code: 'KTM-MOTO/1994', desc: '1994' },
      { code: 'KTM-MOTO/1993', desc: '1993' },
      { code: 'KTM-MOTO/1992', desc: '1992' },
      { code: 'KTM-MOTO/1991', desc: '1991' },
      { code: 'KTM-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO': [
      { code: 'KURAZAI-MOTO/2020', desc: '2020' },
      { code: 'KURAZAI-MOTO/2019', desc: '2019' },
      { code: 'KURAZAI-MOTO/2018', desc: '2018' },
      { code: 'KURAZAI-MOTO/2017', desc: '2017' },
      { code: 'KURAZAI-MOTO/2016', desc: '2016' },
      { code: 'KURAZAI-MOTO/2015', desc: '2015' },
      { code: 'KURAZAI-MOTO/2014', desc: '2014' },
      { code: 'KURAZAI-MOTO/2013', desc: '2013' },
      { code: 'KURAZAI-MOTO/2012', desc: '2012' },
      { code: 'KURAZAI-MOTO/2011', desc: '2011' },
      { code: 'KURAZAI-MOTO/2010', desc: '2010' },
      { code: 'KURAZAI-MOTO/2009', desc: '2009' },
      { code: 'KURAZAI-MOTO/2008', desc: '2008' },
      { code: 'KURAZAI-MOTO/2007', desc: '2007' },
      { code: 'KURAZAI-MOTO/2006', desc: '2006' },
      { code: 'KURAZAI-MOTO/2005', desc: '2005' },
      { code: 'KURAZAI-MOTO/2004', desc: '2004' },
      { code: 'KURAZAI-MOTO/2003', desc: '2003' },
      { code: 'KURAZAI-MOTO/2002', desc: '2002' },
      { code: 'KURAZAI-MOTO/2001', desc: '2001' },
      { code: 'KURAZAI-MOTO/2000', desc: '2000' },
      { code: 'KURAZAI-MOTO/1999', desc: '1999' },
      { code: 'KURAZAI-MOTO/1998', desc: '1998' },
      { code: 'KURAZAI-MOTO/1997', desc: '1997' },
      { code: 'KURAZAI-MOTO/1996', desc: '1996' },
      { code: 'KURAZAI-MOTO/1995', desc: '1995' },
      { code: 'KURAZAI-MOTO/1994', desc: '1994' },
      { code: 'KURAZAI-MOTO/1993', desc: '1993' },
      { code: 'KURAZAI-MOTO/1992', desc: '1992' },
      { code: 'KURAZAI-MOTO/1991', desc: '1991' },
      { code: 'KURAZAI-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_LIFAN-MOTO': [
      { code: 'LIFAN-MOTO/2020', desc: '2020' },
      { code: 'LIFAN-MOTO/2019', desc: '2019' },
      { code: 'LIFAN-MOTO/2018', desc: '2018' },
      { code: 'LIFAN-MOTO/2017', desc: '2017' },
      { code: 'LIFAN-MOTO/2016', desc: '2016' },
      { code: 'LIFAN-MOTO/2015', desc: '2015' },
      { code: 'LIFAN-MOTO/2014', desc: '2014' },
      { code: 'LIFAN-MOTO/2013', desc: '2013' },
      { code: 'LIFAN-MOTO/2012', desc: '2012' },
      { code: 'LIFAN-MOTO/2011', desc: '2011' },
      { code: 'LIFAN-MOTO/2010', desc: '2010' },
      { code: 'LIFAN-MOTO/2009', desc: '2009' },
      { code: 'LIFAN-MOTO/2008', desc: '2008' },
      { code: 'LIFAN-MOTO/2007', desc: '2007' },
      { code: 'LIFAN-MOTO/2006', desc: '2006' },
      { code: 'LIFAN-MOTO/2005', desc: '2005' },
      { code: 'LIFAN-MOTO/2004', desc: '2004' },
      { code: 'LIFAN-MOTO/2003', desc: '2003' },
      { code: 'LIFAN-MOTO/2002', desc: '2002' },
      { code: 'LIFAN-MOTO/2001', desc: '2001' },
      { code: 'LIFAN-MOTO/2000', desc: '2000' },
      { code: 'LIFAN-MOTO/1999', desc: '1999' },
      { code: 'LIFAN-MOTO/1998', desc: '1998' },
      { code: 'LIFAN-MOTO/1997', desc: '1997' },
      { code: 'LIFAN-MOTO/1996', desc: '1996' },
      { code: 'LIFAN-MOTO/1995', desc: '1995' },
      { code: 'LIFAN-MOTO/1994', desc: '1994' },
      { code: 'LIFAN-MOTO/1993', desc: '1993' },
      { code: 'LIFAN-MOTO/1992', desc: '1992' },
      { code: 'LIFAN-MOTO/1991', desc: '1991' },
      { code: 'LIFAN-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO': [
      { code: 'MVAGUSTA-MOTO/2020', desc: '2020' },
      { code: 'MVAGUSTA-MOTO/2019', desc: '2019' },
      { code: 'MVAGUSTA-MOTO/2018', desc: '2018' },
      { code: 'MVAGUSTA-MOTO/2017', desc: '2017' },
      { code: 'MVAGUSTA-MOTO/2016', desc: '2016' },
      { code: 'MVAGUSTA-MOTO/2015', desc: '2015' },
      { code: 'MVAGUSTA-MOTO/2014', desc: '2014' },
      { code: 'MVAGUSTA-MOTO/2013', desc: '2013' },
      { code: 'MVAGUSTA-MOTO/2012', desc: '2012' },
      { code: 'MVAGUSTA-MOTO/2011', desc: '2011' },
      { code: 'MVAGUSTA-MOTO/2010', desc: '2010' },
      { code: 'MVAGUSTA-MOTO/2009', desc: '2009' },
      { code: 'MVAGUSTA-MOTO/2008', desc: '2008' },
      { code: 'MVAGUSTA-MOTO/2007', desc: '2007' },
      { code: 'MVAGUSTA-MOTO/2006', desc: '2006' },
      { code: 'MVAGUSTA-MOTO/2005', desc: '2005' },
      { code: 'MVAGUSTA-MOTO/2004', desc: '2004' },
      { code: 'MVAGUSTA-MOTO/2003', desc: '2003' },
      { code: 'MVAGUSTA-MOTO/2002', desc: '2002' },
      { code: 'MVAGUSTA-MOTO/2001', desc: '2001' },
      { code: 'MVAGUSTA-MOTO/2000', desc: '2000' },
      { code: 'MVAGUSTA-MOTO/1999', desc: '1999' },
      { code: 'MVAGUSTA-MOTO/1998', desc: '1998' },
      { code: 'MVAGUSTA-MOTO/1997', desc: '1997' },
      { code: 'MVAGUSTA-MOTO/1996', desc: '1996' },
      { code: 'MVAGUSTA-MOTO/1995', desc: '1995' },
      { code: 'MVAGUSTA-MOTO/1994', desc: '1994' },
      { code: 'MVAGUSTA-MOTO/1993', desc: '1993' },
      { code: 'MVAGUSTA-MOTO/1992', desc: '1992' },
      { code: 'MVAGUSTA-MOTO/1991', desc: '1991' },
      { code: 'MVAGUSTA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO': [
      { code: 'PEUGEOT-MOTO/2020', desc: '2020' },
      { code: 'PEUGEOT-MOTO/2019', desc: '2019' },
      { code: 'PEUGEOT-MOTO/2018', desc: '2018' },
      { code: 'PEUGEOT-MOTO/2017', desc: '2017' },
      { code: 'PEUGEOT-MOTO/2016', desc: '2016' },
      { code: 'PEUGEOT-MOTO/2015', desc: '2015' },
      { code: 'PEUGEOT-MOTO/2014', desc: '2014' },
      { code: 'PEUGEOT-MOTO/2013', desc: '2013' },
      { code: 'PEUGEOT-MOTO/2012', desc: '2012' },
      { code: 'PEUGEOT-MOTO/2011', desc: '2011' },
      { code: 'PEUGEOT-MOTO/2010', desc: '2010' },
      { code: 'PEUGEOT-MOTO/2009', desc: '2009' },
      { code: 'PEUGEOT-MOTO/2008', desc: '2008' },
      { code: 'PEUGEOT-MOTO/2007', desc: '2007' },
      { code: 'PEUGEOT-MOTO/2006', desc: '2006' },
      { code: 'PEUGEOT-MOTO/2005', desc: '2005' },
      { code: 'PEUGEOT-MOTO/2004', desc: '2004' },
      { code: 'PEUGEOT-MOTO/2003', desc: '2003' },
      { code: 'PEUGEOT-MOTO/2002', desc: '2002' },
      { code: 'PEUGEOT-MOTO/2001', desc: '2001' },
      { code: 'PEUGEOT-MOTO/2000', desc: '2000' },
      { code: 'PEUGEOT-MOTO/1999', desc: '1999' },
      { code: 'PEUGEOT-MOTO/1998', desc: '1998' },
      { code: 'PEUGEOT-MOTO/1997', desc: '1997' },
      { code: 'PEUGEOT-MOTO/1996', desc: '1996' },
      { code: 'PEUGEOT-MOTO/1995', desc: '1995' },
      { code: 'PEUGEOT-MOTO/1994', desc: '1994' },
      { code: 'PEUGEOT-MOTO/1993', desc: '1993' },
      { code: 'PEUGEOT-MOTO/1992', desc: '1992' },
      { code: 'PEUGEOT-MOTO/1991', desc: '1991' },
      { code: 'PEUGEOT-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO': [
      { code: 'PIAGGIO-MOTO/2020', desc: '2020' },
      { code: 'PIAGGIO-MOTO/2019', desc: '2019' },
      { code: 'PIAGGIO-MOTO/2018', desc: '2018' },
      { code: 'PIAGGIO-MOTO/2017', desc: '2017' },
      { code: 'PIAGGIO-MOTO/2016', desc: '2016' },
      { code: 'PIAGGIO-MOTO/2015', desc: '2015' },
      { code: 'PIAGGIO-MOTO/2014', desc: '2014' },
      { code: 'PIAGGIO-MOTO/2013', desc: '2013' },
      { code: 'PIAGGIO-MOTO/2012', desc: '2012' },
      { code: 'PIAGGIO-MOTO/2011', desc: '2011' },
      { code: 'PIAGGIO-MOTO/2010', desc: '2010' },
      { code: 'PIAGGIO-MOTO/2009', desc: '2009' },
      { code: 'PIAGGIO-MOTO/2008', desc: '2008' },
      { code: 'PIAGGIO-MOTO/2007', desc: '2007' },
      { code: 'PIAGGIO-MOTO/2006', desc: '2006' },
      { code: 'PIAGGIO-MOTO/2005', desc: '2005' },
      { code: 'PIAGGIO-MOTO/2004', desc: '2004' },
      { code: 'PIAGGIO-MOTO/2003', desc: '2003' },
      { code: 'PIAGGIO-MOTO/2002', desc: '2002' },
      { code: 'PIAGGIO-MOTO/2001', desc: '2001' },
      { code: 'PIAGGIO-MOTO/2000', desc: '2000' },
      { code: 'PIAGGIO-MOTO/1999', desc: '1999' },
      { code: 'PIAGGIO-MOTO/1998', desc: '1998' },
      { code: 'PIAGGIO-MOTO/1997', desc: '1997' },
      { code: 'PIAGGIO-MOTO/1996', desc: '1996' },
      { code: 'PIAGGIO-MOTO/1995', desc: '1995' },
      { code: 'PIAGGIO-MOTO/1994', desc: '1994' },
      { code: 'PIAGGIO-MOTO/1993', desc: '1993' },
      { code: 'PIAGGIO-MOTO/1992', desc: '1992' },
      { code: 'PIAGGIO-MOTO/1991', desc: '1991' },
      { code: 'PIAGGIO-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_POLARIS-MOTO': [
      { code: 'POLARIS-MOTO/2020', desc: '2020' },
      { code: 'POLARIS-MOTO/2019', desc: '2019' },
      { code: 'POLARIS-MOTO/2018', desc: '2018' },
      { code: 'POLARIS-MOTO/2017', desc: '2017' },
      { code: 'POLARIS-MOTO/2016', desc: '2016' },
      { code: 'POLARIS-MOTO/2015', desc: '2015' },
      { code: 'POLARIS-MOTO/2014', desc: '2014' },
      { code: 'POLARIS-MOTO/2013', desc: '2013' },
      { code: 'POLARIS-MOTO/2012', desc: '2012' },
      { code: 'POLARIS-MOTO/2011', desc: '2011' },
      { code: 'POLARIS-MOTO/2010', desc: '2010' },
      { code: 'POLARIS-MOTO/2009', desc: '2009' },
      { code: 'POLARIS-MOTO/2008', desc: '2008' },
      { code: 'POLARIS-MOTO/2007', desc: '2007' },
      { code: 'POLARIS-MOTO/2006', desc: '2006' },
      { code: 'POLARIS-MOTO/2005', desc: '2005' },
      { code: 'POLARIS-MOTO/2004', desc: '2004' },
      { code: 'POLARIS-MOTO/2003', desc: '2003' },
      { code: 'POLARIS-MOTO/2002', desc: '2002' },
      { code: 'POLARIS-MOTO/2001', desc: '2001' },
      { code: 'POLARIS-MOTO/2000', desc: '2000' },
      { code: 'POLARIS-MOTO/1999', desc: '1999' },
      { code: 'POLARIS-MOTO/1998', desc: '1998' },
      { code: 'POLARIS-MOTO/1997', desc: '1997' },
      { code: 'POLARIS-MOTO/1996', desc: '1996' },
      { code: 'POLARIS-MOTO/1995', desc: '1995' },
      { code: 'POLARIS-MOTO/1994', desc: '1994' },
      { code: 'POLARIS-MOTO/1993', desc: '1993' },
      { code: 'POLARIS-MOTO/1992', desc: '1992' },
      { code: 'POLARIS-MOTO/1991', desc: '1991' },
      { code: 'POLARIS-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO': [
      { code: 'SUZUKI-MOTO/2020', desc: '2020' },
      { code: 'SUZUKI-MOTO/2019', desc: '2019' },
      { code: 'SUZUKI-MOTO/2018', desc: '2018' },
      { code: 'SUZUKI-MOTO/2017', desc: '2017' },
      { code: 'SUZUKI-MOTO/2016', desc: '2016' },
      { code: 'SUZUKI-MOTO/2015', desc: '2015' },
      { code: 'SUZUKI-MOTO/2014', desc: '2014' },
      { code: 'SUZUKI-MOTO/2013', desc: '2013' },
      { code: 'SUZUKI-MOTO/2012', desc: '2012' },
      { code: 'SUZUKI-MOTO/2011', desc: '2011' },
      { code: 'SUZUKI-MOTO/2010', desc: '2010' },
      { code: 'SUZUKI-MOTO/2009', desc: '2009' },
      { code: 'SUZUKI-MOTO/2008', desc: '2008' },
      { code: 'SUZUKI-MOTO/2007', desc: '2007' },
      { code: 'SUZUKI-MOTO/2006', desc: '2006' },
      { code: 'SUZUKI-MOTO/2005', desc: '2005' },
      { code: 'SUZUKI-MOTO/2004', desc: '2004' },
      { code: 'SUZUKI-MOTO/2003', desc: '2003' },
      { code: 'SUZUKI-MOTO/2002', desc: '2002' },
      { code: 'SUZUKI-MOTO/2001', desc: '2001' },
      { code: 'SUZUKI-MOTO/2000', desc: '2000' },
      { code: 'SUZUKI-MOTO/1999', desc: '1999' },
      { code: 'SUZUKI-MOTO/1998', desc: '1998' },
      { code: 'SUZUKI-MOTO/1997', desc: '1997' },
      { code: 'SUZUKI-MOTO/1996', desc: '1996' },
      { code: 'SUZUKI-MOTO/1995', desc: '1995' },
      { code: 'SUZUKI-MOTO/1994', desc: '1994' },
      { code: 'SUZUKI-MOTO/1993', desc: '1993' },
      { code: 'SUZUKI-MOTO/1992', desc: '1992' },
      { code: 'SUZUKI-MOTO/1991', desc: '1991' },
      { code: 'SUZUKI-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO': [
      { code: 'TRUMPH-MOTO/2020', desc: '2020' },
      { code: 'TRUMPH-MOTO/2019', desc: '2019' },
      { code: 'TRUMPH-MOTO/2018', desc: '2018' },
      { code: 'TRUMPH-MOTO/2017', desc: '2017' },
      { code: 'TRUMPH-MOTO/2016', desc: '2016' },
      { code: 'TRUMPH-MOTO/2015', desc: '2015' },
      { code: 'TRUMPH-MOTO/2014', desc: '2014' },
      { code: 'TRUMPH-MOTO/2013', desc: '2013' },
      { code: 'TRUMPH-MOTO/2012', desc: '2012' },
      { code: 'TRUMPH-MOTO/2011', desc: '2011' },
      { code: 'TRUMPH-MOTO/2010', desc: '2010' },
      { code: 'TRUMPH-MOTO/2009', desc: '2009' },
      { code: 'TRUMPH-MOTO/2008', desc: '2008' },
      { code: 'TRUMPH-MOTO/2007', desc: '2007' },
      { code: 'TRUMPH-MOTO/2006', desc: '2006' },
      { code: 'TRUMPH-MOTO/2005', desc: '2005' },
      { code: 'TRUMPH-MOTO/2004', desc: '2004' },
      { code: 'TRUMPH-MOTO/2003', desc: '2003' },
      { code: 'TRUMPH-MOTO/2002', desc: '2002' },
      { code: 'TRUMPH-MOTO/2001', desc: '2001' },
      { code: 'TRUMPH-MOTO/2000', desc: '2000' },
      { code: 'TRUMPH-MOTO/1999', desc: '1999' },
      { code: 'TRUMPH-MOTO/1998', desc: '1998' },
      { code: 'TRUMPH-MOTO/1997', desc: '1997' },
      { code: 'TRUMPH-MOTO/1996', desc: '1996' },
      { code: 'TRUMPH-MOTO/1995', desc: '1995' },
      { code: 'TRUMPH-MOTO/1994', desc: '1994' },
      { code: 'TRUMPH-MOTO/1993', desc: '1993' },
      { code: 'TRUMPH-MOTO/1992', desc: '1992' },
      { code: 'TRUMPH-MOTO/1991', desc: '1991' },
      { code: 'TRUMPH-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_TVS-MOTO': [
      { code: 'TVS-MOTO/2020', desc: '2020' },
      { code: 'TVS-MOTO/2019', desc: '2019' },
      { code: 'TVS-MOTO/2018', desc: '2018' },
      { code: 'TVS-MOTO/2017', desc: '2017' },
      { code: 'TVS-MOTO/2016', desc: '2016' },
      { code: 'TVS-MOTO/2015', desc: '2015' },
      { code: 'TVS-MOTO/2014', desc: '2014' },
      { code: 'TVS-MOTO/2013', desc: '2013' },
      { code: 'TVS-MOTO/2012', desc: '2012' },
      { code: 'TVS-MOTO/2011', desc: '2011' },
      { code: 'TVS-MOTO/2010', desc: '2010' },
      { code: 'TVS-MOTO/2009', desc: '2009' },
      { code: 'TVS-MOTO/2008', desc: '2008' },
      { code: 'TVS-MOTO/2007', desc: '2007' },
      { code: 'TVS-MOTO/2006', desc: '2006' },
      { code: 'TVS-MOTO/2005', desc: '2005' },
      { code: 'TVS-MOTO/2004', desc: '2004' },
      { code: 'TVS-MOTO/2003', desc: '2003' },
      { code: 'TVS-MOTO/2002', desc: '2002' },
      { code: 'TVS-MOTO/2001', desc: '2001' },
      { code: 'TVS-MOTO/2000', desc: '2000' },
      { code: 'TVS-MOTO/1999', desc: '1999' },
      { code: 'TVS-MOTO/1998', desc: '1998' },
      { code: 'TVS-MOTO/1997', desc: '1997' },
      { code: 'TVS-MOTO/1996', desc: '1996' },
      { code: 'TVS-MOTO/1995', desc: '1995' },
      { code: 'TVS-MOTO/1994', desc: '1994' },
      { code: 'TVS-MOTO/1993', desc: '1993' },
      { code: 'TVS-MOTO/1992', desc: '1992' },
      { code: 'TVS-MOTO/1991', desc: '1991' },
      { code: 'TVS-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_VENTO-MOTO': [
      { code: 'VENTO-MOTO/2020', desc: '2020' },
      { code: 'VENTO-MOTO/2019', desc: '2019' },
      { code: 'VENTO-MOTO/2018', desc: '2018' },
      { code: 'VENTO-MOTO/2017', desc: '2017' },
      { code: 'VENTO-MOTO/2016', desc: '2016' },
      { code: 'VENTO-MOTO/2015', desc: '2015' },
      { code: 'VENTO-MOTO/2014', desc: '2014' },
      { code: 'VENTO-MOTO/2013', desc: '2013' },
      { code: 'VENTO-MOTO/2012', desc: '2012' },
      { code: 'VENTO-MOTO/2011', desc: '2011' },
      { code: 'VENTO-MOTO/2010', desc: '2010' },
      { code: 'VENTO-MOTO/2009', desc: '2009' },
      { code: 'VENTO-MOTO/2008', desc: '2008' },
      { code: 'VENTO-MOTO/2007', desc: '2007' },
      { code: 'VENTO-MOTO/2006', desc: '2006' },
      { code: 'VENTO-MOTO/2005', desc: '2005' },
      { code: 'VENTO-MOTO/2004', desc: '2004' },
      { code: 'VENTO-MOTO/2003', desc: '2003' },
      { code: 'VENTO-MOTO/2002', desc: '2002' },
      { code: 'VENTO-MOTO/2001', desc: '2001' },
      { code: 'VENTO-MOTO/2000', desc: '2000' },
      { code: 'VENTO-MOTO/1999', desc: '1999' },
      { code: 'VENTO-MOTO/1998', desc: '1998' },
      { code: 'VENTO-MOTO/1997', desc: '1997' },
      { code: 'VENTO-MOTO/1996', desc: '1996' },
      { code: 'VENTO-MOTO/1995', desc: '1995' },
      { code: 'VENTO-MOTO/1994', desc: '1994' },
      { code: 'VENTO-MOTO/1993', desc: '1993' },
      { code: 'VENTO-MOTO/1992', desc: '1992' },
      { code: 'VENTO-MOTO/1991', desc: '1991' },
      { code: 'VENTO-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_VESPA-MOTO': [
      { code: 'VESPA-MOTO/2020', desc: '2020' },
      { code: 'VESPA-MOTO/2019', desc: '2019' },
      { code: 'VESPA-MOTO/2018', desc: '2018' },
      { code: 'VESPA-MOTO/2017', desc: '2017' },
      { code: 'VESPA-MOTO/2016', desc: '2016' },
      { code: 'VESPA-MOTO/2015', desc: '2015' },
      { code: 'VESPA-MOTO/2014', desc: '2014' },
      { code: 'VESPA-MOTO/2013', desc: '2013' },
      { code: 'VESPA-MOTO/2012', desc: '2012' },
      { code: 'VESPA-MOTO/2011', desc: '2011' },
      { code: 'VESPA-MOTO/2010', desc: '2010' },
      { code: 'VESPA-MOTO/2009', desc: '2009' },
      { code: 'VESPA-MOTO/2008', desc: '2008' },
      { code: 'VESPA-MOTO/2007', desc: '2007' },
      { code: 'VESPA-MOTO/2006', desc: '2006' },
      { code: 'VESPA-MOTO/2005', desc: '2005' },
      { code: 'VESPA-MOTO/2004', desc: '2004' },
      { code: 'VESPA-MOTO/2003', desc: '2003' },
      { code: 'VESPA-MOTO/2002', desc: '2002' },
      { code: 'VESPA-MOTO/2001', desc: '2001' },
      { code: 'VESPA-MOTO/2000', desc: '2000' },
      { code: 'VESPA-MOTO/1999', desc: '1999' },
      { code: 'VESPA-MOTO/1998', desc: '1998' },
      { code: 'VESPA-MOTO/1997', desc: '1997' },
      { code: 'VESPA-MOTO/1996', desc: '1996' },
      { code: 'VESPA-MOTO/1995', desc: '1995' },
      { code: 'VESPA-MOTO/1994', desc: '1994' },
      { code: 'VESPA-MOTO/1993', desc: '1993' },
      { code: 'VESPA-MOTO/1992', desc: '1992' },
      { code: 'VESPA-MOTO/1991', desc: '1991' },
      { code: 'VESPA-MOTO/1990', desc: '1990' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO': [
      { code: 'YAMAHA-MOTO/2020', desc: '2020' },
      { code: 'YAMAHA-MOTO/2019', desc: '2019' },
      { code: 'YAMAHA-MOTO/2018', desc: '2018' },
      { code: 'YAMAHA-MOTO/2017', desc: '2017' },
      { code: 'YAMAHA-MOTO/2016', desc: '2016' },
      { code: 'YAMAHA-MOTO/2015', desc: '2015' },
      { code: 'YAMAHA-MOTO/2014', desc: '2014' },
      { code: 'YAMAHA-MOTO/2013', desc: '2013' },
      { code: 'YAMAHA-MOTO/2012', desc: '2012' },
      { code: 'YAMAHA-MOTO/2011', desc: '2011' },
      { code: 'YAMAHA-MOTO/2010', desc: '2010' },
      { code: 'YAMAHA-MOTO/2009', desc: '2009' },
      { code: 'YAMAHA-MOTO/2008', desc: '2008' },
      { code: 'YAMAHA-MOTO/2007', desc: '2007' },
      { code: 'YAMAHA-MOTO/2006', desc: '2006' },
      { code: 'YAMAHA-MOTO/2005', desc: '2005' },
      { code: 'YAMAHA-MOTO/2004', desc: '2004' },
      { code: 'YAMAHA-MOTO/2003', desc: '2003' },
      { code: 'YAMAHA-MOTO/2002', desc: '2002' },
      { code: 'YAMAHA-MOTO/2001', desc: '2001' },
      { code: 'YAMAHA-MOTO/2000', desc: '2000' },
      { code: 'YAMAHA-MOTO/1999', desc: '1999' },
      { code: 'YAMAHA-MOTO/1998', desc: '1998' },
      { code: 'YAMAHA-MOTO/1997', desc: '1997' },
      { code: 'YAMAHA-MOTO/1996', desc: '1996' },
      { code: 'YAMAHA-MOTO/1995', desc: '1995' },
      { code: 'YAMAHA-MOTO/1994', desc: '1994' },
      { code: 'YAMAHA-MOTO/1993', desc: '1993' },
      { code: 'YAMAHA-MOTO/1992', desc: '1992' },
      { code: 'YAMAHA-MOTO/1991', desc: '1991' },
      { code: 'YAMAHA-MOTO/1990', desc: '1990' },
    ],
  },
  vehicleBrands: {
    'ES-MX_/browse/cars': [
      { code: '/browse/cars/acura', desc: 'ACURA', modelYears: null },
      {
        code: '/browse/cars/alfa_romeo',
        desc: 'ALFA ROMEO',
        modelYears: null,
      },
      { code: '/browse/cars/audi', desc: 'AUDI', modelYears: null },
      { code: '/browse/cars/bentley', desc: 'BENTLEY', modelYears: null },
      { code: '/browse/cars/bmw', desc: 'BMW', modelYears: null },
      { code: '/browse/cars/buick', desc: 'BUICK', modelYears: null },
      {
        code: '/browse/cars/cadillac',
        desc: 'CADILLAC',
        modelYears: null,
      },
      {
        code: '/browse/cars/chevrolet',
        desc: 'CHEVROLET',
        modelYears: null,
      },
      {
        code: '/browse/cars/chrysler',
        desc: 'CHRYSLER',
        modelYears: null,
      },
      { code: '/browse/cars/dodge', desc: 'DODGE', modelYears: null },
      { code: '/browse/cars/ferrari', desc: 'FERRARI', modelYears: null },
      { code: '/browse/cars/fiat', desc: 'FIAT', modelYears: null },
      { code: '/browse/cars/ford', desc: 'FORD', modelYears: null },
      { code: '/browse/cars/gmc', desc: 'GMC', modelYears: null },
      { code: '/browse/cars/honda', desc: 'HONDA', modelYears: null },
      { code: '/browse/cars/hummer', desc: 'HUMMER', modelYears: null },
      { code: '/browse/cars/hyundai', desc: 'HYUNDAI', modelYears: null },
      {
        code: '/browse/cars/infiniti',
        desc: 'INFINITI',
        modelYears: null,
      },
      { code: '/browse/cars/isuzu', desc: 'ISUZU', modelYears: null },
      { code: '/browse/cars/jaguar', desc: 'JAGUAR', modelYears: null },
      { code: '/browse/cars/jeep', desc: 'JEEP', modelYears: null },
      { code: '/browse/cars/kia', desc: 'KIA', modelYears: null },
      {
        code: '/browse/cars/lamborghini',
        desc: 'LAMBORGHINI',
        modelYears: null,
      },
      {
        code: '/browse/cars/land_rover',
        desc: 'LAND ROVER',
        modelYears: null,
      },
      { code: '/browse/cars/lexus', desc: 'LEXUS', modelYears: null },
      { code: '/browse/cars/lincoln', desc: 'LINCOLN', modelYears: null },
      { code: '/browse/cars/lotus', desc: 'LOTUS', modelYears: null },
      {
        code: '/browse/cars/maserati',
        desc: 'MASERATI',
        modelYears: null,
      },
      { code: '/browse/cars/maybach', desc: 'MAYBACH', modelYears: null },
      { code: '/browse/cars/mazda', desc: 'MAZDA', modelYears: null },
      {
        code: '/browse/cars/mercedes-benz',
        desc: 'MERCEDES-BENZ',
        modelYears: null,
      },
      { code: '/browse/cars/mercury', desc: 'MERCURY', modelYears: null },
      {
        code: '/browse/cars/mg_rover',
        desc: 'MG ROVER',
        modelYears: null,
      },
      { code: '/browse/cars/mini', desc: 'MINI', modelYears: null },
      {
        code: '/browse/cars/mitsubishi',
        desc: 'MITSUBISHI',
        modelYears: null,
      },
      { code: '/browse/cars/nissan', desc: 'NISSAN', modelYears: null },
      { code: '/browse/cars/peugeot', desc: 'PEUGEOT', modelYears: null },
      { code: '/browse/cars/pontiac', desc: 'PONTIAC', modelYears: null },
      { code: '/browse/cars/porsche', desc: 'PORSCHE', modelYears: null },
      {
        code: '/browse/cars/ram_trucks',
        desc: 'RAM TRUCKS',
        modelYears: null,
      },
      { code: '/browse/cars/renault', desc: 'RENAULT', modelYears: null },
      { code: '/browse/cars/saab', desc: 'SAAB', modelYears: null },
      { code: '/browse/cars/seat', desc: 'SEAT', modelYears: null },
      { code: '/browse/cars/smart', desc: 'SMART', modelYears: null },
      { code: '/browse/cars/subaru', desc: 'SUBARU', modelYears: null },
      { code: '/browse/cars/suzuki', desc: 'SUZUKI', modelYears: null },
      { code: '/browse/cars/toyota', desc: 'TOYOTA', modelYears: null },
      {
        code: '/browse/cars/volkswagen',
        desc: 'VOLKSWAGEN',
        modelYears: null,
      },
      { code: '/browse/cars/volvo', desc: 'VOLVO', modelYears: null },
    ],
    'ES-MX_/browse/vans': [
      { code: '/browse/vans/fiat', desc: 'FIAT', modelYears: null },
      { code: '/browse/vans/ford', desc: 'FORD', modelYears: null },
      { code: '/browse/vans/hyundai', desc: 'HYUNDAI', modelYears: null },
      { code: '/browse/vans/isuzu', desc: 'ISUZU', modelYears: null },
      {
        code: '/browse/vans/land_rover',
        desc: 'LAND ROVER',
        modelYears: null,
      },
      {
        code: '/browse/vans/mercedes-benz',
        desc: 'MERCEDES-BENZ',
        modelYears: null,
      },
      {
        code: '/browse/vans/mitsubishi',
        desc: 'MITSUBISHI',
        modelYears: null,
      },
      { code: '/browse/vans/nissan', desc: 'NISSAN', modelYears: null },
      { code: '/browse/vans/peugeot', desc: 'PEUGEOT', modelYears: null },
      { code: '/browse/vans/renault', desc: 'RENAULT', modelYears: null },
      { code: '/browse/vans/toyota', desc: 'TOYOTA', modelYears: null },
      {
        code: '/browse/vans/volkswagen',
        desc: 'VOLKSWAGEN',
        modelYears: null,
      },
    ],
    'ES-MX_MOTO': [
      { code: 'BAJAJ-MOTO', desc: 'Bajaj-MOTO', modelYears: null },
      { code: 'BENELLI-MOTO', desc: 'Benelli-MOTO', modelYears: null },
      { code: 'BMW-MOTO', desc: 'Bmw-MOTO', modelYears: null },
      { code: 'CANAM-MOTO', desc: 'Can Am-MOTO', modelYears: null },
      { code: 'CARABELA-MOTO', desc: 'Carabela-MOTO', modelYears: null },
      { code: 'DINAMO-MOTO', desc: 'Dinamo-MOTO', modelYears: null },
      { code: 'DUCATI-MOTO', desc: 'Ducati-MOTO', modelYears: null },
      { code: 'GASGAS-MOTO', desc: 'Gas Gas-MOTO', modelYears: null },
      { code: 'GILERA-MOTO', desc: 'Gilera-MOTO', modelYears: null },
      {
        code: 'HARLEYDAVIDSON-MOTO',
        desc: 'Harley Davidson-MOTO',
        modelYears: null,
      },
      { code: 'HONDA-MOTO', desc: 'Honda-MOTO', modelYears: null },
      {
        code: 'HUSQVARNA-MOTO',
        desc: 'Husqvarna-MOTO',
        modelYears: null,
      },
      { code: 'INDIAN-MOTO', desc: 'Indian-MOTO', modelYears: null },
      { code: 'ISLO-MOTO', desc: 'Islo-MOTO', modelYears: null },
      { code: 'ITALICA-MOTO', desc: 'Italica-MOTO', modelYears: null },
      { code: 'IZUKA-MOTO', desc: 'Izuka-MOTO', modelYears: null },
      { code: 'KAWASAKI-MOTO', desc: 'Kawasaki-MOTO', modelYears: null },
      { code: 'KEEWAY-MOTO', desc: 'Keeway-MOTO', modelYears: null },
      { code: 'KIMCO-MOTO', desc: 'Kimco-MOTO', modelYears: null },
      { code: 'KTM-MOTO', desc: 'Ktm-MOTO', modelYears: null },
      { code: 'KURAZAI-MOTO', desc: 'Kurazai-MOTO', modelYears: null },
      { code: 'LIFAN-MOTO', desc: 'Lifan-MOTO', modelYears: null },
      { code: 'MVAGUSTA-MOTO', desc: 'Mv Agusta-MOTO', modelYears: null },
      { code: 'PEUGEOT-MOTO', desc: 'Peugeot-MOTO', modelYears: null },
      { code: 'PIAGGIO-MOTO', desc: 'Piaggio-MOTO', modelYears: null },
      { code: 'POLARIS-MOTO', desc: 'Polaris-MOTO', modelYears: null },
      { code: 'SUZUKI-MOTO', desc: 'Suzuki-MOTO', modelYears: null },
      { code: 'TRUMPH-MOTO', desc: 'Trumph-MOTO', modelYears: null },
      { code: 'TVS-MOTO', desc: 'Tvs-MOTO', modelYears: null },
      { code: 'VENTO-MOTO', desc: 'Vento-MOTO', modelYears: null },
      { code: 'VESPA-MOTO', desc: 'Vespa-MOTO', modelYears: null },
      { code: 'YAMAHA-MOTO', desc: 'Yamaha-MOTO', modelYears: null },
    ],
  },
  vehicleModels: {
    'ES-MX_/browse/cars_/browse/cars/acura/2021': [
      {
        code: '/browse/cars/acura/2021/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2020': [
      {
        code: '/browse/cars/acura/2020/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2019': [
      {
        code: '/browse/vans/fiat/2019/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2018': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/1998': [
      {
        code: '/browse/cars/acura/1998/integra',
        desc: 'Integra',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/1999': [
      {
        code: '/browse/cars/acura/1999/integra',
        desc: 'Integra',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2000': [
      {
        code: '/browse/cars/acura/2000/integra',
        desc: 'Integra',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2001': [
      {
        code: '/browse/cars/acura/2001/integra',
        desc: 'Integra',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2001/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2002': [
      {
        code: '/browse/cars/acura/2002/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2003': [
      {
        code: '/browse/cars/acura/2003/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2004': [
      {
        code: '/browse/cars/acura/2004/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2005': [
      {
        code: '/browse/cars/acura/2005/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2005/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2005/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2006': [
      {
        code: '/browse/cars/acura/2006/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2006/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2006/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2007': [
      {
        code: '/browse/cars/acura/2007/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2007/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2007/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2007/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2008': [
      {
        code: '/browse/cars/acura/2008/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2008/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2008/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2008/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2009': [
      {
        code: '/browse/cars/acura/2009/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2009/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2009/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2009/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2009/tsx',
        desc: 'TSX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2010': [
      {
        code: '/browse/cars/acura/2010/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2010/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2010/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2010/tsx',
        desc: 'TSX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2010/zdx',
        desc: 'ZDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2011': [
      {
        code: '/browse/cars/acura/2011/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2011/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2011/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2011/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2011/tsx',
        desc: 'TSX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2011/zdx',
        desc: 'ZDX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2012': [
      {
        code: '/browse/cars/acura/2012/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2012/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2012/rl',
        desc: 'RL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2012/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2012/tsx',
        desc: 'TSX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2013': [
      {
        code: '/browse/cars/acura/2013/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2013/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2013/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2013/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2013/tsx',
        desc: 'TSX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2014': [
      {
        code: '/browse/cars/acura/2014/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2014/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2014/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2014/rlx',
        desc: 'RLX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2014/tl',
        desc: 'TL',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2014/tsx',
        desc: 'TSX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2015': [
      {
        code: '/browse/cars/acura/2015/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2015/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2015/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2015/rlx',
        desc: 'RLX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2015/tlx',
        desc: 'TLX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2016': [
      {
        code: '/browse/cars/acura/2016/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2016/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2016/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2016/rlx',
        desc: 'RLX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2016/tlx',
        desc: 'TLX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2017': [
      {
        code: '/browse/cars/acura/2017/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2017/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2017/nsx',
        desc: 'NSX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2017/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2017/tlx',
        desc: 'TLX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2018': [
      {
        code: '/browse/cars/acura/2018/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2018/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2018/nsx',
        desc: 'NSX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2018/tlx',
        desc: 'TLX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/acura/2019': [
      {
        code: '/browse/cars/acura/2019/ilx',
        desc: 'ILX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2019/mdx',
        desc: 'MDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2019/nsx',
        desc: 'NSX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2019/rdx',
        desc: 'RDX',
        brandCode: '/browse/cars/acura',
      },
      {
        code: '/browse/cars/acura/2019/tlx',
        desc: 'TLX',
        brandCode: '/browse/cars/acura',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/1998': [
      {
        code: '/browse/cars/alfa_romeo/1998/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/1999': [
      {
        code: '/browse/cars/alfa_romeo/1999/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2000': [
      {
        code: '/browse/cars/alfa_romeo/2000/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2001': [
      {
        code: '/browse/cars/alfa_romeo/2001/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2002': [
      {
        code: '/browse/cars/alfa_romeo/2002/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2003': [
      {
        code: '/browse/cars/alfa_romeo/2003/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2004': [
      {
        code: '/browse/cars/alfa_romeo/2004/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2005': [
      {
        code: '/browse/cars/alfa_romeo/2005/156',
        desc: '156',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2005/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2006': [
      {
        code: '/browse/cars/alfa_romeo/2006/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2007': [
      {
        code: '/browse/cars/alfa_romeo/2007/147',
        desc: '147',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2007/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2008': [
      {
        code: '/browse/cars/alfa_romeo/2008/147',
        desc: '147',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2008/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2009': [
      {
        code: '/browse/cars/alfa_romeo/2009/147',
        desc: '147',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2009/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2010': [
      {
        code: '/browse/cars/alfa_romeo/2010/147',
        desc: '147',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2010/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2010/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2010/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2011': [
      {
        code: '/browse/cars/alfa_romeo/2011/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2011/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2011/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2012': [
      {
        code: '/browse/cars/alfa_romeo/2012/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2012/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2012/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2013': [
      {
        code: '/browse/cars/alfa_romeo/2013/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2013/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2013/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2014': [
      {
        code: '/browse/cars/alfa_romeo/2014/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2014/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2014/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2015': [
      {
        code: '/browse/cars/alfa_romeo/2015/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2015/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2015/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2016': [
      {
        code: '/browse/cars/alfa_romeo/2016/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2016/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2016/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2017': [
      {
        code: '/browse/cars/alfa_romeo/2017/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2017/4c',
        desc: '4C',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2017/giulia',
        desc: 'Giulia',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2017/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2017/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2017/stelvio',
        desc: 'Stelvio',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2018': [
      {
        code: '/browse/cars/alfa_romeo/2018/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2018/4c',
        desc: '4C',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2018/giulia',
        desc: 'Giulia',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2018/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2018/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2018/stelvio',
        desc: 'Stelvio',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2019': [
      {
        code: '/browse/cars/alfa_romeo/2019/159',
        desc: '159',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2019/giulia',
        desc: 'Giulia',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2019/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2019/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2019/stelvio',
        desc: 'Stelvio',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2020': [
      {
        code: '/browse/cars/alfa_romeo/2020/giulia',
        desc: 'Giulia',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2020/giulietta',
        desc: 'Giulietta',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2020/mito',
        desc: 'MiTo',
        brandCode: '/browse/cars/alfa_romeo',
      },
      {
        code: '/browse/cars/alfa_romeo/2020/stelvio',
        desc: 'Stelvio',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/alfa_romeo/2021': [
      {
        code: '/browse/cars/alfa_romeo/2021/giulia',
        desc: 'Giulia',
        brandCode: '/browse/cars/alfa_romeo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2022': [
      {
        code: '/browse/cars/audi/2022/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/rs3',
        desc: 'RS3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2022/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2021': [
      {
        code: '/browse/cars/audi/2021/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/rs3',
        desc: 'RS3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2021/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2020': [
      {
        code: '/browse/cars/audi/2020/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/rs3',
        desc: 'RS3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2020/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2019': [
      {
        code: '/browse/cars/audi/2019/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/rs3',
        desc: 'RS3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2019/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2018': [
      {
        code: '/browse/cars/audi/2018/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/rs3',
        desc: 'RS3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2018/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2017': [
      {
        code: '/browse/cars/audi/2017/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/rs3',
        desc: 'RS3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2017/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2016': [
      {
        code: '/browse/cars/audi/2016/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2016/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2015': [
      {
        code: '/browse/cars/audi/2015/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2015/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2014': [
      {
        code: '/browse/cars/audi/2014/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/s3',
        desc: 'S3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2014/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2013': [
      {
        code: '/browse/cars/audi/2013/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2013/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2012': [
      {
        code: '/browse/cars/audi/2012/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/s7',
        desc: 'S7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2012/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2011': [
      {
        code: '/browse/cars/audi/2011/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/q3',
        desc: 'Q3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2011/tts',
        desc: 'TTS',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2010': [
      {
        code: '/browse/cars/audi/2010/a1',
        desc: 'A1',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/a7',
        desc: 'A7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/rs5',
        desc: 'RS5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2010/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2009': [
      {
        code: '/browse/cars/audi/2009/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/r8',
        desc: 'R8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2009/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2008': [
      {
        code: '/browse/cars/audi/2008/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/q5',
        desc: 'Q5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2008/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2007': [
      {
        code: '/browse/cars/audi/2007/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/a5',
        desc: 'A5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/q7',
        desc: 'Q7',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/s5',
        desc: 'S5',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2007/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2006': [
      {
        code: '/browse/cars/audi/2006/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2006/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2005': [
      {
        code: '/browse/cars/audi/2005/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2005/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2004': [
      {
        code: '/browse/cars/audi/2004/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2004/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2003': [
      {
        code: '/browse/cars/audi/2003/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2003/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2002': [
      {
        code: '/browse/cars/audi/2002/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/rs6',
        desc: 'RS6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2002/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2001': [
      {
        code: '/browse/cars/audi/2001/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2001/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/2000': [
      {
        code: '/browse/cars/audi/2000/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/rs4',
        desc: 'RS4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/2000/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1999': [
      {
        code: '/browse/cars/audi/1999/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/s6',
        desc: 'S6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1999/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1998': [
      {
        code: '/browse/cars/audi/1998/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/s4',
        desc: 'S4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/s8',
        desc: 'S8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1998/tt',
        desc: 'TT',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1997': [
      {
        code: '/browse/cars/audi/1997/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1997/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1997/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1997/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1997/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1996': [
      {
        code: '/browse/cars/audi/1996/a3',
        desc: 'A3',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1996/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1996/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1996/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1996/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1995': [
      {
        code: '/browse/cars/audi/1995/a4',
        desc: 'A4',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1995/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1995/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1995/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1994': [
      {
        code: '/browse/cars/audi/1994/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1994/a8',
        desc: 'A8',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1994/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1993': [
      {
        code: '/browse/cars/audi/1993/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1993/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1992': [
      {
        code: '/browse/cars/audi/1992/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1992/cabriolet',
        desc: 'Cabriolet',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1991': [
      {
        code: '/browse/cars/audi/1991/100',
        desc: '100',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1991/80',
        desc: '80',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1991/a6',
        desc: 'A6',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1990': [
      {
        code: '/browse/cars/audi/1990/100',
        desc: '100',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1990/80',
        desc: '80',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/audi/1989': [
      {
        code: '/browse/cars/audi/1989/100',
        desc: '100',
        brandCode: '/browse/cars/audi',
      },
      {
        code: '/browse/cars/audi/1989/80',
        desc: '80',
        brandCode: '/browse/cars/audi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2003': [
      {
        code: '/browse/cars/bentley/2003/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2004': [
      {
        code: '/browse/cars/bentley/2004/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2005': [
      {
        code: '/browse/cars/bentley/2005/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2006': [
      {
        code: '/browse/cars/bentley/2006/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2006/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2007': [
      {
        code: '/browse/cars/bentley/2007/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2007/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2008': [
      {
        code: '/browse/cars/bentley/2008/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2008/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2009': [
      {
        code: '/browse/cars/bentley/2009/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2009/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2010': [
      {
        code: '/browse/cars/bentley/2010/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2010/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2011': [
      {
        code: '/browse/cars/bentley/2011/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2011/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2012': [
      {
        code: '/browse/cars/bentley/2012/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2012/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2012/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2013': [
      {
        code: '/browse/cars/bentley/2013/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2013/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2013/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2014': [
      {
        code: '/browse/cars/bentley/2014/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2014/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2014/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2015': [
      {
        code: '/browse/cars/bentley/2015/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2015/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2016': [
      {
        code: '/browse/cars/bentley/2016/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
      {
        code: '/browse/cars/bentley/2016/mulsanne',
        desc: 'Mulsanne',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2017': [
      {
        code: '/browse/cars/bentley/2017/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2018': [
      {
        code: '/browse/cars/bentley/2018/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2019': [
      {
        code: '/browse/cars/bentley/2019/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2020': [
      {
        code: '/browse/cars/bentley/2020/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bentley/2021': [
      {
        code: '/browse/cars/bentley/2021/continental_gtc',
        desc: 'Continental GTC',
        brandCode: '/browse/cars/bentley',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2021': [
      {
        code: '/browse/cars/bmw/2021/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/x2',
        desc: 'X2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2021/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2020': [
      {
        code: '/browse/cars/bmw/2020/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/x2',
        desc: 'X2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2020/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2019': [
      {
        code: '/browse/cars/bmw/2019/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x2',
        desc: 'X2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x4',
        desc: 'X4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2019/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2018': [
      {
        code: '/browse/cars/bmw/2018/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x2',
        desc: 'X2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x4',
        desc: 'X4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2018/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2017': [
      {
        code: '/browse/cars/bmw/2017/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x2',
        desc: 'X2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x4',
        desc: 'X4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2017/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2016': [
      {
        code: '/browse/cars/bmw/2016/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/28ix',
        desc: '28iX',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/x4',
        desc: 'X4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2016/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2015': [
      {
        code: '/browse/cars/bmw/2015/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/28i',
        desc: '28i',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/x4',
        desc: 'X4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2015/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2014': [
      {
        code: '/browse/cars/bmw/2014/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/m',
        desc: 'M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x4',
        desc: 'X4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/x6_m',
        desc: 'X6 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2014/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2013': [
      {
        code: '/browse/cars/bmw/2013/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/2_series',
        desc: '2 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/4_series',
        desc: '4 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/i_series',
        desc: 'i Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/m2',
        desc: 'M2',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/x6_m',
        desc: 'X6 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2013/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2012': [
      {
        code: '/browse/cars/bmw/2012/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/m_coupe',
        desc: 'M Coupe',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/x6_m',
        desc: 'X6 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2012/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2011': [
      {
        code: '/browse/cars/bmw/2011/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/m_coupe',
        desc: 'M Coupe',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/x5_m',
        desc: 'X5 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/x6_m',
        desc: 'X6 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2011/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2010': [
      {
        code: '/browse/cars/bmw/2010/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2010/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2009': [
      {
        code: '/browse/cars/bmw/2009/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/x1',
        desc: 'X1',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/x6_m',
        desc: 'X6 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2009/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2008': [
      {
        code: '/browse/cars/bmw/2008/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/x6',
        desc: 'X6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2008/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2007': [
      {
        code: '/browse/cars/bmw/2007/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2007/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2006': [
      {
        code: '/browse/cars/bmw/2006/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2006/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2005': [
      {
        code: '/browse/cars/bmw/2005/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/m6',
        desc: 'M6',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2005/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2004': [
      {
        code: '/browse/cars/bmw/2004/1_series',
        desc: '1 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2004/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2002': [
      {
        code: '/browse/cars/bmw/2002/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/z3',
        desc: 'Z3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2002/z8',
        desc: 'Z8',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2003': [
      {
        code: '/browse/cars/bmw/2003/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/6_series',
        desc: '6 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/x3',
        desc: 'X3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/z3',
        desc: 'Z3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/z4',
        desc: 'Z4',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2003/z8',
        desc: 'Z8',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2001': [
      {
        code: '/browse/cars/bmw/2001/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/z3',
        desc: 'Z3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2001/z8',
        desc: 'Z8',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/2000': [
      {
        code: '/browse/cars/bmw/2000/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/m',
        desc: 'M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/m5',
        desc: 'M5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/z3',
        desc: 'Z3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/z3_m',
        desc: 'Z3 M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/2000/z8',
        desc: 'Z8',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1999': [
      {
        code: '/browse/cars/bmw/1999/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/m',
        desc: 'M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/x5',
        desc: 'X5',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/z3',
        desc: 'Z3',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1999/z3_m',
        desc: 'Z3 M',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1998': [
      {
        code: '/browse/cars/bmw/1998/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1998/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1998/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1998/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1998/m',
        desc: 'M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1998/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1997': [
      {
        code: '/browse/cars/bmw/1997/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1997/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1997/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1997/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1997/m',
        desc: 'M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1997/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1996': [
      {
        code: '/browse/cars/bmw/1996/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1996/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1996/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1996/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1996/m',
        desc: 'M',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1996/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1995': [
      {
        code: '/browse/cars/bmw/1995/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1995/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1995/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1995/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1995/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1994': [
      {
        code: '/browse/cars/bmw/1994/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1994/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1994/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1994/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1994/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1993': [
      {
        code: '/browse/cars/bmw/1993/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1993/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1993/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1993/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1993/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1992': [
      {
        code: '/browse/cars/bmw/1992/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1992/5_series',
        desc: '5 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1992/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1992/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1992/m3',
        desc: 'M3',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1991': [
      {
        code: '/browse/cars/bmw/1991/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1991/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1991/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1990': [
      {
        code: '/browse/cars/bmw/1990/3_series',
        desc: '3 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1990/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1990/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1989': [
      {
        code: '/browse/cars/bmw/1989/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
      {
        code: '/browse/cars/bmw/1989/8_series',
        desc: '8 Series',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1988': [
      {
        code: '/browse/cars/bmw/1988/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/bmw/1987': [
      {
        code: '/browse/cars/bmw/1987/7_series',
        desc: '7 Series',
        brandCode: '/browse/cars/bmw',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2009': [
      {
        code: '/browse/cars/buick/2009/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2010': [
      {
        code: '/browse/cars/buick/2010/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2010/lacrosse',
        desc: 'LaCrosse',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2011': [
      {
        code: '/browse/cars/buick/2011/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2011/lacrosse',
        desc: 'LaCrosse',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2011/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2012': [
      {
        code: '/browse/cars/buick/2012/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2012/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2013': [
      {
        code: '/browse/cars/buick/2013/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2013/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2013/verano',
        desc: 'Verano',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2015': [
      {
        code: '/browse/cars/buick/2015/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2015/encore',
        desc: 'Encore',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2015/lacrosse',
        desc: 'LaCrosse',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2015/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2015/verano',
        desc: 'Verano',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2014': [
      {
        code: '/browse/cars/buick/2014/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2014/encore',
        desc: 'Encore',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2014/lacrosse',
        desc: 'LaCrosse',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2014/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2014/verano',
        desc: 'Verano',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2016': [
      {
        code: '/browse/cars/buick/2016/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2016/encore',
        desc: 'Encore',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2016/lacrosse',
        desc: 'LaCrosse',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2016/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2016/verano',
        desc: 'Verano',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2017': [
      {
        code: '/browse/cars/buick/2017/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2017/encore',
        desc: 'Encore',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2017/envision',
        desc: 'Envision',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2017/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2018': [
      {
        code: '/browse/cars/buick/2018/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2018/encore',
        desc: 'Encore',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2018/envision',
        desc: 'Envision',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2018/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2019': [
      {
        code: '/browse/cars/buick/2019/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2019/encore',
        desc: 'Encore',
        brandCode: '/browse/cars/buick',
      },
      {
        code: '/browse/cars/buick/2019/regal',
        desc: 'Regal',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/buick/2020': [
      {
        code: '/browse/cars/buick/2020/enclave',
        desc: 'Enclave',
        brandCode: '/browse/cars/buick',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2022': [
      {
        code: '/browse/cars/chevrolet/2022/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2022/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2022/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2021': [
      {
        code: '/browse/cars/chevrolet/2021/aveo',
        desc: 'Aveo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/cheyenne',
        desc: 'Cheyenne',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/onix',
        desc: 'Onix',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2021/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2020': [
      {
        code: '/browse/cars/chevrolet/2020/aveo',
        desc: 'Aveo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/bolt',
        desc: 'BOLT',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/cheyenne',
        desc: 'Cheyenne',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/traverse',
        desc: 'Traverse',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2020/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2019': [
      {
        code: '/browse/cars/chevrolet/2019/aveo',
        desc: 'Aveo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/beat',
        desc: 'Beat',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/bolt',
        desc: 'BOLT',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/cheyenne',
        desc: 'Cheyenne',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/corvette_convertible',
        desc: 'Corvette Convertible',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/traverse',
        desc: 'Traverse',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2019/volt',
        desc: 'Volt',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2018': [
      {
        code: '/browse/cars/chevrolet/2018/beat',
        desc: 'Beat',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/corsica',
        desc: 'Corsica',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/traverse',
        desc: 'Traverse',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2018/volt',
        desc: 'Volt',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2017': [
      {
        code: '/browse/cars/chevrolet/2017/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/silverado_3500_hd',
        desc: 'Silverado 3500 HD',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/traverse',
        desc: 'Traverse',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2017/volt',
        desc: 'Volt',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2016': [
      {
        code: '/browse/cars/chevrolet/2016/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/silverado_3500_hd',
        desc: 'Silverado 3500 HD',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/traverse',
        desc: 'Traverse',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2016/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2015': [
      {
        code: '/browse/cars/chevrolet/2015/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2015/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2014': [
      {
        code: '/browse/cars/chevrolet/2014/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/traverse',
        desc: 'Traverse',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2014/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2013': [
      {
        code: '/browse/cars/chevrolet/2013/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2013/trax',
        desc: 'Trax',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2012': [
      {
        code: '/browse/cars/chevrolet/2012/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2012/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2011': [
      {
        code: '/browse/cars/chevrolet/2011/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/sonic',
        desc: 'Sonic',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2011/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2010': [
      {
        code: '/browse/cars/chevrolet/2010/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/cruze',
        desc: 'Cruze',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2010/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2009': [
      {
        code: '/browse/cars/chevrolet/2009/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/aveo',
        desc: 'Aveo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/spark',
        desc: 'Spark',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2009/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2008': [
      {
        code: '/browse/cars/chevrolet/2008/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/aveo',
        desc: 'Aveo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/captiva',
        desc: 'Captiva',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/hhr',
        desc: 'HHR',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/tracker',
        desc: 'Tracker',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/uplander',
        desc: 'Uplander',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2008/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2007': [
      {
        code: '/browse/cars/chevrolet/2007/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/aveo',
        desc: 'Aveo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/corvette_convertible',
        desc: 'Corvette Convertible',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/hhr',
        desc: 'HHR',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/silverdo',
        desc: 'Silverdo',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/tracker',
        desc: 'Tracker',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/trailblazer',
        desc: 'Trailblazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/uplander',
        desc: 'Uplander',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2007/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2006': [
      {
        code: '/browse/cars/chevrolet/2006/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/hhr',
        desc: 'HHR',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/tahoe',
        desc: 'Tahoe',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/tosca_series',
        desc: 'Tosca Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/tracker',
        desc: 'Tracker',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/trailblazer',
        desc: 'Trailblazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/uplander',
        desc: 'Uplander',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2006/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2005': [
      {
        code: '/browse/cars/chevrolet/2005/astra',
        desc: 'Astra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/equinox',
        desc: 'Equinox',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/impala',
        desc: 'Impala',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/matiz_series',
        desc: 'Matiz Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/tracker',
        desc: 'Tracker',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/trailblazer',
        desc: 'Trailblazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/uplander',
        desc: 'Uplander',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2005/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2004': [
      {
        code: '/browse/cars/chevrolet/2004/astra',
        desc: 'Astra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/colorado',
        desc: 'Colorado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/epica_series',
        desc: 'Epica Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/impala',
        desc: 'Impala',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/optra',
        desc: 'Optra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/silverado_2500',
        desc: 'Silverado 2500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/tornado',
        desc: 'Tornado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/trailblazer',
        desc: 'Trailblazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2004/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2003': [
      {
        code: '/browse/cars/chevrolet/2003/astra',
        desc: 'Astra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/express',
        desc: 'Express',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/impala',
        desc: 'Impala',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/s10',
        desc: 'S10',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/trailblazer',
        desc: 'Trailblazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2003/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2002': [
      {
        code: '/browse/cars/chevrolet/2002/astra',
        desc: 'Astra',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/avalanche',
        desc: 'Avalanche',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/impala',
        desc: 'Impala',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/s10',
        desc: 'S10',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/silverado',
        desc: 'Silverado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/silverado_1500',
        desc: 'Silverado 1500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/trailblazer',
        desc: 'Trailblazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2002/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2001': [
      {
        code: '/browse/cars/chevrolet/2001/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/impala',
        desc: 'Impala',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/silverado',
        desc: 'Silverado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/silverado_3500',
        desc: 'Silverado 3500',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2001/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/2000': [
      {
        code: '/browse/cars/chevrolet/2000/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/chevy',
        desc: 'Chevy',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/impala',
        desc: 'Impala',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/malibu',
        desc: 'Malibu',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/silverado',
        desc: 'Silverado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/2000/zafira',
        desc: 'Zafira',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1999': [
      {
        code: '/browse/cars/chevrolet/1999/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/silverado',
        desc: 'Silverado',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/sonora_series',
        desc: 'Sonora Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/suburban',
        desc: 'Suburban',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1999/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1997': [
      {
        code: '/browse/cars/chevrolet/1997/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1997/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1997/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1997/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1997/lumina',
        desc: 'Lumina',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1997/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1997/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1998': [
      {
        code: '/browse/cars/chevrolet/1998/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1998/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1998/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1998/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1998/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1998/vectra_series',
        desc: 'Vectra Series',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1998/venture',
        desc: 'Venture',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1996': [
      {
        code: '/browse/cars/chevrolet/1996/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1996/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1996/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1996/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1996/lumina',
        desc: 'Lumina',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1996/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1995': [
      {
        code: '/browse/cars/chevrolet/1995/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1995/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1995/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1995/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1995/corvette',
        desc: 'Corvette',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1995/lumina',
        desc: 'Lumina',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1995/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1994': [
      {
        code: '/browse/cars/chevrolet/1994/astro',
        desc: 'Astro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1994/blazer',
        desc: 'Blazer',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1994/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1994/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1994/corvette_convertible',
        desc: 'Corvette Convertible',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1994/lumina',
        desc: 'Lumina',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1994/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1993': [
      {
        code: '/browse/cars/chevrolet/1993/camaro',
        desc: 'Camaro',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1993/cavalier',
        desc: 'Cavalier',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1993/corvette_convertible',
        desc: 'Corvette Convertible',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1993/lumina',
        desc: 'Lumina',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1993/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1992': [
      {
        code: '/browse/cars/chevrolet/1992/corvette_convertible',
        desc: 'Corvette Convertible',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1992/lumina',
        desc: 'Lumina',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1992/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1991': [
      {
        code: '/browse/cars/chevrolet/1991/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1990': [
      {
        code: '/browse/cars/chevrolet/1990/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1989': [
      {
        code: '/browse/cars/chevrolet/1989/corsica',
        desc: 'Corsica',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1989/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1987': [
      {
        code: '/browse/cars/chevrolet/1987/corsica',
        desc: 'Corsica',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1987/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1988': [
      {
        code: '/browse/cars/chevrolet/1988/corsica',
        desc: 'Corsica',
        brandCode: '/browse/cars/chevrolet',
      },
      {
        code: '/browse/cars/chevrolet/1988/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1986': [
      {
        code: '/browse/cars/chevrolet/1986/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1985': [
      {
        code: '/browse/cars/chevrolet/1985/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chevrolet/1984': [
      {
        code: '/browse/cars/chevrolet/1984/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/chevrolet',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1995': [
      {
        code: '/browse/cars/chrysler/1995/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1995/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1995/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1995/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1995/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1995/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1988': [
      {
        code: '/browse/cars/chrysler/1988/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1989': [
      {
        code: '/browse/cars/chrysler/1989/lebaron',
        desc: 'LeBaron',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1989/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1990': [
      {
        code: '/browse/cars/chrysler/1990/lebaron',
        desc: 'LeBaron',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1990/spirit',
        desc: 'Spirit',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1990/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1990/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1991': [
      {
        code: '/browse/cars/chrysler/1991/lebaron',
        desc: 'LeBaron',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1991/spirit',
        desc: 'Spirit',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1991/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1991/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1992': [
      {
        code: '/browse/cars/chrysler/1992/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1992/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1992/lebaron',
        desc: 'LeBaron',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1992/spirit',
        desc: 'Spirit',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1992/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1993': [
      {
        code: '/browse/cars/chrysler/1993/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1993/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1993/lebaron',
        desc: 'LeBaron',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1993/spirit',
        desc: 'Spirit',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1993/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1994': [
      {
        code: '/browse/cars/chrysler/1994/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1994/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1994/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1994/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1994/lebaron',
        desc: 'LeBaron',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1994/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1994/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1996': [
      {
        code: '/browse/cars/chrysler/1996/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1996/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1996/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1996/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1996/new_yorker',
        desc: 'New Yorker',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1996/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1996/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1997': [
      {
        code: '/browse/cars/chrysler/1997/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1997/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1997/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1997/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1997/new_yorker',
        desc: 'New Yorker',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1997/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1997/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1998': [
      {
        code: '/browse/cars/chrysler/1998/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1998/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1998/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1998/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1998/new_yorker',
        desc: 'New Yorker',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1998/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1998/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/1999': [
      {
        code: '/browse/cars/chrysler/1999/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/1999/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2000': [
      {
        code: '/browse/cars/chrysler/2000/300_m',
        desc: '300 M',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/cirrus',
        desc: 'Cirrus',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2000/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2001': [
      {
        code: '/browse/cars/chrysler/2001/300_m',
        desc: '300 M',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/grand_voyager',
        desc: 'Grand Voyager',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2001/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2002': [
      {
        code: '/browse/cars/chrysler/2002/300_m',
        desc: '300 M',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2002/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2003': [
      {
        code: '/browse/cars/chrysler/2003/300_m',
        desc: '300 M',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2003/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2004': [
      {
        code: '/browse/cars/chrysler/2004/300_m',
        desc: '300 M',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/concorde',
        desc: 'Concorde',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2004/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2005': [
      {
        code: '/browse/cars/chrysler/2005/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2005/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2006': [
      {
        code: '/browse/cars/chrysler/2006/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2006/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2007': [
      {
        code: '/browse/cars/chrysler/2007/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/aspen',
        desc: 'Aspen',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/sebring',
        desc: 'Sebring',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2007/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2008': [
      {
        code: '/browse/cars/chrysler/2008/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/aspen',
        desc: 'Aspen',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2008/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2009': [
      {
        code: '/browse/cars/chrysler/2009/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/aspen',
        desc: 'Aspen',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2009/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2010': [
      {
        code: '/browse/cars/chrysler/2010/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2010/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2011': [
      {
        code: '/browse/cars/chrysler/2011/200',
        desc: '200',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2011/voyager',
        desc: 'Voyager',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2012': [
      {
        code: '/browse/cars/chrysler/2012/200',
        desc: '200',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2012/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2013': [
      {
        code: '/browse/cars/chrysler/2013/200',
        desc: '200',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/crossfire',
        desc: 'Crossfire',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2013/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2014': [
      {
        code: '/browse/cars/chrysler/2014/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2014/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2014/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2014/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2014/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2014/pt_cruiser',
        desc: 'PT Cruiser',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2014/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2015': [
      {
        code: '/browse/cars/chrysler/2015/200',
        desc: '200',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2015/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2016': [
      {
        code: '/browse/cars/chrysler/2016/200',
        desc: '200',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2016/town_country',
        desc: 'Town & Country',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2017': [
      {
        code: '/browse/cars/chrysler/2017/200',
        desc: '200',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2017/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2017/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2017/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2017/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2017/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2018': [
      {
        code: '/browse/cars/chrysler/2018/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2018/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2018/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2018/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2019': [
      {
        code: '/browse/cars/chrysler/2019/300',
        desc: '300',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2019/300_c',
        desc: '300 C',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2019/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2019/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2020': [
      {
        code: '/browse/cars/chrysler/2020/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2020/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/chrysler/2021': [
      {
        code: '/browse/cars/chrysler/2021/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/chrysler',
      },
      {
        code: '/browse/cars/chrysler/2021/pacifica',
        desc: 'Pacifica',
        brandCode: '/browse/cars/chrysler',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2021': [
      {
        code: '/browse/cars/dodge/2021/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2020': [
      {
        code: '/browse/cars/dodge/2020/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2019': [
      {
        code: '/browse/cars/dodge/2019/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2019/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2019/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2019/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2019/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2018': [
      {
        code: '/browse/cars/dodge/2018/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2018/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2018/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2018/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2018/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2018/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2017': [
      {
        code: '/browse/cars/dodge/2017/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2017/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2016': [
      {
        code: '/browse/cars/dodge/2016/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/dart',
        desc: 'Dart',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2016/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2015': [
      {
        code: '/browse/cars/dodge/2015/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/dart',
        desc: 'Dart',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2015/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2014': [
      {
        code: '/browse/cars/dodge/2014/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/dart',
        desc: 'Dart',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/i10',
        desc: 'i10',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2014/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2013': [
      {
        code: '/browse/cars/dodge/2013/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/dart',
        desc: 'Dart',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/i10',
        desc: 'i10',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2013/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2012': [
      {
        code: '/browse/cars/dodge/2012/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/i10',
        desc: 'i10',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2012/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2011': [
      {
        code: '/browse/cars/dodge/2011/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2011/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2010': [
      {
        code: '/browse/cars/dodge/2010/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/nitro',
        desc: 'Nitro',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2010/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2009': [
      {
        code: '/browse/cars/dodge/2009/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/journey',
        desc: 'Journey',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2009/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2008': [
      {
        code: '/browse/cars/dodge/2008/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/challenger',
        desc: 'Challenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/nitro',
        desc: 'Nitro',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2008/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2007': [
      {
        code: '/browse/cars/dodge/2007/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/avenger',
        desc: 'Avenger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/nitro',
        desc: 'Nitro',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2007/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2006': [
      {
        code: '/browse/cars/dodge/2006/attitude',
        desc: 'Attitude',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/caliber',
        desc: 'Caliber',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2006/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2005': [
      {
        code: '/browse/cars/dodge/2005/charger',
        desc: 'Charger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2005/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2004': [
      {
        code: '/browse/cars/dodge/2004/dakota',
        desc: 'Dakota',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2004/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2003': [
      {
        code: '/browse/cars/dodge/2003/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2003/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2002': [
      {
        code: '/browse/cars/dodge/2002/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2002/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2002/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2002/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2002/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2002/ram_3500',
        desc: 'RAM 3500',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2002/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2001': [
      {
        code: '/browse/cars/dodge/2001/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2001/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2001/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2001/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2001/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2001/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/2000': [
      {
        code: '/browse/cars/dodge/2000/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2000/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2000/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2000/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2000/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/2000/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1999': [
      {
        code: '/browse/cars/dodge/1999/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1999/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1999/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1999/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1999/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1999/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1997': [
      {
        code: '/browse/cars/dodge/1997/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1997/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1997/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1997/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1997/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1997/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1998': [
      {
        code: '/browse/cars/dodge/1998/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1998/durango',
        desc: 'Durango',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1998/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1998/intrepid',
        desc: 'Intrepid',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1998/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1998/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1996': [
      {
        code: '/browse/cars/dodge/1996/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1996/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1996/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1996/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1996/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1996/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1995': [
      {
        code: '/browse/cars/dodge/1995/caravan',
        desc: 'Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1995/grand_caravan',
        desc: 'Grand Caravan',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1995/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1995/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1995/stratus',
        desc: 'Stratus',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1995/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1994': [
      {
        code: '/browse/cars/dodge/1994/neon',
        desc: 'Neon',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1994/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
      {
        code: '/browse/cars/dodge/1994/viper',
        desc: 'Viper',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1993': [
      {
        code: '/browse/cars/dodge/1993/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1992': [
      {
        code: '/browse/cars/dodge/1992/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1991': [
      {
        code: '/browse/cars/dodge/1991/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1990': [
      {
        code: '/browse/cars/dodge/1990/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1989': [
      {
        code: '/browse/cars/dodge/1989/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1988': [
      {
        code: '/browse/cars/dodge/1988/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1987': [
      {
        code: '/browse/cars/dodge/1987/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1986': [
      {
        code: '/browse/cars/dodge/1986/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1985': [
      {
        code: '/browse/cars/dodge/1985/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1984': [
      {
        code: '/browse/cars/dodge/1984/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1983': [
      {
        code: '/browse/cars/dodge/1983/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1982': [
      {
        code: '/browse/cars/dodge/1982/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1981': [
      {
        code: '/browse/cars/dodge/1981/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/dodge/1980': [
      {
        code: '/browse/cars/dodge/1980/ramcharger',
        desc: 'Ramcharger',
        brandCode: '/browse/cars/dodge',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1984': [
      {
        code: '/browse/cars/ferrari/1984/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1985': [
      {
        code: '/browse/cars/ferrari/1985/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1986': [
      {
        code: '/browse/cars/ferrari/1986/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1987': [
      {
        code: '/browse/cars/ferrari/1987/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1988': [
      {
        code: '/browse/cars/ferrari/1988/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1989': [
      {
        code: '/browse/cars/ferrari/1989/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1990': [
      {
        code: '/browse/cars/ferrari/1990/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1991': [
      {
        code: '/browse/cars/ferrari/1991/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1992': [
      {
        code: '/browse/cars/ferrari/1992/testarossa',
        desc: 'Testarossa',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1994': [
      {
        code: '/browse/cars/ferrari/1994/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1995': [
      {
        code: '/browse/cars/ferrari/1995/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1996': [
      {
        code: '/browse/cars/ferrari/1996/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1997': [
      {
        code: '/browse/cars/ferrari/1997/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1998': [
      {
        code: '/browse/cars/ferrari/1998/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/1999': [
      {
        code: '/browse/cars/ferrari/1999/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/1999/456m',
        desc: '456M',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/2000': [
      {
        code: '/browse/cars/ferrari/2000/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/2000/456m',
        desc: '456M',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/2001': [
      {
        code: '/browse/cars/ferrari/2001/360',
        desc: '360',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/2001/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/2001/456m',
        desc: '456M',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/2002': [
      {
        code: '/browse/cars/ferrari/2002/360',
        desc: '360',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/2002/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/2003': [
      {
        code: '/browse/cars/ferrari/2003/360',
        desc: '360',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/2003/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/2004': [
      {
        code: '/browse/cars/ferrari/2004/360',
        desc: '360',
        brandCode: '/browse/cars/ferrari',
      },
      {
        code: '/browse/cars/ferrari/2004/456',
        desc: '456',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ferrari/2010': [
      {
        code: '/browse/cars/ferrari/2010/612',
        desc: '612',
        brandCode: '/browse/cars/ferrari',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2022': [
      {
        code: '/browse/cars/fiat/2022/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2022/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2021': [
      {
        code: '/browse/cars/fiat/2021/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2021/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2020': [
      {
        code: '/browse/cars/fiat/2020/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2020/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2019': [
      {
        code: '/browse/cars/fiat/2019/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2019/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2019/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2019/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2019/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2018': [
      {
        code: '/browse/cars/fiat/2018/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2018/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2018/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2018/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2018/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2017': [
      {
        code: '/browse/cars/fiat/2017/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2017/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2016': [
      {
        code: '/browse/cars/fiat/2016/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/mobi',
        desc: 'Mobi',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2016/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2015': [
      {
        code: '/browse/cars/fiat/2015/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2015/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2014': [
      {
        code: '/browse/cars/fiat/2014/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2014/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2013': [
      {
        code: '/browse/cars/fiat/2013/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2013/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2012': [
      {
        code: '/browse/cars/fiat/2012/500',
        desc: '500',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2012/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2011': [
      {
        code: '/browse/cars/fiat/2011/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2011/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2010': [
      {
        code: '/browse/cars/fiat/2010/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2010/uno',
        desc: 'Uno',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2009': [
      {
        code: '/browse/cars/fiat/2009/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2009/500_c',
        desc: '500 C',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2009/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2009/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2009/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2009/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2009/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2008': [
      {
        code: '/browse/cars/fiat/2008/500_abarth',
        desc: '500 Abarth',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2008/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2008/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2008/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2008/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2008/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2008/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2007': [
      {
        code: '/browse/cars/fiat/2007/bravo',
        desc: 'Bravo',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2007/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2007/linea',
        desc: 'Linea',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2007/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2007/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2007/punto',
        desc: 'Punto',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2006': [
      {
        code: '/browse/cars/fiat/2006/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2006/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2006/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2006/stilo',
        desc: 'Stilo',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2005': [
      {
        code: '/browse/cars/fiat/2005/grande_punto',
        desc: 'Grande Punto',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2005/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2005/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2005/stilo',
        desc: 'Stilo',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2004': [
      {
        code: '/browse/cars/fiat/2004/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2004/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2004/stilo',
        desc: 'Stilo',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2003': [
      {
        code: '/browse/cars/fiat/2003/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2003/panda',
        desc: 'Panda',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2003/stilo',
        desc: 'Stilo',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2002': [
      {
        code: '/browse/cars/fiat/2002/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2002/stilo',
        desc: 'Stilo',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2001': [
      {
        code: '/browse/cars/fiat/2001/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
      {
        code: '/browse/cars/fiat/2001/stilo',
        desc: 'Stilo',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/2000': [
      {
        code: '/browse/cars/fiat/2000/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/1999': [
      {
        code: '/browse/cars/fiat/1999/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/fiat/1998': [
      {
        code: '/browse/cars/fiat/1998/palio',
        desc: 'Palio',
        brandCode: '/browse/cars/fiat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1981': [
      {
        code: '/browse/cars/ford/1981/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1984': [
      {
        code: '/browse/cars/ford/1984/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1982': [
      {
        code: '/browse/cars/ford/1982/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1983': [
      {
        code: '/browse/cars/ford/1983/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1985': [
      {
        code: '/browse/cars/ford/1985/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1986': [
      {
        code: '/browse/cars/ford/1986/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1986/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1987': [
      {
        code: '/browse/cars/ford/1987/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1987/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1987/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1988': [
      {
        code: '/browse/cars/ford/1988/capri',
        desc: 'Capri',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1988/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1988/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1989': [
      {
        code: '/browse/cars/ford/1989/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1989/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1990': [
      {
        code: '/browse/cars/ford/1990/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1990/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1991': [
      {
        code: '/browse/cars/ford/1991/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1991/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1991/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1991/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1991/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1991/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1991/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1992': [
      {
        code: '/browse/cars/ford/1992/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1992/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1993': [
      {
        code: '/browse/cars/ford/1993/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1993/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1994': [
      {
        code: '/browse/cars/ford/1994/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1994/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1995': [
      {
        code: '/browse/cars/ford/1995/contour',
        desc: 'Contour',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1995/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1996': [
      {
        code: '/browse/cars/ford/1996/contour',
        desc: 'Contour',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/f_superduty',
        desc: 'F Superduty',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1996/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1997': [
      {
        code: '/browse/cars/ford/1997/contour',
        desc: 'Contour',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1997/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1998': [
      {
        code: '/browse/cars/ford/1998/contour',
        desc: 'Contour',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/thunderbird',
        desc: 'Thunderbird',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1998/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/1999': [
      {
        code: '/browse/cars/ford/1999/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/f_superduty',
        desc: 'F Superduty',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/1999/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2000': [
      {
        code: '/browse/cars/ford/2000/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/excursion',
        desc: 'Excursion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2000/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2001': [
      {
        code: '/browse/cars/ford/2001/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/excursion',
        desc: 'Excursion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2001/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2002': [
      {
        code: '/browse/cars/ford/2002/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/e450',
        desc: 'E450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/excursion',
        desc: 'Excursion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2002/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2003': [
      {
        code: '/browse/cars/ford/2003/club_wagon',
        desc: 'Club Wagon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/e450',
        desc: 'E450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/e550',
        desc: 'E550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/escort',
        desc: 'Escort',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/excursion',
        desc: 'Excursion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/explorer_sport',
        desc: 'Explorer Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2003/windstar',
        desc: 'Windstar',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2004': [
      {
        code: '/browse/cars/ford/2004/club_wagon',
        desc: 'Club Wagon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/e450',
        desc: 'E450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/excursion',
        desc: 'Excursion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/freestar',
        desc: 'Freestar',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2004/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2005': [
      {
        code: '/browse/cars/ford/2005/club_wagon',
        desc: 'Club Wagon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/excursion',
        desc: 'Excursion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/five_hundred',
        desc: 'Five Hundred',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/freestar',
        desc: 'Freestar',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2005/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2006': [
      {
        code: '/browse/cars/ford/2006/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/five_hundred',
        desc: 'Five Hundred',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/freestar',
        desc: 'Freestar',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2006/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2007': [
      {
        code: '/browse/cars/ford/2007/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/explorer_sport_trac',
        desc: 'Explorer Sport Trac',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/five_hundred',
        desc: 'Five Hundred',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/freestar',
        desc: 'Freestar',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/mondeo',
        desc: 'Mondeo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2007/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2008': [
      {
        code: '/browse/cars/ford/2008/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/explorer_sport_trac',
        desc: 'Explorer Sport Trac',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2008/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2009': [
      {
        code: '/browse/cars/ford/2009/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/explorer_sport_trac',
        desc: 'Explorer Sport Trac',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2009/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2010': [
      {
        code: '/browse/cars/ford/2010/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/explorer_eddie_bauer',
        desc: 'Explorer Eddie Bauer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/explorer_sport_trac',
        desc: 'Explorer Sport Trac',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2010/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2011': [
      {
        code: '/browse/cars/ford/2011/crown_victoria',
        desc: 'Crown Victoria',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/e450',
        desc: 'E450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/ikon',
        desc: 'Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2011/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2012': [
      {
        code: '/browse/cars/ford/2012/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/fiesta_ikon',
        desc: 'Fiesta Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2012/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2013': [
      {
        code: '/browse/cars/ford/2013/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/fiesta_ikon',
        desc: 'Fiesta Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2013/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2014': [
      {
        code: '/browse/cars/ford/2014/econoline',
        desc: 'Econoline',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/fiesta_ikon',
        desc: 'Fiesta Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/gt',
        desc: 'GT',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/ka',
        desc: 'Ka',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2014/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2015': [
      {
        code: '/browse/cars/ford/2015/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/fiesta_ikon',
        desc: 'Fiesta Ikon',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2015/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2016': [
      {
        code: '/browse/cars/ford/2016/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/figo',
        desc: 'Figo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2016/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2017': [
      {
        code: '/browse/cars/ford/2017/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/figo',
        desc: 'Figo',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2017/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2018': [
      {
        code: '/browse/cars/ford/2018/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/f550',
        desc: 'F550',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2018/transit_150',
        desc: 'Transit 150',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2019': [
      {
        code: '/browse/cars/ford/2019/ecosport',
        desc: 'Ecosport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/escape',
        desc: 'Escape',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/expedition',
        desc: 'Expedition',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/explorer',
        desc: 'Explorer',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/f-150',
        desc: 'F-150',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/f250',
        desc: 'F250',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/f450',
        desc: 'F450',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/fiesta',
        desc: 'Fiesta',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2019/transit_150',
        desc: 'Transit 150',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2020': [
      {
        code: '/browse/cars/ford/2020/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2020/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2020/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2020/fusion',
        desc: 'Fusion',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2020/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2020/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2021': [
      {
        code: '/browse/cars/ford/2021/bronco_sport',
        desc: 'Bronco Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2021/edge',
        desc: 'Edge',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2021/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2021/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2021/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2021/ranger',
        desc: 'Ranger',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ford/2022': [
      {
        code: '/browse/cars/ford/2022/bronco_sport',
        desc: 'Bronco Sport',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2022/f-350',
        desc: 'F-350',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2022/focus',
        desc: 'Focus',
        brandCode: '/browse/cars/ford',
      },
      {
        code: '/browse/cars/ford/2022/mustang',
        desc: 'Mustang',
        brandCode: '/browse/cars/ford',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2022': [
      {
        code: '/browse/cars/gmc/2022/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2022/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2021': [
      {
        code: '/browse/cars/gmc/2021/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2021/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2020': [
      {
        code: '/browse/cars/gmc/2020/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2020/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2019': [
      {
        code: '/browse/cars/gmc/2019/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2019/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2019/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2018': [
      {
        code: '/browse/cars/gmc/2018/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2018/sierra',
        desc: 'Sierra',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2018/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2018/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2018/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2017': [
      {
        code: '/browse/cars/gmc/2017/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2017/sierra',
        desc: 'Sierra',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2017/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2017/sierra_2500',
        desc: 'Sierra 2500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2017/sierra_2500hd',
        desc: 'Sierra 2500HD',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2017/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2017/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2016': [
      {
        code: '/browse/cars/gmc/2016/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/sierra',
        desc: 'Sierra',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/sierra_2500',
        desc: 'Sierra 2500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/sierra_2500hd',
        desc: 'Sierra 2500HD',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/sierra_3500',
        desc: 'Sierra 3500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2016/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2015': [
      {
        code: '/browse/cars/gmc/2015/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2015/sierra',
        desc: 'Sierra',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2015/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2015/sierra_2500',
        desc: 'Sierra 2500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2015/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2015/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2014': [
      {
        code: '/browse/cars/gmc/2014/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2014/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2014/sierra_2500',
        desc: 'Sierra 2500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2014/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2014/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2013': [
      {
        code: '/browse/cars/gmc/2013/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2013/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2013/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2013/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2012': [
      {
        code: '/browse/cars/gmc/2012/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2012/canyon',
        desc: 'Canyon',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2012/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2012/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2012/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2011': [
      {
        code: '/browse/cars/gmc/2011/acadia',
        desc: 'Acadia',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2011/canyon',
        desc: 'Canyon',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2011/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2011/terrain',
        desc: 'Terrain',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2011/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2010': [
      {
        code: '/browse/cars/gmc/2010/canyon',
        desc: 'Canyon',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2010/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2010/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/gmc/2009': [
      {
        code: '/browse/cars/gmc/2009/canyon',
        desc: 'Canyon',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2009/sierra_1500',
        desc: 'Sierra 1500',
        brandCode: '/browse/cars/gmc',
      },
      {
        code: '/browse/cars/gmc/2009/yukon',
        desc: 'Yukon',
        brandCode: '/browse/cars/gmc',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2022': [
      {
        code: '/browse/cars/honda/2022/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2022/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2022/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2022/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2022/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2021': [
      {
        code: '/browse/cars/honda/2021/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2021/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2021/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2021/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2021/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2020': [
      {
        code: '/browse/cars/honda/2020/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2020/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2019': [
      {
        code: '/browse/cars/honda/2019/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/insight',
        desc: 'Insight',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2019/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2018': [
      {
        code: '/browse/cars/honda/2018/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2018/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2017': [
      {
        code: '/browse/cars/honda/2017/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2017/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2016': [
      {
        code: '/browse/cars/honda/2016/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/cr-z',
        desc: 'CR-Z',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2016/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2015': [
      {
        code: '/browse/cars/honda/2015/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/crosstour',
        desc: 'Crosstour',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/cr-z',
        desc: 'CR-Z',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2015/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2014': [
      {
        code: '/browse/cars/honda/2014/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/crosstour',
        desc: 'Crosstour',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/cr-z',
        desc: 'CR-Z',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/hr-v',
        desc: 'HR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/jazz',
        desc: 'Jazz',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2014/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2013': [
      {
        code: '/browse/cars/honda/2013/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/crosstour',
        desc: 'Crosstour',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/cr-z',
        desc: 'CR-Z',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2013/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2012': [
      {
        code: '/browse/cars/honda/2012/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/crosstour',
        desc: 'Crosstour',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2012/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2011': [
      {
        code: '/browse/cars/honda/2011/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2011/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2010': [
      {
        code: '/browse/cars/honda/2010/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/city',
        desc: 'City',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2010/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2009': [
      {
        code: '/browse/cars/honda/2009/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2009/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2009/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2009/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2009/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2009/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2009/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2008': [
      {
        code: '/browse/cars/honda/2008/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2008/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2008/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2008/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2008/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2008/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2008/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2007': [
      {
        code: '/browse/cars/honda/2007/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2007/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2007/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2007/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2007/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2007/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2007/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2006': [
      {
        code: '/browse/cars/honda/2006/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/element',
        desc: 'Element',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2006/ridgeline',
        desc: 'Ridgeline',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2005': [
      {
        code: '/browse/cars/honda/2005/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2005/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2005/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2005/element',
        desc: 'Element',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2005/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2005/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2005/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2004': [
      {
        code: '/browse/cars/honda/2004/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2004/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2004/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2004/element',
        desc: 'Element',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2004/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2004/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2004/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2003': [
      {
        code: '/browse/cars/honda/2003/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2003/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2003/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2003/element',
        desc: 'Element',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2003/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2003/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2003/pilot',
        desc: 'Pilot',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2002': [
      {
        code: '/browse/cars/honda/2002/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2002/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2002/cr-v',
        desc: 'CR-V',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2002/fit',
        desc: 'Fit',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2002/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2002/passport',
        desc: 'Passport',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2001': [
      {
        code: '/browse/cars/honda/2001/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2001/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/2000': [
      {
        code: '/browse/cars/honda/2000/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2000/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/2000/odyssey',
        desc: 'Odyssey',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1999': [
      {
        code: '/browse/cars/honda/1999/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1999/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1998': [
      {
        code: '/browse/cars/honda/1998/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1998/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1997': [
      {
        code: '/browse/cars/honda/1997/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1997/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1996': [
      {
        code: '/browse/cars/honda/1996/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1996/civic',
        desc: 'Civic',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1996/prelude',
        desc: 'Prelude',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1995': [
      {
        code: '/browse/cars/honda/1995/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1995/prelude',
        desc: 'Prelude',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1994': [
      {
        code: '/browse/cars/honda/1994/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1994/prelude',
        desc: 'Prelude',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1993': [
      {
        code: '/browse/cars/honda/1993/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1993/prelude',
        desc: 'Prelude',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1992': [
      {
        code: '/browse/cars/honda/1992/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1992/prelude',
        desc: 'Prelude',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1991': [
      {
        code: '/browse/cars/honda/1991/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
      {
        code: '/browse/cars/honda/1991/prelude',
        desc: 'Prelude',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1990': [
      {
        code: '/browse/cars/honda/1990/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/honda/1989': [
      {
        code: '/browse/cars/honda/1989/accord',
        desc: 'Accord',
        brandCode: '/browse/cars/honda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2010': [
      {
        code: '/browse/cars/hummer/2010/h3',
        desc: 'H3',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2009': [
      {
        code: '/browse/cars/hummer/2009/h2',
        desc: 'H2',
        brandCode: '/browse/cars/hummer',
      },
      {
        code: '/browse/cars/hummer/2009/h3',
        desc: 'H3',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2008': [
      {
        code: '/browse/cars/hummer/2008/h2',
        desc: 'H2',
        brandCode: '/browse/cars/hummer',
      },
      {
        code: '/browse/cars/hummer/2008/h3',
        desc: 'H3',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2007': [
      {
        code: '/browse/cars/hummer/2007/h2',
        desc: 'H2',
        brandCode: '/browse/cars/hummer',
      },
      {
        code: '/browse/cars/hummer/2007/h3',
        desc: 'H3',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2006': [
      {
        code: '/browse/cars/hummer/2006/h2',
        desc: 'H2',
        brandCode: '/browse/cars/hummer',
      },
      {
        code: '/browse/cars/hummer/2006/h3',
        desc: 'H3',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2005': [
      {
        code: '/browse/cars/hummer/2005/h2',
        desc: 'H2',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hummer/2004': [
      {
        code: '/browse/cars/hummer/2004/h2',
        desc: 'H2',
        brandCode: '/browse/cars/hummer',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2022': [
      {
        code: '/browse/cars/hyundai/2022/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2022/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2022/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2022/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2021': [
      {
        code: '/browse/cars/hyundai/2021/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2021/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2021/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2021/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2021/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2020': [
      {
        code: '/browse/cars/hyundai/2020/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2020/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2020/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2020/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2020/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2019': [
      {
        code: '/browse/cars/hyundai/2019/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2019/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2019/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2019/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2019/ioniq',
        desc: 'Ioniq',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2019/sonata',
        desc: 'Sonata',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2019/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2018': [
      {
        code: '/browse/cars/hyundai/2018/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/ioniq',
        desc: 'Ioniq',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/santa_fe',
        desc: 'Santa Fe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/sonata',
        desc: 'Sonata',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2018/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2017': [
      {
        code: '/browse/cars/hyundai/2017/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/grand_i10',
        desc: 'Grand i10',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/santa_fe',
        desc: 'Santa Fe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/sonata',
        desc: 'Sonata',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2017/veloster',
        desc: 'Veloster',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2016': [
      {
        code: '/browse/cars/hyundai/2016/creta',
        desc: 'Creta',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/grand_i10',
        desc: 'Grand i10',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/santa_fe',
        desc: 'Santa Fe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/sonata',
        desc: 'Sonata',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2016/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2015': [
      {
        code: '/browse/cars/hyundai/2015/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/ix35',
        desc: 'ix35',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/santa_fe',
        desc: 'Santa Fe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/sonata',
        desc: 'Sonata',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2015/tucson',
        desc: 'Tucson',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2014': [
      {
        code: '/browse/cars/hyundai/2014/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/genesis',
        desc: 'Genesis',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/ix35',
        desc: 'ix35',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2014/santa_fe',
        desc: 'Santa Fe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2013': [
      {
        code: '/browse/cars/hyundai/2013/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2013/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2013/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2013/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2013/santa_fe',
        desc: 'Santa Fe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2012': [
      {
        code: '/browse/cars/hyundai/2012/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2012/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2012/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2012/elantra',
        desc: 'Elantra',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2012/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2011': [
      {
        code: '/browse/cars/hyundai/2011/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2011/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2011/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2011/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2010': [
      {
        code: '/browse/cars/hyundai/2010/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2010/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2010/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2010/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2009': [
      {
        code: '/browse/cars/hyundai/2009/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2009/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2009/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2009/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2008': [
      {
        code: '/browse/cars/hyundai/2008/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2008/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2008/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2008/genesis_coupe',
        desc: 'Genesis Coupe',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2007': [
      {
        code: '/browse/cars/hyundai/2007/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2007/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2007/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2006': [
      {
        code: '/browse/cars/hyundai/2006/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2006/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2006/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2005': [
      {
        code: '/browse/cars/hyundai/2005/amica',
        desc: 'Amica',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2005/atos',
        desc: 'Atos',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/2005/atoz',
        desc: 'Atoz',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/2004': [
      {
        code: '/browse/cars/hyundai/2004/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1999': [
      {
        code: '/browse/cars/hyundai/1999/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1998': [
      {
        code: '/browse/cars/hyundai/1998/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1996': [
      {
        code: '/browse/cars/hyundai/1996/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1997': [
      {
        code: '/browse/cars/hyundai/1997/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1995': [
      {
        code: '/browse/cars/hyundai/1995/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1995/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1994': [
      {
        code: '/browse/cars/hyundai/1994/accent',
        desc: 'Accent',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1994/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1994/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1993': [
      {
        code: '/browse/cars/hyundai/1993/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1993/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1992': [
      {
        code: '/browse/cars/hyundai/1992/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1992/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1991': [
      {
        code: '/browse/cars/hyundai/1991/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1991/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1990': [
      {
        code: '/browse/cars/hyundai/1990/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1990/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1989': [
      {
        code: '/browse/cars/hyundai/1989/excel',
        desc: 'Excel',
        brandCode: '/browse/cars/hyundai',
      },
      {
        code: '/browse/cars/hyundai/1989/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1988': [
      {
        code: '/browse/cars/hyundai/1988/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1987': [
      {
        code: '/browse/cars/hyundai/1987/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/hyundai/1986': [
      {
        code: '/browse/cars/hyundai/1986/pony_excel',
        desc: 'Pony/Excel',
        brandCode: '/browse/cars/hyundai',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/1990': [
      {
        code: '/browse/cars/infiniti/1990/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/1991': [
      {
        code: '/browse/cars/infiniti/1991/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/1992': [
      {
        code: '/browse/cars/infiniti/1992/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/1993': [
      {
        code: '/browse/cars/infiniti/1993/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/1998': [
      {
        code: '/browse/cars/infiniti/1998/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2000': [
      {
        code: '/browse/cars/infiniti/2000/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2001': [
      {
        code: '/browse/cars/infiniti/2001/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2002': [
      {
        code: '/browse/cars/infiniti/2002/i35',
        desc: 'I35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2002/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2003': [
      {
        code: '/browse/cars/infiniti/2003/i35',
        desc: 'I35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2003/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2004': [
      {
        code: '/browse/cars/infiniti/2004/i35',
        desc: 'I35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2004/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2005': [
      {
        code: '/browse/cars/infiniti/2005/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2006': [
      {
        code: '/browse/cars/infiniti/2006/q45',
        desc: 'Q45',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2008': [
      {
        code: '/browse/cars/infiniti/2008/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2008/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2009': [
      {
        code: '/browse/cars/infiniti/2009/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2009/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2010': [
      {
        code: '/browse/cars/infiniti/2010/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2010/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2010/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2011': [
      {
        code: '/browse/cars/infiniti/2011/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2011/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2011/m37',
        desc: 'M37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2011/m56',
        desc: 'M56',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2012': [
      {
        code: '/browse/cars/infiniti/2012/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2012/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2012/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2012/m37',
        desc: 'M37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2012/m56',
        desc: 'M56',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2012/qx56',
        desc: 'QX56',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2013': [
      {
        code: '/browse/cars/infiniti/2013/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/fx37',
        desc: 'FX37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/jx35',
        desc: 'JX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/m37',
        desc: 'M37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/m56',
        desc: 'M56',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/qx56',
        desc: 'QX56',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2013/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2014': [
      {
        code: '/browse/cars/infiniti/2014/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/q50s',
        desc: 'Q50S',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/q60s',
        desc: 'Q60S',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/q70',
        desc: 'Q70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/q70l',
        desc: 'Q70L',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2014/qx80',
        desc: 'QX80',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2015': [
      {
        code: '/browse/cars/infiniti/2015/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/q50s',
        desc: 'Q50S',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/q60s',
        desc: 'Q60S',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/q70',
        desc: 'Q70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/q70l',
        desc: 'Q70L',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2015/qx80',
        desc: 'QX80',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2016': [
      {
        code: '/browse/cars/infiniti/2016/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/q70',
        desc: 'Q70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/q70l',
        desc: 'Q70L',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2016/qx80',
        desc: 'QX80',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2017': [
      {
        code: '/browse/cars/infiniti/2017/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/q60',
        desc: 'Q60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/q70',
        desc: 'Q70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/q70l',
        desc: 'Q70L',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/qx30',
        desc: 'QX30',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2017/qx80',
        desc: 'QX80',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2018': [
      {
        code: '/browse/cars/infiniti/2018/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/fx50',
        desc: 'FX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/g37',
        desc: 'G37',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/q60',
        desc: 'Q60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/q70',
        desc: 'Q70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/q70l',
        desc: 'Q70L',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/qx30',
        desc: 'QX30',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2018/qx80',
        desc: 'QX80',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2019': [
      {
        code: '/browse/cars/infiniti/2019/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/q70',
        desc: 'Q70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/q70l',
        desc: 'Q70L',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/qx30',
        desc: 'QX30',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/qx50',
        desc: 'QX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2019/qx80',
        desc: 'QX80',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2020': [
      {
        code: '/browse/cars/infiniti/2020/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2020/q50',
        desc: 'Q50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2020/qx50',
        desc: 'QX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2020/qx60',
        desc: 'QX60',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2020/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2021': [
      {
        code: '/browse/cars/infiniti/2021/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2021/qx50',
        desc: 'QX50',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2021/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/infiniti/2022': [
      {
        code: '/browse/cars/infiniti/2022/fx35',
        desc: 'FX35',
        brandCode: '/browse/cars/infiniti',
      },
      {
        code: '/browse/cars/infiniti/2022/qx70',
        desc: 'QX70',
        brandCode: '/browse/cars/infiniti',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/2004': [
      {
        code: '/browse/cars/isuzu/2004/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/2003': [
      {
        code: '/browse/cars/isuzu/2003/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/2002': [
      {
        code: '/browse/cars/isuzu/2002/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/2001': [
      {
        code: '/browse/cars/isuzu/2001/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/2000': [
      {
        code: '/browse/cars/isuzu/2000/hombre',
        desc: 'Hombre',
        brandCode: '/browse/cars/isuzu',
      },
      {
        code: '/browse/cars/isuzu/2000/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/1999': [
      {
        code: '/browse/cars/isuzu/1999/hombre',
        desc: 'Hombre',
        brandCode: '/browse/cars/isuzu',
      },
      {
        code: '/browse/cars/isuzu/1999/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/1998': [
      {
        code: '/browse/cars/isuzu/1998/hombre',
        desc: 'Hombre',
        brandCode: '/browse/cars/isuzu',
      },
      {
        code: '/browse/cars/isuzu/1998/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/1997': [
      {
        code: '/browse/cars/isuzu/1997/hombre',
        desc: 'Hombre',
        brandCode: '/browse/cars/isuzu',
      },
      {
        code: '/browse/cars/isuzu/1997/rodeo',
        desc: 'Rodeo',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/isuzu/1996': [
      {
        code: '/browse/cars/isuzu/1996/hombre',
        desc: 'Hombre',
        brandCode: '/browse/cars/isuzu',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2021': [
      {
        code: '/browse/cars/jaguar/2021/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2021/xe',
        desc: 'XE',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2021/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2020': [
      {
        code: '/browse/cars/jaguar/2020/e_-_pace',
        desc: 'E - Pace',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2020/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2020/xe',
        desc: 'XE',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2020/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2020/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2019': [
      {
        code: '/browse/cars/jaguar/2019/e_-_pace',
        desc: 'E - Pace',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2019/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2019/xe',
        desc: 'XE',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2019/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2019/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2018': [
      {
        code: '/browse/cars/jaguar/2018/e_-_pace',
        desc: 'E - Pace',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2018/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2018/f-type',
        desc: 'F-Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2018/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2018/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2017': [
      {
        code: '/browse/cars/jaguar/2017/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2017/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2017/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2016': [
      {
        code: '/browse/cars/jaguar/2016/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2016/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2016/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2015': [
      {
        code: '/browse/cars/jaguar/2015/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2015/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2015/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2015/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2015/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2015/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2014': [
      {
        code: '/browse/cars/jaguar/2014/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2014/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2014/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2014/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2014/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2014/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2013': [
      {
        code: '/browse/cars/jaguar/2013/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2013/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2013/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2013/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2013/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2013/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2013/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2012': [
      {
        code: '/browse/cars/jaguar/2012/f_type',
        desc: 'F Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2012/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2011': [
      {
        code: '/browse/cars/jaguar/2011/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2011/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2010': [
      {
        code: '/browse/cars/jaguar/2010/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2010/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2009': [
      {
        code: '/browse/cars/jaguar/2009/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/vanden',
        desc: 'Vanden',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/xfr',
        desc: 'XFR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/xfr-s',
        desc: 'XFR-S',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2009/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2008': [
      {
        code: '/browse/cars/jaguar/2008/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/s-type',
        desc: 'S-Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/vanden',
        desc: 'Vanden',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/xj8',
        desc: 'XJ8',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2008/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2007': [
      {
        code: '/browse/cars/jaguar/2007/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/s-type',
        desc: 'S-Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/vanden',
        desc: 'Vanden',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/xf',
        desc: 'XF',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/xj8',
        desc: 'XJ8',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/xjr',
        desc: 'XJR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2007/x-type',
        desc: 'X-Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2006': [
      {
        code: '/browse/cars/jaguar/2006/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/s-type',
        desc: 'S-Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/vanden',
        desc: 'Vanden',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/xj8',
        desc: 'XJ8',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/xk8',
        desc: 'XK8',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/xkr',
        desc: 'XKR',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2006/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2005': [
      {
        code: '/browse/cars/jaguar/2005/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/s-type',
        desc: 'S-Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/vanden',
        desc: 'Vanden',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/xj',
        desc: 'XJ',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/xj8',
        desc: 'XJ8',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2005/x-type',
        desc: 'X-Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2004': [
      {
        code: '/browse/cars/jaguar/2004/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2004/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2004/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2003': [
      {
        code: '/browse/cars/jaguar/2003/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2003/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2003/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2002': [
      {
        code: '/browse/cars/jaguar/2002/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2002/xk',
        desc: 'XK',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2002/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2001': [
      {
        code: '/browse/cars/jaguar/2001/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/2001/x_type',
        desc: 'X Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/2000': [
      {
        code: '/browse/cars/jaguar/2000/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/1999': [
      {
        code: '/browse/cars/jaguar/1999/s_type',
        desc: 'S Type',
        brandCode: '/browse/cars/jaguar',
      },
      {
        code: '/browse/cars/jaguar/1999/xk8',
        desc: 'XK8',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/1997': [
      {
        code: '/browse/cars/jaguar/1997/xk8',
        desc: 'XK8',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/1998': [
      {
        code: '/browse/cars/jaguar/1998/xk8',
        desc: 'XK8',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jaguar/1996': [
      {
        code: '/browse/cars/jaguar/1996/xk8',
        desc: 'XK8',
        brandCode: '/browse/cars/jaguar',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1991': [
      {
        code: '/browse/cars/jeep/1991/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1992': [
      {
        code: '/browse/cars/jeep/1992/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1993': [
      {
        code: '/browse/cars/jeep/1993/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1994': [
      {
        code: '/browse/cars/jeep/1994/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1995': [
      {
        code: '/browse/cars/jeep/1995/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1996': [
      {
        code: '/browse/cars/jeep/1996/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1996/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1997': [
      {
        code: '/browse/cars/jeep/1997/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1997/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1997/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1998': [
      {
        code: '/browse/cars/jeep/1998/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1998/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1998/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/1999': [
      {
        code: '/browse/cars/jeep/1999/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1999/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/1999/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2000': [
      {
        code: '/browse/cars/jeep/2000/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2000/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2000/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2001': [
      {
        code: '/browse/cars/jeep/2001/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2001/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2001/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2002': [
      {
        code: '/browse/cars/jeep/2002/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2002/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2002/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2003': [
      {
        code: '/browse/cars/jeep/2003/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2003/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2003/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2004': [
      {
        code: '/browse/cars/jeep/2004/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2004/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2004/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2005': [
      {
        code: '/browse/cars/jeep/2005/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2005/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2005/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2006': [
      {
        code: '/browse/cars/jeep/2006/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2006/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2006/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2006/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2007': [
      {
        code: '/browse/cars/jeep/2007/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2007/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2007/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2007/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2007/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2007/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2008': [
      {
        code: '/browse/cars/jeep/2008/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2008/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2008/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2008/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2008/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2008/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2009': [
      {
        code: '/browse/cars/jeep/2009/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2009/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2009/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2009/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2009/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2009/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2010': [
      {
        code: '/browse/cars/jeep/2010/commander',
        desc: 'Commander',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2010/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2010/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2010/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2010/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2010/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2011': [
      {
        code: '/browse/cars/jeep/2011/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2011/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2011/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2011/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2011/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2012': [
      {
        code: '/browse/cars/jeep/2012/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2012/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2012/liberty',
        desc: 'Liberty',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2012/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2012/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2013': [
      {
        code: '/browse/cars/jeep/2013/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2013/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2013/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2013/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2014': [
      {
        code: '/browse/cars/jeep/2014/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2014/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2014/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2014/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2014/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2015': [
      {
        code: '/browse/cars/jeep/2015/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2015/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2015/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2015/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2015/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2016': [
      {
        code: '/browse/cars/jeep/2016/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2016/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2016/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2016/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2016/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2017': [
      {
        code: '/browse/cars/jeep/2017/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2017/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2017/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2017/patriot',
        desc: 'Patriot',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2017/renegade',
        desc: 'Renegade',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2017/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2018': [
      {
        code: '/browse/cars/jeep/2018/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2018/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2018/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2018/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2019': [
      {
        code: '/browse/cars/jeep/2019/cherokee',
        desc: 'Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2019/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2019/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2019/wrangler',
        desc: 'Wrangler',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2020': [
      {
        code: '/browse/cars/jeep/2020/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
      {
        code: '/browse/cars/jeep/2020/grand_cherokee',
        desc: 'Grand Cherokee',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/jeep/2021': [
      {
        code: '/browse/cars/jeep/2021/compass',
        desc: 'Compass',
        brandCode: '/browse/cars/jeep',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2022': [
      {
        code: '/browse/cars/kia/2022/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2022/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2022/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2022/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2021': [
      {
        code: '/browse/cars/kia/2021/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2021/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2021/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2021/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2021/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2020': [
      {
        code: '/browse/cars/kia/2020/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2020/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2020/sedona',
        desc: 'Sedona',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2020/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2020/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2020/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2019': [
      {
        code: '/browse/cars/kia/2019/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/niro',
        desc: 'Niro',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/rio',
        desc: 'Rio',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/sedona',
        desc: 'Sedona',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/sorento',
        desc: 'Sorento',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2019/stinger',
        desc: 'Stinger',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2018': [
      {
        code: '/browse/cars/kia/2018/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/niro',
        desc: 'Niro',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/rio',
        desc: 'Rio',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/sorento',
        desc: 'Sorento',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2018/stinger',
        desc: 'Stinger',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2017': [
      {
        code: '/browse/cars/kia/2017/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/niro',
        desc: 'Niro',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/rio',
        desc: 'Rio',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/sorento',
        desc: 'Sorento',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2017/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2016': [
      {
        code: '/browse/cars/kia/2016/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2016/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2016/rio',
        desc: 'Rio',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2016/sorento',
        desc: 'Sorento',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2016/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2016/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2016/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2015': [
      {
        code: '/browse/cars/kia/2015/forte',
        desc: 'Forte',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2015/optima',
        desc: 'Optima',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2015/rio',
        desc: 'Rio',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2015/sorento_iii',
        desc: 'Sorento III',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2015/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
      {
        code: '/browse/cars/kia/2015/sportage',
        desc: 'Sportage',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2014': [
      {
        code: '/browse/cars/kia/2014/soul',
        desc: 'Soul',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2006': [
      {
        code: '/browse/cars/kia/2006/spectra',
        desc: 'Spectra',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2005': [
      {
        code: '/browse/cars/kia/2005/spectra',
        desc: 'Spectra',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/kia/2004': [
      {
        code: '/browse/cars/kia/2004/spectra',
        desc: 'Spectra',
        brandCode: '/browse/cars/kia',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2008': [
      {
        code: '/browse/cars/lamborghini/2008/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2007': [
      {
        code: '/browse/cars/lamborghini/2007/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2006': [
      {
        code: '/browse/cars/lamborghini/2006/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2005': [
      {
        code: '/browse/cars/lamborghini/2005/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2004': [
      {
        code: '/browse/cars/lamborghini/2004/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2003': [
      {
        code: '/browse/cars/lamborghini/2003/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2002': [
      {
        code: '/browse/cars/lamborghini/2002/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lamborghini/2001': [
      {
        code: '/browse/cars/lamborghini/2001/murcielago',
        desc: 'Murcielago',
        brandCode: '/browse/cars/lamborghini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2022': [
      {
        code: '/browse/cars/land_rover/2022/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2022/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2022/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2022/range_rover_velar',
        desc: 'Range Rover Velar',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2021': [
      {
        code: '/browse/cars/land_rover/2021/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2021/discovery',
        desc: 'Discovery',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2021/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2021/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2021/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2021/range_rover_velar',
        desc: 'Range Rover Velar',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2020': [
      {
        code: '/browse/cars/land_rover/2020/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2020/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2020/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2020/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2020/range_rover_velar',
        desc: 'Range Rover Velar',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2019': [
      {
        code: '/browse/cars/land_rover/2019/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2019/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2019/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2019/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2019/range_rover_velar',
        desc: 'Range Rover Velar',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2018': [
      {
        code: '/browse/cars/land_rover/2018/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2018/discovery_ii',
        desc: 'Discovery II',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2018/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2018/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2018/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2018/range_rover_velar',
        desc: 'Range Rover Velar',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2017': [
      {
        code: '/browse/cars/land_rover/2017/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2017/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2017/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2017/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2016': [
      {
        code: '/browse/cars/land_rover/2016/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2016/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2016/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2016/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2015': [
      {
        code: '/browse/cars/land_rover/2015/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2015/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2015/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2015/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2015/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2014': [
      {
        code: '/browse/cars/land_rover/2014/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2014/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2014/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2014/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2014/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2013': [
      {
        code: '/browse/cars/land_rover/2013/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2013/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2013/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2013/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2013/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2012': [
      {
        code: '/browse/cars/land_rover/2012/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2012/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2012/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2012/lr4',
        desc: 'LR4',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2012/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2012/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2011': [
      {
        code: '/browse/cars/land_rover/2011/defender',
        desc: 'Defender',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2011/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2011/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2011/range_rover_evoque',
        desc: 'Range Rover Evoque',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2011/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2010': [
      {
        code: '/browse/cars/land_rover/2010/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2010/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2009': [
      {
        code: '/browse/cars/land_rover/2009/discovery_3',
        desc: 'Discovery 3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2009/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2009/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2009/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2008': [
      {
        code: '/browse/cars/land_rover/2008/discovery_3',
        desc: 'Discovery 3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2008/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2008/lr2',
        desc: 'LR2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2008/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2007': [
      {
        code: '/browse/cars/land_rover/2007/discovery_3',
        desc: 'Discovery 3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2007/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2007/lr3',
        desc: 'LR3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2007/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2007/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2006': [
      {
        code: '/browse/cars/land_rover/2006/discovery_3',
        desc: 'Discovery 3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2006/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2006/freelander_2',
        desc: 'Freelander 2',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2006/lr3',
        desc: 'LR3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2006/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2006/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2005': [
      {
        code: '/browse/cars/land_rover/2005/discovery_3',
        desc: 'Discovery 3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2005/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2005/lr3',
        desc: 'LR3',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2005/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2005/range_rover_sport',
        desc: 'Range Rover Sport',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2004': [
      {
        code: '/browse/cars/land_rover/2004/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2003': [
      {
        code: '/browse/cars/land_rover/2003/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2002': [
      {
        code: '/browse/cars/land_rover/2002/discovery_ii',
        desc: 'Discovery II',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2002/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2001': [
      {
        code: '/browse/cars/land_rover/2001/discovery_ii',
        desc: 'Discovery II',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2001/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2001/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/2000': [
      {
        code: '/browse/cars/land_rover/2000/discovery_ii',
        desc: 'Discovery II',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2000/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/2000/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/1999': [
      {
        code: '/browse/cars/land_rover/1999/discovery_ii',
        desc: 'Discovery II',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/1999/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/1999/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/1998': [
      {
        code: '/browse/cars/land_rover/1998/discovery_ii',
        desc: 'Discovery II',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/1998/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/1998/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/1996': [
      {
        code: '/browse/cars/land_rover/1996/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/1997': [
      {
        code: '/browse/cars/land_rover/1997/discovery',
        desc: 'Discovery',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/1997/freelander',
        desc: 'Freelander',
        brandCode: '/browse/cars/land_rover',
      },
      {
        code: '/browse/cars/land_rover/1997/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/1995': [
      {
        code: '/browse/cars/land_rover/1995/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/land_rover/1994': [
      {
        code: '/browse/cars/land_rover/1994/range_rover',
        desc: 'Range Rover',
        brandCode: '/browse/cars/land_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1991': [
      {
        code: '/browse/cars/lexus/1991/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1992': [
      {
        code: '/browse/cars/lexus/1992/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1993': [
      {
        code: '/browse/cars/lexus/1993/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1994': [
      {
        code: '/browse/cars/lexus/1994/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
      {
        code: '/browse/cars/lexus/1994/gs',
        desc: 'GS',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1995': [
      {
        code: '/browse/cars/lexus/1995/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
      {
        code: '/browse/cars/lexus/1995/gs',
        desc: 'GS',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1996': [
      {
        code: '/browse/cars/lexus/1996/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
      {
        code: '/browse/cars/lexus/1996/gs',
        desc: 'GS',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/1997': [
      {
        code: '/browse/cars/lexus/1997/es',
        desc: 'ES',
        brandCode: '/browse/cars/lexus',
      },
      {
        code: '/browse/cars/lexus/1997/gs',
        desc: 'GS',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/2000': [
      {
        code: '/browse/cars/lexus/2000/rx',
        desc: 'RX',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/2001': [
      {
        code: '/browse/cars/lexus/2001/rx',
        desc: 'RX',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/2002': [
      {
        code: '/browse/cars/lexus/2002/rx',
        desc: 'RX',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lexus/2003': [
      {
        code: '/browse/cars/lexus/2003/rx',
        desc: 'RX',
        brandCode: '/browse/cars/lexus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2020': [
      {
        code: '/browse/cars/lincoln/2020/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2020/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2019': [
      {
        code: '/browse/cars/lincoln/2019/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2019/mkc',
        desc: 'MKC',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2019/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2019/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2018': [
      {
        code: '/browse/cars/lincoln/2018/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2018/mkc',
        desc: 'MKC',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2018/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2018/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2018/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2017': [
      {
        code: '/browse/cars/lincoln/2017/continental',
        desc: 'Continental',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2017/mkc',
        desc: 'MKC',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2017/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2017/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2017/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2016': [
      {
        code: '/browse/cars/lincoln/2016/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2016/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2016/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2015': [
      {
        code: '/browse/cars/lincoln/2015/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2015/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2015/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2015/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2014': [
      {
        code: '/browse/cars/lincoln/2014/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2014/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2014/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2014/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2013': [
      {
        code: '/browse/cars/lincoln/2013/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2013/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2013/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2013/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2012': [
      {
        code: '/browse/cars/lincoln/2012/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2012/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2012/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2012/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2011': [
      {
        code: '/browse/cars/lincoln/2011/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2011/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2011/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2011/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2011/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2010': [
      {
        code: '/browse/cars/lincoln/2010/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2010/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2010/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2010/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2009': [
      {
        code: '/browse/cars/lincoln/2009/mks',
        desc: 'MKS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2009/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2009/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2009/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2009/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2008': [
      {
        code: '/browse/cars/lincoln/2008/mark_lt',
        desc: 'Mark LT',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2008/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2008/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2008/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2008/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2007': [
      {
        code: '/browse/cars/lincoln/2007/mark_lt',
        desc: 'Mark LT',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2007/mkx',
        desc: 'MKX',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2007/mkz',
        desc: 'MKZ',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2007/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2007/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2006': [
      {
        code: '/browse/cars/lincoln/2006/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2006/mark_lt',
        desc: 'Mark LT',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2006/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2006/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2006/zephyr',
        desc: 'Zephyr',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2005': [
      {
        code: '/browse/cars/lincoln/2005/aviator',
        desc: 'Aviator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2005/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2005/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2005/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2004': [
      {
        code: '/browse/cars/lincoln/2004/aviator',
        desc: 'Aviator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2004/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2004/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2004/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2002': [
      {
        code: '/browse/cars/lincoln/2002/blackwood',
        desc: 'Blackwood',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2002/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2002/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2002/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2003': [
      {
        code: '/browse/cars/lincoln/2003/aviator',
        desc: 'Aviator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2003/blackwood',
        desc: 'Blackwood',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2003/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2003/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2003/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2001': [
      {
        code: '/browse/cars/lincoln/2001/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2001/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2001/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/2000': [
      {
        code: '/browse/cars/lincoln/2000/ls',
        desc: 'LS',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2000/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/2000/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lincoln/1999': [
      {
        code: '/browse/cars/lincoln/1999/navigator',
        desc: 'Navigator',
        brandCode: '/browse/cars/lincoln',
      },
      {
        code: '/browse/cars/lincoln/1999/town_car',
        desc: 'Town Car',
        brandCode: '/browse/cars/lincoln',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lotus/1998': [
      {
        code: '/browse/cars/lotus/1998/esprit',
        desc: 'Esprit',
        brandCode: '/browse/cars/lotus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lotus/1997': [
      {
        code: '/browse/cars/lotus/1997/esprit',
        desc: 'Esprit',
        brandCode: '/browse/cars/lotus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/lotus/1996': [
      {
        code: '/browse/cars/lotus/1996/esprit',
        desc: 'Esprit',
        brandCode: '/browse/cars/lotus',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2018': [
      {
        code: '/browse/cars/maserati/2018/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2017': [
      {
        code: '/browse/cars/maserati/2017/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2017/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2016': [
      {
        code: '/browse/cars/maserati/2016/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2016/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2015': [
      {
        code: '/browse/cars/maserati/2015/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2015/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2014': [
      {
        code: '/browse/cars/maserati/2014/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2014/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2014/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2014/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2013': [
      {
        code: '/browse/cars/maserati/2013/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2013/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2013/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2013/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2012': [
      {
        code: '/browse/cars/maserati/2012/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2012/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2012/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2012/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2011': [
      {
        code: '/browse/cars/maserati/2011/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2011/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2011/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2011/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2011/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2010': [
      {
        code: '/browse/cars/maserati/2010/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2010/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2010/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2010/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2010/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2009': [
      {
        code: '/browse/cars/maserati/2009/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2009/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2009/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2009/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2009/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2008': [
      {
        code: '/browse/cars/maserati/2008/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2008/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2008/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2008/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2008/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2006': [
      {
        code: '/browse/cars/maserati/2006/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2006/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2006/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2006/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2007': [
      {
        code: '/browse/cars/maserati/2007/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2007/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2007/granturismo',
        desc: 'Granturismo',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2007/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2007/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2005': [
      {
        code: '/browse/cars/maserati/2005/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2005/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2005/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2005/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2004': [
      {
        code: '/browse/cars/maserati/2004/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2004/gransport',
        desc: 'Gransport',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2004/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2004/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2003': [
      {
        code: '/browse/cars/maserati/2003/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2003/quattroporte',
        desc: 'Quattroporte',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2003/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2002': [
      {
        code: '/browse/cars/maserati/2002/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2002/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maserati/2001': [
      {
        code: '/browse/cars/maserati/2001/4200',
        desc: '4200',
        brandCode: '/browse/cars/maserati',
      },
      {
        code: '/browse/cars/maserati/2001/spyder',
        desc: 'Spyder',
        brandCode: '/browse/cars/maserati',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2009': [
      {
        code: '/browse/cars/maybach/2009/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2009/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2002': [
      {
        code: '/browse/cars/maybach/2002/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2002/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2003': [
      {
        code: '/browse/cars/maybach/2003/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2003/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2004': [
      {
        code: '/browse/cars/maybach/2004/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2004/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2005': [
      {
        code: '/browse/cars/maybach/2005/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2005/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2006': [
      {
        code: '/browse/cars/maybach/2006/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2006/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2007': [
      {
        code: '/browse/cars/maybach/2007/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2007/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2008': [
      {
        code: '/browse/cars/maybach/2008/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2008/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2010': [
      {
        code: '/browse/cars/maybach/2010/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2010/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2011': [
      {
        code: '/browse/cars/maybach/2011/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2011/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/maybach/2012': [
      {
        code: '/browse/cars/maybach/2012/57',
        desc: '57',
        brandCode: '/browse/cars/maybach',
      },
      {
        code: '/browse/cars/maybach/2012/62',
        desc: '62',
        brandCode: '/browse/cars/maybach',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2022': [
      {
        code: '/browse/cars/mazda/2022/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2022/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2021': [
      {
        code: '/browse/cars/mazda/2021/cx-3',
        desc: 'CX-3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2021/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2021/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2021/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2021/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2021/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2020': [
      {
        code: '/browse/cars/mazda/2020/cx-3',
        desc: 'CX-3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2020/cx-30',
        desc: 'CX-30',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2020/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2020/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2020/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2018': [
      {
        code: '/browse/cars/mazda/2018/b4000',
        desc: 'B4000',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/cx-3',
        desc: 'CX-3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2018/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2019': [
      {
        code: '/browse/cars/mazda/2019/cx-3',
        desc: 'CX-3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2019/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2019/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2019/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2019/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2017': [
      {
        code: '/browse/cars/mazda/2017/cx-3',
        desc: 'CX-3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2017/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2016': [
      {
        code: '/browse/cars/mazda/2016/cx-3',
        desc: 'CX-3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2016/mx-5_miata',
        desc: 'MX-5 Miata',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2015': [
      {
        code: '/browse/cars/mazda/2015/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2015/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2015/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2015/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2015/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2015/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2015/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2014': [
      {
        code: '/browse/cars/mazda/2014/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2014/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2014/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2014/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2014/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2014/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2014/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2013': [
      {
        code: '/browse/cars/mazda/2013/cx-5',
        desc: 'CX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2013/mx-5_miata',
        desc: 'MX-5 Miata',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2012': [
      {
        code: '/browse/cars/mazda/2012/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2012/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2011': [
      {
        code: '/browse/cars/mazda/2011/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2011/mx-5_miata',
        desc: 'MX-5 Miata',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2010': [
      {
        code: '/browse/cars/mazda/2010/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2010/mx-5_miata',
        desc: 'MX-5 Miata',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2009': [
      {
        code: '/browse/cars/mazda/2009/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2009/mx-5_miata',
        desc: 'MX-5 Miata',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2008': [
      {
        code: '/browse/cars/mazda/2008/b3000',
        desc: 'B3000',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/b-series',
        desc: 'B-Series',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2008/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2007': [
      {
        code: '/browse/cars/mazda/2007/b3000',
        desc: 'B3000',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/cx-7',
        desc: 'CX-7',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/cx-9',
        desc: 'CX-9',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/mazda_2',
        desc: 'Mazda 2',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2007/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2006': [
      {
        code: '/browse/cars/mazda/2006/b3000',
        desc: 'B3000',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2006/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2006/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2006/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2006/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2006/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2005': [
      {
        code: '/browse/cars/mazda/2005/b3000',
        desc: 'B3000',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2005/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2005/mazda_5',
        desc: 'Mazda 5',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2005/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2005/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2005/mx-5',
        desc: 'MX-5',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2004': [
      {
        code: '/browse/cars/mazda/2004/mazda_3',
        desc: 'Mazda 3',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2004/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2004/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2003': [
      {
        code: '/browse/cars/mazda/2003/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2003/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2002': [
      {
        code: '/browse/cars/mazda/2002/b-series',
        desc: 'B-Series',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2002/mazda_6',
        desc: 'Mazda 6',
        brandCode: '/browse/cars/mazda',
      },
      {
        code: '/browse/cars/mazda/2002/mpv',
        desc: 'MPV',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/2001': [
      {
        code: '/browse/cars/mazda/2001/b-series',
        desc: 'B-Series',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/1991': [
      {
        code: '/browse/cars/mazda/1991/929',
        desc: '929',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mazda/1990': [
      {
        code: '/browse/cars/mazda/1990/929',
        desc: '929',
        brandCode: '/browse/cars/mazda',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1980': [
      {
        code: '/browse/cars/mercedes-benz/1980/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1981': [
      {
        code: '/browse/cars/mercedes-benz/1981/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1982': [
      {
        code: '/browse/cars/mercedes-benz/1982/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1983': [
      {
        code: '/browse/cars/mercedes-benz/1983/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1984': [
      {
        code: '/browse/cars/mercedes-benz/1984/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1985': [
      {
        code: '/browse/cars/mercedes-benz/1985/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1985/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1986': [
      {
        code: '/browse/cars/mercedes-benz/1986/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1986/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1987': [
      {
        code: '/browse/cars/mercedes-benz/1987/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1987/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1988': [
      {
        code: '/browse/cars/mercedes-benz/1988/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1988/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1989': [
      {
        code: '/browse/cars/mercedes-benz/1989/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1989/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1990': [
      {
        code: '/browse/cars/mercedes-benz/1990/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1990/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1990/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1991': [
      {
        code: '/browse/cars/mercedes-benz/1991/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1991/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1991/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1991/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1992': [
      {
        code: '/browse/cars/mercedes-benz/1992/230',
        desc: '230',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1992/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1992/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1992/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1992/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1992/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1993': [
      {
        code: '/browse/cars/mercedes-benz/1993/500',
        desc: '500',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1993/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1993/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1993/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1993/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1993/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1994': [
      {
        code: '/browse/cars/mercedes-benz/1994/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1994/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1994/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1994/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1994/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1995': [
      {
        code: '/browse/cars/mercedes-benz/1995/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1995/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1995/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1995/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1995/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1996': [
      {
        code: '/browse/cars/mercedes-benz/1996/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1996/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1996/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1996/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1996/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1996/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1996/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1997': [
      {
        code: '/browse/cars/mercedes-benz/1997/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1997/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1998': [
      {
        code: '/browse/cars/mercedes-benz/1998/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1998/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/1999': [
      {
        code: '/browse/cars/mercedes-benz/1999/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/1999/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2000': [
      {
        code: '/browse/cars/mercedes-benz/2000/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2000/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2001': [
      {
        code: '/browse/cars/mercedes-benz/2001/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2001/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2002': [
      {
        code: '/browse/cars/mercedes-benz/2002/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/sl',
        desc: 'SL',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2002/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2003': [
      {
        code: '/browse/cars/mercedes-benz/2003/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2003/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2004': [
      {
        code: '/browse/cars/mercedes-benz/2004/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2004/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2005': [
      {
        code: '/browse/cars/mercedes-benz/2005/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/clk-class',
        desc: 'CLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/cls-class',
        desc: 'CLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2005/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2006': [
      {
        code: '/browse/cars/mercedes-benz/2006/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/clk-class',
        desc: 'CLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/cls-class',
        desc: 'CLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2006/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2007': [
      {
        code: '/browse/cars/mercedes-benz/2007/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/clk-class',
        desc: 'CLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/cls-class',
        desc: 'CLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2007/slr',
        desc: 'SLR',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2008': [
      {
        code: '/browse/cars/mercedes-benz/2008/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/clk-class',
        desc: 'CLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/cls-class',
        desc: 'CLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2008/slr',
        desc: 'SLR',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2009': [
      {
        code: '/browse/cars/mercedes-benz/2009/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/clk-class',
        desc: 'CLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/g_-_class',
        desc: 'G - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2009/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2010': [
      {
        code: '/browse/cars/mercedes-benz/2010/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/clk_-_class',
        desc: 'CLK - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2010/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2011': [
      {
        code: '/browse/cars/mercedes-benz/2011/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/cls-class',
        desc: 'CLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/sls',
        desc: 'SLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2011/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2012': [
      {
        code: '/browse/cars/mercedes-benz/2012/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2012/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2013': [
      {
        code: '/browse/cars/mercedes-benz/2013/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2013/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2014': [
      {
        code: '/browse/cars/mercedes-benz/2014/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2014/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2015': [
      {
        code: '/browse/cars/mercedes-benz/2015/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/glc_class',
        desc: 'GLC Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/gle',
        desc: 'GLE',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/glk-class',
        desc: 'GLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2015/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2016': [
      {
        code: '/browse/cars/mercedes-benz/2016/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/clk',
        desc: 'CLK',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/gla',
        desc: 'GLA',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/glc_class',
        desc: 'GLC Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/gle',
        desc: 'GLE',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/r-class',
        desc: 'R-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2016/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2018': [
      {
        code: '/browse/cars/mercedes-benz/2018/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/amg',
        desc: 'AMG',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/gla',
        desc: 'GLA',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/glc_class',
        desc: 'GLC Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/gle',
        desc: 'GLE',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/gls',
        desc: 'GLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/slc',
        desc: 'SLC',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2018/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2017': [
      {
        code: '/browse/cars/mercedes-benz/2017/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/amg',
        desc: 'AMG',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/gla',
        desc: 'GLA',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/glc_class',
        desc: 'GLC Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/gle',
        desc: 'GLE',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/gls',
        desc: 'GLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/slc',
        desc: 'SLC',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2017/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2019': [
      {
        code: '/browse/cars/mercedes-benz/2019/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/amg',
        desc: 'AMG',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/glc_class',
        desc: 'GLC Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/gl-class',
        desc: 'GL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2019/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2020': [
      {
        code: '/browse/cars/mercedes-benz/2020/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2020/sls-class',
        desc: 'SLS-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2021': [
      {
        code: '/browse/cars/mercedes-benz/2021/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/cl-class',
        desc: 'CL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/cls',
        desc: 'CLS',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/cls_-_class',
        desc: 'CLS - Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2021/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercedes-benz/2022': [
      {
        code: '/browse/cars/mercedes-benz/2022/a-class',
        desc: 'A-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/b-class',
        desc: 'B-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/c-class',
        desc: 'C-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/cla-class',
        desc: 'CLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/e-class',
        desc: 'E-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/g-class',
        desc: 'G-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/gla-class',
        desc: 'GLA-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/m-class',
        desc: 'M-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/s-class',
        desc: 'S-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/sl-class',
        desc: 'SL-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
      {
        code: '/browse/cars/mercedes-benz/2022/slk-class',
        desc: 'SLK-Class',
        brandCode: '/browse/cars/mercedes-benz',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2011': [
      {
        code: '/browse/cars/mercury/2011/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2010': [
      {
        code: '/browse/cars/mercury/2010/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2010/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2009': [
      {
        code: '/browse/cars/mercury/2009/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2009/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2008': [
      {
        code: '/browse/cars/mercury/2008/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2008/milan',
        desc: 'Milan',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2008/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2007': [
      {
        code: '/browse/cars/mercury/2007/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2007/milan',
        desc: 'Milan',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2007/montego',
        desc: 'Montego',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2007/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2006': [
      {
        code: '/browse/cars/mercury/2006/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2006/montego',
        desc: 'Montego',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2006/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2005': [
      {
        code: '/browse/cars/mercury/2005/mariner',
        desc: 'Mariner',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2005/montego',
        desc: 'Montego',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2005/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2004': [
      {
        code: '/browse/cars/mercury/2004/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2003': [
      {
        code: '/browse/cars/mercury/2003/mountaineer',
        desc: 'Mountaineer',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2003/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2002': [
      {
        code: '/browse/cars/mercury/2002/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2001': [
      {
        code: '/browse/cars/mercury/2001/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2001/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/2000': [
      {
        code: '/browse/cars/mercury/2000/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/2000/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1999': [
      {
        code: '/browse/cars/mercury/1999/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1999/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1998': [
      {
        code: '/browse/cars/mercury/1998/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1998/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1997': [
      {
        code: '/browse/cars/mercury/1997/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1997/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1997/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1996': [
      {
        code: '/browse/cars/mercury/1996/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1996/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1996/sable',
        desc: 'Sable',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1995': [
      {
        code: '/browse/cars/mercury/1995/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1995/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1994': [
      {
        code: '/browse/cars/mercury/1994/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
      {
        code: '/browse/cars/mercury/1994/mystique',
        desc: 'Mystique',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1993': [
      {
        code: '/browse/cars/mercury/1993/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1992': [
      {
        code: '/browse/cars/mercury/1992/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mercury/1991': [
      {
        code: '/browse/cars/mercury/1991/grand_marquis',
        desc: 'Grand Marquis',
        brandCode: '/browse/cars/mercury',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/2005': [
      {
        code: '/browse/cars/mg_rover/2005/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2005/tf',
        desc: 'TF',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2005/zr',
        desc: 'ZR',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2005/zt',
        desc: 'ZT',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/2004': [
      {
        code: '/browse/cars/mg_rover/2004/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2004/tf',
        desc: 'TF',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2004/zr',
        desc: 'ZR',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2004/zt',
        desc: 'ZT',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/2003': [
      {
        code: '/browse/cars/mg_rover/2003/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2003/tf',
        desc: 'TF',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2003/zr',
        desc: 'ZR',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2003/zt',
        desc: 'ZT',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/2002': [
      {
        code: '/browse/cars/mg_rover/2002/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2002/tf',
        desc: 'TF',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2002/zr',
        desc: 'ZR',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2002/zt',
        desc: 'ZT',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/2001': [
      {
        code: '/browse/cars/mg_rover/2001/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2001/zr',
        desc: 'ZR',
        brandCode: '/browse/cars/mg_rover',
      },
      {
        code: '/browse/cars/mg_rover/2001/zt',
        desc: 'ZT',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/2000': [
      {
        code: '/browse/cars/mg_rover/2000/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mg_rover/1999': [
      {
        code: '/browse/cars/mg_rover/1999/75',
        desc: '75',
        brandCode: '/browse/cars/mg_rover',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2022': [
      {
        code: '/browse/cars/mini/2022/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2022/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2022/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2022/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2022/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2022/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2022/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2021': [
      {
        code: '/browse/cars/mini/2021/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2021/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2021/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2021/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2021/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2021/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2021/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2020': [
      {
        code: '/browse/cars/mini/2020/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2020/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2020/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2020/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2020/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2020/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2020/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2019': [
      {
        code: '/browse/cars/mini/2019/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2019/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2019/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2019/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2019/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2019/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2019/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2018': [
      {
        code: '/browse/cars/mini/2018/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2018/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2018/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2018/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2018/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2018/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2018/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2017': [
      {
        code: '/browse/cars/mini/2017/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2017/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2017/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2017/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2017/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2017/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2017/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2016': [
      {
        code: '/browse/cars/mini/2016/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2016/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2016/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2016/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2016/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2016/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2015': [
      {
        code: '/browse/cars/mini/2015/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/cooper_s_countryman',
        desc: 'Cooper S Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/cooper_sx',
        desc: 'Cooper SX',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2015/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2014': [
      {
        code: '/browse/cars/mini/2014/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2014/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2014/cooper_sx',
        desc: 'Cooper SX',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2014/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2014/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2014/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2014/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2013': [
      {
        code: '/browse/cars/mini/2013/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/cooper_sx',
        desc: 'Cooper SX',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/cooper_works_clubman',
        desc: 'Cooper Works Clubman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2013/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2012': [
      {
        code: '/browse/cars/mini/2012/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/cooper_s',
        desc: 'Cooper S',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/cooper_works',
        desc: 'Cooper Works',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/cooper_works_clubman',
        desc: 'Cooper Works Clubman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/paceman',
        desc: 'Paceman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2012/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mini/2011': [
      {
        code: '/browse/cars/mini/2011/cooper',
        desc: 'Cooper',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2011/cooper_countryman',
        desc: 'Cooper Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2011/cooper_works',
        desc: 'Cooper Works',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2011/cooper_works_clubman',
        desc: 'Cooper Works Clubman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2011/countryman',
        desc: 'Countryman',
        brandCode: '/browse/cars/mini',
      },
      {
        code: '/browse/cars/mini/2011/mini',
        desc: 'Mini',
        brandCode: '/browse/cars/mini',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2022': [
      {
        code: '/browse/cars/mitsubishi/2022/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2022/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2021': [
      {
        code: '/browse/cars/mitsubishi/2021/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2021/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2020': [
      {
        code: '/browse/cars/mitsubishi/2020/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2020/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2020/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2019': [
      {
        code: '/browse/cars/mitsubishi/2019/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2019/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2019/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2019/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2019/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2018': [
      {
        code: '/browse/cars/mitsubishi/2018/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2018/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2018/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2018/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2017': [
      {
        code: '/browse/cars/mitsubishi/2017/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2017/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2017/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2017/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2016': [
      {
        code: '/browse/cars/mitsubishi/2016/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2016/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2016/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2016/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2016/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2016/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2015': [
      {
        code: '/browse/cars/mitsubishi/2015/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2015/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2015/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2015/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2015/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2015/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2014': [
      {
        code: '/browse/cars/mitsubishi/2014/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2014/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2014/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2014/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2014/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2014/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2014/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2013': [
      {
        code: '/browse/cars/mitsubishi/2013/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2013/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2012': [
      {
        code: '/browse/cars/mitsubishi/2012/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/mirage',
        desc: 'Mirage',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2012/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2011': [
      {
        code: '/browse/cars/mitsubishi/2011/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2011/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2010': [
      {
        code: '/browse/cars/mitsubishi/2010/asx',
        desc: 'ASX',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/endeavor',
        desc: 'Endeavor',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2010/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2009': [
      {
        code: '/browse/cars/mitsubishi/2009/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2009/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2008': [
      {
        code: '/browse/cars/mitsubishi/2008/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/endeavor',
        desc: 'Endeavor',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2008/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2007': [
      {
        code: '/browse/cars/mitsubishi/2007/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/endeavor',
        desc: 'Endeavor',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/l200',
        desc: 'L200',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2007/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2005': [
      {
        code: '/browse/cars/mitsubishi/2005/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/endeavor',
        desc: 'Endeavor',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2005/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2006': [
      {
        code: '/browse/cars/mitsubishi/2006/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/endeavor',
        desc: 'Endeavor',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2006/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2004': [
      {
        code: '/browse/cars/mitsubishi/2004/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/endeavor',
        desc: 'Endeavor',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/grandis',
        desc: 'Grandis',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/lancer',
        desc: 'Lancer',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2004/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2003': [
      {
        code: '/browse/cars/mitsubishi/2003/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/galant',
        desc: 'Galant',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/outlander',
        desc: 'Outlander',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2003/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2002': [
      {
        code: '/browse/cars/mitsubishi/2002/eclipse',
        desc: 'Eclipse',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2002/lancer_evolution',
        desc: 'Lancer Evolution',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2002/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2002/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2002/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2002/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2001': [
      {
        code: '/browse/cars/mitsubishi/2001/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2001/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2001/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2001/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/1999': [
      {
        code: '/browse/cars/mitsubishi/1999/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/mitsubishi/2000': [
      {
        code: '/browse/cars/mitsubishi/2000/montero',
        desc: 'Montero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2000/pajero',
        desc: 'Pajero',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2000/shogun',
        desc: 'Shogun',
        brandCode: '/browse/cars/mitsubishi',
      },
      {
        code: '/browse/cars/mitsubishi/2000/space_star',
        desc: 'Space Star',
        brandCode: '/browse/cars/mitsubishi',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2022': [
      {
        code: '/browse/cars/nissan/2022/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2022/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2020': [
      {
        code: '/browse/cars/nissan/2020/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2020/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2021': [
      {
        code: '/browse/cars/nissan/2021/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2021/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2019': [
      {
        code: '/browse/cars/nissan/2019/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/leaf',
        desc: 'Leaf',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2019/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2018': [
      {
        code: '/browse/cars/nissan/2018/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/leaf',
        desc: 'Leaf',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2018/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2017': [
      {
        code: '/browse/cars/nissan/2017/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/gt-r',
        desc: 'GT-R',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/juke',
        desc: 'Juke',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2017/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2016': [
      {
        code: '/browse/cars/nissan/2016/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/juke',
        desc: 'Juke',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/kicks',
        desc: 'Kicks',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2016/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2015': [
      {
        code: '/browse/cars/nissan/2015/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/juke',
        desc: 'Juke',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2015/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2014': [
      {
        code: '/browse/cars/nissan/2014/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/juke',
        desc: 'Juke',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2014/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2013': [
      {
        code: '/browse/cars/nissan/2013/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/juke',
        desc: 'Juke',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/nv3500',
        desc: 'NV3500',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2013/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2012': [
      {
        code: '/browse/cars/nissan/2012/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/nv3500',
        desc: 'NV3500',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2012/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2010': [
      {
        code: '/browse/cars/nissan/2010/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/aprio',
        desc: 'Aprio',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2010/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2011': [
      {
        code: '/browse/cars/nissan/2011/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/juke',
        desc: 'Juke',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/versa',
        desc: 'Versa',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2011/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2009': [
      {
        code: '/browse/cars/nissan/2009/370z',
        desc: '370Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/aprio',
        desc: 'Aprio',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/micra',
        desc: 'Micra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2009/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2008': [
      {
        code: '/browse/cars/nissan/2008/350z',
        desc: '350Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/aprio',
        desc: 'Aprio',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/micra',
        desc: 'Micra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/np300',
        desc: 'NP300',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/rogue',
        desc: 'Rogue',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2008/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2007': [
      {
        code: '/browse/cars/nissan/2007/350z',
        desc: '350Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/micra',
        desc: 'Micra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2007/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2006': [
      {
        code: '/browse/cars/nissan/2006/350z',
        desc: '350Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/micra',
        desc: 'Micra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2006/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2004': [
      {
        code: '/browse/cars/nissan/2004/350z',
        desc: '350Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2004/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2005': [
      {
        code: '/browse/cars/nissan/2005/350z',
        desc: '350Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/armada',
        desc: 'Armada',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/micra',
        desc: 'Micra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/note',
        desc: 'Note',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/tiida',
        desc: 'Tiida',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/titan',
        desc: 'Titan',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2005/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2003': [
      {
        code: '/browse/cars/nissan/2003/350z',
        desc: '350Z',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/march',
        desc: 'March',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/murano',
        desc: 'Murano',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2003/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2002': [
      {
        code: '/browse/cars/nissan/2002/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2002/x-trail',
        desc: 'X-Trail',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2001': [
      {
        code: '/browse/cars/nissan/2001/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/altima',
        desc: 'Altima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/platina',
        desc: 'Platina',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2001/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/2000': [
      {
        code: '/browse/cars/nissan/2000/almera',
        desc: 'Almera',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/qx',
        desc: 'QX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/2000/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1999': [
      {
        code: '/browse/cars/nissan/1999/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1999/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1999/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1999/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1999/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1999/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1999/xterra',
        desc: 'Xterra',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1998': [
      {
        code: '/browse/cars/nissan/1998/frontier',
        desc: 'Frontier',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1998/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1998/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1998/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1998/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1998/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1996': [
      {
        code: '/browse/cars/nissan/1996/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1996/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1996/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1996/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1996/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1996/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1996/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1997': [
      {
        code: '/browse/cars/nissan/1997/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1997/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1997/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1997/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1997/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1995': [
      {
        code: '/browse/cars/nissan/1995/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1995/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1995/pathfinder',
        desc: 'Pathfinder',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1995/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1995/sentra',
        desc: 'Sentra',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1995/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1995/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1994': [
      {
        code: '/browse/cars/nissan/1994/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1994/d21',
        desc: 'D21',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1994/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1994/quest',
        desc: 'Quest',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1994/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1994/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1993': [
      {
        code: '/browse/cars/nissan/1993/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1993/d21',
        desc: 'D21',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1993/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1993/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1993/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1992': [
      {
        code: '/browse/cars/nissan/1992/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1992/d21',
        desc: 'D21',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1992/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1992/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1992/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1991': [
      {
        code: '/browse/cars/nissan/1991/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1991/d21',
        desc: 'D21',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1991/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1991/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1991/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1990': [
      {
        code: '/browse/cars/nissan/1990/300zx',
        desc: '300ZX',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1990/d21',
        desc: 'D21',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1990/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1990/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1990/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1989': [
      {
        code: '/browse/cars/nissan/1989/maxima',
        desc: 'Maxima',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1989/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1989/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1988': [
      {
        code: '/browse/cars/nissan/1988/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1988/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1987': [
      {
        code: '/browse/cars/nissan/1987/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1987/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1986': [
      {
        code: '/browse/cars/nissan/1986/stanza',
        desc: 'Stanza',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1986/sunny',
        desc: 'Sunny',
        brandCode: '/browse/cars/nissan',
      },
      {
        code: '/browse/cars/nissan/1986/tsuru',
        desc: 'Tsuru',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1985': [
      {
        code: '/browse/cars/nissan/1985/stanza',
        desc: 'Stanza',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1984': [
      {
        code: '/browse/cars/nissan/1984/stanza',
        desc: 'Stanza',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1983': [
      {
        code: '/browse/cars/nissan/1983/stanza',
        desc: 'Stanza',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/nissan/1982': [
      {
        code: '/browse/cars/nissan/1982/stanza',
        desc: 'Stanza',
        brandCode: '/browse/cars/nissan',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2022': [
      {
        code: '/browse/cars/peugeot/2022/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2022/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2022/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2022/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2022/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2022/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2021': [
      {
        code: '/browse/cars/peugeot/2021/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2021/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2021/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2021/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2021/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2021/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2021/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2020': [
      {
        code: '/browse/cars/peugeot/2020/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2020/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2020/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2020/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2020/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2020/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2020/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2019': [
      {
        code: '/browse/cars/peugeot/2019/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2019/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2019/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2019/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2019/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2019/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2019/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2018': [
      {
        code: '/browse/cars/peugeot/2018/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2018/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2018/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2018/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2018/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2018/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2018/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2017': [
      {
        code: '/browse/cars/peugeot/2017/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2017/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2016': [
      {
        code: '/browse/cars/peugeot/2016/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2016/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2015': [
      {
        code: '/browse/cars/peugeot/2015/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2015/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2014': [
      {
        code: '/browse/cars/peugeot/2014/2008',
        desc: '2008',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2014/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2013': [
      {
        code: '/browse/cars/peugeot/2013/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2013/rcz',
        desc: 'RCZ',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2012': [
      {
        code: '/browse/cars/peugeot/2012/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/208',
        desc: '208',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/301',
        desc: '301',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2012/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2011': [
      {
        code: '/browse/cars/peugeot/2011/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2011/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2011/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2011/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2011/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2011/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2010': [
      {
        code: '/browse/cars/peugeot/2010/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2010/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2009': [
      {
        code: '/browse/cars/peugeot/2009/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2009/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2008': [
      {
        code: '/browse/cars/peugeot/2008/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/partner',
        desc: 'Partner',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2008/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2007': [
      {
        code: '/browse/cars/peugeot/2007/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/308',
        desc: '308',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/expert',
        desc: 'Expert',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2007/partner_tepee',
        desc: 'Partner Tepee',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2005': [
      {
        code: '/browse/cars/peugeot/2005/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2005/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2005/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2005/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2005/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2006': [
      {
        code: '/browse/cars/peugeot/2006/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2006/207',
        desc: '207',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2006/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2006/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2006/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2004': [
      {
        code: '/browse/cars/peugeot/2004/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2004/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2004/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2004/407',
        desc: '407',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2004/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2003': [
      {
        code: '/browse/cars/peugeot/2003/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2003/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2003/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2003/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2001': [
      {
        code: '/browse/cars/peugeot/2001/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2001/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2001/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2001/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2001/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2002': [
      {
        code: '/browse/cars/peugeot/2002/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2002/307',
        desc: '307',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2002/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2002/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/2000': [
      {
        code: '/browse/cars/peugeot/2000/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2000/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2000/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/2000/607',
        desc: '607',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1999': [
      {
        code: '/browse/cars/peugeot/1999/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1999/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1999/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1998': [
      {
        code: '/browse/cars/peugeot/1998/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1998/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1998/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1997': [
      {
        code: '/browse/cars/peugeot/1997/206',
        desc: '206',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1997/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1997/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1996': [
      {
        code: '/browse/cars/peugeot/1996/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1996/406',
        desc: '406',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1995': [
      {
        code: '/browse/cars/peugeot/1995/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1995/405',
        desc: '405',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1994': [
      {
        code: '/browse/cars/peugeot/1994/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1994/405',
        desc: '405',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1993': [
      {
        code: '/browse/cars/peugeot/1993/306',
        desc: '306',
        brandCode: '/browse/cars/peugeot',
      },
      {
        code: '/browse/cars/peugeot/1993/405',
        desc: '405',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/peugeot/1992': [
      {
        code: '/browse/cars/peugeot/1992/405',
        desc: '405',
        brandCode: '/browse/cars/peugeot',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1991': [
      {
        code: '/browse/cars/pontiac/1991/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1992': [
      {
        code: '/browse/cars/pontiac/1992/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1992/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1993': [
      {
        code: '/browse/cars/pontiac/1993/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1993/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1994': [
      {
        code: '/browse/cars/pontiac/1994/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1994/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1996': [
      {
        code: '/browse/cars/pontiac/1996/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1996/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1995': [
      {
        code: '/browse/cars/pontiac/1995/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1995/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1997': [
      {
        code: '/browse/cars/pontiac/1997/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1997/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1997/sunfire',
        desc: 'Sunfire',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1998': [
      {
        code: '/browse/cars/pontiac/1998/bonneville',
        desc: 'Bonneville',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1998/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1998/sunfire',
        desc: 'Sunfire',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/1999': [
      {
        code: '/browse/cars/pontiac/1999/grand_am',
        desc: 'Grand Am',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1999/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1999/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/1999/sunfire',
        desc: 'Sunfire',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2000': [
      {
        code: '/browse/cars/pontiac/2000/grand_am',
        desc: 'Grand Am',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2000/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2000/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2000/sunfire',
        desc: 'Sunfire',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2001': [
      {
        code: '/browse/cars/pontiac/2001/aztek',
        desc: 'Aztek',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2001/grand_am',
        desc: 'Grand Am',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2001/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2001/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2001/sunfire',
        desc: 'Sunfire',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2002': [
      {
        code: '/browse/cars/pontiac/2002/aztek',
        desc: 'Aztek',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2002/grand_am',
        desc: 'Grand Am',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2002/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2002/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2002/sunfire',
        desc: 'Sunfire',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2003': [
      {
        code: '/browse/cars/pontiac/2003/aztek',
        desc: 'Aztek',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2003/grand_am',
        desc: 'Grand Am',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2003/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2003/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2004': [
      {
        code: '/browse/cars/pontiac/2004/aztek',
        desc: 'Aztek',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2004/grand_am',
        desc: 'Grand Am',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2004/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2004/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2005': [
      {
        code: '/browse/cars/pontiac/2005/aztek',
        desc: 'Aztek',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2005/g6',
        desc: 'G6',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2005/grand_prix',
        desc: 'Grand Prix',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2005/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2006': [
      {
        code: '/browse/cars/pontiac/2006/g3',
        desc: 'G3',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2006/g6',
        desc: 'G6',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2006/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2006/solstice',
        desc: 'Solstice',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2006/torrent',
        desc: 'Torrent',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2007': [
      {
        code: '/browse/cars/pontiac/2007/g3',
        desc: 'G3',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2007/g5',
        desc: 'G5',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2007/g6',
        desc: 'G6',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2007/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2007/solstice',
        desc: 'Solstice',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2007/torrent',
        desc: 'Torrent',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2008': [
      {
        code: '/browse/cars/pontiac/2008/g3',
        desc: 'G3',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2008/g5',
        desc: 'G5',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2008/g6',
        desc: 'G6',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2008/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2008/solstice',
        desc: 'Solstice',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2008/torrent',
        desc: 'Torrent',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2009': [
      {
        code: '/browse/cars/pontiac/2009/g3',
        desc: 'G3',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2009/g5',
        desc: 'G5',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2009/g6',
        desc: 'G6',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2009/montana',
        desc: 'Montana',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2009/solstice',
        desc: 'Solstice',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2009/torrent',
        desc: 'Torrent',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/pontiac/2010': [
      {
        code: '/browse/cars/pontiac/2010/g3',
        desc: 'G3',
        brandCode: '/browse/cars/pontiac',
      },
      {
        code: '/browse/cars/pontiac/2010/g5',
        desc: 'G5',
        brandCode: '/browse/cars/pontiac',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2022': [
      {
        code: '/browse/cars/porsche/2022/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2022/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2022/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2021': [
      {
        code: '/browse/cars/porsche/2021/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2021/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2021/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2021/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2020': [
      {
        code: '/browse/cars/porsche/2020/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2020/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2020/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2020/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2019': [
      {
        code: '/browse/cars/porsche/2019/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2019/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2019/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2019/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2019/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2019/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2018': [
      {
        code: '/browse/cars/porsche/2018/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2018/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2018/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2018/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2018/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2018/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2017': [
      {
        code: '/browse/cars/porsche/2017/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2017/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2017/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2017/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2017/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2017/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2016': [
      {
        code: '/browse/cars/porsche/2016/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2016/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2016/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2016/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2016/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2016/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2015': [
      {
        code: '/browse/cars/porsche/2015/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2015/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2015/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2015/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2015/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2015/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2014': [
      {
        code: '/browse/cars/porsche/2014/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2014/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2014/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2014/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2014/macan',
        desc: 'Macan',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2014/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2012': [
      {
        code: '/browse/cars/porsche/2012/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2012/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2012/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2012/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2012/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2013': [
      {
        code: '/browse/cars/porsche/2013/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2013/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2013/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2013/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2013/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2011': [
      {
        code: '/browse/cars/porsche/2011/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2011/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2011/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2011/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2011/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2011/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2010': [
      {
        code: '/browse/cars/porsche/2010/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2010/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2010/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2010/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2010/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2010/panamera',
        desc: 'Panamera',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2009': [
      {
        code: '/browse/cars/porsche/2009/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2009/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2009/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2009/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2009/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2008': [
      {
        code: '/browse/cars/porsche/2008/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2008/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2008/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2008/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2008/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2007': [
      {
        code: '/browse/cars/porsche/2007/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2007/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2007/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2007/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2006': [
      {
        code: '/browse/cars/porsche/2006/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2006/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2006/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2006/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2006/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2005': [
      {
        code: '/browse/cars/porsche/2005/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2005/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2005/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2005/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2005/cayman',
        desc: 'Cayman',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2004': [
      {
        code: '/browse/cars/porsche/2004/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2004/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2004/carrera',
        desc: 'Carrera',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2004/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2003': [
      {
        code: '/browse/cars/porsche/2003/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2003/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2003/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2002': [
      {
        code: '/browse/cars/porsche/2002/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2002/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2002/cayenne',
        desc: 'Cayenne',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2001': [
      {
        code: '/browse/cars/porsche/2001/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2001/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/2000': [
      {
        code: '/browse/cars/porsche/2000/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/2000/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/1999': [
      {
        code: '/browse/cars/porsche/1999/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/1999/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/1998': [
      {
        code: '/browse/cars/porsche/1998/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/1998/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/1997': [
      {
        code: '/browse/cars/porsche/1997/911',
        desc: '911',
        brandCode: '/browse/cars/porsche',
      },
      {
        code: '/browse/cars/porsche/1997/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/porsche/1996': [
      {
        code: '/browse/cars/porsche/1996/boxster',
        desc: 'Boxster',
        brandCode: '/browse/cars/porsche',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2011': [
      {
        code: '/browse/cars/ram_trucks/2011/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2011/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2011/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2012': [
      {
        code: '/browse/cars/ram_trucks/2012/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2012/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2012/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2014': [
      {
        code: '/browse/cars/ram_trucks/2014/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2014/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2014/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2014/ram_promaster',
        desc: 'RAM Promaster',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2013': [
      {
        code: '/browse/cars/ram_trucks/2013/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2013/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2013/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2015': [
      {
        code: '/browse/cars/ram_trucks/2015/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2015/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2015/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2015/ram_promaster',
        desc: 'RAM Promaster',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2016': [
      {
        code: '/browse/cars/ram_trucks/2016/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2016/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2016/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2016/ram_promaster',
        desc: 'RAM Promaster',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2017': [
      {
        code: '/browse/cars/ram_trucks/2017/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2017/ram_2500',
        desc: 'RAM 2500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2017/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2017/ram_5500',
        desc: 'RAM 5500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2017/ram_promaster',
        desc: 'RAM Promaster',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2018': [
      {
        code: '/browse/cars/ram_trucks/2018/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2018/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2018/ram_promaster',
        desc: 'RAM Promaster',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2019': [
      {
        code: '/browse/cars/ram_trucks/2019/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2019/ram_4000',
        desc: 'RAM 4000',
        brandCode: '/browse/cars/ram_trucks',
      },
      {
        code: '/browse/cars/ram_trucks/2019/ram_promaster',
        desc: 'RAM Promaster',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2020': [
      {
        code: '/browse/cars/ram_trucks/2020/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2021': [
      {
        code: '/browse/cars/ram_trucks/2021/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/ram_trucks/2022': [
      {
        code: '/browse/cars/ram_trucks/2022/ram_1500',
        desc: 'RAM 1500',
        brandCode: '/browse/cars/ram_trucks',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2022': [
      {
        code: '/browse/cars/renault/2022/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2022/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2022/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2021': [
      {
        code: '/browse/cars/renault/2021/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2021/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2021/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2020': [
      {
        code: '/browse/cars/renault/2020/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2020/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2020/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2020/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2019': [
      {
        code: '/browse/cars/renault/2019/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2019/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2019/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2019/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2018': [
      {
        code: '/browse/cars/renault/2018/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2018/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2018/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2018/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2018/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2018/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2017': [
      {
        code: '/browse/cars/renault/2017/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2017/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2016': [
      {
        code: '/browse/cars/renault/2016/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2016/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2015': [
      {
        code: '/browse/cars/renault/2015/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2015/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2014': [
      {
        code: '/browse/cars/renault/2014/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/logan',
        desc: 'Logan',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2014/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2012': [
      {
        code: '/browse/cars/renault/2012/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2012/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2013': [
      {
        code: '/browse/cars/renault/2013/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/duster',
        desc: 'Duster',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2013/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2011': [
      {
        code: '/browse/cars/renault/2011/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2011/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2010': [
      {
        code: '/browse/cars/renault/2010/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/fluence',
        desc: 'Fluence',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2010/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2009': [
      {
        code: '/browse/cars/renault/2009/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2009/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2009/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2009/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2009/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2009/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2009/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2008': [
      {
        code: '/browse/cars/renault/2008/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2008/kangoo',
        desc: 'Kangoo',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2008/koleos',
        desc: 'Koleos',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2008/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2008/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2008/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2008/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2007': [
      {
        code: '/browse/cars/renault/2007/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2007/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2007/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2007/sandero',
        desc: 'Sandero',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2007/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2006': [
      {
        code: '/browse/cars/renault/2006/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2006/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2006/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2006/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2005': [
      {
        code: '/browse/cars/renault/2005/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2005/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2005/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2005/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2004': [
      {
        code: '/browse/cars/renault/2004/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2004/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2004/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2004/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2003': [
      {
        code: '/browse/cars/renault/2003/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2003/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2003/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2003/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2002': [
      {
        code: '/browse/cars/renault/2002/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2002/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2002/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2002/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2001': [
      {
        code: '/browse/cars/renault/2001/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2001/laguna',
        desc: 'Laguna',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2001/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2001/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/2000': [
      {
        code: '/browse/cars/renault/2000/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2000/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/2000/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/1999': [
      {
        code: '/browse/cars/renault/1999/clio',
        desc: 'Clio',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/1999/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
      {
        code: '/browse/cars/renault/1999/scenic',
        desc: 'Scenic',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/renault/1998': [
      {
        code: '/browse/cars/renault/1998/megane',
        desc: 'Megane',
        brandCode: '/browse/cars/renault',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2019': [
      {
        code: '/browse/cars/saab/2019/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2018': [
      {
        code: '/browse/cars/saab/2018/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2017': [
      {
        code: '/browse/cars/saab/2017/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2016': [
      {
        code: '/browse/cars/saab/2016/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2015': [
      {
        code: '/browse/cars/saab/2015/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2015/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2014': [
      {
        code: '/browse/cars/saab/2014/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2014/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2013': [
      {
        code: '/browse/cars/saab/2013/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2013/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2012': [
      {
        code: '/browse/cars/saab/2012/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2012/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2011': [
      {
        code: '/browse/cars/saab/2011/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2011/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2010': [
      {
        code: '/browse/cars/saab/2010/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2010/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2009': [
      {
        code: '/browse/cars/saab/2009/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2009/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2008': [
      {
        code: '/browse/cars/saab/2008/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2008/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2007': [
      {
        code: '/browse/cars/saab/2007/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2007/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2006': [
      {
        code: '/browse/cars/saab/2006/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2006/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2005': [
      {
        code: '/browse/cars/saab/2005/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2005/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2004': [
      {
        code: '/browse/cars/saab/2004/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2004/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2003': [
      {
        code: '/browse/cars/saab/2003/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2003/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2001': [
      {
        code: '/browse/cars/saab/2001/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2001/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2002': [
      {
        code: '/browse/cars/saab/2002/9_3',
        desc: '9.3',
        brandCode: '/browse/cars/saab',
      },
      {
        code: '/browse/cars/saab/2002/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/2000': [
      {
        code: '/browse/cars/saab/2000/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1999': [
      {
        code: '/browse/cars/saab/1999/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1998': [
      {
        code: '/browse/cars/saab/1998/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1997': [
      {
        code: '/browse/cars/saab/1997/9_5',
        desc: '9.5',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1993': [
      {
        code: '/browse/cars/saab/1993/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1992': [
      {
        code: '/browse/cars/saab/1992/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1991': [
      {
        code: '/browse/cars/saab/1991/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1990': [
      {
        code: '/browse/cars/saab/1990/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1989': [
      {
        code: '/browse/cars/saab/1989/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1988': [
      {
        code: '/browse/cars/saab/1988/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1987': [
      {
        code: '/browse/cars/saab/1987/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1986': [
      {
        code: '/browse/cars/saab/1986/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1985': [
      {
        code: '/browse/cars/saab/1985/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1984': [
      {
        code: '/browse/cars/saab/1984/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1983': [
      {
        code: '/browse/cars/saab/1983/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1982': [
      {
        code: '/browse/cars/saab/1982/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1981': [
      {
        code: '/browse/cars/saab/1981/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1980': [
      {
        code: '/browse/cars/saab/1980/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/saab/1979': [
      {
        code: '/browse/cars/saab/1979/900',
        desc: '900',
        brandCode: '/browse/cars/saab',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/1997': [
      {
        code: '/browse/cars/seat/1997/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/1998': [
      {
        code: '/browse/cars/seat/1998/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/1998/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/1998/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/1999': [
      {
        code: '/browse/cars/seat/1999/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/1999/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/1999/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2000': [
      {
        code: '/browse/cars/seat/2000/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2000/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2000/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2001': [
      {
        code: '/browse/cars/seat/2001/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2001/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2001/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2001/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2002': [
      {
        code: '/browse/cars/seat/2002/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2002/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2002/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2002/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2003': [
      {
        code: '/browse/cars/seat/2003/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2003/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2003/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2003/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2004': [
      {
        code: '/browse/cars/seat/2004/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2004/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2004/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2004/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2004/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2005': [
      {
        code: '/browse/cars/seat/2005/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2005/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2005/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2005/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2005/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2006': [
      {
        code: '/browse/cars/seat/2006/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2006/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2006/altea_xl',
        desc: 'Altea XL',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2006/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2006/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2006/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2007': [
      {
        code: '/browse/cars/seat/2007/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2007/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2007/altea_xl',
        desc: 'Altea XL',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2007/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2007/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2007/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2008': [
      {
        code: '/browse/cars/seat/2008/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2008/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2008/altea_xl',
        desc: 'Altea XL',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2008/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2008/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2008/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2009': [
      {
        code: '/browse/cars/seat/2009/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2009/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2009/altea_xl',
        desc: 'Altea XL',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2009/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2009/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2009/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2009/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2010': [
      {
        code: '/browse/cars/seat/2010/alhambra',
        desc: 'Alhambra',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2010/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2010/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2010/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2010/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2010/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2011': [
      {
        code: '/browse/cars/seat/2011/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2011/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2011/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2011/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2011/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2012': [
      {
        code: '/browse/cars/seat/2012/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2012/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2012/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2012/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2012/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2013': [
      {
        code: '/browse/cars/seat/2013/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2013/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2013/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2013/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2013/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2013/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2013/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2014': [
      {
        code: '/browse/cars/seat/2014/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2014/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2014/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2014/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2014/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2014/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2014/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2015': [
      {
        code: '/browse/cars/seat/2015/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2015/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2015/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2015/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2015/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2015/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2015/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2016': [
      {
        code: '/browse/cars/seat/2016/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2016/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2017': [
      {
        code: '/browse/cars/seat/2017/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2017/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2018': [
      {
        code: '/browse/cars/seat/2018/altea',
        desc: 'Altea',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2018/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2019': [
      {
        code: '/browse/cars/seat/2019/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2019/exeo',
        desc: 'Exeo',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2019/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2019/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2019/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2019/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2019/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2020': [
      {
        code: '/browse/cars/seat/2020/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2020/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2020/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2020/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2020/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2020/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2021': [
      {
        code: '/browse/cars/seat/2021/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2021/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2021/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2021/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2021/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2021/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/seat/2022': [
      {
        code: '/browse/cars/seat/2022/ateca',
        desc: 'Ateca',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2022/ibiza',
        desc: 'Ibiza',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2022/leon',
        desc: 'Leon',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2022/leon_sc',
        desc: 'Leon SC',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2022/leon_st',
        desc: 'Leon ST',
        brandCode: '/browse/cars/seat',
      },
      {
        code: '/browse/cars/seat/2022/toledo',
        desc: 'Toledo',
        brandCode: '/browse/cars/seat',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2022': [
      {
        code: '/browse/cars/smart/2022/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2022/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2021': [
      {
        code: '/browse/cars/smart/2021/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2021/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2020': [
      {
        code: '/browse/cars/smart/2020/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2020/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2019': [
      {
        code: '/browse/cars/smart/2019/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2019/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2018': [
      {
        code: '/browse/cars/smart/2018/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2018/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2017': [
      {
        code: '/browse/cars/smart/2017/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2017/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2016': [
      {
        code: '/browse/cars/smart/2016/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2016/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2015': [
      {
        code: '/browse/cars/smart/2015/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2015/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2014': [
      {
        code: '/browse/cars/smart/2014/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2014/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2013': [
      {
        code: '/browse/cars/smart/2013/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2013/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2012': [
      {
        code: '/browse/cars/smart/2012/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2012/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2011': [
      {
        code: '/browse/cars/smart/2011/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2011/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2010': [
      {
        code: '/browse/cars/smart/2010/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2010/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2009': [
      {
        code: '/browse/cars/smart/2009/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2009/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2008': [
      {
        code: '/browse/cars/smart/2008/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2008/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2007': [
      {
        code: '/browse/cars/smart/2007/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2007/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2006': [
      {
        code: '/browse/cars/smart/2006/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2006/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2005': [
      {
        code: '/browse/cars/smart/2005/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2005/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2005/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2004': [
      {
        code: '/browse/cars/smart/2004/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2004/fortwo',
        desc: 'Fortwo',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2004/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/smart/2003': [
      {
        code: '/browse/cars/smart/2003/forfour',
        desc: 'Forfour',
        brandCode: '/browse/cars/smart',
      },
      {
        code: '/browse/cars/smart/2003/roadster',
        desc: 'Roadster',
        brandCode: '/browse/cars/smart',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2003': [
      {
        code: '/browse/cars/subaru/2003/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2003/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2004': [
      {
        code: '/browse/cars/subaru/2004/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2004/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2004/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2005': [
      {
        code: '/browse/cars/subaru/2005/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2005/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2005/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2005/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2006': [
      {
        code: '/browse/cars/subaru/2006/b9_tribeca',
        desc: 'B9 Tribeca',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2006/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2006/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2006/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2006/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2007': [
      {
        code: '/browse/cars/subaru/2007/b9_tribeca',
        desc: 'B9 Tribeca',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2007/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2007/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2007/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2007/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2007/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2008': [
      {
        code: '/browse/cars/subaru/2008/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2008/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2008/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2008/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2008/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2008/tribeca',
        desc: 'Tribeca',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2009': [
      {
        code: '/browse/cars/subaru/2009/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2009/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2009/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2009/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2009/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2009/tribeca',
        desc: 'Tribeca',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2010': [
      {
        code: '/browse/cars/subaru/2010/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2010/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2010/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2010/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2010/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2010/tribeca',
        desc: 'Tribeca',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2011': [
      {
        code: '/browse/cars/subaru/2011/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2011/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2011/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2011/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2011/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2011/tribeca',
        desc: 'Tribeca',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2012': [
      {
        code: '/browse/cars/subaru/2012/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/tribeca',
        desc: 'Tribeca',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2012/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2013': [
      {
        code: '/browse/cars/subaru/2013/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2013/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2013/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2013/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2013/legacy_outback',
        desc: 'Legacy/Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2013/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2013/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2014': [
      {
        code: '/browse/cars/subaru/2014/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2014/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2014/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2014/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2014/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2014/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2016': [
      {
        code: '/browse/cars/subaru/2016/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2016/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2016/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2016/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2016/wrx',
        desc: 'WRX',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2016/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2015': [
      {
        code: '/browse/cars/subaru/2015/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2015/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2015/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2015/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2015/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2015/wrx',
        desc: 'WRX',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2015/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2017': [
      {
        code: '/browse/cars/subaru/2017/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2017/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2017/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2017/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2017/wrx',
        desc: 'WRX',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2017/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2018': [
      {
        code: '/browse/cars/subaru/2018/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2018/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2018/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2018/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2018/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2018/wrx',
        desc: 'WRX',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2018/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2019': [
      {
        code: '/browse/cars/subaru/2019/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2019/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2019/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2019/legacy',
        desc: 'Legacy',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2019/outback',
        desc: 'Outback',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2019/wrx',
        desc: 'WRX',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2019/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2020': [
      {
        code: '/browse/cars/subaru/2020/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2020/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2020/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2020/wrx',
        desc: 'WRX',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2020/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2021': [
      {
        code: '/browse/cars/subaru/2021/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2021/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2021/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2021/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/subaru/2022': [
      {
        code: '/browse/cars/subaru/2022/brz',
        desc: 'BRZ',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2022/forester',
        desc: 'Forester',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2022/impreza',
        desc: 'Impreza',
        brandCode: '/browse/cars/subaru',
      },
      {
        code: '/browse/cars/subaru/2022/xv',
        desc: 'XV',
        brandCode: '/browse/cars/subaru',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2022': [
      {
        code: '/browse/cars/suzuki/2022/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2022/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2021': [
      {
        code: '/browse/cars/suzuki/2021/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2021/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2020': [
      {
        code: '/browse/cars/suzuki/2020/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2020/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2020/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2019': [
      {
        code: '/browse/cars/suzuki/2019/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2019/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2019/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2018': [
      {
        code: '/browse/cars/suzuki/2018/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2018/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2018/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2017': [
      {
        code: '/browse/cars/suzuki/2017/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2017/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2017/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2016': [
      {
        code: '/browse/cars/suzuki/2016/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2016/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2016/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2015': [
      {
        code: '/browse/cars/suzuki/2015/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2015/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2015/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2014': [
      {
        code: '/browse/cars/suzuki/2014/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2014/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2013': [
      {
        code: '/browse/cars/suzuki/2013/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2013/kizashi',
        desc: 'Kizashi',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2013/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2012': [
      {
        code: '/browse/cars/suzuki/2012/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2012/kizashi',
        desc: 'Kizashi',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2012/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2011': [
      {
        code: '/browse/cars/suzuki/2011/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2011/kizashi',
        desc: 'Kizashi',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2011/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2011/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2010': [
      {
        code: '/browse/cars/suzuki/2010/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2010/kizashi',
        desc: 'Kizashi',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2010/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2010/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2009': [
      {
        code: '/browse/cars/suzuki/2009/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2009/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2009/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2008': [
      {
        code: '/browse/cars/suzuki/2008/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2008/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2008/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2008/xl7',
        desc: 'XL7',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2007': [
      {
        code: '/browse/cars/suzuki/2007/aerio',
        desc: 'Aerio',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2007/grand_vitara',
        desc: 'Grand Vitara',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2007/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2007/sx4',
        desc: 'SX4',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2006': [
      {
        code: '/browse/cars/suzuki/2006/aerio',
        desc: 'Aerio',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2006/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2006/xl-7',
        desc: 'XL-7',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2005': [
      {
        code: '/browse/cars/suzuki/2005/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2004': [
      {
        code: '/browse/cars/suzuki/2004/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2003': [
      {
        code: '/browse/cars/suzuki/2003/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2002': [
      {
        code: '/browse/cars/suzuki/2002/esteem',
        desc: 'Esteem',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2002/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2000': [
      {
        code: '/browse/cars/suzuki/2000/esteem',
        desc: 'Esteem',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2000/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2000/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/2001': [
      {
        code: '/browse/cars/suzuki/2001/esteem',
        desc: 'Esteem',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/2001/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1999': [
      {
        code: '/browse/cars/suzuki/1999/esteem',
        desc: 'Esteem',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1999/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1999/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1998': [
      {
        code: '/browse/cars/suzuki/1998/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1998/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1998/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1998/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1997': [
      {
        code: '/browse/cars/suzuki/1997/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1997/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1997/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1997/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1995': [
      {
        code: '/browse/cars/suzuki/1995/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1995/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1995/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1995/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1996': [
      {
        code: '/browse/cars/suzuki/1996/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1996/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1996/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1996/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1994': [
      {
        code: '/browse/cars/suzuki/1994/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1994/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1994/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1994/vitara',
        desc: 'Vitara',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1993': [
      {
        code: '/browse/cars/suzuki/1993/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1993/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1993/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1992': [
      {
        code: '/browse/cars/suzuki/1992/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1992/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1992/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1991': [
      {
        code: '/browse/cars/suzuki/1991/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1991/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1991/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1990': [
      {
        code: '/browse/cars/suzuki/1990/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1990/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1990/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1989': [
      {
        code: '/browse/cars/suzuki/1989/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1989/sidekick',
        desc: 'Sidekick',
        brandCode: '/browse/cars/suzuki',
      },
      {
        code: '/browse/cars/suzuki/1989/swift',
        desc: 'Swift',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1988': [
      {
        code: '/browse/cars/suzuki/1988/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1987': [
      {
        code: '/browse/cars/suzuki/1987/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1986': [
      {
        code: '/browse/cars/suzuki/1986/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1985': [
      {
        code: '/browse/cars/suzuki/1985/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/suzuki/1984': [
      {
        code: '/browse/cars/suzuki/1984/samurai',
        desc: 'Samurai',
        brandCode: '/browse/cars/suzuki',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1994': [
      {
        code: '/browse/cars/toyota/1994/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1994/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1987': [
      {
        code: '/browse/cars/toyota/1987/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1988': [
      {
        code: '/browse/cars/toyota/1988/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1989': [
      {
        code: '/browse/cars/toyota/1989/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1989/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1989/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1991': [
      {
        code: '/browse/cars/toyota/1991/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1991/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1991/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1990': [
      {
        code: '/browse/cars/toyota/1990/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1990/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1990/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1992': [
      {
        code: '/browse/cars/toyota/1992/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1992/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1992/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1993': [
      {
        code: '/browse/cars/toyota/1993/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1993/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1995': [
      {
        code: '/browse/cars/toyota/1995/pickup',
        desc: 'Pickup',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1997': [
      {
        code: '/browse/cars/toyota/1997/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1997/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1998': [
      {
        code: '/browse/cars/toyota/1998/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1998/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/1999': [
      {
        code: '/browse/cars/toyota/1999/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1999/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/1999/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2000': [
      {
        code: '/browse/cars/toyota/2000/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2000/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2000/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2000/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2001': [
      {
        code: '/browse/cars/toyota/2001/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2001/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2001/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2001/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2001/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2001/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2002': [
      {
        code: '/browse/cars/toyota/2002/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2002/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2002/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2002/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2002/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2003': [
      {
        code: '/browse/cars/toyota/2003/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2003/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2004': [
      {
        code: '/browse/cars/toyota/2004/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2004/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2005': [
      {
        code: '/browse/cars/toyota/2005/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2005/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2006': [
      {
        code: '/browse/cars/toyota/2006/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2006/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2007': [
      {
        code: '/browse/cars/toyota/2007/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/camry_solara',
        desc: 'Camry Solara',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2007/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2008': [
      {
        code: '/browse/cars/toyota/2008/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/camry_solara',
        desc: 'Camry Solara',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2008/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2009': [
      {
        code: '/browse/cars/toyota/2009/4_runner',
        desc: '4 Runner',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2009/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2010': [
      {
        code: '/browse/cars/toyota/2010/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2010/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2011': [
      {
        code: '/browse/cars/toyota/2011/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/mr2',
        desc: 'MR2',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2011/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2012': [
      {
        code: '/browse/cars/toyota/2012/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2012/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2013': [
      {
        code: '/browse/cars/toyota/2013/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/matrix',
        desc: 'Matrix',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2013/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2014': [
      {
        code: '/browse/cars/toyota/2014/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/fj_cruiser',
        desc: 'FJ Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2014/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2015': [
      {
        code: '/browse/cars/toyota/2015/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2015/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2016': [
      {
        code: '/browse/cars/toyota/2016/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2016/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2017': [
      {
        code: '/browse/cars/toyota/2017/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2017/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2018': [
      {
        code: '/browse/cars/toyota/2018/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/c-hr',
        desc: 'C-HR',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2018/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2019': [
      {
        code: '/browse/cars/toyota/2019/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/camry_hybrid',
        desc: 'Camry Hybrid',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/c-hr',
        desc: 'C-HR',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/highlander',
        desc: 'Highlander',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/land_cruiser',
        desc: 'Land Cruiser',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/sequoia',
        desc: 'Sequoia',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/tercel',
        desc: 'Tercel',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/tundra',
        desc: 'Tundra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2019/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2020': [
      {
        code: '/browse/cars/toyota/2020/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/camry',
        desc: 'Camry',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/corolla',
        desc: 'Corolla',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/sienna',
        desc: 'Sienna',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/supra',
        desc: 'Supra',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2020/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2021': [
      {
        code: '/browse/cars/toyota/2021/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2021/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2021/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2021/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2021/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2021/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/toyota/2022': [
      {
        code: '/browse/cars/toyota/2022/avanza',
        desc: 'Avanza',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2022/hilux',
        desc: 'HiLux',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2022/prius',
        desc: 'Prius',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2022/rav4',
        desc: 'RAV4',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2022/tacoma',
        desc: 'Tacoma',
        brandCode: '/browse/cars/toyota',
      },
      {
        code: '/browse/cars/toyota/2022/yaris',
        desc: 'Yaris',
        brandCode: '/browse/cars/toyota',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2022': [
      {
        code: '/browse/cars/volkswagen/2022/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2022/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2021': [
      {
        code: '/browse/cars/volkswagen/2021/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2021/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2020': [
      {
        code: '/browse/cars/volkswagen/2020/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2020/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2019': [
      {
        code: '/browse/cars/volkswagen/2019/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/golf_r',
        desc: 'Golf R',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2019/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2018': [
      {
        code: '/browse/cars/volkswagen/2018/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2018/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2017': [
      {
        code: '/browse/cars/volkswagen/2017/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2017/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2016': [
      {
        code: '/browse/cars/volkswagen/2016/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2016/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2015': [
      {
        code: '/browse/cars/volkswagen/2015/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/caddy',
        desc: 'Caddy',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/cc',
        desc: 'CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2015/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2014': [
      {
        code: '/browse/cars/volkswagen/2014/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/cc',
        desc: 'CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2014/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2013': [
      {
        code: '/browse/cars/volkswagen/2013/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/beetle_cabriolet',
        desc: 'Beetle Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/cc',
        desc: 'CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2013/vento',
        desc: 'Vento',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2012': [
      {
        code: '/browse/cars/volkswagen/2012/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/routan',
        desc: 'Routan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2012/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2011': [
      {
        code: '/browse/cars/volkswagen/2011/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/routan',
        desc: 'Routan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2011/up',
        desc: 'Up',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2010': [
      {
        code: '/browse/cars/volkswagen/2010/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/clasico',
        desc: 'Clasico',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/routan',
        desc: 'Routan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2010/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2009': [
      {
        code: '/browse/cars/volkswagen/2009/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/gol',
        desc: 'Gol',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/routan',
        desc: 'Routan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/saveiro',
        desc: 'Saveiro',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2009/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2008': [
      {
        code: '/browse/cars/volkswagen/2008/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/passat_cc',
        desc: 'Passat CC',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/tiguan',
        desc: 'Tiguan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2008/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2007': [
      {
        code: '/browse/cars/volkswagen/2007/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/fox',
        desc: 'Fox',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/gti',
        desc: 'GTI',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2007/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2006': [
      {
        code: '/browse/cars/volkswagen/2006/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/eos',
        desc: 'Eos',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/fox',
        desc: 'Fox',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/gti',
        desc: 'GTI',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2006/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2005': [
      {
        code: '/browse/cars/volkswagen/2005/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/bora',
        desc: 'Bora',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/fox',
        desc: 'Fox',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/gti',
        desc: 'GTI',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2005/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2004': [
      {
        code: '/browse/cars/volkswagen/2004/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/fox',
        desc: 'Fox',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/golf_r32',
        desc: 'Golf R32',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2004/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2003': [
      {
        code: '/browse/cars/volkswagen/2003/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/golf_r32',
        desc: 'Golf R32',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2003/touareg',
        desc: 'Touareg',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2002': [
      {
        code: '/browse/cars/volkswagen/2002/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2002/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2002/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2002/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2002/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2002/polo',
        desc: 'Polo',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2002/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2000': [
      {
        code: '/browse/cars/volkswagen/2000/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2000/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2000/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2000/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2000/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2000/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2000/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/2001': [
      {
        code: '/browse/cars/volkswagen/2001/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2001/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2001/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2001/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2001/pointer',
        desc: 'Pointer',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/2001/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1999': [
      {
        code: '/browse/cars/volkswagen/1999/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1999/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1998': [
      {
        code: '/browse/cars/volkswagen/1998/beetle',
        desc: 'Beetle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1998/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1997': [
      {
        code: '/browse/cars/volkswagen/1997/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1997/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1997/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1997/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1997/golf_vr6',
        desc: 'Golf VR6',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1997/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1997/sharan',
        desc: 'Sharan',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1996': [
      {
        code: '/browse/cars/volkswagen/1996/caravelle',
        desc: 'Caravelle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1996/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1996/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1996/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1996/golf_vr6',
        desc: 'Golf VR6',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1996/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1996/polo_classic',
        desc: 'Polo Classic',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1995': [
      {
        code: '/browse/cars/volkswagen/1995/caravelle',
        desc: 'Caravelle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/golf_vr6',
        desc: 'Golf VR6',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1995/polo_classic',
        desc: 'Polo Classic',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1993': [
      {
        code: '/browse/cars/volkswagen/1993/caravelle',
        desc: 'Caravelle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1993/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1993/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1993/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1993/golf_vr6',
        desc: 'Golf VR6',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1993/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1994': [
      {
        code: '/browse/cars/volkswagen/1994/caravelle',
        desc: 'Caravelle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1994/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1994/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1994/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1994/golf_cabriolet',
        desc: 'Golf Cabriolet',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1994/golf_vr6',
        desc: 'Golf VR6',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1994/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1992': [
      {
        code: '/browse/cars/volkswagen/1992/caravelle',
        desc: 'Caravelle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/eurovan',
        desc: 'Eurovan',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/golf_vr6',
        desc: 'Golf VR6',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1992/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1991': [
      {
        code: '/browse/cars/volkswagen/1991/caravelle',
        desc: 'Caravelle',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1991/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1991/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1991/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1991/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1991/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1990': [
      {
        code: '/browse/cars/volkswagen/1990/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1990/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1990/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1990/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1990/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1989': [
      {
        code: '/browse/cars/volkswagen/1989/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1989/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1989/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1989/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1989/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1988': [
      {
        code: '/browse/cars/volkswagen/1988/corrado',
        desc: 'Corrado',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1988/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1988/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1988/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1988/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1987': [
      {
        code: '/browse/cars/volkswagen/1987/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1987/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1987/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1987/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1986': [
      {
        code: '/browse/cars/volkswagen/1986/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1986/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1986/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1986/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1985': [
      {
        code: '/browse/cars/volkswagen/1985/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1985/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1985/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1985/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1984': [
      {
        code: '/browse/cars/volkswagen/1984/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1984/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1984/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1984/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1983': [
      {
        code: '/browse/cars/volkswagen/1983/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1983/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1983/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1983/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1982': [
      {
        code: '/browse/cars/volkswagen/1982/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1982/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1982/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1982/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1981': [
      {
        code: '/browse/cars/volkswagen/1981/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1981/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1981/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1981/passat',
        desc: 'Passat',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1980': [
      {
        code: '/browse/cars/volkswagen/1980/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1980/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1980/jetta',
        desc: 'Jetta',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1978': [
      {
        code: '/browse/cars/volkswagen/1978/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1979': [
      {
        code: '/browse/cars/volkswagen/1979/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
      {
        code: '/browse/cars/volkswagen/1979/golf_gti',
        desc: 'Golf GTi',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1977': [
      {
        code: '/browse/cars/volkswagen/1977/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1976': [
      {
        code: '/browse/cars/volkswagen/1976/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volkswagen/1975': [
      {
        code: '/browse/cars/volkswagen/1975/golf',
        desc: 'Golf',
        brandCode: '/browse/cars/volkswagen',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1982': [
      {
        code: '/browse/cars/volvo/1982/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1983': [
      {
        code: '/browse/cars/volvo/1983/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1984': [
      {
        code: '/browse/cars/volvo/1984/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1984/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1985': [
      {
        code: '/browse/cars/volvo/1985/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1985/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1986': [
      {
        code: '/browse/cars/volvo/1986/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1986/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1987': [
      {
        code: '/browse/cars/volvo/1987/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1987/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1987/780',
        desc: '780',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1988': [
      {
        code: '/browse/cars/volvo/1988/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1988/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1988/780',
        desc: '780',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1989': [
      {
        code: '/browse/cars/volvo/1989/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1989/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1989/780',
        desc: '780',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1990': [
      {
        code: '/browse/cars/volvo/1990/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1990/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1990/780',
        desc: '780',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1991': [
      {
        code: '/browse/cars/volvo/1991/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1991/740',
        desc: '740',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1992': [
      {
        code: '/browse/cars/volvo/1992/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1993': [
      {
        code: '/browse/cars/volvo/1993/240',
        desc: '240',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1995': [
      {
        code: '/browse/cars/volvo/1995/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1996': [
      {
        code: '/browse/cars/volvo/1996/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1997': [
      {
        code: '/browse/cars/volvo/1997/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1997/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1997/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1998': [
      {
        code: '/browse/cars/volvo/1998/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1998/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1998/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1998/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1998/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/1999': [
      {
        code: '/browse/cars/volvo/1999/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1999/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1999/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1999/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/1999/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2000': [
      {
        code: '/browse/cars/volvo/2000/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2000/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2001': [
      {
        code: '/browse/cars/volvo/2001/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2001/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2002': [
      {
        code: '/browse/cars/volvo/2002/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2002/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2003': [
      {
        code: '/browse/cars/volvo/2003/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2003/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2004': [
      {
        code: '/browse/cars/volvo/2004/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/xc70',
        desc: 'XC70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2004/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2005': [
      {
        code: '/browse/cars/volvo/2005/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/xc70',
        desc: 'XC70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2005/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2006': [
      {
        code: '/browse/cars/volvo/2006/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/xc70',
        desc: 'XC70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2006/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2007': [
      {
        code: '/browse/cars/volvo/2007/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/v70',
        desc: 'V70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/xc70',
        desc: 'XC70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2007/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2008': [
      {
        code: '/browse/cars/volvo/2008/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2008/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2008/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2008/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2008/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2008/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2008/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2009': [
      {
        code: '/browse/cars/volvo/2009/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2009/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2009/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2009/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2009/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2009/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2009/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2010': [
      {
        code: '/browse/cars/volvo/2010/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2010/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2011': [
      {
        code: '/browse/cars/volvo/2011/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/s70',
        desc: 'S70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2011/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2012': [
      {
        code: '/browse/cars/volvo/2012/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/s40',
        desc: 'S40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2012/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2013': [
      {
        code: '/browse/cars/volvo/2013/c30',
        desc: 'C30',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/c70',
        desc: 'C70',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2013/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2014': [
      {
        code: '/browse/cars/volvo/2014/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2014/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2014/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2014/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2014/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2014/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2016': [
      {
        code: '/browse/cars/volvo/2016/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2016/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2016/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2016/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2016/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2016/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2016/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2015': [
      {
        code: '/browse/cars/volvo/2015/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2015/s80',
        desc: 'S80',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2015/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2015/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2015/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2015/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2017': [
      {
        code: '/browse/cars/volvo/2017/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2017/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2017/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2017/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2017/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2017/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2017/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2018': [
      {
        code: '/browse/cars/volvo/2018/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2018/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2018/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2018/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2018/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2018/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2018/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2019': [
      {
        code: '/browse/cars/volvo/2019/s60',
        desc: 'S60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/xc_40',
        desc: 'XC 40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2019/xc90',
        desc: 'XC90',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2020': [
      {
        code: '/browse/cars/volvo/2020/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2020/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2020/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2020/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2020/xc_40',
        desc: 'XC 40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2020/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2021': [
      {
        code: '/browse/cars/volvo/2021/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2021/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2021/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2021/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2021/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/cars_/browse/cars/volvo/2022': [
      {
        code: '/browse/cars/volvo/2022/s90',
        desc: 'S90',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2022/v40',
        desc: 'V40',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2022/v50',
        desc: 'V50',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2022/v60',
        desc: 'V60',
        brandCode: '/browse/cars/volvo',
      },
      {
        code: '/browse/cars/volvo/2022/xc60',
        desc: 'XC60',
        brandCode: '/browse/cars/volvo',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2018': [
      {
        code: '/browse/vans/fiat/2018/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2017': [
      {
        code: '/browse/vans/fiat/2017/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2016': [
      {
        code: '/browse/vans/fiat/2016/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2015': [
      {
        code: '/browse/vans/fiat/2015/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2014': [
      {
        code: '/browse/vans/fiat/2014/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2013': [
      {
        code: '/browse/vans/fiat/2013/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2012': [
      {
        code: '/browse/vans/fiat/2012/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2011': [
      {
        code: '/browse/vans/fiat/2011/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2010': [
      {
        code: '/browse/vans/fiat/2010/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2009': [
      {
        code: '/browse/vans/fiat/2009/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2008': [
      {
        code: '/browse/vans/fiat/2008/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2007': [
      {
        code: '/browse/vans/fiat/2007/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/fiat/2006': [
      {
        code: '/browse/vans/fiat/2006/ducato',
        desc: 'Ducato',
        brandCode: '/browse/vans/fiat',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2022': [
      {
        code: '/browse/vans/ford/2022/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2021': [
      {
        code: '/browse/vans/ford/2021/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2020': [
      {
        code: '/browse/vans/ford/2020/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2019': [
      {
        code: '/browse/vans/ford/2019/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2018': [
      {
        code: '/browse/vans/ford/2018/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2017': [
      {
        code: '/browse/vans/ford/2017/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2017/transit_150',
        desc: 'Transit 150',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2017/transit_250',
        desc: 'Transit 250',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2017/transit_350',
        desc: 'Transit 350',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2016': [
      {
        code: '/browse/vans/ford/2016/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2016/transit_150',
        desc: 'Transit 150',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2016/transit_250',
        desc: 'Transit 250',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2016/transit_350',
        desc: 'Transit 350',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2016/transit_cutaway',
        desc: 'Transit Cutaway',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2015': [
      {
        code: '/browse/vans/ford/2015/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2015/transit_250',
        desc: 'Transit 250',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2015/transit_350',
        desc: 'Transit 350',
        brandCode: '/browse/vans/ford',
      },
      {
        code: '/browse/vans/ford/2015/transit_cutaway',
        desc: 'Transit Cutaway',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2014': [
      {
        code: '/browse/vans/ford/2014/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/ford/2013': [
      {
        code: '/browse/vans/ford/2013/transit',
        desc: 'Transit',
        brandCode: '/browse/vans/ford',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1982': [
      {
        code: '/browse/vans/hyundai/1982/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1983': [
      {
        code: '/browse/vans/hyundai/1983/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1984': [
      {
        code: '/browse/vans/hyundai/1984/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1985': [
      {
        code: '/browse/vans/hyundai/1985/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1986': [
      {
        code: '/browse/vans/hyundai/1986/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1987': [
      {
        code: '/browse/vans/hyundai/1987/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1988': [
      {
        code: '/browse/vans/hyundai/1988/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1989': [
      {
        code: '/browse/vans/hyundai/1989/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1990': [
      {
        code: '/browse/vans/hyundai/1990/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1991': [
      {
        code: '/browse/vans/hyundai/1991/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/hyundai/1992': [
      {
        code: '/browse/vans/hyundai/1992/pony_series',
        desc: 'Pony Series',
        brandCode: '/browse/vans/hyundai',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2018': [
      {
        code: '/browse/vans/isuzu/2018/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2017': [
      {
        code: '/browse/vans/isuzu/2017/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2016': [
      {
        code: '/browse/vans/isuzu/2016/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2015': [
      {
        code: '/browse/vans/isuzu/2015/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2014': [
      {
        code: '/browse/vans/isuzu/2014/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2013': [
      {
        code: '/browse/vans/isuzu/2013/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2012': [
      {
        code: '/browse/vans/isuzu/2012/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2011': [
      {
        code: '/browse/vans/isuzu/2011/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2010': [
      {
        code: '/browse/vans/isuzu/2010/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2009': [
      {
        code: '/browse/vans/isuzu/2009/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/isuzu/2008': [
      {
        code: '/browse/vans/isuzu/2008/elf',
        desc: 'Elf',
        brandCode: '/browse/vans/isuzu',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2021': [
      {
        code: '/browse/vans/land_rover/2021/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2020': [
      {
        code: '/browse/vans/land_rover/2020/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2019': [
      {
        code: '/browse/vans/land_rover/2019/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2018': [
      {
        code: '/browse/vans/land_rover/2018/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2017': [
      {
        code: '/browse/vans/land_rover/2017/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2016': [
      {
        code: '/browse/vans/land_rover/2016/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2015': [
      {
        code: '/browse/vans/land_rover/2015/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2014': [
      {
        code: '/browse/vans/land_rover/2014/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2013': [
      {
        code: '/browse/vans/land_rover/2013/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2012': [
      {
        code: '/browse/vans/land_rover/2012/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2011': [
      {
        code: '/browse/vans/land_rover/2011/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2006': [
      {
        code: '/browse/vans/land_rover/2006/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2006/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2005': [
      {
        code: '/browse/vans/land_rover/2005/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2005/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2004': [
      {
        code: '/browse/vans/land_rover/2004/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2004/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2003': [
      {
        code: '/browse/vans/land_rover/2003/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2003/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2002': [
      {
        code: '/browse/vans/land_rover/2002/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2002/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2001': [
      {
        code: '/browse/vans/land_rover/2001/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2001/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/2000': [
      {
        code: '/browse/vans/land_rover/2000/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/2000/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1999': [
      {
        code: '/browse/vans/land_rover/1999/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
      {
        code: '/browse/vans/land_rover/1999/freelander_series',
        desc: 'Freelander Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1998': [
      {
        code: '/browse/vans/land_rover/1998/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1997': [
      {
        code: '/browse/vans/land_rover/1997/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1996': [
      {
        code: '/browse/vans/land_rover/1996/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1995': [
      {
        code: '/browse/vans/land_rover/1995/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1994': [
      {
        code: '/browse/vans/land_rover/1994/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1993': [
      {
        code: '/browse/vans/land_rover/1993/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1992': [
      {
        code: '/browse/vans/land_rover/1992/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1991': [
      {
        code: '/browse/vans/land_rover/1991/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/land_rover/1990': [
      {
        code: '/browse/vans/land_rover/1990/defender_series',
        desc: 'Defender Series',
        brandCode: '/browse/vans/land_rover',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2003': [
      {
        code: '/browse/vans/mercedes-benz/2003/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2004': [
      {
        code: '/browse/vans/mercedes-benz/2004/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2005': [
      {
        code: '/browse/vans/mercedes-benz/2005/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2005/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2006': [
      {
        code: '/browse/vans/mercedes-benz/2006/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2006/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2007': [
      {
        code: '/browse/vans/mercedes-benz/2007/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2007/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2008': [
      {
        code: '/browse/vans/mercedes-benz/2008/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2008/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2009': [
      {
        code: '/browse/vans/mercedes-benz/2009/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2009/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2010': [
      {
        code: '/browse/vans/mercedes-benz/2010/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2010/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2011': [
      {
        code: '/browse/vans/mercedes-benz/2011/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2011/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2012': [
      {
        code: '/browse/vans/mercedes-benz/2012/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2012/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2013': [
      {
        code: '/browse/vans/mercedes-benz/2013/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
      {
        code: '/browse/vans/mercedes-benz/2013/vito_series',
        desc: 'Vito Series',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2014': [
      {
        code: '/browse/vans/mercedes-benz/2014/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2015': [
      {
        code: '/browse/vans/mercedes-benz/2015/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mercedes-benz/2016': [
      {
        code: '/browse/vans/mercedes-benz/2016/sprinter',
        desc: 'Sprinter',
        brandCode: '/browse/vans/mercedes-benz',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mitsubishi/2010': [
      {
        code: '/browse/vans/mitsubishi/2010/l200_series',
        desc: 'L200 Series',
        brandCode: '/browse/vans/mitsubishi',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mitsubishi/2009': [
      {
        code: '/browse/vans/mitsubishi/2009/l200_series',
        desc: 'L200 Series',
        brandCode: '/browse/vans/mitsubishi',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mitsubishi/2008': [
      {
        code: '/browse/vans/mitsubishi/2008/l200_series',
        desc: 'L200 Series',
        brandCode: '/browse/vans/mitsubishi',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mitsubishi/2007': [
      {
        code: '/browse/vans/mitsubishi/2007/l200_series',
        desc: 'L200 Series',
        brandCode: '/browse/vans/mitsubishi',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/mitsubishi/2006': [
      {
        code: '/browse/vans/mitsubishi/2006/l200_series',
        desc: 'L200 Series',
        brandCode: '/browse/vans/mitsubishi',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2013': [
      {
        code: '/browse/vans/nissan/2013/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2012': [
      {
        code: '/browse/vans/nissan/2012/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2011': [
      {
        code: '/browse/vans/nissan/2011/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2010': [
      {
        code: '/browse/vans/nissan/2010/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2009': [
      {
        code: '/browse/vans/nissan/2009/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2008': [
      {
        code: '/browse/vans/nissan/2008/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2007': [
      {
        code: '/browse/vans/nissan/2007/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/2006': [
      {
        code: '/browse/vans/nissan/2006/cabstar_series',
        desc: 'Cabstar Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1995': [
      {
        code: '/browse/vans/nissan/1995/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1994': [
      {
        code: '/browse/vans/nissan/1994/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1993': [
      {
        code: '/browse/vans/nissan/1993/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1992': [
      {
        code: '/browse/vans/nissan/1992/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1991': [
      {
        code: '/browse/vans/nissan/1991/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1990': [
      {
        code: '/browse/vans/nissan/1990/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1989': [
      {
        code: '/browse/vans/nissan/1989/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1988': [
      {
        code: '/browse/vans/nissan/1988/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1987': [
      {
        code: '/browse/vans/nissan/1987/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1986': [
      {
        code: '/browse/vans/nissan/1986/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/nissan/1985': [
      {
        code: '/browse/vans/nissan/1985/urvan_series',
        desc: 'Urvan Series',
        brandCode: '/browse/vans/nissan',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2000': [
      {
        code: '/browse/vans/peugeot/2000/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2001': [
      {
        code: '/browse/vans/peugeot/2001/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2002': [
      {
        code: '/browse/vans/peugeot/2002/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2007': [
      {
        code: '/browse/vans/peugeot/2007/boxer_series',
        desc: 'Boxer Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2007/expert_series',
        desc: 'Expert Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2008': [
      {
        code: '/browse/vans/peugeot/2008/boxer_series',
        desc: 'Boxer Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2008/expert_series',
        desc: 'Expert Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2008/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2009': [
      {
        code: '/browse/vans/peugeot/2009/boxer_series',
        desc: 'Boxer Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2009/expert_series',
        desc: 'Expert Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2009/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2010': [
      {
        code: '/browse/vans/peugeot/2010/boxer_series',
        desc: 'Boxer Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2010/expert_series',
        desc: 'Expert Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2010/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2011': [
      {
        code: '/browse/vans/peugeot/2011/boxer_series',
        desc: 'Boxer Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2011/expert_series',
        desc: 'Expert Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2011/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/peugeot/2012': [
      {
        code: '/browse/vans/peugeot/2012/boxer_series',
        desc: 'Boxer Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2012/expert_series',
        desc: 'Expert Series',
        brandCode: '/browse/vans/peugeot',
      },
      {
        code: '/browse/vans/peugeot/2012/partner_series',
        desc: 'Partner Series',
        brandCode: '/browse/vans/peugeot',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2002': [
      {
        code: '/browse/vans/renault/2002/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2001': [
      {
        code: '/browse/vans/renault/2001/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2003': [
      {
        code: '/browse/vans/renault/2003/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2004': [
      {
        code: '/browse/vans/renault/2004/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2005': [
      {
        code: '/browse/vans/renault/2005/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2006': [
      {
        code: '/browse/vans/renault/2006/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2007': [
      {
        code: '/browse/vans/renault/2007/kangoo_express',
        desc: 'Kangoo Express',
        brandCode: '/browse/vans/renault',
      },
      {
        code: '/browse/vans/renault/2007/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2008': [
      {
        code: '/browse/vans/renault/2008/kangoo_express',
        desc: 'Kangoo Express',
        brandCode: '/browse/vans/renault',
      },
      {
        code: '/browse/vans/renault/2008/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2009': [
      {
        code: '/browse/vans/renault/2009/kangoo_express',
        desc: 'Kangoo Express',
        brandCode: '/browse/vans/renault',
      },
      {
        code: '/browse/vans/renault/2009/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2010': [
      {
        code: '/browse/vans/renault/2010/kangoo_express',
        desc: 'Kangoo Express',
        brandCode: '/browse/vans/renault',
      },
      {
        code: '/browse/vans/renault/2010/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/renault/2011': [
      {
        code: '/browse/vans/renault/2011/kangoo_express',
        desc: 'Kangoo Express',
        brandCode: '/browse/vans/renault',
      },
      {
        code: '/browse/vans/renault/2011/kangoo_series',
        desc: 'Kangoo Series',
        brandCode: '/browse/vans/renault',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2005': [
      {
        code: '/browse/vans/toyota/2005/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2006': [
      {
        code: '/browse/vans/toyota/2006/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2007': [
      {
        code: '/browse/vans/toyota/2007/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2008': [
      {
        code: '/browse/vans/toyota/2008/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2009': [
      {
        code: '/browse/vans/toyota/2009/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2010': [
      {
        code: '/browse/vans/toyota/2010/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2011': [
      {
        code: '/browse/vans/toyota/2011/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2012': [
      {
        code: '/browse/vans/toyota/2012/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2013': [
      {
        code: '/browse/vans/toyota/2013/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2014': [
      {
        code: '/browse/vans/toyota/2014/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2015': [
      {
        code: '/browse/vans/toyota/2015/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2016': [
      {
        code: '/browse/vans/toyota/2016/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2017': [
      {
        code: '/browse/vans/toyota/2017/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2018': [
      {
        code: '/browse/vans/toyota/2018/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/toyota/2019': [
      {
        code: '/browse/vans/toyota/2019/hiace',
        desc: 'HiAce',
        brandCode: '/browse/vans/toyota',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2021': [
      {
        code: '/browse/vans/volkswagen/2021/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2020': [
      {
        code: '/browse/vans/volkswagen/2020/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2019': [
      {
        code: '/browse/vans/volkswagen/2019/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2018': [
      {
        code: '/browse/vans/volkswagen/2018/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2016': [
      {
        code: '/browse/vans/volkswagen/2016/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2016/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2017': [
      {
        code: '/browse/vans/volkswagen/2017/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2015': [
      {
        code: '/browse/vans/volkswagen/2015/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2015/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2014': [
      {
        code: '/browse/vans/volkswagen/2014/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2014/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2013': [
      {
        code: '/browse/vans/volkswagen/2013/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2013/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2012': [
      {
        code: '/browse/vans/volkswagen/2012/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2012/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2011': [
      {
        code: '/browse/vans/volkswagen/2011/amarok_series',
        desc: 'Amarok Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2011/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2010': [
      {
        code: '/browse/vans/volkswagen/2010/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2009': [
      {
        code: '/browse/vans/volkswagen/2009/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2009/transporter_t5_series',
        desc: 'Transporter T5 Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2008': [
      {
        code: '/browse/vans/volkswagen/2008/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2008/transporter_t5_series',
        desc: 'Transporter T5 Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2007': [
      {
        code: '/browse/vans/volkswagen/2007/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2007/transporter_t5_series',
        desc: 'Transporter T5 Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/2006': [
      {
        code: '/browse/vans/volkswagen/2006/crafter_series',
        desc: 'Crafter Series',
        brandCode: '/browse/vans/volkswagen',
      },
      {
        code: '/browse/vans/volkswagen/2006/transporter_t5_series',
        desc: 'Transporter T5 Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/1996': [
      {
        code: '/browse/vans/volkswagen/1996/transporter_t4_razorback_series',
        desc: 'Transporter T4 & Razorback Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/1995': [
      {
        code: '/browse/vans/volkswagen/1995/transporter_t4_razorback_series',
        desc: 'Transporter T4 & Razorback Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/1994': [
      {
        code: '/browse/vans/volkswagen/1994/transporter_t4_razorback_series',
        desc: 'Transporter T4 & Razorback Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/1992': [
      {
        code: '/browse/vans/volkswagen/1992/transporter_t4_razorback_series',
        desc: 'Transporter T4 & Razorback Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/1993': [
      {
        code: '/browse/vans/volkswagen/1993/transporter_t4_razorback_series',
        desc: 'Transporter T4 & Razorback Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_/browse/vans_/browse/vans/volkswagen/1991': [
      {
        code: '/browse/vans/volkswagen/1991/transporter_t4_razorback_series',
        desc: 'Transporter T4 & Razorback Series',
        brandCode: '/browse/vans/volkswagen',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1990': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1991': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1992': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1993': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1994': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1995': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1996': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1997': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1998': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/1999': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2000': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2001': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2002': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2003': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2004': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2005': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2006': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2007': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2008': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2009': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2010': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2011': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2012': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2013': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2014': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2015': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2016': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2017': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2018': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2019': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BAJAJ-MOTO/2020': [
      {
        code: 'AVENGER220CRUISE-MOTO',
        desc: 'AVENGER 220 CRUISE ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER100BM-MOTO',
        desc: 'BOXER 100 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'BOXER150BM-MOTO',
        desc: 'BOXER 150 BM',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER100-MOTO',
        desc: 'DISCOVER 100 ',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER125ST-MOTO',
        desc: 'DISCOVER 125 ST',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DISCOVER135LS-MOTO',
        desc: 'DISCOVER 135 LS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'DOMMINAR400-MOTO',
        desc: 'DOMMINAR 400',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PLATINA100ES-MOTO',
        desc: 'PLATINA 100 ES',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR150NS/AS-MOTO',
        desc: 'PULSAR 150 NS/AS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR180DTS-MOTO',
        desc: 'PULSAR 180 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200AS/NS-MOTO',
        desc: 'PULSAR 200 AS/NS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR200RS-MOTO',
        desc: 'PULSAR 200 RS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'PULSAR220DTS-MOTO',
        desc: 'PULSAR 220 DTS',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'RE4SPETROL/LPG/CNG-MOTO',
        desc: 'RE 4S PETROL/LPG/CNG',
        brandCode: 'BAJAJ-MOTO',
      },
      {
        code: 'REMAXIMA-MOTO',
        desc: 'RE MAXIMA',
        brandCode: 'BAJAJ-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2020': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2019': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2017': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2016': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2015': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2014': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2013': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2012': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2011': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2010': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2009': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2008': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2007': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2006': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2005': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2004': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2003': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2001': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2002': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/2000': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1999': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1998': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1997': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1996': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1995': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1994': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1993': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1992': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1991': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BENELLI-MOTO/1990': [
      { code: '180S-MOTO', desc: '180S', brandCode: 'BENELLI-MOTO' },
      {
        code: '302R/302S-MOTO',
        desc: '302R/302S',
        brandCode: 'BENELLI-MOTO',
      },
      { code: '502C-MOTO', desc: '502 C', brandCode: 'BENELLI-MOTO' },
      {
        code: 'LEONCINO250-MOTO',
        desc: 'LEONCINO 250',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'LEONCINO500-MOTO',
        desc: 'LEONCINO 500',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT135-MOTO', desc: 'TNT 135', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TNT150I-MOTO',
        desc: 'TNT 150I',
        brandCode: 'BENELLI-MOTO',
      },
      { code: 'TNT25N-MOTO', desc: 'TNT 25N', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK251-MOTO', desc: 'TRK 251', brandCode: 'BENELLI-MOTO' },
      { code: 'TRK502-MOTO', desc: 'TRK 502', brandCode: 'BENELLI-MOTO' },
      {
        code: 'TRK502X-MOTO',
        desc: 'TRK 502X',
        brandCode: 'BENELLI-MOTO',
      },
      {
        code: 'ZENZERO350-MOTO',
        desc: 'ZENZERO 350',
        brandCode: 'BENELLI-MOTO',
      },
    ],
    'ES-MX_MOTO_BMW-MOTO/1990': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1991': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1992': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1993': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1994': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1995': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1996': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1997': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1998': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/1999': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2000': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2001': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2002': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2003': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2004': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2005': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2006': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2007': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2008': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2009': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2010': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2011': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2012': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2013': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2014': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2015': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2016': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2018': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2017': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2019': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_BMW-MOTO/2020': [
      {
        code: 'C600SPORT/SPECIALEDITION-MOTO',
        desc: 'C 600 SPORT / SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'C650GT-MOTO', desc: 'C 650 GT', brandCode: 'BMW-MOTO' },
      {
        code: 'C650GTSPECIALEDITION-MOTO',
        desc: 'C 650 GT SPECIAL EDITION',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F650GS-MOTO', desc: 'F 650 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'F700GS/BASICA/LWR-MOTO',
        desc: 'F 700 GS / BASICA / LWR',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'F800GS/ADVENTURE/-MOTO',
        desc: 'F 800 GS / ADVENTURE /',
        brandCode: 'BMW-MOTO',
      },
      { code: 'F800GT-MOTO', desc: 'F800 GT', brandCode: 'BMW-MOTO' },
      { code: 'F800R-MOTO', desc: 'F 800 R', brandCode: 'BMW-MOTO' },
      { code: 'F800S-MOTO', desc: 'F 800 S', brandCode: 'BMW-MOTO' },
      { code: 'F800ST-MOTO', desc: 'F 800 ST', brandCode: 'BMW-MOTO' },
      { code: 'G450X-MOTO', desc: 'G 450X', brandCode: 'BMW-MOTO' },
      {
        code: 'G650GS/SERAT/LWR-MOTO',
        desc: 'G 650 GS /SERAT / LWR',
        brandCode: 'BMW-MOTO',
      },
      { code: 'G650LWR-MOTO', desc: 'G 650 LWR', brandCode: 'BMW-MOTO' },
      {
        code: 'G650SERAT-MOTO',
        desc: 'G 650 SERAT',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCHALLENGER-MOTO',
        desc: 'G 650 XCHALLENGER',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XCOUNTRY-MOTO',
        desc: 'G 650 XCOUNTRY',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'G650XMOTO-MOTO',
        desc: 'G 650 XMOTO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'HP2MEGAMOTO-MOTO',
        desc: 'HP2 MEGAMOTO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'HP2SPORT-MOTO', desc: 'HP2 SPORT', brandCode: 'BMW-MOTO' },
      { code: 'HP4-MOTO', desc: 'HP4', brandCode: 'BMW-MOTO' },
      { code: 'K1200GT-MOTO', desc: 'K 1200 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1200LT-MOTO', desc: 'K 1200 LT', brandCode: 'BMW-MOTO' },
      { code: 'K1200R-MOTO', desc: 'K 1200 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1200RSPORT-MOTO',
        desc: 'K 1200 R SPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1200S-MOTO', desc: 'K 1200 S', brandCode: 'BMW-MOTO' },
      { code: 'K1300GT-MOTO', desc: 'K 1300 GT', brandCode: 'BMW-MOTO' },
      { code: 'K1300R-MOTO', desc: 'K 1300 R', brandCode: 'BMW-MOTO' },
      {
        code: 'K1300S/MOTORSPORT-MOTO',
        desc: 'K 1300 S / MOTORSPORT',
        brandCode: 'BMW-MOTO',
      },
      { code: 'K1300S-MOTO', desc: 'K 1300 S', brandCode: 'BMW-MOTO' },
      {
        code: 'K1600GT/GTSPORT/GTL/GTLEXCLUSIVE-MOTO',
        desc: 'K 1600 GT / GT SPORT / GTL / GTL EXCLUSIVE',
        brandCode: 'BMW-MOTO',
      },
      { code: 'NINET-MOTO', desc: 'NINET', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200GSADVENTUREECO-MOTO',
        desc: 'R 1200 GS ADVENTURE ECO',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSADVENTURE-MOTO',
        desc: 'R 1200 GS ADVENTURE',
        brandCode: 'BMW-MOTO',
      },
      {
        code: 'R1200GSECO-MOTO',
        desc: 'R 1200 GS ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200GS-MOTO', desc: 'R 1200 GS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RECO-MOTO',
        desc: 'R 1200 R ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200R-MOTO', desc: 'R 1200 R', brandCode: 'BMW-MOTO' },
      { code: 'R1200RS-MOTO', desc: 'R 1200 RS', brandCode: 'BMW-MOTO' },
      {
        code: 'R1200RTECO-MOTO',
        desc: 'R 1200 RT ECO',
        brandCode: 'BMW-MOTO',
      },
      { code: 'R1200RT-MOTO', desc: 'R 1200 RT', brandCode: 'BMW-MOTO' },
      { code: 'S1000R-MOTO', desc: 'S 1000R', brandCode: 'BMW-MOTO' },
      { code: 'S1000RR-MOTO', desc: 'S 1000 RR', brandCode: 'BMW-MOTO' },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2020': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2019': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2018': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2017': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2016': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2015': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2014': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2013': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2012': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2011': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2010': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2009': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2007': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2008': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2006': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2005': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2004': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2003': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2002': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2001': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/2000': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1999': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1997': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1998': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1996': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1995': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1994': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1993': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1990': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1991': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CANAM-MOTO/1992': [
      {
        code: 'COMMANDERLTD1000-MOTO',
        desc: 'COMMANDER LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERMAXXT1000/MAXLTD1000-MOTO',
        desc: 'COMMANDER MAX XT 1000 / MAX LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERSTD800R-MOTO',
        desc: 'COMMANDER STD 800 R ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'COMMANDERXT1000/XT-P1000-MOTO',
        desc: 'COMMANDER XT 1000 / XT-P 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD250-MOTO',
        desc: 'DS STD 250 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTD90/X90/STD250/XMX450DS-MOTO',
        desc: 'DS STD 90 /  X 90 / STD 250 / X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDX90-MOTO',
        desc: 'DS STD X 90 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'DSSTDXMX450DS-MOTO',
        desc: 'DS STD X MX 450 DS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXDS1000R/XDSTURBO-MOTO',
        desc: 'MAVERICK MAX X DS 1000R / X DS TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKMAXXRSDPS1000R-MOTO',
        desc: 'MAVERICK MAX X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXDS1000/TURBO-MOTO',
        desc: 'MAVERICK X DS 1000 / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERICKXMR1000R/XRS1000R/XRSDPS1000R-MOTO',
        desc: 'MAVERICK X MR 1000R / X RS 1000R / X RS DPS 1000R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'MAVERIKXDS1000R/TURBO-MOTO',
        desc: 'MAVERIK X DS 1000R / TURBO',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDER6X6XT1000-MOTO',
        desc: 'OUTLANDER 6X6 XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLMAXDPS450-MOTO',
        desc: 'OUTLANDER L MAX DPS 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD450-MOTO',
        desc: 'OUTLANDER L STD 450 ',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERLSTD500-MOTO',
        desc: 'OUTLANDER L STD 500',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT1000/XT-P1000/LTD1000-MOTO',
        desc: 'OUTLANDER MAX XT 1000 / XT-P 1000 / LTD 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT650-MOTO',
        desc: 'OUTLANDER MAX XT 650',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERMAXXT800R/XT-P800R-MOTO',
        desc: 'OUTLANDER MAX XT  800 R / XT-P 800 R',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'OUTLANDERXMR1000/XT1000-MOTO',
        desc: 'OUTLANDER X MR 1000 / XT 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'RENEGADESTD/XXC1000-MOTO',
        desc: 'RENEGADE STD / X XC 1000',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERF3-MOTO',
        desc: 'SPYDER F3',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRS-MOTO',
        desc: 'SPYDER RS',
        brandCode: 'CANAM-MOTO',
      },
      {
        code: 'SPYDERRT-MOTO',
        desc: 'SPYDER RT',
        brandCode: 'CANAM-MOTO',
      },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2020': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2019': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2018': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2017': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2016': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2015': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2014': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2013': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2012': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2011': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2010': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2009': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2008': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2007': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2005': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2006': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2004': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2003': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2002': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2001': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/2000': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1999': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1998': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1997': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1996': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1995': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1994': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1993': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1992': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1991': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_CARABELA-MOTO/1990': [
      {
        code: 'C110DLX-MOTO',
        desc: 'C 110 DLX',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'C110-MOTO', desc: 'C110', brandCode: 'CARABELA-MOTO' },
      { code: 'C150-MOTO', desc: 'C 150', brandCode: 'CARABELA-MOTO' },
      { code: 'C200-MOTO', desc: 'C 200', brandCode: 'CARABELA-MOTO' },
      { code: 'DK150-MOTO', desc: 'DK 150', brandCode: 'CARABELA-MOTO' },
      { code: 'DUSTER-MOTO', desc: 'DUSTER', brandCode: 'CARABELA-MOTO' },
      {
        code: 'FIRECAT-MOTO',
        desc: 'FIRECAT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GOLIATH-MOTO',
        desc: 'GOLIATH',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILASPORT-MOTO',
        desc: 'GORILA SPORT',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GORILLA-MOTO',
        desc: 'GORILLA',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GREASER-MOTO',
        desc: 'GREASER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'GS2150-MOTO',
        desc: 'GS2 150',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'GS3-MOTO', desc: 'GS3', brandCode: 'CARABELA-MOTO' },
      { code: 'GS4-MOTO', desc: 'GS4', brandCode: 'CARABELA-MOTO' },
      {
        code: 'INVADER-MOTO',
        desc: 'INVADER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'KONCEPT125-MOTO',
        desc: 'KONCEPT 125',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'MILESTONE-MOTO',
        desc: 'MILESTONE',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'NEMSIS-MOTO', desc: 'NEMSIS', brandCode: 'CARABELA-MOTO' },
      { code: 'QUARK-MOTO', desc: 'QUARK', brandCode: 'CARABELA-MOTO' },
      { code: 'R8-MOTO', desc: 'R8', brandCode: 'CARABELA-MOTO' },
      {
        code: 'ROADPOWER-MOTO',
        desc: 'ROAD POWER',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'ROUTE200-MOTO',
        desc: 'ROUTE 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'RP3-MOTO', desc: 'RP3', brandCode: 'CARABELA-MOTO' },
      { code: 'SHARK-MOTO', desc: 'SHARK', brandCode: 'CARABELA-MOTO' },
      {
        code: 'VOLKANO-MOTO',
        desc: 'VOLKANO',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOLTA150-MOTO',
        desc: 'VOLTA 150',
        brandCode: 'CARABELA-MOTO',
      },
      {
        code: 'VOODOO200-MOTO',
        desc: 'VOODOO 200',
        brandCode: 'CARABELA-MOTO',
      },
      { code: 'VX150-MOTO', desc: 'VX 150', brandCode: 'CARABELA-MOTO' },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1990': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1991': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1992': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1993': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1994': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1995': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1996': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1997': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1998': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/1999': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2001': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2000': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2002': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2003': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2004': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2005': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2006': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2007': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2008': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2010': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2009': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2011': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2012': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2013': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2015': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2014': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2016': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2017': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2018': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2019': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DINAMO-MOTO/2020': [
      {
        code: 'ADVENTURE-MOTO',
        desc: 'ADVENTURE',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CHOPPER200-MOTO',
        desc: 'CHOPPER 200',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/PATULLA-MOTO',
        desc: 'CUSTOM / PATULLA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM/RENEGADA-MOTO',
        desc: 'CUSTOM / RENEGADA',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'CUSTOM150-MOTO',
        desc: 'CUSTOM 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'FENIX150-MOTO',
        desc: 'FENIX 150',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'INTREPIDA-MOTO',
        desc: 'INTREPIDA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'JESSY-R-MOTO', desc: 'JESSY-R', brandCode: 'DINAMO-MOTO' },
      { code: 'MAX-2-MOTO', desc: 'MAX-2', brandCode: 'DINAMO-MOTO' },
      { code: 'METRO-MOTO', desc: 'METRO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RAYOELITE250-MOTO',
        desc: 'RAYO ELITE 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'RAYO-MOTO', desc: 'RAYO', brandCode: 'DINAMO-MOTO' },
      {
        code: 'RENEGADA250-MOTO',
        desc: 'RENEGADA 250',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'ROKY-MOTO', desc: 'ROKY', brandCode: 'DINAMO-MOTO' },
      {
        code: 'SPORT250-MOTO',
        desc: 'SPORT 250 ',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SPORT250SPORTR1-MOTO',
        desc: 'SPORT 250 SPORT R1',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERROCKY-MOTO',
        desc: 'SUPER ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERSPORT2P-MOTO',
        desc: 'SUPER SPORT 2P',
        brandCode: 'DINAMO-MOTO',
      },
      {
        code: 'SUPERTITI-MOTO',
        desc: 'SUPER TiTi',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'U4-MOTO', desc: 'U4', brandCode: 'DINAMO-MOTO' },
      { code: 'U5-MOTO', desc: 'U5', brandCode: 'DINAMO-MOTO' },
      {
        code: 'UTILITARIA-MOTO',
        desc: 'UTILITARIA',
        brandCode: 'DINAMO-MOTO',
      },
      { code: 'XIME-MOTO', desc: 'XIME', brandCode: 'DINAMO-MOTO' },
      {
        code: 'XTREMEROCKY-MOTO',
        desc: 'XTREME ROCKY',
        brandCode: 'DINAMO-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2020': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2019': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2018': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2016': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2017': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2015': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2014': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2013': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2012': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2011': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2010': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2009': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2008': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2006': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2007': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2005': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2004': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2003': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2002': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2001': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/2000': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1999': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1998': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1997': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1996': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1995': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1994': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1993': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1992': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1991': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_DUCATI-MOTO/1990': [
      { code: '1198R-MOTO', desc: '1198 R', brandCode: 'DUCATI-MOTO' },
      {
        code: '1198RTRDYBAYLISS-MOTO',
        desc: '1198 R TRDY BAYLISS',
        brandCode: 'DUCATI-MOTO',
      },
      { code: '1198S-MOTO', desc: '1198 S', brandCode: 'DUCATI-MOTO' },
      {
        code: '1299PANIGALE/S-MOTO',
        desc: '1299 PANIGALE / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '1299PANIGALE-MOTO',
        desc: '1299 PANIGALE ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: '899PANIGALE-MOTO',
        desc: '899 PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'DESMOSEDICIRR-MOTO',
        desc: 'DESMOSEDICI RR',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'DIAVEL-MOTO', desc: 'DIAVEL', brandCode: 'DUCATI-MOTO' },
      {
        code: 'GT1000BICOLORE-MOTO',
        desc: 'GT 1000 BICOLORE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'GT1000BICOLORETOURNING-MOTO',
        desc: 'GT 1000 BICOLORE TOURNING',
        brandCode: 'DUCATI-MOTO',
      },
      { code: 'GT1000-MOTO', desc: 'GT 1000', brandCode: 'DUCATI-MOTO' },
      {
        code: 'HYPERMOTARD/S-MOTO',
        desc: 'HYPERMOTARD / S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100-MOTO',
        desc: 'HYPERMOTARD 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERMOTARD1100S-MOTO',
        desc: 'HYPERMOTARD 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'HYPERSTRADA-MOTO',
        desc: 'HYPERSTRADA',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100-MOTO',
        desc: 'MONSTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1100S-MOTO',
        desc: 'MONSTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER1200/1200S/SSTRIPE-MOTO',
        desc: 'MONSTER 1200 / 1200 S / S STRIPE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696+-MOTO',
        desc: 'MONSTER 696+',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER696-MOTO',
        desc: 'MONSTER 696',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MONSTER821-MOTO',
        desc: 'MONSTER 821',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100-MOTO',
        desc: 'MULTISTRADA 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'MULTISTRADA1100S-MOTO',
        desc: 'MULTISTRADA 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'PANIGALE-MOTO',
        desc: 'PANIGALE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERCLASSIC-MOTO',
        desc: 'SCRAMBLER CLASSIC',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERENDURO-MOTO',
        desc: 'SCRAMBLER ENDURO',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERICON-MOTO',
        desc: 'SCRAMBLER ICON ',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SCRAMBLERTHROTTLE-MOTO',
        desc: 'SCRAMBLER THROTTLE',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SPORT100S-MOTO',
        desc: 'SPORT 100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100-MOTO',
        desc: 'STREETFIGHTER 1100',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER1100S-MOTO',
        desc: 'STREETFIGHTER 1100 S',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'STREETFIGHTER848-MOTO',
        desc: 'STREETFIGHTER 848',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE1198-MOTO',
        desc: 'SUPERVIKE  1198',
        brandCode: 'DUCATI-MOTO',
      },
      {
        code: 'SUPERVIKE848-MOTO',
        desc: 'SUPERVIKE 848',
        brandCode: 'DUCATI-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1990': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1991': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1992': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1993': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1994': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1995': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1996': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1997': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1998': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/1999': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2000': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2001': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2002': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2003': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2004': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2005': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2006': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2007': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2008': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2009': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2010': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2011': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2012': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2013': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2014': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2015': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2016': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2017': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2018': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2019': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_GILERA-MOTO/2020': [
      {
        code: 'FUOCO500-MOTO',
        desc: 'FUOCO 500',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER125ST-MOTO',
        desc: 'RUNNER 125 ST',
        brandCode: 'GILERA-MOTO',
      },
      {
        code: 'RUNNER50SP-MOTO',
        desc: 'RUNNER 50SP',
        brandCode: 'GILERA-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2020': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2019': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2018': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2017': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2016': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2015': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2014': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2013': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2012': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2011': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2010': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2008': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2009': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2007': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2006': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2005': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2004': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2003': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2002': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2001': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/2000': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1999': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1998': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1997': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1996': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1995': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1994': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1993': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1992': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1991': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HARLEYDAVIDSON-MOTO/1990': [
      {
        code: 'BREAKOUT-MOTO',
        desc: 'BREAKOUT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOGLIDEULTRA-MOTO',
        desc: 'CVO GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOLIMITED-MOTO',
        desc: 'CVO LIMITED',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'CVOSOFTAILDELUXE-MOTO',
        desc: 'CVO SOFTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNAFATBOB-MOTO',
        desc: 'DYNA FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASTREETBOB-MOTO',
        desc: 'DYNA STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'DYNASUPERGLIDECUSTOM-MOTO',
        desc: 'DYNA SUPER GLIDE CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDESTANDARD-MOTO',
        desc: 'ELECTRA GLIDE STANDARD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACLASSIC-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRACVO-MOTO',
        desc: 'ELECTRA GLIDE ULTRA CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ELECTRAGLIDEULTRALIMITED/LOW-MOTO',
        desc: 'ELECTRA GLIDE ULTRA LIMITED / LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FATBOB-MOTO',
        desc: 'FAT BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FORTY-EIGHT-MOTO',
        desc: 'FORTY-EIGHT',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'FREEWHEELER-MOTO',
        desc: 'FREEWHEELER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'HERITAGESOFTAILCLASSIC-MOTO',
        desc: 'HERITAGE SOFTAIL CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'LOWRIDER-MOTO',
        desc: 'LOW RIDER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'NIGHTRODSPECIAL-MOTO',
        desc: 'NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADGLIDE/SPECIAL-MOTO',
        desc: 'ROAD GLIDE / SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKINGCLASSIC-MOTO',
        desc: 'ROAD KING CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'ROADKING-MOTO',
        desc: 'ROAD KING',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SEVENTY-TWO-MOTO',
        desc: 'SEVENTY-TWO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILCROSSBONES-MOTO',
        desc: 'SOFTAIL CROSS BONES',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILFATBOY-MOTO',
        desc: 'SOFTAIL FAT BOY',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILHERITAGECLASSIC-MOTO',
        desc: 'SOFTAIL HERITAGE CLASSIC',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILNIGHTTRAIN-MOTO',
        desc: 'SOFTAIL NIGHTTRAIN',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKERC-MOTO',
        desc: 'SOFTAIL ROCKER C',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILROCKER-MOTO',
        desc: 'SOFTAIL ROCKER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTAILSLIM-MOTO',
        desc: 'SOFTAIL SLIM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTALCUSTOM-MOTO',
        desc: 'SOFTAL CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTILSPRINGERCVO-MOTO',
        desc: 'SOFTIL SPRINGER CVO',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SOFTTAILDELUXE-MOTO',
        desc: 'SOFTTAIL DELUXE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTERIRON883-MOTO',
        desc: 'SPORTER IRON 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200CUSTOM-MOTO',
        desc: 'SPORTSTER 1200 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200LOW-MOTO',
        desc: 'SPORTSTER 1200 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER1200NIGHTSTER-MOTO',
        desc: 'SPORTSTER 1200 NIGHTSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883CUSTOM-MOTO',
        desc: 'SPORTSTER 883 CUSTOM',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883IRON-MOTO',
        desc: 'SPORTSTER 883 IRON',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883LOW-MOTO',
        desc: 'SPORTSTER 883 LOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883-MOTO',
        desc: 'SPORTSTER 883',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SPORTSTER883ROADDSTER-MOTO',
        desc: 'SPORTSTER 883 ROADDSTER',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREET750/STREET500-MOTO',
        desc: 'STREET 750 / STREET 500',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETBOB-MOTO',
        desc: 'STREET BOB',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE/STREETGLIDESPECIAL-MOTO',
        desc: 'STREET GLIDE / STREET GLIDESPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'STREETGLIDE-MOTO',
        desc: 'STREET GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW1200T-MOTO',
        desc: 'SUPERLOW 1200T',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SUPERLOW-MOTO',
        desc: 'SUPERLOW',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'SWITCHBACK-MOTO',
        desc: 'SWITCHBACK',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'TRIGLIDEULTRA-MOTO',
        desc: 'TRI GLIDE ULTRA',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'V-ROADMUSCLE-MOTO',
        desc: 'V-ROAD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCNIGHTRODSPECIAL-MOTO',
        desc: 'VRSC  NIGHT ROD SPECIAL',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCRODMUSCLE-MOTO',
        desc: 'VRSC  ROD MUSCLE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'VRSCVROD-MOTO',
        desc: 'VRSC  V ROD',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
      {
        code: 'WIDEGLIDE-MOTO',
        desc: 'WIDE GLIDE',
        brandCode: 'HARLEYDAVIDSON-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1990': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1991': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1992': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1993': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1994': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1995': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1996': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1997': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1998': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/1999': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2000': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2001': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2002': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2003': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2004': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2005': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2006': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2007': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2008': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2009': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2010': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2011': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2012': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2013': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2014': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2015': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2016': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2017': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2018': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2019': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HONDA-MOTO/2020': [
      {
        code: '150CARGO-MOTO',
        desc: ' 150 CARGO',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB1300ABS-MOTO',
        desc: 'CB 1300 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB1300-MOTO', desc: 'CB 1300', brandCode: 'HONDA-MOTO' },
      {
        code: 'CB1300SABS-MOTO',
        desc: 'CB 1300 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CB600FABS-MOTO',
        desc: 'CB 600 F ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CB600F-MOTO', desc: 'CB 600 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000ABS-MOTO',
        desc: 'CBF 1000 ABS',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CBF1000-MOTO', desc: 'CBF 1000', brandCode: 'HONDA-MOTO' },
      {
        code: 'CBF1000T-MOTO',
        desc: 'CBF 1000 T ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600NABS-MOTO',
        desc: 'CBF 600 N ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600N-MOTO',
        desc: 'CBF 600 N',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600SABS-MOTO',
        desc: 'CBF 600 S ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBF600S-MOTO',
        desc: 'CBF 600 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABS-MOTO',
        desc: 'CBR 1000 RR C ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRCABSREPSOL-MOTO',
        desc: 'CBR 1000 RR C ABS REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RR-MOTO',
        desc: 'CBR 1000 RR ',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR1000RRREPSOL-MOTO',
        desc: 'CBR 1000 RR REPSOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR250R-MOTO',
        desc: 'CBR 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABSHANSFREE-MOTO',
        desc: 'CBR 600 RR C-ABS HANSFREE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RRC-ABS-MOTO',
        desc: 'CBR 600 RR C-ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR600RR-MOTO',
        desc: 'CBR 600 RR',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBR650F-MOTO',
        desc: 'CBR 650 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CBX250TWISTER-MOTO',
        desc: 'CBX 250TWISTER',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CGL125TOOL-MOTO',
        desc: 'CGL 125 TOOL',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF150F-MOTO',
        desc: 'CRF 150 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF230F-MOTO',
        desc: 'CRF 230 F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250R-MOTO',
        desc: 'CRF 250 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF250X-MOTO',
        desc: 'CRF 250 X',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450R-MOTO',
        desc: 'CRF 450 R',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CRF450X-MOTO',
        desc: 'CRF 450 X',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'CRF50F-MOTO', desc: 'CRF 50 F', brandCode: 'HONDA-MOTO' },
      { code: 'CRF80F-MOTO', desc: 'CRF 80 F', brandCode: 'HONDA-MOTO' },
      {
        code: 'CRUSING125-MOTO',
        desc: 'CRUSING 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000RABS-MOTO',
        desc: 'CS 1000 R ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'CS1000R-MOTO',
        desc: 'CS 1000 R',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'DIO110-MOTO', desc: 'DIO 110', brandCode: 'HONDA-MOTO' },
      { code: 'DN-01-MOTO', desc: 'DN-01', brandCode: 'HONDA-MOTO' },
      {
        code: 'FORZA125-MOTO',
        desc: 'FORZA 125',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250ABS-MOTO',
        desc: 'FORZA 250 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'FORZA250S-MOTO',
        desc: 'FORZA 250 S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'GOLDWINGAIRBAG-MOTO',
        desc: 'GOLD WING AIRBAG',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLEABS-MOTO',
        desc: 'NT 700 V DEAVILLE ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NT700VDEAVILLE-MOTO',
        desc: 'NT 700 V DEAVILLE',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'NX4FALCON-MOTO',
        desc: 'NX4 FALCON',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300ABS-MOTO',
        desc: 'PAN-EUROPEAN 1300  ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'PAN-EUROPEAN1300-MOTO',
        desc: 'PAN-EUROPEAN 1300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'PCX150-MOTO', desc: 'PCX 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'RCV213-S-MOTO',
        desc: 'RCV213-S',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH125/150/300-MOTO',
        desc: 'SH 125 / 150 / 300',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH150-MOTO', desc: 'SH 150', brandCode: 'HONDA-MOTO' },
      {
        code: 'SH300IRSCOOPY-MOTO',
        desc: 'SH 300i R SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPYABS-MOTO',
        desc: 'SH 300i SCOOPY ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SH300ISCOOPY-MOTO',
        desc: 'SH 300i SCOOPY',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SH300-MOTO', desc: 'S H300', brandCode: 'HONDA-MOTO' },
      {
        code: 'SILVERWING600ABS-MOTO',
        desc: 'SILVER WING 600 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SILVERWING600-MOTO',
        desc: 'SILVER WING 600',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'SUPERCUB110-MOTO',
        desc: 'SUPER CUB 110',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'SW-T400-MOTO', desc: 'SW-T400', brandCode: 'HONDA-MOTO' },
      { code: 'SWT600-MOTO', desc: 'SW T600', brandCode: 'HONDA-MOTO' },
      {
        code: 'TRANSALP700ABS-MOTO',
        desc: 'TRANSALP 700 ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRANSALP700-MOTO',
        desc: 'TRANSALP 700',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX250(ATV)-MOTO',
        desc: 'TRX 250 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX420(ATV)-MOTO',
        desc: 'TRX 420 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX500(ATV)-MOTO',
        desc: 'TRX 500 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'TRX680(ATV)-MOTO',
        desc: 'TRX 680 (ATV)',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000ABS-MOTO',
        desc: 'VARADERO 1000ABS',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VARADERO1000-MOTO',
        desc: 'VARADERO 1000',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR1200F-MOTO',
        desc: 'VFR 1200F',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VFR800F/1200F-MOTO',
        desc: 'VFR 800 F / 1200 F',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VFRABS-MOTO', desc: 'VFR ABS', brandCode: 'HONDA-MOTO' },
      { code: 'VFR-MOTO', desc: 'VFR', brandCode: 'HONDA-MOTO' },
      {
        code: 'VT750SHADOW-MOTO',
        desc: 'VT 750 SHADOW',
        brandCode: 'HONDA-MOTO',
      },
      {
        code: 'VT750SHADOWSPIRIT-MOTO',
        desc: 'VT 750 SHADOW SPIRIT',
        brandCode: 'HONDA-MOTO',
      },
      { code: 'VTR250-MOTO', desc: 'VTR 250', brandCode: 'HONDA-MOTO' },
      { code: 'WAVE100-MOTO', desc: 'WAVE 100', brandCode: 'HONDA-MOTO' },
      {
        code: 'XR250TORNADO-MOTO',
        desc: 'XR 250 TORNADO',
        brandCode: 'HONDA-MOTO',
      },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2020': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2019': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2018': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2017': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2016': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2015': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2014': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2013': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2012': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2011': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2010': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2009': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2008': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2007': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2006': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2005': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2004': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2003': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2002': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2001': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/2000': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1999': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1998': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1996': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1997': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1995': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1994': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1992': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1993': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1991': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_HUSQVARNA-MOTO/1990': [
      {
        code: '701SUPERMOTO-MOTO',
        desc: '701 SUPERMOTO',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FC250-MOTO', desc: 'FC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC350-MOTO', desc: 'FC 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FC450-MOTO', desc: 'FC 450', brandCode: 'HUSQVARNA-MOTO' },
      {
        code: 'FE250/350/450/501-MOTO',
        desc: 'FE 250 / 350 / 450 / 501',
        brandCode: 'HUSQVARNA-MOTO',
      },
      { code: 'FE350-MOTO', desc: 'FE 350', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE450-MOTO', desc: 'FE450', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'FE501-MOTO', desc: 'FE501', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC125-MOTO', desc: 'TC 125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC250-MOTO', desc: 'TC 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TC85-MOTO', desc: 'TC 85', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE125-MOTO', desc: 'TE125', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE250-MOTO', desc: 'TE 250', brandCode: 'HUSQVARNA-MOTO' },
      { code: 'TE300-MOTO', desc: 'TE 300', brandCode: 'HUSQVARNA-MOTO' },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1997': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1990': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1991': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1992': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1994': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1993': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1995': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1996': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1998': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2000': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/1999': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2001': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2002': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2003': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2004': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2005': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2006': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2007': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2008': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2009': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2010': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2011': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2012': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2013': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2014': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2015': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2016': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2017': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2018': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2019': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_INDIAN-MOTO/2020': [
      {
        code: 'CHALLENGER-MOTO',
        desc: 'CHALLENGER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'CRUSIER-MOTO', desc: 'CRUSIER', brandCode: 'INDIAN-MOTO' },
      {
        code: 'FTR1200-MOTO',
        desc: 'FTR 1200',
        brandCode: 'INDIAN-MOTO',
      },
      {
        code: 'ROADMASTER-MOTO',
        desc: 'ROADMASTER',
        brandCode: 'INDIAN-MOTO',
      },
      { code: 'SCOUT-MOTO', desc: 'SCOUT', brandCode: 'INDIAN-MOTO' },
      {
        code: 'SPRINGFIELD-MOTO',
        desc: 'SPRINGFIELD',
        brandCode: 'INDIAN-MOTO',
      },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2020': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2019': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2017': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2018': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2016': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2015': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2014': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2013': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2012': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2011': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2010': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2009': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2008': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2007': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2006': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2005': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2004': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2003': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2002': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2001': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/2000': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1999': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1998': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1997': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1996': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1995': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1994': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1993': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1992': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1991': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ISLO-MOTO/1990': [
      {
        code: '125RACER-MOTO',
        desc: '125 RACER',
        brandCode: 'ISLO-MOTO',
      },
      { code: 'DRIFTER-MOTO', desc: 'DRIFTER', brandCode: 'ISLO-MOTO' },
      { code: 'KRAKEN-MOTO', desc: 'KRAKEN', brandCode: 'ISLO-MOTO' },
      { code: 'SPIDER-MOTO', desc: 'SPIDER', brandCode: 'ISLO-MOTO' },
      { code: 'WARRIOR-MOTO', desc: 'WARRIOR', brandCode: 'ISLO-MOTO' },
      { code: 'ZEBRA-MOTO', desc: 'ZEBRA ', brandCode: 'ISLO-MOTO' },
      { code: 'ZERO-MOTO', desc: 'ZERO', brandCode: 'ISLO-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1990': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1991': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1992': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1993': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1994': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1995': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1996': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1997': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1998': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/1999': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2000': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2001': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2002': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2003': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2004': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2005': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2006': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2007': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2008': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2009': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2010': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2011': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2012': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2013': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2014': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2015': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2016': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2017': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2018': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2019': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_ITALICA-MOTO/2020': [
      { code: '150Z-MOTO', desc: '150 Z', brandCode: 'ITALICA-MOTO' },
      { code: '250Z-MOTO', desc: '250 Z', brandCode: 'ITALICA-MOTO' },
      {
        code: 'AT110RT-MOTO',
        desc: 'AT 110 RT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'AT110XT-MOTO',
        desc: 'AT 110  XT ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVERSA250-MOTO',
        desc: 'ATVCON REVERSA 250',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'ATVCONREVRESA150-MOTO',
        desc: 'ATV CON REVRESA 150 ',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'CS125LED-MOTO',
        desc: 'CS 125 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'CS125-MOTO', desc: 'CS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DM150-MOTO', desc: 'DM 150', brandCode: 'ITALICA-MOTO' },
      { code: 'DM200-MOTO', desc: 'DM 200', brandCode: 'ITALICA-MOTO' },
      { code: 'DS125-MOTO', desc: 'DS 125', brandCode: 'ITALICA-MOTO' },
      { code: 'DS150-MOTO', desc: 'DS 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT125CLASICA-MOTO',
        desc: 'FT 125 CLASICA',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT125-MOTO', desc: 'FT 125', brandCode: 'ITALICA-MOTO' },
      { code: 'FT150-MOTO', desc: 'FT 150', brandCode: 'ITALICA-MOTO' },
      {
        code: 'FT150SPORT-MOTO',
        desc: 'FT 150 Sport',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'FT180-MOTO', desc: 'FT 180', brandCode: 'ITALICA-MOTO' },
      { code: 'FT200-MOTO', desc: 'FT 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'GS150LED-MOTO',
        desc: 'GS 150 LED',
        brandCode: 'ITALICA-MOTO',
      },
      {
        code: 'GTS175LED-MOTO',
        desc: 'GTS 175 LED',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'IRT650-MOTO', desc: 'IRT 650', brandCode: 'ITALICA-MOTO' },
      {
        code: 'RC150GT-MOTO',
        desc: 'RC 150 GT',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'RC200-MOTO', desc: 'RC 200', brandCode: 'ITALICA-MOTO' },
      { code: 'RT200-MOTO', desc: 'RT 200', brandCode: 'ITALICA-MOTO' },
      { code: 'ST90-MOTO', desc: 'ST 90', brandCode: 'ITALICA-MOTO' },
      { code: 'TC200-MOTO', desc: 'TC 200', brandCode: 'ITALICA-MOTO' },
      {
        code: 'T-REX70-MOTO',
        desc: 'T-REX 70',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'TS170-MOTO', desc: 'TS 170', brandCode: 'ITALICA-MOTO' },
      {
        code: 'VITALIA125-MOTO',
        desc: 'VITALIA 125',
        brandCode: 'ITALICA-MOTO',
      },
      { code: 'WS150-MOTO', desc: 'WS 150', brandCode: 'ITALICA-MOTO' },
      { code: 'WS175-MOTO', desc: 'WS 175', brandCode: 'ITALICA-MOTO' },
      { code: 'XS125-MOTO', desc: 'XS 125', brandCode: 'ITALICA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2019': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2020': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2017': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2018': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2016': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2014': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2015': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2013': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2012': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2011': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2010': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2009': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2008': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2006': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2007': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2005': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2004': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2003': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2002': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2001': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/2000': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1999': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1998': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1997': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1996': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1995': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1994': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1993': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1992': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1991': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_IZUKA-MOTO/1990': [
      { code: 'CL150-MOTO', desc: 'CL 150', brandCode: 'IZUKA-MOTO' },
      { code: 'KL110-MOTO', desc: 'KL 110', brandCode: 'IZUKA-MOTO' },
      { code: 'TL125A-MOTO', desc: 'TL 125 A', brandCode: 'IZUKA-MOTO' },
      { code: 'TL150N-MOTO', desc: 'TL 150 N', brandCode: 'IZUKA-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1990': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1991': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1992': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1993': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1994': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1995': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1996': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1997': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1998': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/1999': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2000': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2001': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2002': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2003': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2004': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2005': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2006': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2007': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2008': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2009': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2010': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2011': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2012': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2013': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2014': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2015': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2016': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2017': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2018': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2019': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KAWASAKI-MOTO/2020': [
      {
        code: 'BRUTEFORCE7504X4IEPS-MOTO',
        desc: 'BRUTE FORCE 750 4X4I EPS',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'H2-MOTO', desc: 'H2 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'H2R-MOTO', desc: 'H2R', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'KLR650ES-MOTO',
        desc: 'KLR 650 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX110-MOTO',
        desc: 'KLX 110',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KLX140-MOTO',
        desc: 'KLX 140',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'KX250ES-MOTO',
        desc: 'KX 250 ES',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'KX250F-MOTO', desc: 'KX250F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX450F-MOTO', desc: 'KX450F', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX65-MOTO', desc: 'KX 65 ', brandCode: 'KAWASAKI-MOTO' },
      { code: 'KX85-MOTO', desc: 'KX 85', brandCode: 'KAWASAKI-MOTO' },
      {
        code: 'NINJA1000SXABS-MOTO',
        desc: 'NINJA 1000 SX ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZ14R-MOTO',
        desc: 'NINJA Z 14R',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-10R/ABS-MOTO',
        desc: 'NINJA ZX-10 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'NINJAZX-6R/ABS-MOTO',
        desc: 'NINJA ZX -6 R / ABS',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TERYX4800FI4X4EPSLE-MOTO',
        desc: 'TERY X 4 800 FI 4X4 EPS LE',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'TX200E-MOTO',
        desc: 'TX 200 E',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS1000-MOTO',
        desc: 'VERSYS 1000',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VERSYS650-MOTO',
        desc: 'VERSYS 650',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN650S-MOTO',
        desc: 'VULCAN 650 S',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CLASSIC-MOTO',
        desc: 'VULCAN 900 CLASSIC',
        brandCode: 'KAWASAKI-MOTO',
      },
      {
        code: 'VULCAN900CUSTOM-MOTO',
        desc: 'VULCAN 900 CUSTOM',
        brandCode: 'KAWASAKI-MOTO',
      },
      { code: 'W800-MOTO', desc: 'W800', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z1000-MOTO', desc: 'Z 1000', brandCode: 'KAWASAKI-MOTO' },
      { code: 'Z300-MOTO', desc: 'Z 300', brandCode: 'KAWASAKI-MOTO' },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO/2020': [
      { code: 'ARSEN-MOTO', desc: 'ARSEN', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'DORADO250-MOTO',
        desc: 'DORADO 250',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT125-MOTO',
        desc: 'F-ACT 125 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT150-MOTO',
        desc: 'F-ACT 150',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'FACTORY-MOTO', desc: 'FACTORY', brandCode: 'KEEWAY-MOTO' },
      { code: 'HACKER-MOTO', desc: 'HACKER', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'LOGIK125-MOTO',
        desc: 'LOGIK 125',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK150-MOTO',
        desc: 'LOGIK 150 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK200I-MOTO',
        desc: 'LOGIK 200i',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'OWENII-MOTO', desc: 'OWEN II', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKIII-MOTO', desc: 'RKIII', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS150-MOTO', desc: 'RKS 150', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS200-MOTO', desc: 'RKS 200', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKV-MOTO', desc: 'RKV ', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'SUPERLIGHT-MOTO',
        desc: 'SUPERLIGHT',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'V-BLADE250-MOTO',
        desc: 'V-BLADE 250',
        brandCode: 'KEEWAY-MOTO',
      },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO/2019': [
      { code: 'ARSEN-MOTO', desc: 'ARSEN', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'DORADO250-MOTO',
        desc: 'DORADO 250',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT125-MOTO',
        desc: 'F-ACT 125 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT150-MOTO',
        desc: 'F-ACT 150',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'FACTORY-MOTO', desc: 'FACTORY', brandCode: 'KEEWAY-MOTO' },
      { code: 'HACKER-MOTO', desc: 'HACKER', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'LOGIK125-MOTO',
        desc: 'LOGIK 125',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK150-MOTO',
        desc: 'LOGIK 150 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK200I-MOTO',
        desc: 'LOGIK 200i',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'OWENII-MOTO', desc: 'OWEN II', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKIII-MOTO', desc: 'RKIII', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS150-MOTO', desc: 'RKS 150', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS200-MOTO', desc: 'RKS 200', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKV-MOTO', desc: 'RKV ', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'SUPERLIGHT-MOTO',
        desc: 'SUPERLIGHT',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'V-BLADE250-MOTO',
        desc: 'V-BLADE 250',
        brandCode: 'KEEWAY-MOTO',
      },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO/2018': [
      { code: 'ARSEN-MOTO', desc: 'ARSEN', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'DORADO250-MOTO',
        desc: 'DORADO 250',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT125-MOTO',
        desc: 'F-ACT 125 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT150-MOTO',
        desc: 'F-ACT 150',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'FACTORY-MOTO', desc: 'FACTORY', brandCode: 'KEEWAY-MOTO' },
      { code: 'HACKER-MOTO', desc: 'HACKER', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'LOGIK125-MOTO',
        desc: 'LOGIK 125',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK150-MOTO',
        desc: 'LOGIK 150 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK200I-MOTO',
        desc: 'LOGIK 200i',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'OWENII-MOTO', desc: 'OWEN II', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKIII-MOTO', desc: 'RKIII', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS150-MOTO', desc: 'RKS 150', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS200-MOTO', desc: 'RKS 200', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKV-MOTO', desc: 'RKV ', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'SUPERLIGHT-MOTO',
        desc: 'SUPERLIGHT',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'V-BLADE250-MOTO',
        desc: 'V-BLADE 250',
        brandCode: 'KEEWAY-MOTO',
      },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO/2017': [
      { code: 'ARSEN-MOTO', desc: 'ARSEN', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'DORADO250-MOTO',
        desc: 'DORADO 250',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT125-MOTO',
        desc: 'F-ACT 125 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT150-MOTO',
        desc: 'F-ACT 150',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'FACTORY-MOTO', desc: 'FACTORY', brandCode: 'KEEWAY-MOTO' },
      { code: 'HACKER-MOTO', desc: 'HACKER', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'LOGIK125-MOTO',
        desc: 'LOGIK 125',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK150-MOTO',
        desc: 'LOGIK 150 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK200I-MOTO',
        desc: 'LOGIK 200i',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'OWENII-MOTO', desc: 'OWEN II', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKIII-MOTO', desc: 'RKIII', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS150-MOTO', desc: 'RKS 150', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS200-MOTO', desc: 'RKS 200', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKV-MOTO', desc: 'RKV ', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'SUPERLIGHT-MOTO',
        desc: 'SUPERLIGHT',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'V-BLADE250-MOTO',
        desc: 'V-BLADE 250',
        brandCode: 'KEEWAY-MOTO',
      },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO/2016': [
      { code: 'ARSEN-MOTO', desc: 'ARSEN', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'DORADO250-MOTO',
        desc: 'DORADO 250',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT125-MOTO',
        desc: 'F-ACT 125 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT150-MOTO',
        desc: 'F-ACT 150',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'FACTORY-MOTO', desc: 'FACTORY', brandCode: 'KEEWAY-MOTO' },
      { code: 'HACKER-MOTO', desc: 'HACKER', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'LOGIK125-MOTO',
        desc: 'LOGIK 125',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK150-MOTO',
        desc: 'LOGIK 150 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK200I-MOTO',
        desc: 'LOGIK 200i',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'OWENII-MOTO', desc: 'OWEN II', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKIII-MOTO', desc: 'RKIII', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS150-MOTO', desc: 'RKS 150', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS200-MOTO', desc: 'RKS 200', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKV-MOTO', desc: 'RKV ', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'SUPERLIGHT-MOTO',
        desc: 'SUPERLIGHT',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'V-BLADE250-MOTO',
        desc: 'V-BLADE 250',
        brandCode: 'KEEWAY-MOTO',
      },
    ],
    'ES-MX_MOTO_KEEWAY-MOTO/2015': [
      { code: 'ARSEN-MOTO', desc: 'ARSEN', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'DORADO250-MOTO',
        desc: 'DORADO 250',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT125-MOTO',
        desc: 'F-ACT 125 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'F-ACT150-MOTO',
        desc: 'F-ACT 150',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'FACTORY-MOTO', desc: 'FACTORY', brandCode: 'KEEWAY-MOTO' },
      { code: 'HACKER-MOTO', desc: 'HACKER', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'LOGIK125-MOTO',
        desc: 'LOGIK 125',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK150-MOTO',
        desc: 'LOGIK 150 ',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'LOGIK200I-MOTO',
        desc: 'LOGIK 200i',
        brandCode: 'KEEWAY-MOTO',
      },
      { code: 'OWENII-MOTO', desc: 'OWEN II', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKIII-MOTO', desc: 'RKIII', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS150-MOTO', desc: 'RKS 150', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKS200-MOTO', desc: 'RKS 200', brandCode: 'KEEWAY-MOTO' },
      { code: 'RKV-MOTO', desc: 'RKV ', brandCode: 'KEEWAY-MOTO' },
      {
        code: 'SUPERLIGHT-MOTO',
        desc: 'SUPERLIGHT',
        brandCode: 'KEEWAY-MOTO',
      },
      {
        code: 'V-BLADE250-MOTO',
        desc: 'V-BLADE 250',
        brandCode: 'KEEWAY-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2020': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2019': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2018': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2017': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2016': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2015': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2014': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2013': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2012': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2011': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2010': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2009': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2008': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2007': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2006': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2005': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2003': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2004': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2002': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2001': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/2000': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1999': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1998': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1997': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1996': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1995': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1994': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1993': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1992': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1991': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KIMCO-MOTO/1990': [
      {
        code: 'AGILITYRS125-MOTO',
        desc: 'AGILITY RS 125',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'GILITYCITY150-MOTO',
        desc: 'GILITY CITY 150',
        brandCode: 'KIMCO-MOTO',
      },
      { code: 'TOPBOY-MOTO', desc: 'TOP BOY', brandCode: 'KIMCO-MOTO' },
      {
        code: 'XCITINGS400-MOTO',
        desc: 'XCITING S 400',
        brandCode: 'KIMCO-MOTO',
      },
      {
        code: 'XTOWN250I-MOTO',
        desc: 'X TOWN 250i',
        brandCode: 'KIMCO-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1990': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1991': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1992': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1993': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1994': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1995': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1996': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1997': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1998': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/1999': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2000': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2001': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2002': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2003': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2004': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2005': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2006': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2007': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2008': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2009': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2010': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2011': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2012': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2013': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2014': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2015': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2016': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2017': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2018': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2019': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KTM-MOTO/2020': [
      {
        code: '1150ADVENTURE-MOTO',
        desc: '1150 ADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1190ADVENTURE/R-MOTO',
        desc: '1190 ADVENTURE / R',
        brandCode: 'KTM-MOTO',
      },
      { code: '1190RC8R-MOTO', desc: '1190RC8 R', brandCode: 'KTM-MOTO' },
      {
        code: '1290SUPERADVENTURE-MOTO',
        desc: '1290 SUPERADVENTURE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '1290SUPERDUKER-MOTO',
        desc: '1290SUPER DUKE R',
        brandCode: 'KTM-MOTO',
      },
      { code: '150SX-MOTO', desc: '150 SX', brandCode: 'KTM-MOTO' },
      { code: '450-MOTO', desc: '450', brandCode: 'KTM-MOTO' },
      { code: '500XC-W-MOTO', desc: '500 XC-W', brandCode: 'KTM-MOTO' },
      {
        code: '690ENDURO-MOTO',
        desc: '690 ENDURO',
        brandCode: 'KTM-MOTO',
      },
      { code: '690SMCR-MOTO', desc: '690 SMC R', brandCode: 'KTM-MOTO' },
      {
        code: '950SUPERENDUROR-MOTO',
        desc: '950 SUPER ENDURO R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: '990ADVENTUREORANGE/BLACK/S/DUKE/DUKER-MOTO',
        desc: '990 adventure orange/black/s/duke/duke R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990-MOTO',
        desc: 'ADVENTURE 990',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'ADVENTURE990R-MOTO',
        desc: 'ADVENTURE 990 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'DUKE200-MOTO', desc: 'DUKE 200 ', brandCode: 'KTM-MOTO' },
      { code: 'DUKE390-MOTO', desc: 'DUKE 390', brandCode: 'KTM-MOTO' },
      { code: 'DUKE690-MOTO', desc: 'DUKE 690 ', brandCode: 'KTM-MOTO' },
      {
        code: 'DUKE690R-MOTO',
        desc: 'DUKE 690 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'DUKE990SUPERDUKE-MOTO',
        desc: 'DUKE 990 SUPERDUKE',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE250R-MOTO',
        desc: 'FREERIDE 250 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'FREERIDE350R-MOTO',
        desc: 'FREERIDE 350 R',
        brandCode: 'KTM-MOTO',
      },
      { code: 'RC390-MOTO', desc: 'RC 390', brandCode: 'KTM-MOTO' },
      {
        code: 'SPORTMINI50SX-MOTO',
        desc: 'SPORTMINI 50 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI65SX-MOTO',
        desc: 'SPORTMINI 65 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SPORTMINI85SX-MOTO',
        desc: 'SPORTMINI 85 SX',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERMOTAR450SMR-MOTO',
        desc: 'SUPER MOTAR 450 SMR',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8-MOTO',
        desc: 'SUPERVIKE 1190 RC 8',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SUPERVIKE1190RC8R-MOTO',
        desc: 'SUPERVIKE 1190 RC 8 R',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'SX250SX-F-MOTO',
        desc: 'SX 250 SX-F',
        brandCode: 'KTM-MOTO',
      },
      { code: 'SX250SX-MOTO', desc: 'SX 250 SX', brandCode: 'KTM-MOTO' },
      {
        code: 'SX450SX-F-MOTO',
        desc: 'SX 450 SX-F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW200XCW-MOTO',
        desc: 'XCW 200 XCW',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCF-W-MOTO',
        desc: 'XCW 250 XCF-W',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW250XCW-E-MOTO',
        desc: 'XCW 250 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW300XCW-E-MOTO',
        desc: 'XCW 300 XCW-E',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW450XCF-MOTO',
        desc: 'XCW 450 XC F',
        brandCode: 'KTM-MOTO',
      },
      {
        code: 'XCW530XCW-MOTO',
        desc: 'XCW 530 XCW',
        brandCode: 'KTM-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2019': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2020': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2018': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2017': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2016': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2015': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2014': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2013': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2012': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2011': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2010': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2009': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2008': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2006': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2007': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2005': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2004': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2003': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2002': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2001': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/2000': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1999': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1998': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1997': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1996': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1995': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1994': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1993': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1991': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1992': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_KURAZAI-MOTO/1990': [
      {
        code: 'BLADE150-MOTO',
        desc: 'BLADE 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'BOBCAT-MOTO', desc: 'BOBCAT', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'CLASSIC125-MOTO',
        desc: 'CLASSIC 125',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150-2-MOTO',
        desc: 'CUSTOM 150 ',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'CUSTOM150BULLDOG-MOTO',
        desc: 'CUSTOM 150 BULLDOG',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'DELIVERYMAX150-MOTO',
        desc: 'DELIVERY MAX 150',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'FIGHTER200-MOTO',
        desc: 'FIGHTER 200',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'GALAXY110-MOTO',
        desc: 'GALAXY 110',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'PARTNER150-MOTO',
        desc: 'PARTNER 150',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'RAPTOR-MOTO', desc: 'RAPTOR', brandCode: 'KURAZAI-MOTO' },
      { code: 'REX-MOTO', desc: 'REX', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'SPARTHA2-MOTO',
        desc: 'SPARTHA2',
        brandCode: 'KURAZAI-MOTO',
      },
      {
        code: 'SURFER125-MOTO',
        desc: 'SURFER 125',
        brandCode: 'KURAZAI-MOTO',
      },
      { code: 'V3-MOTO', desc: 'V3', brandCode: 'KURAZAI-MOTO' },
      {
        code: 'YAKUZA/FIGHTER-MOTO',
        desc: 'YAKUZA /FIGHTER',
        brandCode: 'KURAZAI-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1990': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1991': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1992': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1993': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1994': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1995': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1996': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1997': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1998': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/1999': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2000': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2001': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2002': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2003': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2004': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2006': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2005': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2007': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2008': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2009': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2010': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2011': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2012': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2013': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2014': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2015': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2016': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2017': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2018': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2019': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_LIFAN-MOTO/2020': [
      {
        code: 'LF110-11H-MOTO',
        desc: 'LF 110-11H',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF125T-7R-MOTO',
        desc: 'LF 125T-7R',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150-13-MOTO',
        desc: 'LF 150-13',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF150T-12-MOTO',
        desc: 'LF 150T-12',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200-16C-MOTO',
        desc: 'LF 200-16C',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LF200GY-5A-MOTO',
        desc: 'LF200 GY-5A',
        brandCode: 'LIFAN-MOTO',
      },
      {
        code: 'LIFAN150-5-MOTO',
        desc: 'LIFAN 150-5',
        brandCode: 'LIFAN-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2019': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2020': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2018': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2017': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2016': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2015': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2014': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2013': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2012': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2011': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2010': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2009': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2008': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2007': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2006': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2005': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2004': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2003': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2002': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2001': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/2000': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1999': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1997': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1998': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1995': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1996': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1994': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1993': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1992': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1991': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_MVAGUSTA-MOTO/1990': [
      {
        code: 'BRUTALE1090ABS-MOTO',
        desc: 'BRUTALE 1090 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RABS-MOTO',
        desc: 'BRUTALE 1090 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE1090RRABS-MOTO',
        desc: 'BRUTALE 1090 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE675EASABS-MOTO',
        desc: 'BRUTALE 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800DRAGSTER/RR-MOTO',
        desc: 'BRUTALE 800 DRAGSTER / RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'BRUTALE800EASABS/ITALIA/RR-MOTO',
        desc: 'BRUTALE 800EAS ABS / ITALIA /RR',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3675EASABS-MOTO',
        desc: 'F 3 675 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F3800EASABS/AGO-MOTO',
        desc: 'F 3 800 EAS ABS / AGO',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078CC-MOTO',
        desc: 'F 4 1078 CC',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR3121+1-MOTO',
        desc: 'F 4 1078 RR 312 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F41078RR312-MOTO',
        desc: 'F 4 1078 RR 312',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4312R1+1-MOTO',
        desc: 'F 4 312 R 1+1',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4ABS-MOTO',
        desc: 'F 4 ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RABS-MOTO',
        desc: 'F 4 R ABS ',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'F4RRABS-MOTO',
        desc: 'F 4 RR ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'RIVALE800EASABS-MOTO',
        desc: 'RIVALE 800 EAS ABS',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'STRADA800-MOTO',
        desc: 'STRADA 800',
        brandCode: 'MVAGUSTA-MOTO',
      },
      {
        code: 'TURISMOVELOCE/LUSSO-MOTO',
        desc: 'TURISMO VELOCE / LUSSO',
        brandCode: 'MVAGUSTA-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1991': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1990': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1992': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1994': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1993': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1995': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1996': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1997': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1998': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/1999': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2000': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2001': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2002': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2003': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2004': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2005': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2006': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2007': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2008': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2009': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2010': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2011': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2012': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2013': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2014': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2015': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2016': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2017': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2018': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2019': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PEUGEOT-MOTO/2020': [
      {
        code: 'CITYSTAR-MOTO',
        desc: 'CITY STAR',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'GEOPOLIS300EVO-MOTO',
        desc: 'GEOPOLIS 300 EVO',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'METROPOLIS400/400RS-MOTO',
        desc: 'METROPOLIS 400 / 400 RS',
        brandCode: 'PEUGEOT-MOTO',
      },
      {
        code: 'MP3YOURBAN300IE-MOTO',
        desc: 'MP3 YOURBAN 300IE',
        brandCode: 'PEUGEOT-MOTO',
      },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2019': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2020': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2018': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2017': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2016': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2014': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2015': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2013': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2012': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2010': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2011': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2009': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2008': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2007': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2006': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2005': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2003': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2004': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2002': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2001': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/2000': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1999': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1998': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1997': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1995': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1996': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1994': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1993': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1992': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1991': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_PIAGGIO-MOTO/1990': [
      {
        code: '350LTESPORT-MOTO',
        desc: '350 LTE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: '500HPESPORT-MOTO',
        desc: '500 HPE SPORT',
        brandCode: 'PIAGGIO-MOTO',
      },
      {
        code: 'BEVERLY350SPORTTOURING-MOTO',
        desc: 'BEVERLY 350 SPORT TOURING',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10125-MOTO', desc: 'X10 125', brandCode: 'PIAGGIO-MOTO' },
      {
        code: 'X10350-MOTO',
        desc: 'X10 350 ',
        brandCode: 'PIAGGIO-MOTO',
      },
      { code: 'X10500-MOTO', desc: 'X10 500', brandCode: 'PIAGGIO-MOTO' },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1991': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1990': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1992': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1993': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1994': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1995': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1996': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1997': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1998': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/1999': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2000': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2001': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2002': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2003': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2004': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2005': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2006': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2007': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2008': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2009': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2010': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2011': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2012': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2013': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2014': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2016': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2015': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2017': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2018': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2019': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_POLARIS-MOTO/2020': [
      {
        code: 'ACE570-MOTO',
        desc: 'ACE 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'ACE570SP-MOTO',
        desc: 'ACE 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      { code: 'ACE-MOTO', desc: 'ACE ', brandCode: 'POLARIS-MOTO' },
      {
        code: 'OUTLAW50-MOTO',
        desc: 'OUTLAW 50',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'OUTLAW90-MOTO',
        desc: 'OUTLAW 90 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'PHONIX200-MOTO',
        desc: 'PHONIX 200',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RAANGERXP900-MOTO',
        desc: 'RAANGER XP 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGER570XP-MOTO',
        desc: 'RANGER 570 XP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW570-MOTO',
        desc: 'RANGER CREW 570',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RANGERCREW900-MOTO',
        desc: 'RANGER CREW 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZR900/S900-MOTO',
        desc: 'RZR 900 / S 900',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'RZRXP1000EPS/XP41000EPS-MOTO',
        desc: 'RZR XP 1000 EPS / XP 4 1000 EPS',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SCRAMBLERXP1000-MOTO',
        desc: 'SCRAMBLER XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMAN90-MOTO',
        desc: 'SPORTSMAN 90',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570-MOTO',
        desc: 'SPORTSMAN ETX 570 ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANETX570SP-MOTO',
        desc: 'SPORTSMAN ETX 570 SP',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURING850SP-MOTO',
        desc: 'SPORTSMAN TOURING 850 SP ',
        brandCode: 'POLARIS-MOTO',
      },
      {
        code: 'SPORTSMANTOURINGXP1000-MOTO',
        desc: 'SPORTSMAN TOURING XP 1000',
        brandCode: 'POLARIS-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2019': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2020': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2018': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2017': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2016': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2015': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2014': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2013': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2012': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2010': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2011': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2009': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2008': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2007': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2006': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2005': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2004': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2003': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2002': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2001': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/2000': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1999': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1998': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1997': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1996': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1995': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1994': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1993': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1992': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1991': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_SUZUKI-MOTO/1990': [
      { code: 'AX100-MOTO', desc: 'AX100', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BANDIT1250SABS-MOTO',
        desc: 'BANDIT  1250 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650ABS-MOTO',
        desc: 'BANDIT 650 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650-MOTO',
        desc: 'BANDIT 650',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650SABS-MOTO',
        desc: 'BANDIT 650 S ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BANDIT650S-MOTO',
        desc: 'BANDIT  650 S',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'B-KING-MOTO', desc: 'B-KING', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'BOULEVARDC50-MOTO',
        desc: 'BOULEVARD C50',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDM109R-MOTO',
        desc: 'BOULEVARD M109R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BOULEVARDS40-MOTO',
        desc: 'BOULEVARD S40',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM250-MOTO',
        desc: 'BURGAM 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400-MOTO',
        desc: 'BURGAM 400',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM400SPECIAL-MOTO',
        desc: 'BURGAM 400 SPECIAL',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'BURGAM650-MOTO',
        desc: 'BURGAM 650',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'DR200-MOTO', desc: 'DR200', brandCode: 'SUZUKI-MOTO' },
      { code: 'DR650-MOTO', desc: 'DR 650', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'EN1252A-MOTO',
        desc: 'EN 125 2A',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'EN125-HU-MOTO',
        desc: 'EN 125-HU',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'FD110-MOTO', desc: 'FD 110', brandCode: 'SUZUKI-MOTO' },
      { code: 'GLADIUS-MOTO', desc: 'GLADIUS', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125H-MOTO', desc: 'GN 125H', brandCode: 'SUZUKI-MOTO' },
      { code: 'GN125-MOTO', desc: 'GN 125', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS150R-MOTO', desc: 'GS 150R', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500F-MOTO', desc: 'GS 500 F', brandCode: 'SUZUKI-MOTO' },
      { code: 'GS500-MOTO', desc: 'GS 500', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSR600ABS-MOTO',
        desc: 'GSR 600 ABS',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GSR600-MOTO', desc: 'GSR 600', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'GSX1300RHAYABUSA-MOTO',
        desc: 'GSX 1300 R HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX650F-MOTO',
        desc: 'GSX 650 F',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R1000/MOTOGPEDITION-MOTO',
        desc: 'GSX-R 1000 / MOTOGP EDITION',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-600-MOTO',
        desc: 'GSX-R-600',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'GSX-R-750-MOTO',
        desc: 'GSX-R-750',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'GTS250-MOTO', desc: 'GTS 250', brandCode: 'SUZUKI-MOTO' },
      { code: 'GZ150-MOTO', desc: 'GZ 150', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'HAYABUSA-MOTO',
        desc: 'HAYABUSA',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'HAYATE-MOTO', desc: 'HAYATE', brandCode: 'SUZUKI-MOTO' },
      { code: 'INAZUMA-MOTO', desc: 'INAZUMA', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'INTRUDER250-MOTO',
        desc: 'INTRUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC1800R-MOTO',
        desc: 'INTRUDER C 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERC800VOLUSIA-MOTO',
        desc: 'INTRUDER C 800 VOLUSIA',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM1800R-MOTO',
        desc: 'INTRUDER M 1800 R',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'INTRUDERM800-MOTO',
        desc: 'INTRUDER M 800',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'MARAUDER250-MOTO',
        desc: 'MARAUDER 250',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'RMX450Z-MOTO', desc: 'RMX450Z', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z250-MOTO', desc: 'RM-Z250', brandCode: 'SUZUKI-MOTO' },
      { code: 'RM-Z450-MOTO', desc: 'RM-Z450', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'SFVGLADIUS-MOTO',
        desc: 'SFV GLADIUS',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'SKYDRIVE125-MOTO',
        desc: 'SKYDRIVE 125',
        brandCode: 'SUZUKI-MOTO',
      },
      { code: 'SV650S-MOTO', desc: 'SV 650 S', brandCode: 'SUZUKI-MOTO' },
      { code: 'SX4-MOTO', desc: 'SX4', brandCode: 'SUZUKI-MOTO' },
      {
        code: 'V-STROM1000-MOTO',
        desc: 'V-STROM 1000',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650ABS/ADVENTURE-MOTO',
        desc: 'V-STROM 650 ABS / ADVENTURE',
        brandCode: 'SUZUKI-MOTO',
      },
      {
        code: 'V-STROM650-MOTO',
        desc: 'V-STROM 650',
        brandCode: 'SUZUKI-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1990': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1991': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1992': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1993': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1994': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1995': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1996': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1997': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1998': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/1999': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2000': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2001': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2002': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2003': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2004': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2005': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2006': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2007': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2008': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2009': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2010': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2011': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2012': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2013': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2014': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2015': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2016': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2017': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2018': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2019': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TRUMPH-MOTO/2020': [
      {
        code: 'AMERICA/LT-MOTO',
        desc: 'AMERICA / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLEAMERICA-MOTO',
        desc: 'BONNEVILLE AMERICA',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLE-MOTO',
        desc: 'BONNEVILLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLESE-MOTO',
        desc: 'BONNEVILLE SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'BONNEVILLET100-MOTO',
        desc: 'BONNEVILLE T 100',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'COMMANDER/LT-MOTO',
        desc: 'COMMANDER / LT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675/R-MOTO',
        desc: 'DAYTONA 675 / R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675-MOTO',
        desc: 'DAYTONA 675',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'DAYTONA675SE-MOTO',
        desc: 'DAYTONA 675 SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIICLADDIC-MOTO',
        desc: 'ROCKET III CLADDIC',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIII-MOTO',
        desc: 'ROCKET III',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIIROADSTER-MOTO',
        desc: 'ROCKET III ROADSTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'ROCKETIIITOURING-MOTO',
        desc: 'ROCKET III TOURING',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SCRAMBLER-MOTO',
        desc: 'SCRAMBLER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDMASTER-MOTO',
        desc: 'SPEEDMASTER',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRIPLER-MOTO',
        desc: 'SPEED TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050I-MOTO',
        desc: 'SPEED TRPLE 1050i',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPEEDTRPLE1050ISE-MOTO',
        desc: 'SPEED TRPLE 1050i SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTSTABS-MOTO',
        desc: 'SPRINT ST ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'SPRINTST-MOTO',
        desc: 'SPRINT ST',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLE-MOTO',
        desc: 'STREET TRIPLE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'STREETTRIPLER-MOTO',
        desc: 'STREET TRIPLE R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THRUXTON1200R-MOTO',
        desc: 'THRUXTON 1200 R',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRDABS-MOTO',
        desc: 'THUNDERBIRD ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRD-MOTO',
        desc: 'THUNDERBIRD',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'THUNDERBIRTHSTORM-MOTO',
        desc: 'THUNDERBIRTH STORM',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XC/XCX-MOTO',
        desc: 'TIGER 800 XC / XCx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGER800XR/XRX-MOTO',
        desc: 'TIGER 800 XR / XRx',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABS-MOTO',
        desc: 'TIGER ABS',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGERABSSE-MOTO',
        desc: 'TIGER ABS SE',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TIGEREXPLORER/XC-MOTO',
        desc: 'TIGER EXPLORER / XC',
        brandCode: 'TRUMPH-MOTO',
      },
      { code: 'TIGER-MOTO', desc: 'TIGER', brandCode: 'TRUMPH-MOTO' },
      {
        code: 'TIGERSPORT-MOTO',
        desc: 'TIGER SPORT',
        brandCode: 'TRUMPH-MOTO',
      },
      {
        code: 'TROPHYSE-MOTO',
        desc: 'TROPHY SE',
        brandCode: 'TRUMPH-MOTO',
      },
    ],
    'ES-MX_MOTO_TVS-MOTO/2020': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2019': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2018': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2017': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2016': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2015': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2014': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2013': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2012': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2011': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2009': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2010': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2007': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2008': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2006': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2005': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2004': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2002': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2003': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2001': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/2000': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1999': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1998': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1996': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1997': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1995': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1994': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1993': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1992': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1991': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_TVS-MOTO/1990': [
      { code: 'FLAME125-MOTO', desc: 'FLAME125', brandCode: 'TVS-MOTO' },
      { code: 'RTR160-MOTO', desc: 'RTR 160 ', brandCode: 'TVS-MOTO' },
      { code: 'RTR180-MOTO', desc: 'RTR 180', brandCode: 'TVS-MOTO' },
      {
        code: 'RTR180TOURING-MOTO',
        desc: 'RTR 180 TOURING',
        brandCode: 'TVS-MOTO',
      },
      { code: 'STAR100-MOTO', desc: 'STAR 100 ', brandCode: 'TVS-MOTO' },
      { code: 'STAR125-MOTO', desc: 'STAR 125', brandCode: 'TVS-MOTO' },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1991': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1990': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1992': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1993': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1994': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1996': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1995': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1997': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1998': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/1999': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2012': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2013': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2014': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2015': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2011': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2016': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2017': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2018': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2019': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VENTO-MOTO/2020': [
      {
        code: 'AVALANCHEEX100-MOTO',
        desc: 'AVALANCHE EX 100',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'AVALANCHEGT5-MOTO',
        desc: 'AVALANCHE GT 5',
        brandCode: 'VENTO-MOTO',
      },
      { code: 'COLT250-MOTO', desc: 'COLT 250', brandCode: 'VENTO-MOTO' },
      { code: 'HOTROD-MOTO', desc: 'HOT ROD', brandCode: 'VENTO-MOTO' },
      {
        code: 'PHANTOMR5-MOTO',
        desc: 'PHANTOM R5',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN250-MOTO',
        desc: 'REBELLIAN 250',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REBELLIAN-MOTO',
        desc: 'REBELLIAN',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'REPTILE400-MOTO',
        desc: 'REPTILE 400',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'V-THUNDER-MOTO',
        desc: 'V-THUNDER',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN125-MOTO',
        desc: 'WORKMAN 125',
        brandCode: 'VENTO-MOTO',
      },
      {
        code: 'WORKMAN150R-MOTO',
        desc: 'WORKMAN 150 R',
        brandCode: 'VENTO-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2019': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2018': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2020': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2017': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2016': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2015': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2014': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2013': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2012': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2011': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2010': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2008': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2009': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2007': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2006': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2005': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2004': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2003': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2002': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2001': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/2000': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1999': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1998': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1997': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1996': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1995': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1994': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1992': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1993': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1991': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_VESPA-MOTO/1990': [
      { code: 'GTS250-2-MOTO', desc: 'GTS 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'GTS300I.E-MOTO',
        desc: 'GTS 300 i.e',
        brandCode: 'VESPA-MOTO',
      },
      {
        code: 'GTS300IESUPER-MOTO',
        desc: 'GTS 300 IE SUPER',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'GTV250-MOTO', desc: 'GTV 250', brandCode: 'VESPA-MOTO' },
      {
        code: 'LX-150I.E./LXV-MOTO',
        desc: 'LX-150 i.e. / LXV',
        brandCode: 'VESPA-MOTO',
      },
      { code: 'LX150-MOTO', desc: 'LX 150', brandCode: 'VESPA-MOTO' },
      { code: 'LXV150-MOTO', desc: 'LXV 150', brandCode: 'VESPA-MOTO' },
      {
        code: 'PRIMAVERA125-MOTO',
        desc: 'PRIMAVERA 125',
        brandCode: 'VESPA-MOTO',
      },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/1997': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/1998': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/1999': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2000': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2001': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2002': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2003': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2004': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2005': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2006': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2007': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2008': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2009': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2010': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2011': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2012': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2013': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2014': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2015': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2016': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2017': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2018': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2019': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
    'ES-MX_MOTO_YAMAHA-MOTO/2020': [
      { code: 'BWS125-MOTO', desc: "BW'S 125", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'BWS125XMOTARD-MOTO',
        desc: "BW'S 125X MOTARD ",
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'BWS-MOTO', desc: "BW's", brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FAZER16-MOTO',
        desc: 'FAZER 16',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'FAZER250-MOTO',
        desc: 'FAZER 250',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FINO-MOTO', desc: 'FINO', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'FJR1300A-MOTO',
        desc: 'FJR1300A',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'FZ-07-MOTO', desc: 'FZ-07', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ-09-MOTO', desc: 'FZ-09', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ16-S-MOTO', desc: 'FZ16 - S', brandCode: 'YAMAHA-MOTO' },
      { code: 'FZ6R-MOTO', desc: 'FZ6 R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'GRANDAXIS100-MOTO',
        desc: 'GRAND AXIS 100',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'JOG100-MOTO', desc: 'JOG 100', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'MAJESTY400-MOTO',
        desc: 'MAJESTY 400',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'NOUVO-MOTO', desc: 'NOUVO', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW50-MOTO', desc: 'PW 50', brandCode: 'YAMAHA-MOTO' },
      { code: 'PW80-MOTO', desc: 'PW 80', brandCode: 'YAMAHA-MOTO' },
      { code: 'R15-MOTO', desc: 'R15', brandCode: 'YAMAHA-MOTO' },
      { code: 'RAYZ-MOTO', desc: 'RAY Z', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'SMAX150-MOTO',
        desc: 'SMAX 150',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'SR250-MOTO', desc: 'SR 250', brandCode: 'YAMAHA-MOTO' },
      { code: 'SZ-R-MOTO', desc: 'SZ-R', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T105ECYPTON-MOTO',
        desc: 'T 105 E CYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'T105ES-MOTO', desc: 'T 105 ES', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'T110CRYPTON-MOTO',
        desc: 'T110 CRYPTON',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'T-MAX530-MOTO',
        desc: 'T-MAX 530',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TRICITY-MOTO', desc: 'TRICITY', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'TT-R230-MOTO',
        desc: 'TT-R 230',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'TTR-50-MOTO', desc: 'TTR-50', brandCode: 'YAMAHA-MOTO' },
      { code: 'TTX-MOTO', desc: 'TTX', brandCode: 'YAMAHA-MOTO' },
      { code: 'VIKING-MOTO', desc: 'VIKING', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR250F-MOTO', desc: 'WR 250 F', brandCode: 'YAMAHA-MOTO' },
      { code: 'WR450F-MOTO', desc: 'WR 450 F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XA125CYGNUSZ-MOTO',
        desc: 'XA 125 CYGNUS Z',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'X-CITY250-MOTO',
        desc: 'X-CITY 250',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XJ6DIVERSION5-MOTO',
        desc: 'XJ6 DIVERSION 5',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT1200ZSUPERTENERE-MOTO',
        desc: 'XT 1200 Z SUPER TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660R/X-MOTO',
        desc: 'XT660 R / X',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XT660ZTENERE-MOTO',
        desc: 'XT 660 Z TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ125SE-MOTO',
        desc: 'XTZ 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XTZ250LANDER-MOTO',
        desc: 'XTZ 250 LANDER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'XTZ250-MOTO', desc: 'XTZ 250 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'XV1300STRYKER-MOTO',
        desc: 'XV 1300 STRYKER',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV250VSTAR-MOTO',
        desc: 'XV 250 V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XV950RBOLT-MOTO',
        desc: 'XV 950R BOLT',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1100AVSTARCLASSIC-MOTO',
        desc: 'XVS 1100A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS1300AVSTAR-MOTO',
        desc: 'XVS 1300A V STAR',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'XVS650AVSTARCLASSIC-MOTO',
        desc: 'XVS 650A V STAR CLASSIC',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YAMAHAXT250TENERE-MOTO',
        desc: 'YAMAHA XT 250 TENERE',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YB125ENTICER-MOTO',
        desc: 'YB 125 ENTICER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YB125-MOTO', desc: 'YB 125', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YBR125CEXPRESS-MOTO',
        desc: 'YBR 125 C EXPRESS',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125E-MOTO',
        desc: 'YBR 125 E ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125R-MOTO',
        desc: 'YBR 125 R',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YBR125SE-MOTO',
        desc: 'YBR 125 SE',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YFM450-MOTO', desc: 'YFM 450 ', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YFMGRIZZLY350-MOTO',
        desc: 'YFM GRIZZLY 350 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY450-MOTO',
        desc: 'YFM GRIZZLY 450',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY550-MOTO',
        desc: 'YFM GRIZZLY 550 ',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YFMGRIZZLY700-MOTO',
        desc: 'YFM GRIZZLY 700',
        brandCode: 'YAMAHA-MOTO',
      },
      {
        code: 'YS250FAQZER-MOTO',
        desc: 'YS 250 FAQZER',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ250F-MOTO', desc: 'YZ250F', brandCode: 'YAMAHA-MOTO' },
      {
        code: 'YZ450F-2-MOTO',
        desc: 'YZ 450 F',
        brandCode: 'YAMAHA-MOTO',
      },
      { code: 'YZ450F-MOTO', desc: 'YZ450F', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R1M-MOTO', desc: 'YZF-R1M', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZFR1-MOTO', desc: 'YZF R1', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R3-MOTO', desc: 'YZF-R3', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZF-R6-MOTO', desc: 'YZF-R6', brandCode: 'YAMAHA-MOTO' },
      { code: 'YZRR6R-MOTO', desc: 'YZR R6R', brandCode: 'YAMAHA-MOTO' },
    ],
  },
  vehicleTypes: {
    'ES-MX': [
      { code: '/browse/cars', desc: 'Autos' },
      { code: '/browse/vans', desc: 'Vehiculos de carga ligera' },
      { code: 'MOTO', desc: 'Motocicleta' },
    ],
  },
  expire: {
    vehicleBrands: 1650563961356 + OTP_EXPIRE_VEHICLEBRANDS,
    vehicleBrandYears: 1650563961356 + OTP_EXPIRE_VEHICLEBRANDYEARS,
    vehicleModels: 1650563961356 + OTP_EXPIRE_VEHICLEMODELS,
  },
  cache: 'expire',
};

const cache = {
  vehicleTypes: {},
  vehicleBrandYears: {},
  vehicleBrands: {},
  vehicleModels: {},
  expire: {
    vehicleBrands: 1650563961356 + OTP_EXPIRE_VEHICLEBRANDS,
    vehicleBrandYears: 1650563961356 + OTP_EXPIRE_VEHICLEBRANDYEARS,
    vehicleModels: 1650563961356 + OTP_EXPIRE_VEHICLEMODELS,
  },
  cache: 'expire'
}
export default cache;
